import { v4 } from 'uuid';
import { action, makeObservable, observable, toJS } from 'mobx';
import {
    IStreamViewPayload,
    IStreamViewState,
    IStreamViewUser,
    LogoPositionsEnum,
    PatternsEnum,
    StreamViewActionsEnum,
} from '@common-types';
import { ADMIN_SS_ID, DEFAULT_CONSTRAINTS } from '@constants';
import { getPatternUsersCount, getUserConstraints, isMobile } from '@utils';
import { Base } from './base-store';

export class StreamViewStore extends Base {
    state: IStreamViewState = {
        primaryColor: '',
        showNames: false,
        users: [],
        selectedPattern: PatternsEnum.OneUser,
        logoPosition: LogoPositionsEnum.TopRight,
        logoImage: '',
        backgroundImage: '',
        isSimpleMode: true,
    };
    updateId: string = '';

    constructor(rootStore) {
        super(rootStore);

        makeObservable(this, {
            state: observable,
            updateId: observable,
            setUpdatedStreamViewState: action,
            updateStateForAll: action,
            changeBackgroundImage: action,
            changeLogoImage: action,
            changeLogoPosition: action,
            changeSelectedPattern: action,
            addUser: action,
            removerUser: action,
            enableUser: action,
            disableUser: action,
            changeShowNames: action,
            changePrimaryColor: action,
            swapUsers: action,
        });

        this.state.isSimpleMode = this.rootStore.appStore.isSimpleMode;
    }

    swapUsers = (id: string, userPosition: number) => {
        const { users } = this.state;
        const firstUser = users.find((user) => id === user.id);
        const firstIndex = users.findIndex((user) => id === user.id);
        const secondUser = users[userPosition];

        if (firstUser && secondUser && firstIndex !== userPosition) {
            users[firstIndex] = secondUser;
            users[userPosition] = firstUser;
            this.updateStateForAll(StreamViewActionsEnum.SwapUsers, { id, userPosition });

            if (this.rootStore.appStore.isUserAuthorized && this.rootStore.appStore.isSimpleMode) {
                this.rootStore.simpleModeStore.applyPattern(this.rootStore.simpleModeStore.currentPattern);
            }
        }
    };

    changeShowNames = (show: boolean) => {
        if (this.state.showNames !== show) {
            this.state.showNames = show;
            this.updateStateForAll(StreamViewActionsEnum.ToggleNames, { show });
        }
    };

    changePrimaryColor = (primaryColor: string) => {
        if (this.state.primaryColor !== primaryColor) {
            this.state.primaryColor = primaryColor;
            this.updateStateForAll(StreamViewActionsEnum.ChangePrimaryColor, { primaryColor });
        }
    };

    setUpdatedStreamViewState = (info: {
        state: IStreamViewState;
        action: StreamViewActionsEnum;
        data: IStreamViewPayload
    }) => {
        const { state, action, data = {} } = info;
        this.state = state;
        this.updateId = v4();
        this.checkActionsAndApplyConstraints(action, data);
    };

    updateStateForAll = (action: StreamViewActionsEnum, data: IStreamViewPayload = {}) => {
        this.updateId = v4();

        if (this.rootStore.appStore.isUserAuthorized) {
            this.rootStore.usersConferenceStore.socket.emit('update-stream-state', {
                state: toJS(this.state),
                action,
                data,
            });

            this.checkActionsAndApplyConstraints(action, data);
        }
    };

    checkActionsAndApplyConstraints = (action: StreamViewActionsEnum, data: IStreamViewPayload = {}) => {
        if (
            action === StreamViewActionsEnum.ChangeSelectedPattern ||
            action === StreamViewActionsEnum.SwapUsers ||
            action === StreamViewActionsEnum.EnableUser ||
            action === StreamViewActionsEnum.DisableUser ||
            action === StreamViewActionsEnum.RemoverUser ||
            action === StreamViewActionsEnum.InsertUser ||
            action === StreamViewActionsEnum.AddUser
        ) {
            //if (!isMobile()) {
                // this.applyConstraintsForUser(action, data);
            //}
        }
    };

    changeBackgroundImage = (img: string) => {
        if (this.state.backgroundImage !== img) {
            this.state.backgroundImage = img;
            this.updateStateForAll(StreamViewActionsEnum.ChangeBackgroundImage, { img });
        }
    };

    changeLogoImage = (img: string) => {
        if (this.state.logoImage !== img) {
            this.state.logoImage = img;
            this.updateStateForAll(StreamViewActionsEnum.ChangeLogoImage, { img });
        }
    };

    changeLogoPosition = (position: LogoPositionsEnum) => {
        if (this.state.logoPosition !== position) {
            this.state.logoPosition = position;
            this.updateStateForAll(StreamViewActionsEnum.ChangeLogoPosition, { position });
        }
    };

    changeSelectedPattern = (pattern: PatternsEnum) => {
        if (this.state.selectedPattern !== pattern) {
            this.state.selectedPattern = pattern;
            this.updateStateForAll(StreamViewActionsEnum.ChangeSelectedPattern, { pattern });
        }
    };

    addUser = (user: IStreamViewUser) => {
        if (this.state.users.every(({ id }) => user.id !== id)) {
            this.state.users.push(user);
            this.updateStateForAll(StreamViewActionsEnum.AddUser, { user });
        }
    };

    insertUser = (user: IStreamViewUser, position: number) => {
        if (this.state.users.every(({ id }) => user.id !== id)) {
            this.state.users.splice(position, 0, user);
            this.updateStateForAll(StreamViewActionsEnum.InsertUser, { user });
        }
    };

    removerUser = (id: string) => {
        if (this.state.users.some((user: IStreamViewUser) => user.id === id)) {
            this.state.users = this.state.users.filter((user: IStreamViewUser) => user.id !== id);
            this.updateStateForAll(StreamViewActionsEnum.RemoverUser, { id });
        }
    };

    enableUser = (id: string) => {
        const user = this.state.users.find((user: IStreamViewUser) => user.id === id);

        if (user && !user.isEnabled) {
            user.isEnabled = true;
            this.updateStateForAll(StreamViewActionsEnum.EnableUser, { id });
        }
    };

    disableUser = (id: string) => {
        const user = this.state.users.find((user: IStreamViewUser) => user.id === id);

        if (user && user.isEnabled) {
            user.isEnabled = false;
            this.updateStateForAll(StreamViewActionsEnum.DisableUser, { id });
        }
    };

    applyConstraintsForUser = (action: StreamViewActionsEnum, data: IStreamViewPayload = {}) => {
        const { isUserAuthorized } = this.rootStore.appStore;
        const { currentUserId } = this.rootStore.usersConferenceStore;
        const { users, selectedPattern } = this.state;
        const userIndex = users
            .filter(({ isEnabled }) => isEnabled)
            .findIndex((user: IStreamViewUser) => user.id === currentUserId);
        const user = users.find((user: IStreamViewUser) => user.id === currentUserId);
        const shownUsersCount = getPatternUsersCount(selectedPattern);
        const isUserShown = userIndex > -1 && userIndex <= (shownUsersCount - 1);
        let constraints = DEFAULT_CONSTRAINTS;

        if (!user) {
            return;
        }

        if (isUserAuthorized && (data?.id === ADMIN_SS_ID || data?.id === currentUserId)) {
            const ssUser = users.find((user: IStreamViewUser) => user.id === ADMIN_SS_ID);
            let ssConstraints = DEFAULT_CONSTRAINTS;

            if (ssUser && ssUser.isEnabled) {
                const ssUserIndex = users
                    .filter(({ isEnabled }) => isEnabled)
                    .findIndex((user: IStreamViewUser) => user.id === ADMIN_SS_ID);
                const isSSUserShown = ssUserIndex > -1 && ssUserIndex <= (shownUsersCount - 1);

                if (isSSUserShown) {
                    ssConstraints = getUserConstraints(
                        selectedPattern,
                        userIndex,
                        this.rootStore.simpleModeStore.defaultSSVideoMediaTrackConstraints,
                    );
                }
            }

            this.rootStore.simpleModeStore.currentSSVideoMediaTrackConstraints = ssConstraints;
            this.rootStore.usersConferenceStore.applyConstraintSS(ssConstraints);
            console.log('SS CONSTRAINTS', this.rootStore.simpleModeStore.currentSSVideoMediaTrackConstraints);
        }

        if (user.isEnabled && isUserShown) {
            constraints = getUserConstraints(selectedPattern, userIndex);
        }

        if (isUserAuthorized) {
            this.rootStore.simpleModeStore.currentVideoMediaTrackConstraints = constraints;
            console.log('ADMIN CONSTRAINTS', this.rootStore.simpleModeStore.currentVideoMediaTrackConstraints);
        } else {
            if (this.rootStore.guestStore.isScreenShare && user.isEnabled && isUserShown) {
                this.rootStore.guestStore.currentVideoMediaTrackConstraints = getUserConstraints(
                    selectedPattern,
                    userIndex,
                    this.rootStore.guestStore.defaultSSMediaTrackConstraints,
                );
            } else {
                this.rootStore.guestStore.currentVideoMediaTrackConstraints = constraints;
            }

            console.log('GUEST CONSTRAINTS', this.rootStore.guestStore.currentVideoMediaTrackConstraints);
        }

        this.rootStore.usersConferenceStore.applyConstraint(constraints);
    };
}
