import React from 'react';
import { ILayer } from '@common-types';
import { VideoWithDevice } from '@components';
import './styles.scss';

interface IProps {
    layer: ILayer;
    setCameraRation: (width: number, height: number) => void;
}

export class CameraElement extends React.Component<IProps> {
    render(): React.ReactNode {
        const { layer, setCameraRation } = this.props;

        return (
            <div className="camera-element">
                <VideoWithDevice
                    id={layer.id}
                    withAudio={false}
                    device={layer.specificData?.device}
                    setVideoRatio={setCameraRation}
                />
            </div>
        );
    }
}
