import { MessageDescriptor, IntlShape } from 'react-intl';
import * as H from 'history';

export class DependencyInjector {
    private static instance: DependencyInjector;
    private _intl: IntlShape;
    private _history: H.History<H.LocationState>;

    private constructor() {}

    static getInstance(): DependencyInjector {
        if (!DependencyInjector.instance) {
            DependencyInjector.instance = new DependencyInjector();
        }

        return DependencyInjector.instance;
    }

    set intl(intl: IntlShape) {
        this._intl = intl;
    }

    get intl(): IntlShape {
        return this._intl;
    }

    set history(history: H.History<H.LocationState>) {
        this._history = history;
    }

    get history(): H.History<H.LocationState> {
        return this._history;
    }

    formatMessage(message: MessageDescriptor): string {
        return this._intl.formatMessage(message);
    }

    fm(id: string): string {
        return this.formatMessage({ id });
    }
}
