import { LogoPositionsEnum, PatternsEnum } from '@common-types';
import {
    FULL_SCREEN_CONSTRAINTS,
    HALF_SCREEN_CONSTRAINTS,
    SMALL_SCREEN_CONSTRAINTS,
    SUPER_SMALL_SCREEN_CONSTRAINTS,
    DEFAULT_CONSTRAINTS,
} from '@constants';

export const getPatternUsersCount = (selectedPattern: PatternsEnum): number => {
    switch (selectedPattern) {
        case PatternsEnum.OneUser: return 1;
        case PatternsEnum.TwoUsers: return 2;
        case PatternsEnum.TwoUsersOneSmall: return 2;
        case PatternsEnum.ThreeUsers: return 3;
        case PatternsEnum.FiveUsers: return 5;
        case PatternsEnum.SixUsers: return 6;
        default: return 1;
    }
};

export const getPatternClass = (selectedPattern: PatternsEnum): string => {
    switch (selectedPattern) {
        case PatternsEnum.OneUser: return 'one-user';
        case PatternsEnum.TwoUsers: return 'two-users';
        case PatternsEnum.TwoUsersOneSmall: return 'two-users-small';
        case PatternsEnum.ThreeUsers: return 'three-users';
        case PatternsEnum.FiveUsers: return 'five-users';
        case PatternsEnum.SixUsers: return 'six-users';
        default: return '';
    }
};

export const getLogoClass = (logoPosition: LogoPositionsEnum): string => {
    switch (logoPosition) {
        case LogoPositionsEnum.TopRight: return 'top-right';
        case LogoPositionsEnum.TopLeft: return 'top-left';
        case LogoPositionsEnum.BottomLeft: return 'bottom-left';
        case LogoPositionsEnum.BottomRight: return 'bottom-right';
        default: return '';
    }
};

export const getBackground = (backgroundImage: string): string => {
    return backgroundImage ? `url(${backgroundImage})` : '#000';
};

export const getUserConstraints = (
    selectedPattern: PatternsEnum,
    userPosition: number,
    fullScreenConstraint: MediaTrackConstraints = FULL_SCREEN_CONSTRAINTS,
): MediaTrackConstraints => {
    switch (selectedPattern) {
        case PatternsEnum.OneUser: {
            return fullScreenConstraint;
        }
        case PatternsEnum.TwoUsers: {
            return fullScreenConstraint;
        }
        case PatternsEnum.TwoUsersOneSmall: {
            if (userPosition === 0) {
                return fullScreenConstraint;
            } else {
                return SMALL_SCREEN_CONSTRAINTS;
            }
        }
        case PatternsEnum.ThreeUsers: {
            if (userPosition === 0) {
                return fullScreenConstraint;
            } else {
                return HALF_SCREEN_CONSTRAINTS;
            }
        }
        case PatternsEnum.FiveUsers: {
            if (userPosition === 0) {
                return fullScreenConstraint
            } else {
                return SUPER_SMALL_SCREEN_CONSTRAINTS;
            }
        }
        case PatternsEnum.SixUsers: {
            if (userPosition === 0) {
                return fullScreenConstraint
            } else {
                return SUPER_SMALL_SCREEN_CONSTRAINTS;
            }
        }
        default: return DEFAULT_CONSTRAINTS;
    }
};
