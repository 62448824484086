import React from 'react';
import { observer, inject } from 'mobx-react';
import { Input } from 'antd';
import { observable, action, makeObservable } from 'mobx';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 } from 'uuid';
import { AdvanceModeStore } from '@services';
import { PlusCircleIcon, EditPenIcon, TrashIcon, UserIcon } from '@assets/icons';
import { IScene } from '@common-types';
import './styles.scss';

interface IProps {
    AdvanceModeStore?: AdvanceModeStore;
}

@inject('AdvanceModeStore')
@observer
export class AdvanceScenes extends React.Component<IProps> {
    sceneToRename: IScene = null;
    sceneName: string = '';

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            sceneName: observable,
            sceneToRename: observable,
            changeName: action,
            handleRenameClick: action,
            onDoubleClick: action,
            onInputBlur: action,
        });
    }

    handleRenameClick = (e, scene: IScene) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        e.stopImmediatePropagation && e.stopImmediatePropagation();

        this.sceneToRename = scene;
        this.sceneName = scene.name;
    };

    changeName = (e) => {
        this.sceneName = e.target.value;
    };

    onInputBlur = () => {
        if (this.sceneToRename) {
            this.props.AdvanceModeStore.changeSceneName(this.sceneToRename, this.sceneName);
        }

        this.sceneName = '';
        this.sceneToRename = null;
    };

    onDoubleClick = (e, scene: IScene) => {
        this.handleRenameClick(e, scene);
    };

    addScene = () => {
        this.onInputBlur();
        const { selectedProject, addScene } = this.props.AdvanceModeStore;

        addScene({
            id: v4(),
            layers: [],
            name: `Scene ${selectedProject.scenes.length + 1}`,
            index: selectedProject.scenes.length,
            node: null,
        });
    };

    selectScene = (scene: IScene) => {
        const { selectedScene } = this.props.AdvanceModeStore;

        if (scene.id !== selectedScene?.id) {
            this.props.AdvanceModeStore.selectScene(scene);
        }

        this.onInputBlur();
    };

    deleteScene = (e, scene: IScene) => {
        this.onInputBlur();
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        e.stopImmediatePropagation && e.stopImmediatePropagation();

        this.props.AdvanceModeStore.removeScene(scene.id);
    };

    onDragEnd = (result) => {
        this.onInputBlur();
        const { swapScenes } = this.props.AdvanceModeStore;

        if (!result.destination || result.source.index === result.destination.index) {
            return;
        }

        swapScenes(result.source.index, result.destination.index);
    };

    onKeyDown = (e) => {
        if (e.code === 'Enter') {
            this.onInputBlur();
        }
    };

    render(): React.ReactNode {
        const {
            selectedProject,
            selectedScene,
            sceneUpdateId,
        } = this.props.AdvanceModeStore!;
        sceneUpdateId;
        this.sceneName;
        this.sceneToRename;

        if (!selectedProject) {
            return null
        }

        const { scenes } = selectedProject;

        return (
            <div className='advance-scenes'>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="scenes">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className='advance-scenes__list'
                                    style={{
                                        height: `${scenes.length * 110 + 4}px`
                                    }}
                                >
                                    {
                                        scenes.map((scene: IScene, i: number) => {
                                            return (
                                                <Draggable key={scene.id} draggableId={`${scene.id}`} index={scene.index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            key={scene.id}
                                                            className={`advance-scenes__scene ${scene.id === selectedScene?.id ? 'selected' : '' }`}
                                                            onClick={() => this.selectScene(scene)}
                                                            style={{
                                                                ...provided.draggableProps?.style,
                                                                background: scene.previewImage ? `linear-gradient(0deg, rgba(22, 28, 33, 0.7), rgba(22, 28, 33, 0.7)), url(${scene.previewImage})` : '#39444D'
                                                            }}
                                                        >
                                                            <div className='advance-scenes__scene-actions'>
                                                                {
                                                                    i > 0
                                                                        ? (
                                                                            <div
                                                                                className='advance-scenes__delete'
                                                                                onClick={(e) => this.deleteScene(e, scene)}
                                                                            >
                                                                                <TrashIcon />
                                                                            </div>
                                                                        )
                                                                        : null
                                                                }
                                                            </div>
                                                            {
                                                                !scene.previewImage ? (
                                                                    <div className="advance-scenes__user-icon">
                                                                        <UserIcon />
                                                                    </div>
                                                                ) : null
                                                            }
                                                            <div className='advance-scenes__name'>
                                                                <div
                                                                    className='advance-scenes__text'

                                                                    onDoubleClick={(e) => this.onDoubleClick(e, scene)}
                                                                >
                                                                    {
                                                                        this.sceneToRename?.id === scene.id
                                                                            ? (
                                                                                <Input
                                                                                    onKeyDown={this.onKeyDown}
                                                                                    className="advance-name-input"
                                                                                    autoFocus={true}
                                                                                    value={this.sceneName}
                                                                                    onChange={this.changeName}
                                                                                    onBlur={this.onInputBlur}
                                                                                />
                                                                            )
                                                                            : scene.name
                                                                    }
                                                                </div>
                                                                <div
                                                                    className='advance-scenes__edit-icon'
                                                                    onClick={(e) => this.handleRenameClick(e, scene)}
                                                                >
                                                                    <EditPenIcon />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>

                                            );
                                        })
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                <div className='advance-scenes__add-scene' onClick={this.addScene}>
                    <PlusCircleIcon />
                </div>
            </div>
        );
    }
}
