export enum Routes {
    SelectMedia = '/select-media',
    SimpleMode = '/simple',
    GuestMode = '/guest',
    AdvancedMode = '/advanced',
    Login = '/login',
    Register = '/register',
    ShareWebCam = '/share-web-cam',
    SharedWebCam = '/shared-web-cam',
    PasswordReset = '/password-reset',
    RequestPasswordReset = '/request-password-reset',
    Main = '/',
}

export enum VideoResolutions {
    High = 'High Definition (720p @ 30 fps)',
}

export enum NavBarTabs {
    Branding = 'branding',
    Captions = 'captions',
    Chat = 'chat',
}

export enum RolesEnum {
    User = 'User',
    Streamer = 'Streamer',
    AdvancedStreamer = 'AdvancedStreamer',
}

export enum ApiRoutes {
    Login = '/api/login',
    Register = '/api/register',
    Confirm = '/api/confirm',
    RefreshToken = '/api/refresh-token',
    User = '/api/user',
    Projects = '/api/projects',
    Images = '/api/images',
    ResetPassword = '/api/reset-password',
    RequestResetPassword = '/api/request-reset-password',
    Scenes = '/api/scenes',
    Layers = '/api/layers',
}
