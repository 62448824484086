import React from 'react';
import { Drawer } from 'antd';
import { observer } from 'mobx-react';
import { observable, action, makeObservable, runInAction } from 'mobx';
import { CloseIcon, ReloadIcon, PlusCircleIcon } from '@assets/icons';
import { DeviceTypes } from '@common-types';
import './styles.scss';

interface IProps {
    isDrawerOpen: boolean;
    closeDrawer: () => void;
    selectMicro: (device: MediaDeviceInfo) => Promise<void>;
}

@observer
export class MicrosDrawer extends React.Component<IProps> {
    micros: MediaDeviceInfo[] = [];

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            micros: observable,
            setDevices: action,
        });
    }

    componentDidMount(): void {
        this.setDevices();
    }

    setDevices = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();

            runInAction(() => {
                this.micros = devices.filter(({ kind }) => {
                    return kind === DeviceTypes.Audio;
                });
            });

        } catch (e) {
            console.error(e);
        }
    };

    reloadList = () => {
        this.setDevices();
    };

    render(): React.ReactNode {
        const { isDrawerOpen, closeDrawer, selectMicro } = this.props;

        return (
            <div className="micros-drawer">
                <Drawer
                    className='micros-drawer__drawer'
                    onClose={closeDrawer}
                    visible={isDrawerOpen}
                    closable={true}
                    destroyOnClose={true}
                    width={380}
                    closeIcon={<CloseIcon />}
                    placement='right'
                    title={(
                        <div className="micros-drawer__title">
                            Audio
                        </div>
                    )}
                >
                    <div className="micros-drawer__reload">
                        <div>
                            Browser
                        </div>
                        <div onClick={this.reloadList} style={{ cursor: 'pointer' }}>
                            <ReloadIcon />
                        </div>
                    </div>
                    <div className="micros-drawer__list">
                        {
                            this.micros.map((device: MediaDeviceInfo) => {
                                return (
                                    <div
                                        key={device.deviceId}
                                        className="micros-drawer__device"
                                        onClick={() => selectMicro(device)}
                                    >
                                        <div className="micros-drawer__name">
                                            {device.label || 'Micro'}
                                        </div>
                                        <div style={{ height: '24px' }}>
                                            <PlusCircleIcon />
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </Drawer>
            </div>
        );
    }
}
