import React from 'react';
import { observer } from 'mobx-react';
import './styles.scss';

interface IProps {
    setCanvasRef: (canvas: HTMLCanvasElement) => void;
}

@observer
export class BrowserCanvas extends React.Component<IProps> {
    canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();

    componentDidMount(): void {
        this.props.setCanvasRef(this.canvasRef?.current);
    }

    render(): React.ReactNode {
        return (
            <div className="custom-browser-canvas">
                <canvas className='custom-browser-canvas__canvas' ref={this.canvasRef} />
            </div>
        );
    }
}
