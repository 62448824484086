import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';
import { ILayer, ITextSettings } from '@common-types';
import './styles.scss';

interface IProps {
    textClasses: string[];
    layer: ILayer;
    changeText: (text: string) => void;
    textSettings: ITextSettings;
}

@observer
export class TextElement extends React.Component<IProps> {
    elementRef: React.RefObject<HTMLDivElement> = React.createRef();
    text: string = '';
    isEditable: boolean = false;
    isFocused: boolean = false;

    constructor(props: IProps) {
        super(props);

        this.text = props.layer.specificData?.text;

        makeObservable(this, {
            text: observable,
            isEditable: observable,
            isFocused: observable,
            onChangeText: action,
            onBlur: action,
            onTextDoubleClick: action,
        });
    }

    onChangeText = (e) => {
        this.text = e.target.innerHTML;
    };

    onBlur = () => {
        this.isEditable = false;
        this.isFocused = false;
        this.props.changeText(this.text);
    };

    onTextDoubleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.isEditable = true;
    };

    componentDidUpdate(): void {
        if (this.isEditable && !this.isFocused) {
            this.isFocused = true;
            this.elementRef?.current.focus();
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(this.elementRef?.current);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }

    render(): React.ReactNode {
        const { layer, textClasses, textSettings } = this.props;

        return (
            <div
                onBlur={this.onBlur}
                ref={this.elementRef}
                onInput={this.onChangeText}
                id={layer.id}
                className={`advance-element-text ${textClasses.join(' ')}`}
                key={layer.id}
                onDoubleClick={this.onTextDoubleClick}
                contentEditable={this.isEditable}
                style={{
                    fontFamily: textSettings?.fontName,
                    fontSize: textSettings?.fontSize,
                    color: textSettings?.color,
                }}
                dangerouslySetInnerHTML={{
                    __html: layer.specificData?.text
                }}
            />
        );
    }
}
