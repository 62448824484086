export const APP_SELECTOR: string = '#root';
export const DEFAULT_FRAME_RATE: number = 30;
export const DEFAULT_TIME_SLICE: number = 50;
export const DEFAULT_VIDEO_BITS_PER_SECOND: number = 2500000;
export const DEFAULT_AUDIO_BITS_PER_SECOND: number = 128000;
export const DEFAULT_MIME_TYPE: string = 'video/webm';
export const DEFAULT_CODEC: string = 'codecs=h264';
export const DEFAULT_CANVAS_BG_COLOR: string = '#161C21';
export const DEFAULT_ADVANCE_CANVAS_BG_COLOR: string = '#353472';
export const STORAGE_NAME_LABEL: string = 'STORAGE_NAME_LABEL';
export const STORAGE_CAMERA_LABEL: string = 'STORAGE_CAMERA_LABEL';
export const STORAGE_MICRO_LABEL: string = 'STORAGE_MICRO_LABEL';
export const STORAGE_AUDIO_LABEL: string = 'STORAGE_AUDIO_LABEL';
export const DESTINATIONS_STORAGE_LABEL: string = 'DESTINATIONS_STORAGE_LABEL';
export const ADVANCE_MODE_LABEL: string = 'ADVANCE';
export const SIMPLE_MODE_LABEL: string = 'SIMPLE';
export const SELECTED_MODE_LABEL: string = 'SELECTED_MODE';
export const ADMIN_SS_ID: string = 'ADMIN_SS_ID';
export const AUDIO_BITRATE: number = 64000;
export const HD_VIDEO_BITRATE: number = 2500000;
export const SD_VIDEO_BITRATE: number = 1500000;
export const SMALL_VIDEO_BITRATE: number = 750000;
export const STORAGE_TOKEN_LABEL: string = 'STORAGE_TOKEN_LABEL';
export const STORAGE_REFRESH_TOKEN_LABEL: string = 'STORAGE_REFRESH_TOKEN_LABEL';
export const DEFAULT_WEB_RTC_CONFIG: RTCConfiguration = {
    iceServers: [
        {
            urls: [
                'stun:stun.l.google.com:19302',
            ],
        },
    ],
};
export const SUPER_SMALL_SCREEN_CONSTRAINTS: MediaTrackConstraints = {
    width: 426,
    height: 240,
};
export const DEFAULT_CONSTRAINTS: MediaTrackConstraints = SUPER_SMALL_SCREEN_CONSTRAINTS;
export const FULL_SCREEN_CONSTRAINTS: MediaTrackConstraints = {
    width: 1280,
    height: 720,
};
export const HALF_SCREEN_CONSTRAINTS: MediaTrackConstraints = {
    width: 854,
    height: 480,
};
export const SMALL_SCREEN_CONSTRAINTS: MediaTrackConstraints = {
    width: 640,
    height: 360,
};
