import SimplePeer, { Instance } from 'simple-peer';
import { action, observable, makeObservable } from 'mobx';
import { DEFAULT_WEB_RTC_CONFIG } from '@constants';
import { showWarnMessage } from '@utils';
import { Base } from './base-store';
import { RootStore } from './root-store';

export class ShareWebCamConferenceStore extends Base {
    localStream: MediaStream = null;
    remoteCameraStream: MediaStream = null;
    currentUserId: string = '';
    perConnectionsMap: { [key: string]: Instance } = {};
    remotePeerConnection: Instance = null;
    remoteVideo: HTMLVideoElement;
    isJoined: boolean = false;

    constructor(rootStore: RootStore) {
        super(rootStore);


        makeObservable(this, {
            currentUserId: observable,
            setCurrentUserId: action,
        });

        this.remotePeerConnection = new SimplePeer({
            initiator: false,
            config: DEFAULT_WEB_RTC_CONFIG,
        });

        this.remotePeerConnection.on('signal', (data) => {
           this.rootStore.usersConferenceStore.socket.emit('share-web-cam-user-sp-signal', {
                 data,
                id: this.currentUserId,
            });
        });

        this.remotePeerConnection.on('stream', async (data) => {
            console.log('SP STREAM', data);
            try {
                await this.makeUserInteraction();
            } catch (e) {
                console.error(e);
            }

            this.remoteCameraStream = data;

            if (this.remoteVideo) {
                this.remoteVideo.srcObject = this.remoteCameraStream;
                this.remoteVideo.play();
            }
        });
    }

    setCurrentUserId = (id: string) => {
        this.currentUserId = id;
    };

    init = () => {
        const socket = this.rootStore.usersConferenceStore.socket;

        if (socket.connected) {
            this.setCurrentUserId(socket.id);
        }

        socket.on('connect', () => {
            console.log('connect', socket.id);
            this.setCurrentUserId(socket.id);
        });

        socket.on('invalid-share-link', () => {
            console.log('invalid-share-link');
            showWarnMessage('Shared link is invalid');
        });

        socket.on('add-user-share-web-cam', (data) => {
            console.log('add-user-share-web-cam', data);
            this.createOffer(data.id);
        });

        socket.on('share-web-cam-admin-sp-signal-receive', (data) => {
            console.log('share-web-cam-admin-sp-signal-receive', data);

            try {
                this.remotePeerConnection.signal(data);
            } catch (error) {
                console.error(error);
            }
        });

        socket.on('share-web-cam-user-sp-signal-receive', (data) => {
            console.log('share-web-cam-user-sp-signal-receive', data);

            try {
                if (this.perConnectionsMap[data.id]) {
                    this.perConnectionsMap[data.id].signal(data.data);
                }
            } catch (error) {
                console.error(error);
            }
        });

        socket.on('remove-user-share-web-cam', (data) => {
            console.log('remove-user-share-web-cam', data);

            if (this.perConnectionsMap[data.id]) {
                this.perConnectionsMap[data.id].destroy();
                this.perConnectionsMap[data.id] = undefined;
                delete this.perConnectionsMap[data.id];
            }
        });
    };

    makeUserInteraction = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
        } catch (e) {
            console.error(e);
        }
    };

    join = async (roomId: string, remoteVideo: HTMLVideoElement) => {
        if (!this.isJoined) {
            try {
                await this.makeUserInteraction();
            } catch (e) {
                console.error(e);
            }

            this.rootStore.usersConferenceStore.socket.emit('join-share-web-cam-room', { roomId });
            this.remoteVideo = remoteVideo;
            this.isJoined = true;
        }
    };

    start = (stream: MediaStream, roomId: string) => {
        try {
            this.localStream = stream;
            this.rootStore.usersConferenceStore.socket.emit('create-share-web-cam-room', { roomId });
        } catch (error) {
            console.error(error);
        }
    };

    createOffer = async (id: string) => {
        try {
            const socket = this.rootStore.usersConferenceStore.socket;

            if (!this.perConnectionsMap[id]) {
                this.perConnectionsMap[id] = new SimplePeer({
                    initiator: true,
                    config: DEFAULT_WEB_RTC_CONFIG,
                });

                this.perConnectionsMap[id].on('signal', (data) => {
                    socket.emit('share-web-cam-admin-sp-signal', {
                        data,
                        id,
                    });
                });

                this.perConnectionsMap[id].addStream(this.localStream);
            }
        } catch (error) {
            console.error(error);
        }
    };

    changeStream = (stream: MediaStream) => {
        try {
            Object.keys(this.perConnectionsMap).forEach((id: string) => {
                this.perConnectionsMap[id].removeStream(this.localStream);
            });

            this.localStream = stream;

            Object.keys(this.perConnectionsMap).forEach((id: string) => {
                this.perConnectionsMap[id].addStream(this.localStream);
            });
        } catch (error) {
            console.error(error);
        }
    };
}
