import {
    FULL_SCREEN_CONSTRAINTS,
    HALF_SCREEN_CONSTRAINTS,
    SD_VIDEO_BITRATE,
    SMALL_VIDEO_BITRATE,
    AUDIO_BITRATE,
    HD_VIDEO_BITRATE,
} from '@constants';

export const updateSDP = (sdp: string, bitrate: number = 2500) => {
    const separator: string = '\r\n';
    const splitSdp: string[] = sdp.split(separator);
    const sdpArray = splitSdp.slice(0, -1);
    let result: string = '';
    let position: number;

    for (let i = 0; i < sdpArray.length; i++) {
        if (sdpArray[i].match(/b=AS:/)) {
            position = i;
        }
    }

    if (position) {
        sdpArray.splice(position, 1);
    }

    for (let i = 0; i < sdpArray.length; i++) {
        if (sdpArray[i].match(/m=video/)) {
            result += sdpArray[i] + separator + 'b=AS:' + `${bitrate}` + separator;
        } else if (sdpArray[i].match(/m=audio/)) {
            result += sdpArray[i] + separator + 'b=AS:' + '64' + separator;
        } else {
            result += sdpArray[i] + separator;
        }

    }
    return result;
};

export const getBitrateForConstraint = (width: number): number => {
    if (width >= FULL_SCREEN_CONSTRAINTS.width) {
        return HD_VIDEO_BITRATE;
    } else if (width >= HALF_SCREEN_CONSTRAINTS.width && width < FULL_SCREEN_CONSTRAINTS.width) {
        return SD_VIDEO_BITRATE;
    } else {
        return SMALL_VIDEO_BITRATE;
    }
};

export const setMaxBitrate = (peerConnection: RTCPeerConnection, videoBitrate: number = HD_VIDEO_BITRATE) => {
    const senders = peerConnection.getSenders();

    senders.forEach((sender) => {
        if (sender) {
            const params = sender.getParameters();

            if (!params.encodings) {
                params.encodings = [{}];
            }

            if (params.encodings[0]) {
                if (sender.track.kind ===  "audio") {
                    params.encodings[0].maxBitrate = AUDIO_BITRATE;
                } else {
                    params.encodings[0].maxBitrate = videoBitrate;
                }

                sender.setParameters(params);
            }
        }
    });
};
