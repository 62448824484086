import React from 'react';

interface IProps {}

export const MessageInfoIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00004 1.33333C6.6815 1.33333 5.39257 1.72433 4.29624 2.45687C3.19991 3.18941 2.34543 4.2306 1.84085 5.44878C1.33626 6.66695 1.20424 8.00739 1.46148 9.3006C1.71871 10.5938 2.35365 11.7817 3.286 12.714C4.21835 13.6464 5.40623 14.2813 6.69944 14.5386C7.99265 14.7958 9.33309 14.6638 10.5513 14.1592C11.7694 13.6546 12.8106 12.8001 13.5432 11.7038C14.2757 10.6075 14.6667 9.31854 14.6667 8C14.6647 6.23249 13.9617 4.53794 12.7119 3.28812C11.4621 2.03831 9.76755 1.3353 8.00004 1.33333ZM8.00004 13.3333C6.94521 13.3333 5.91406 13.0205 5.037 12.4345C4.15994 11.8485 3.47635 11.0155 3.07269 10.041C2.66902 9.06644 2.5634 7.99408 2.76919 6.95952C2.97498 5.92495 3.48293 4.97464 4.22881 4.22876C4.97469 3.48288 5.925 2.97493 6.95956 2.76914C7.99413 2.56336 9.06648 2.66897 10.041 3.07264C11.0156 3.47631 11.8485 4.1599 12.4345 5.03696C13.0206 5.91402 13.3334 6.94517 13.3334 8C13.3318 9.41399 12.7693 10.7696 11.7695 11.7694C10.7696 12.7693 9.41403 13.3317 8.00004 13.3333ZM8.00004 7.66667C7.82323 7.66667 7.65366 7.7369 7.52864 7.86193C7.40361 7.98695 7.33338 8.15652 7.33338 8.33333V10.3333C7.33338 10.5101 7.40361 10.6797 7.52864 10.8047C7.65366 10.9298 7.82323 11 8.00004 11C8.17685 11 8.34642 10.9298 8.47145 10.8047C8.59647 10.6797 8.66671 10.5101 8.66671 10.3333V8.33333C8.66671 8.15652 8.59647 7.98695 8.47145 7.86193C8.34642 7.7369 8.17685 7.66667 8.00004 7.66667ZM8.00004 5C7.83523 5 7.67411 5.04887 7.53707 5.14044C7.40003 5.23201 7.29322 5.36216 7.23014 5.51443C7.16707 5.6667 7.15057 5.83426 7.18272 5.99591C7.21488 6.15756 7.29424 6.30604 7.41079 6.42259C7.52733 6.53913 7.67582 6.6185 7.83747 6.65065C7.99912 6.68281 8.16667 6.6663 8.31895 6.60323C8.47122 6.54016 8.60137 6.43335 8.69293 6.29631C8.7845 6.15927 8.83338 5.99815 8.83338 5.83333C8.83338 5.61232 8.74558 5.40036 8.5893 5.24408C8.43302 5.0878 8.22106 5 8.00004 5Z" fill="white"/>
        </svg>
    );
};
