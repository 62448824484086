import * as React from 'react';

export enum DeviceTypes {
    Video = 'videoinput',
    Audio = 'audioinput',
    AudioOutput = 'audiooutput',
}

export enum PatternsEnum {
    OneUser = 'OneUser',
    TwoUsers = 'TwoUsers',
    ThreeUsers = 'ThreeUsers',
    TwoUsersOneSmall = 'TwoUsersOneSmall',
    FiveUsers = 'FiveUsers',
    SixUsers = 'SixUsers',
}

export enum ConferenceRolesEnum {
    Admin = 'admin',
    User = 'user',
}

export interface IUserConnection {
    id: string;
    stream: MediaStream;
    name: string;
    userRole: string;
    isMuted: boolean;
    isOut: boolean;
    mediaStreamAudioSourceNode: MediaStreamAudioSourceNode;
}

export enum LogoPositionsEnum {
    TopLeft = 'TopLeft',
    TopRight = 'TopRight',
    BottomLeft = 'BottomLeft',
    BottomRight = 'BottomRight',
}

export interface IStreamDestination {
    active?: boolean;
    id?: number;
    name: string;
    url: string;
    streamKey: string;
    isValid: boolean;
    type?: string;
    iconsId: number;
    colorIcon?: React.ReactNode;
    icon?: React.ReactNode;
}
