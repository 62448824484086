import React from 'react';

interface IProps {}

export const TicTocColorIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="4" fill="#0B0D0E"/>
            <path d="M24.902 10H29.4837C29.9947 13.8222 31.9338 16.2443 35.9939 16.5873V21.036C33.6208 21.2985 31.5872 20.3604 29.5537 19.1634C29.5537 19.3594 29.6132 25.4356 29.5117 28.3337C29.4102 31.3124 28.3461 33.934 26.0115 35.8906C21.0623 40.0452 14.0375 37.8016 12.361 31.4489C11.4509 27.9872 12.242 24.8686 14.9581 22.401C16.4876 21.0009 18.3742 20.3779 20.3973 20.1189C20.8313 20.0629 21.2688 20.0384 21.7378 19.9964V24.7006C21.1428 24.8476 20.5408 24.9771 19.9493 25.1521C19.5389 25.2706 19.1386 25.4216 18.7522 25.6036C18.2132 25.8461 17.7371 26.2094 17.3611 26.6654C16.985 27.1214 16.719 27.6578 16.5836 28.2332C16.4482 28.8086 16.4471 29.4074 16.5804 29.9832C16.7137 30.5591 16.9778 31.0965 17.3522 31.5539C17.8024 32.1764 18.4148 32.6636 19.1226 32.9623C19.8305 33.2609 20.6067 33.3598 21.3668 33.2479C22.8964 33.0624 23.8554 32.0999 24.4225 30.7173C24.8145 29.6232 24.9847 28.4619 24.923 27.3012C24.902 21.589 24.902 15.8802 24.8915 10.168C24.892 10.1119 24.8955 10.0558 24.902 10Z" fill="white"/>
        </svg>
    );
};