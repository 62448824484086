import { action, observable, makeObservable } from 'mobx';
import { v4 } from 'uuid';
import { showScreenShareErrorMessage, getCameraConstraint, setSinkId } from '@utils';
import { Base } from './base-store';
import { RootStore } from './root-store';
import { STORAGE_AUDIO_LABEL } from '@constants';

// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext || null;

export class ShareWebCamStore extends Base {
    video: HTMLVideoElement;
    cameraStream: MediaStream;
    screenShareStream: MediaStream;
    audioStream: MediaStream;
    isVideoMuted: boolean = false;
    isMicroMuted: boolean = false;
    isScreenShare: boolean = false;
    isSettingsModalShown: boolean = false;
    currentCamera: string = null;
    currentMicro: string = null;
    currentAudio: string = null;
    roomId: string = v4();
    isVideoBlur: boolean = false;

    constructor(rootStore: RootStore) {
        super(rootStore);

        makeObservable(this, {
            isVideoMuted: observable,
            isMicroMuted: observable,
            isScreenShare: observable,
            isSettingsModalShown: observable,
            isVideoBlur: observable,
            currentCamera: observable,
            currentMicro: observable,
            currentAudio: observable,
            setCurrentCamera: action,
            setCurrentMicro: action,
            openSettingsModal: action,
            closeSettingsModal: action,
            toggleVideo: action,
            toggleMicros: action,
            applyEnabled: action,
            updateCamera: action,
            updateMicro: action,
            stopScreenShare: action,
            start: action,
            addCamera: action,
            addScreenShare: action,
            toggleIsVideoBlur: action,
            setCurrentAudio: action,
            updateCurrentAudio: action,
        });
    }

    toggleIsVideoBlur = () => {
        this.isVideoBlur = !this.isVideoBlur;

        if (this.isVideoBlur) {
            this.blurVideo();
        }
    };

    blurVideo = () => {};

    toggleVideo = () => {
        this.isVideoMuted = !this.isVideoMuted;
        const cameraTracks = this.cameraStream?.getVideoTracks();
        const ssTracks = this.screenShareStream?.getVideoTracks();

        if (cameraTracks && cameraTracks.length) {
            cameraTracks[0].enabled = !this.isVideoMuted;
        }

        if (ssTracks && ssTracks.length) {
            ssTracks[0].enabled = !this.isVideoMuted;
        }
    };

    toggleMicros = () => {
        this.isMicroMuted = !this.isMicroMuted;
        const cameraTracks = this.cameraStream?.getAudioTracks();
        const ssTracks = this.screenShareStream?.getAudioTracks();
        const audioTracks = this.audioStream?.getAudioTracks();

        if (cameraTracks && cameraTracks.length) {
            cameraTracks[0].enabled = !this.isMicroMuted;
        }

        if (ssTracks && ssTracks.length) {
            ssTracks[0].enabled = !this.isMicroMuted;
        }

        if (audioTracks && audioTracks.length) {
            audioTracks[0].enabled = !this.isMicroMuted;
        }
    };

    setCurrentCamera = (camera: string) => {
        this.currentCamera = camera;
    };

    setCurrentMicro = (micro: string) => {
        this.currentMicro = micro;
    };

    setCurrentAudio = (audio: string) => {
        this.currentAudio = audio;
    };

    updateCurrentAudio = (deviceId: string) => {
        if (deviceId && deviceId !== this.currentAudio) {
            this.currentAudio = deviceId;
            setSinkId(deviceId);
        }
    };

    openSettingsModal = () => {
        this.isSettingsModalShown = true;
    };

    closeSettingsModal = () => {
        this.isSettingsModalShown = false;
    };

    start = async (videoElement: HTMLVideoElement) => {
        this.video = videoElement;

        if (this.currentMicro || this.currentCamera) {
            await this.addCamera();
            this.rootStore.shareWebCamConferenceStore.init();
            this.rootStore.shareWebCamConferenceStore.start(this.cameraStream, this.roomId);
        }
    };

    updateCamera = async (camera: string) => {
        try {
            if (camera !== this.currentCamera) {
                this.currentCamera = camera;
                await this.addCamera();
            }
        } catch (error) {
            console.error(error);
        }
    };

    updateMicro = async (micro: string) => {
        try {
            if (micro !== this.currentMicro) {
                this.currentMicro = micro;
                await this.addCamera();
            }
        } catch (error) {
            console.error(error)
        }
    };

    addCamera = async (isInitial: boolean = false) => {
        try {
            this.stopScreenShare();

            const config: MediaStreamConstraints = {
                audio: {
                    deviceId: 'default',
                },
                video: {
                    deviceId: 'default',
                },
            };

            if (this.currentMicro) {
                config.audio = {
                    deviceId: this.currentMicro,
                };
            }

            if (this.currentCamera) {
                config.video = {
                    ...getCameraConstraint(),
                    deviceId: this.currentCamera,
                };
            }

            if (this.cameraStream) {
                this.cameraStream.getTracks().forEach((track) => {
                    track.stop();
                });
            }

            const stream = await navigator.mediaDevices.getUserMedia(config);

            this.video.srcObject = stream;
            this.video.play();
            this.cameraStream = stream;
            this.audioStream = stream;
            this.applyEnabled();

            if (isInitial) {
                this.rootStore.shareWebCamConferenceStore.localStream = stream;
            } else {
                this.rootStore.shareWebCamConferenceStore.changeStream(stream);
            }
        } catch (error) {
            console.error(error);
        }
    };

    addScreenShare = async () => {
        try {
            // @ts-ignore
            const stream: MediaStream = await navigator.mediaDevices.getDisplayMedia(
                {
                    video: {
                        // @ts-ignore
                        cursor: 'always',
                    },
                    audio: true,
                }
            );

            this.video.srcObject = stream;
            this.video.play();
            this.screenShareStream = stream;

            const audioTracks = stream.getAudioTracks();

            this.cameraStream.getTracks().forEach((track) => {
                track.stop();
            });

            if (audioTracks && audioTracks[0] && AudioContext) {
                const audioSteam = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: this.currentMicro || 'default',
                    },
                });
                const audioContext = new AudioContext();
                const destination = audioContext.createMediaStreamDestination();
                const audioScreenShare = audioContext.createMediaStreamSource(stream);
                const audioCamera = audioContext.createMediaStreamSource(audioSteam);

                audioScreenShare.connect(destination);
                audioCamera.connect(destination);

                this.audioStream = destination.stream;
            } else {
                const audioSteam = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: this.currentMicro || 'default',
                    },
                });
                this.audioStream = audioSteam;
            }

            this.rootStore.shareWebCamConferenceStore.changeStream(new MediaStream([
                ...stream.getVideoTracks(),
                ...this.audioStream.getAudioTracks(),
            ]));

            stream.getVideoTracks()[0].onended = () => {
                this.addCamera();
            };

            this.isScreenShare = true;
            this.applyEnabled();
        } catch (error) {
            console.error(error);
            showScreenShareErrorMessage(this.dependencies.intl);
        }
    };

    stopScreenShare = () => {
        this.isScreenShare = false;

        if (this.screenShareStream) {
            this.screenShareStream.getTracks().forEach((track: MediaStreamTrack) => {
                track.stop();
            });
            this.screenShareStream = undefined;
        }
    };

    applyEnabled = () => {
        const cameraVideoTracks = this.cameraStream?.getVideoTracks();
        const cameraAudioTracks = this.cameraStream?.getAudioTracks();
        const screenShareVideoTracks = this.screenShareStream?.getVideoTracks();
        const screenShareAudioTracks = this.screenShareStream?.getAudioTracks();
        const audioStreamTracks = this.audioStream?.getAudioTracks();

        if (cameraVideoTracks && cameraVideoTracks.length) {
            cameraVideoTracks[0].enabled = !this.isVideoMuted;
        }

        if (cameraAudioTracks && cameraAudioTracks.length) {
            cameraAudioTracks[0].enabled = !this.isMicroMuted;
        }

        if (screenShareVideoTracks && screenShareVideoTracks.length) {
            screenShareVideoTracks[0].enabled = !this.isVideoMuted;
        }

        if (screenShareAudioTracks && screenShareAudioTracks.length) {
            screenShareAudioTracks[0].enabled = !this.isMicroMuted;
        }

        if (audioStreamTracks && audioStreamTracks.length) {
            audioStreamTracks[0].enabled = !this.isMicroMuted;
        }
    };
}
