import React from 'react';

interface IProps {}

export const FontSettingsIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.60001 2H16.4C17.28 2 18 2.72 18 3.60001V16.4C18 17.28 17.28 18 16.4 18H3.60001C2.72 18 2 17.28 2 16.4V3.60001C2 2.72 2.72 2 3.60001 2ZM8.6144 12.3203L8.08525 14H6L8.70848 6H11.2915L14 14H11.9147L11.3856 12.3203H8.6144ZM9.96668 8.01562L9.07692 10.8516H10.9231L10.0294 8.01562H9.96668Z" fill="#748499"/>
        </svg>
    );
};
