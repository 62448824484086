import React from 'react';

interface IProps {}

export const CollapseIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.71006 1.29L6.59006 5.17L10.4701 1.29C10.8601 0.899998 11.4901 0.899998 11.8801 1.29C12.2701 1.68 12.2701 2.31 11.8801 2.7L7.29006 7.29C6.90006 7.68 6.27006 7.68 5.88006 7.29L1.29006 2.7C0.900059 2.31 0.900059 1.68 1.29006 1.29C1.68006 0.909998 2.32006 0.899998 2.71006 1.29Z" fill="white"/>
        </svg>
    );
};
