import { LogoPositionsEnum, PatternsEnum } from './stream';

export enum StreamTypeEnum {
    Camera = 'Camera',
    ScreenShare = 'ScreenShare',
}

export interface IStreamViewUser {
    id: string;
    name: string;
    isEnabled: boolean;
    position: number;
    streamType: StreamTypeEnum;
}

export interface IStreamViewState {
    showNames: boolean;
    primaryColor: string;
    logoImage: string;
    logoPosition: LogoPositionsEnum;
    backgroundImage: string;
    selectedPattern: PatternsEnum;
    users: IStreamViewUser[];
    isSimpleMode: boolean;
}

export enum DragItemTypes {
    User = 'User',
}

export enum StreamViewActionsEnum {
    SwapUsers = 'SwapUsers',
    ToggleNames = 'ToggleNames',
    ChangePrimaryColor = 'ChangePrimaryColor',
    ChangeBackgroundImage = 'ChangeBackgroundImage',
    ChangeLogoImage = 'ChangeLogoImage',
    ChangeLogoPosition = 'ChangeLogoPosition',
    ChangeSelectedPattern = 'ChangeSelectedPattern',
    AddUser = 'AddUser',
    InsertUser = 'InsertUser',
    RemoverUser = 'RemoverUser',
    EnableUser = 'EnableUser',
    DisableUser = 'DisableUser',
}

export interface IStreamViewPayload {
    id?: string;
    user?: IStreamViewUser;
    pattern?: PatternsEnum;
    position?: LogoPositionsEnum;
    img?: string;
    primaryColor?: string;
    show?: boolean;
    userPosition?: number;
}
