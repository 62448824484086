import React from 'react';

interface IProps {}

export const SoundGreyIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 10.461V14.461C3 15.011 3.45 15.461 4 15.461H7L10.29 18.751C10.92 19.381 12 18.931 12 18.041V6.87102C12 5.98102 10.92 5.53102 10.29 6.16102L7 9.46102H4C3.45 9.46102 3 9.91102 3 10.461ZM16.5 12.461C16.5 10.691 15.48 9.17102 14 8.43102V16.481C15.48 15.751 16.5 14.231 16.5 12.461ZM14 4.91102V5.11102C14 5.49102 14.25 5.82102 14.6 5.96102C17.18 6.99102 19 9.52102 19 12.461C19 15.401 17.18 17.931 14.6 18.961C14.24 19.101 14 19.431 14 19.811V20.011C14 20.641 14.63 21.081 15.21 20.861C18.6 19.571 21 16.301 21 12.461C21 8.62102 18.6 5.35102 15.21 4.06102C14.63 3.83102 14 4.28102 14 4.91102Z" fill="#748499"/>
        </svg>
    );
};
