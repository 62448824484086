import React from 'react';
import { Drawer, Input } from 'antd';
import { observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import { IStreamDestination } from '@common-types';
import { PurpleButton } from '@components';
import { showWarnMessage } from '@utils';
import './style.scss';
import { ArrowLeftIcon } from '@assets/icons';

interface IProps {
    name: string;
    url: string;
    streamKey: string;
    isModalShown: boolean;
    selectedDestination?: IStreamDestination;
    onClose: () => void;
    destinations: IStreamDestination[];
    onSave: (
        id: number,
        iconsId: number,
        name: string,
        url: string,
        streamKey: string,
    ) => void | Promise<void>;
}

@observer
export class AddDestinationDrawer extends React.Component<IProps> {
    name: string = '';
    url: string = '';
    streamKey: string = '';

    constructor(props: IProps) {
        super(props);

        this.name = props.name;
        this.url = props.url;
        this.streamKey = props.streamKey;

        makeObservable(this, {
            name: observable,
            url: observable,
            streamKey: observable,
            onNameChange: action,
            onUrlChange: action,
            onStreamKeyChange: action,
        });
    }

    onNameChange = (e) => {
        this.name = e.target.value;
    };

    onUrlChange = (e) => {
        this.url = e.target.value;
    };

    onStreamKeyChange = (e) => {
        this.streamKey = e.target.value;
    };

    onSave = () => {
        const { onSave, onClose, selectedDestination } = this.props;

        if (this.streamKey && this.name && this.url) {
            const url = this.url[this.url.length - 1] === '/' ? this.url : `${this.url}/`;

            onSave(
                selectedDestination.id,
                selectedDestination.iconsId,
                this.name,
                url,
                this.streamKey,
            );
            onClose();
        } else {
            showWarnMessage(`Destination should have name, url and stream key`);
        }
    };

    render(): React.ReactNode {
        const { isModalShown, onClose, name } = this.props;

        return (
            <Drawer
                closable={false}
                onClose={onClose}
                destroyOnClose={true}
                visible={isModalShown}
                width={window.innerWidth > 440 ? 408 : 320}
                className="add-destination-modal"
            >
                <div className="add-destination-modal__wrapper">
                    <div className="add-destination-modal__top">
                        <div className="add-destination-modal__actions">
                            <div className="add-destination-modal__icon" onClick={onClose}>
                                <ArrowLeftIcon />
                            </div>
                        </div>
                        <div className="add-destination-modal__header">
                            Add {name} Channel
                        </div>
                    </div>
                    <div className="add-destination-modal__mid">
                        <div style={{ width: '100%' }}>
                            <div className="add-destination-modal__input-wrapper">
                                <div className="add-destination-modal__label">
                                    Name
                                </div>
                                <div className="add-destination-modal__input">
                                    <Input
                                        value={this.name}
                                        onChange={this.onNameChange}
                                    />
                                </div>
                            </div>
                            <div className="add-destination-modal__input-wrapper">
                                <div className="add-destination-modal__label">
                                    URL
                                </div>
                                <div className="add-destination-modal__input">
                                    <Input
                                        value={this.url}
                                        onChange={this.onUrlChange}
                                    />
                                </div>
                            </div>
                            <div className="add-destination-modal__input-wrapper">
                                <div className="add-destination-modal__label">
                                    Stream key
                                </div>
                                <div className="add-destination-modal__input">
                                    <Input
                                        value={this.streamKey}
                                        onChange={this.onStreamKeyChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <PurpleButton onClick={this.onSave}>
                            Save Changes
                        </PurpleButton>
                    </div>
                </div>
            </Drawer>
        );
    }
}
