import React from 'react';

interface IProps {}

export const StreepChatIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="62" height="12" viewBox="0 0 62 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.29764 0C7.99578 0.199882 8.72497 0.329099 9.38805 0.61378C11.1289 1.36283 12.404 2.58635 12.8036 4.50644C13.2283 6.54766 12.428 8.16186 10.8705 9.43888C9.82376 10.296 8.58975 10.7199 7.25758 10.8845C6.95508 10.9218 6.81586 10.8169 6.81586 10.513C6.81285 9.51258 6.79582 8.51115 6.81586 7.51174C6.81986 7.33911 6.95308 7.14529 7.07428 7.00194C7.85856 6.07521 8.65686 5.15858 9.51025 4.16926C7.41483 4.16926 5.40556 4.16926 3.31215 4.16926C3.45138 4.3439 3.55555 4.4812 3.66773 4.61142C4.36386 5.41903 5.07202 6.21755 5.75212 7.03929C5.88634 7.20182 5.97649 7.45016 5.9815 7.66114C6.00654 8.57777 5.9815 9.49542 5.99953 10.412C6.00754 10.8249 5.8573 10.9612 5.45865 10.8482C4.87169 10.6816 4.34683 10.8219 3.77991 11.0551C2.8504 11.4387 1.86579 11.6901 0.904227 11.998C0.877183 11.9546 0.85114 11.9122 0.824096 11.8688C1.15564 11.253 1.47816 10.6321 1.82172 10.0234C2.00402 9.70035 1.98799 9.43787 1.71254 9.17641C-1.49969 6.12064 0.0929053 1.69597 4.11646 0.385631C4.60025 0.228148 5.10307 0.127198 5.59787 0C6.1648 0 6.73172 0 7.29764 0Z" fill="white"/>
            <path d="M61.998 3.60393C61.367 3.60393 60.735 3.60393 60.0409 3.60393C60.0409 5.34029 60.0409 7.03121 60.0409 8.75746C59.7714 8.75746 59.5641 8.75746 59.2706 8.75746C59.2706 7.06553 59.2706 5.3635 59.2706 3.61201C58.5765 3.61201 57.9675 3.61201 57.3354 3.61201C57.3354 3.40001 57.3354 3.2395 57.3354 3.00429C58.884 3.00429 60.4405 3.00429 61.997 3.00429C61.998 3.20417 61.998 3.40405 61.998 3.60393Z" fill="white"/>
            <path d="M32.1131 8.73021C32.032 8.76353 32.016 8.77564 32.0009 8.77564C30.6217 8.79684 30.8651 8.99167 30.7098 7.68134C30.5977 6.74048 30.3933 6.51536 29.4678 6.51233C29.1393 6.51132 28.8107 6.51233 28.4251 6.51233C28.4251 7.28864 28.4251 8.01346 28.4251 8.76454C27.9924 8.76454 27.6178 8.76454 27.2031 8.76454C27.2031 6.84647 27.2031 4.94356 27.2031 3.01136C28.365 3.01136 29.5109 2.973 30.6517 3.0265C31.3068 3.05679 31.8176 3.59183 31.9439 4.24094C32.0931 5.00514 31.8597 5.62194 31.1305 6.03281C32.2674 6.65669 31.7165 7.83882 32.1131 8.73021ZM28.4482 5.59267C29.0171 5.59267 29.5319 5.61892 30.0428 5.58459C30.4344 5.55835 30.6718 5.31606 30.6988 4.90217C30.7239 4.50846 30.6588 4.09759 30.219 4.04207C29.6421 3.96938 29.0481 4.0239 28.4482 4.0239C28.4482 4.55893 28.4482 5.05157 28.4482 5.59267Z" fill="white"/>
            <path d="M21.2054 4.68209C20.8107 4.68209 20.4321 4.68209 20.0535 4.68209C19.752 3.95221 19.2402 3.65744 18.539 3.89669C18.3187 3.9724 18.0683 4.23891 18.0252 4.45596C17.9952 4.6094 18.2566 4.92336 18.4509 4.99705C19.0238 5.21309 19.6378 5.32009 20.2198 5.51695C21.2575 5.86825 21.7142 6.79397 21.2775 7.72978C21.1253 8.05484 20.8378 8.39101 20.5253 8.55354C19.5186 9.07545 18.4679 9.06637 17.4633 8.52023C16.8473 8.18507 16.5428 7.63792 16.5678 6.88786C16.9745 6.88786 17.3511 6.88786 17.7267 6.88786C18.0232 7.77319 18.7083 8.15479 19.5807 7.84991C19.8201 7.76613 20.1256 7.48447 20.1467 7.26844C20.1667 7.06351 19.8993 6.72533 19.6769 6.62437C19.1811 6.40026 18.6332 6.29427 18.1103 6.12871C17.1207 5.81677 16.69 5.28678 16.7571 4.47615C16.8253 3.65239 17.4312 2.99419 18.3748 2.9205C18.9848 2.87305 19.6438 2.9417 20.2238 3.13249C20.8859 3.34954 21.2284 3.9189 21.2054 4.68209Z" fill="white"/>
            <path d="M36.6941 6.75662C36.6941 7.43097 36.6941 8.07807 36.6941 8.75948C36.2744 8.75948 35.8848 8.75948 35.4561 8.75948C35.4561 6.8606 35.4561 4.96778 35.4561 2.93362C36.6059 2.99419 37.7688 2.973 38.9017 3.14461C39.6699 3.2607 40.0866 4.07437 40.0445 4.92639C40.0015 5.80163 39.5497 6.44065 38.7354 6.59409C38.0893 6.71624 37.4193 6.70413 36.6941 6.75662ZM36.7251 5.66636C37.246 5.66636 37.7147 5.71078 38.1705 5.65424C38.6332 5.5967 38.8255 5.25751 38.8075 4.79212C38.7905 4.33886 38.5561 4.05922 38.1224 4.01884C37.6677 3.97644 37.2069 4.00976 36.7251 4.00976C36.7251 4.5771 36.7251 5.11618 36.7251 5.66636Z" fill="white"/>
            <path d="M51.3729 3.01338C51.3729 4.92539 51.3729 6.82023 51.3729 8.75344C51.1495 8.75344 50.9261 8.75344 50.6437 8.75344C50.6437 7.90747 50.6437 7.04939 50.6437 6.15497C49.613 6.15497 48.6394 6.15497 47.6027 6.15497C47.6027 6.99992 47.6027 7.85599 47.6027 8.75142C47.3183 8.75142 47.0959 8.75142 46.8345 8.75142C46.8345 6.85859 46.8345 4.95265 46.8345 3.0154C47.0608 3.0154 47.2852 3.0154 47.5617 3.0154C47.5617 3.81695 47.5617 4.61143 47.5617 5.44327C48.5843 5.44327 49.5569 5.44327 50.6016 5.44327C50.6016 4.64576 50.6016 3.84219 50.6016 3.01237C50.8981 3.01338 51.1225 3.01338 51.3729 3.01338Z" fill="white"/>
            <path d="M24.7463 8.76959C24.3096 8.76959 23.933 8.76959 23.5023 8.76959C23.5023 7.21192 23.5023 5.66939 23.5023 4.05418C22.8943 4.05418 22.3374 4.05418 21.7534 4.05418C21.7534 3.6736 21.7534 3.3657 21.7534 3.02045C23.325 3.02045 24.8805 3.02045 26.4651 3.02045C26.4651 3.34147 26.4651 3.65038 26.4651 4.03803C25.9112 4.03803 25.3583 4.03803 24.7463 4.03803C24.7463 5.6593 24.7463 7.20384 24.7463 8.76959Z" fill="white"/>
            <path d="M45.7293 4.67805C45.2796 4.78708 45.0272 4.71137 44.8068 4.26214C44.293 3.21225 42.5652 3.23547 41.854 4.25911C41.2691 5.10205 41.256 6.65265 41.848 7.49155C42.3088 8.14369 42.9578 8.36982 43.7321 8.22949C44.4583 8.09725 44.8509 7.62379 45.0082 6.92622C45.0262 6.84546 45.0502 6.76672 45.0743 6.68697C45.0783 6.67183 45.0933 6.65971 45.1173 6.62842C45.3287 6.62842 45.554 6.62842 45.7794 6.62842C45.7644 7.63793 45.1524 8.49399 44.2609 8.77867C43.088 9.15319 41.833 8.79078 41.208 7.89737C40.4327 6.78893 40.4617 4.93346 41.2731 3.84218C42.0894 2.74182 43.9194 2.49954 44.9981 3.34652C45.4278 3.68268 45.6502 4.08345 45.7293 4.67805Z" fill="white"/>
            <path d="M57.2954 8.74536C56.7435 8.91092 56.46 8.7605 56.2847 8.20022C55.9131 7.01708 55.8791 7.02819 54.634 7.02819C53.4241 7.02819 53.396 7.0191 53.0124 8.18205C52.8381 8.70902 52.5887 8.92808 52.0098 8.7393C52.2522 8.10937 52.4946 7.4754 52.74 6.84345C53.1967 5.66535 53.6645 4.4913 54.1062 3.30816C54.2083 3.03357 54.3626 2.96089 54.627 2.98209C54.8674 3.00228 55.0958 2.93666 55.223 3.27282C55.8941 5.04451 56.5942 6.8061 57.2824 8.57172C57.2984 8.61614 57.2904 8.66964 57.2954 8.74536ZM54.6581 3.65442C54.3075 4.6296 53.999 5.48869 53.6865 6.35687C54.3706 6.35687 54.9716 6.35687 55.6227 6.35687C55.3051 5.4685 55.0056 4.6296 54.6581 3.65442Z" fill="white"/>
            <path d="M34.2746 8.77261C33.8559 8.77261 33.4783 8.77261 33.0596 8.77261C33.0596 6.85354 33.0596 4.94759 33.0596 3.01439C33.4662 3.01439 33.8569 3.01439 34.2746 3.01439C34.2746 4.92942 34.2746 6.82427 34.2746 8.77261Z" fill="white"/>
        </svg>
    );
};