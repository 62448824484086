import React from 'react';
import './styles.scss';

interface IProps {
    id?: string;
    device: MediaDeviceInfo;
    withAudio?: boolean;
    setVideoRatio?: (width: number, height: number) => void;
    addAudioSource?: (device: MediaDeviceInfo) => void;
}

export class VideoWithDevice extends React.Component<IProps> {
    videoRef: React.RefObject<HTMLVideoElement> = React.createRef();
    stream: MediaStream;

    async componentDidMount() {
        try {
            const { device, setVideoRatio, withAudio = true, addAudioSource } = this.props;
            this.stream = await navigator.mediaDevices.getUserMedia({
                audio: withAudio,
                video: {
                    deviceId: device.deviceId,
                }
            });
            const audio = this.stream.getAudioTracks();

            this.videoRef.current.srcObject = this.stream;

            if (setVideoRatio) {
                this.videoRef.current.addEventListener('resize', this.onVideoResize);
            }

            if (withAudio && addAudioSource && audio && audio[0]) {
                const deviceId = audio[0].getSettings().deviceId;
                const devices = await navigator.mediaDevices.enumerateDevices();
                const deviceInfo = devices.find((device: MediaDeviceInfo) => {
                    return device.deviceId === deviceId;
                });

                if (deviceInfo) {
                    addAudioSource(deviceInfo);
                }
            }

        } catch (e) {
            console.error(e);
        }
    }

    onVideoResize = () => {
        const { setVideoRatio } = this.props;

        if (
            setVideoRatio &&
            this.videoRef.current.videoWidth &&
            this.videoRef.current.videoHeight
        ) {
            setVideoRatio(this.videoRef.current.videoWidth, this.videoRef.current.videoHeight);
        }
    };

    componentWillUnmount(): void {
        if (this.stream) {
            this.stream.getTracks().forEach((track: MediaStreamTrack) => {
                track.stop();
            });
        }

        if (this.props.setVideoRatio) {
            this.videoRef.current.removeEventListener('resize', this.onVideoResize);
        }
    }

    render(): React.ReactNode {
        const { id = '' } = this.props;

        return (
            <video
                id={id}
                className="video-with-device"
                ref={this.videoRef}
                autoPlay={true}
                muted={true}
            />
        );
    }
}
