import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import {
    VideoIcon,
    ArrowBottomIcon,
    MicroIcon,
    MicroOffIcon,
    SmallWebCamIcon,
    CameraOffIcon,
} from '@assets/icons';
import { PurpleButton, SoundMeter, SecondaryButton } from '@components';
import { DeviceTypes, Routes, VideoResolutions } from '@common-types';
import { AppStore, UserMediaStore, UsersConferenceStore } from '@services';
import { showWarnMessage } from '@utils';
import bgTop from '../../../assets/images/bg-top.png';
import bgBottom from '../../../assets/images/bg-bottom.png';
import './styles.scss';

interface IProps {
    AppStore?: AppStore;
    UserMediaStore?: UserMediaStore;
    UsersConferenceStore?: UsersConferenceStore;
}

@inject('AppStore', 'UserMediaStore', 'UsersConferenceStore')
@observer
export class SelectMedia extends React.Component<IProps> {
    videoRef: React.RefObject<HTMLVideoElement> = React.createRef();
    isMediaStarted: boolean = false;

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            isMediaStarted: observable,
            onDidMount: action,
            onCameraSelect: action,
            onMicroSelect: action,
        });
    }

    getRoute = (): Routes => {
        const { isSimpleMode, isUserAuthorized } = this.props.AppStore!;
        const searchItems = location.search.slice(1).split('&');
        let roomId = '';

        searchItems.forEach((searchItem: string) => {
            const [name, value] = searchItem.split('=');

            if (name.toLowerCase() === 'roomid') {
                roomId = value;
            }
        });

        if (isUserAuthorized && !roomId) {
            return isSimpleMode ? Routes.SimpleMode : Routes.AdvancedMode;
        }

        return Routes.GuestMode;
    };

    checkAudioStream = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

            stream.getTracks().forEach((track) => {
                track.stop();
            });
        } catch (error) {
            showWarnMessage('Warn, you have some problems with selected micro (it can be blocked by other apps)');
            console.error('audio error', error);
            this.props.UserMediaStore.selectMicro(null);
        }
    };

    checkVideoAudioStream = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

            stream.getTracks().forEach((track) => {
                track.stop();
            });
        } catch (error) {
            showWarnMessage('Warn, you have some problems with selected camera (it can be blocked by other apps)');
            console.error('video error', error);
            this.props.UserMediaStore.selectCamera(null);
        }
    };

    onDidMount = async () => {
        try {
            const { isUserAuthorized, userAuthCheckPromise } = this.props.AppStore;
            const { createSession } = this.props.UsersConferenceStore;

            await this.setDevices();

            try {
                await this.checkVideoAudioStream();
            } catch (error) {
                await this.checkAudioStream();
                console.error(error);
            }

            await this.props.UserMediaStore!.init(this.videoRef.current);

            navigator.mediaDevices.ondevicechange = async () => {
                await this.setDevices();
            };

            this.isMediaStarted = true;

            if (isUserAuthorized) {
                await userAuthCheckPromise;
                createSession();
            }
        } catch (error) {
            console.error(error);
        }
    };

    componentDidMount() {
        this.onDidMount();
    }

    setDevices = async () => {
        const { setCameras, setMicros } = this.props.UserMediaStore!;
        const devices = await navigator.mediaDevices.enumerateDevices();

        const cameras = devices.filter(({ kind }) => {
            return kind === DeviceTypes.Video;
        });
        const micros = devices.filter(({ kind }) => {
            return kind === DeviceTypes.Audio;
        });

        setCameras(cameras);
        setMicros(micros);
    };

    getDevice = (arr: MediaDeviceInfo[], id: string): MediaDeviceInfo | undefined => {
        return arr.find(({ deviceId }) => {
            return deviceId === id;
        });
    };

    onCameraSelect = async (value) => {
        this.isMediaStarted = false;
        const { cameras, selectCamera } = this.props.UserMediaStore!;

        const device = this.getDevice(cameras, value);

        if (device) {
            await selectCamera(device);
        }

        this.isMediaStarted = true;
    };

    onMicroSelect = async (value) => {
        this.isMediaStarted = false;
        const { micros, selectMicro } = this.props.UserMediaStore!;

        const device = this.getDevice(micros, value);

        if (device) {
            await selectMicro(device);
        }

        this.isMediaStarted = true;
    };

    onNameChange = (e) => {
        this.props.UserMediaStore!.changeName(e.target.value);
    };

    componentWillUnmount(): void {
        try {
            const { stream } = this.props.UserMediaStore!;

            if (stream) {
                stream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    render(): React.ReactNode {
        const {
            name,
            selectedCamera,
            selectedMicro,
            micros,
            cameras,
            videoResolution,
            isMicroEnabled,
            isCameraEnabled,
            toggleCamera,
            toggleMicro,
            stream,
        } = this.props.UserMediaStore!;
        const { isLoading } = this.props.AppStore!;
        const isEnabled: boolean = !!(
            name && videoResolution && (selectedMicro || selectedCamera) && this.isMediaStarted && !isLoading
        );

        return (
            <div className='select-media'>
                <img className='select-media__bg-top' src={bgTop} alt="background" />
                <img className='select-media__bg-bottom' src={bgBottom} alt="background" />
                <div className='select-media__wrapper'>
                    <div className='select-media__top'>
                        <div className='select-media__header'>
                            <div className='select-media__icon'>
                                <VideoIcon />
                            </div>
                            <FormattedMessage id='accessing.media' />
                        </div>
                        <div className='select-media__description'>
                            <FormattedMessage id='to.create.broadcast' />
                        </div>
                        <div className='select-media__main-form-wrapper'>
                            <div className='select-media__selects'>
                                <div className='select-media__input-wrapper with-margin'>
                                    <div className='select-media__label'>
                                        <FormattedMessage id='your.display.name' />
                                    </div>
                                    <div className='select-media__input'>
                                        <Input
                                            value={name}
                                            onChange={this.onNameChange}
                                        />
                                    </div>
                                </div>
                                <div className='select-media__input-wrapper with-margin'>
                                    <div className='select-media__label'>
                                        <FormattedMessage id='audio.input' />
                                    </div>
                                    <div className='select-media__select'>
                                        <Select
                                            value={selectedMicro?.deviceId}
                                            onChange={this.onMicroSelect}
                                            suffixIcon={ArrowBottomIcon}
                                        >
                                            {
                                                micros.map(({ deviceId, label }) => {
                                                    return (
                                                        <Select.Option key={deviceId} value={deviceId}>
                                                            {label || 'Micro'}
                                                        </Select.Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div className='select-media__input-wrapper with-margin'>
                                    <div className='select-media__label'>
                                        <FormattedMessage id='video.input' />
                                    </div>
                                    <div className='select-media__select'>
                                        <Select
                                            value={selectedCamera?.deviceId}
                                            onChange={this.onCameraSelect}
                                            suffixIcon={ArrowBottomIcon}
                                        >
                                            {
                                                cameras.map(({ deviceId, label }) => {
                                                    return (
                                                        <Select.Option key={deviceId} value={deviceId}>
                                                            {label || 'Camera'}
                                                        </Select.Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div className='select-media__input-wrapper'>
                                    <div className='select-media__label'>
                                        <FormattedMessage id='video.resolution' />
                                    </div>
                                    <div className='select-media__select'>
                                        <Select
                                            value={videoResolution}
                                            disabled={true}
                                            onChange={this.onMicroSelect}
                                            suffixIcon={ArrowBottomIcon}
                                        >

                                            <Select.Option value={VideoResolutions.High}>
                                                {VideoResolutions.High}
                                            </Select.Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className='select-media__camera-wrapper'>
                                <div className='select-media__camera-video-wrapper'>
                                    <video
                                        className='select-media__camera-preview'
                                        muted={true}
                                        autoPlay={true}
                                        ref={this.videoRef}
                                    />
                                    <div className='select-media__media-actions'>
                                        <div
                                            className={`select-media__media-action mr ${!isCameraEnabled ? 'mute' : ''}`}
                                            onClick={toggleCamera}
                                        >
                                            {isCameraEnabled ? <SmallWebCamIcon /> : <CameraOffIcon />}
                                        </div>
                                        <div
                                            className={`select-media__media-action ${!isCameraEnabled ? 'mute' : ''}`}
                                            onClick={toggleMicro}
                                        >
                                            {isMicroEnabled ? <MicroIcon /> : <MicroOffIcon />}
                                        </div>
                                    </div>
                                    <div className='select-media__sound-meter'>
                                        {stream && <SoundMeter stream={stream} />}
                                    </div>
                                </div>
                                <div className='select-media__camera-text'>
                                    It's your video preview. Nobody don't see this.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='select-media__actions'>
                        {
                            !isEnabled
                                ? (
                                <a className='select-media__flex-link'>
                                    <SecondaryButton disabled={!isEnabled}>
                                        Share web cam
                                    </SecondaryButton>
                                    <PurpleButton disabled={!isEnabled}>
                                        <FormattedMessage id='lets.go' />
                                    </PurpleButton>
                                </a>
                                )
                                : (
                                    <>
                                        <Link to={`${Routes.ShareWebCam}${location.search}`}>
                                            <SecondaryButton>
                                                Share web cam
                                            </SecondaryButton>
                                        </Link>
                                        <Link to={`${this.getRoute()}${location.search}`}>
                                            <PurpleButton>
                                                <FormattedMessage id='lets.go' />
                                            </PurpleButton>
                                        </Link>
                                    </>
                                )
                        }
                    </div>
                </div>
            </div>
        );
    }
}
