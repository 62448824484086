import React from 'react';
import { action, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import { observer } from 'mobx-react';
import { Modal, Upload } from 'antd';
import { ImageTypesEnum, IUserImage } from '@common-types';
import { axiosInstance, getAxiosHeaders, showWarnMessage } from '@utils';
import { PurpleButton } from '@components';
import { CloseIcon, PaperClipIcon, TrashRedIcon, UploadIcon } from '@assets/icons';
import './styles.scss';

interface IProps {
    addFile: (file: IUserImage) => void;
    isModalOpen: boolean;
    closeModal: () => void;
    userId: number;
    fileType: ImageTypesEnum;
}

@observer
export class CustomFileUpload extends React.Component<IProps> {
    uploadedImages: {
        img: IUserImage;
        name: string;
        originFileObj: File | Blob;
    }[] = [];

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            uploadedImages: observable,
            onClose: action,
            handleChange: action,
            removeFile: action,
            addFile: action,
        });
    }

    beforeUpload = () => {
        return false;
    };

    removeFile = (id: string) => {
        this.uploadedImages = this.uploadedImages.filter((img) => {
            return img?.img?.id !== id;
        });
    };

    addFile = (img: IUserImage, name: string, originFileObj: File | Blob) => {
        if (this.uploadedImages.every((file) => file.img.id !== img.id)) {
            this.uploadedImages.push({
                img,
                name,
                originFileObj,
            });
        }
    };

    onClose = () => {
        this.props.closeModal();
        this.uploadedImages = [];
    };

    handleChange = async (info) => {
        info.fileList.forEach((file) => {
            if (file.size < 2 * 1024 * 1024) {
                this.addFile({
                    id: v4(),
                    src: '',
                    type: this.props.fileType,
                }, file.name, file.originFileObj);
            } else {
                showWarnMessage('File should be max 2mbs');
            }
        });
    };

    saveFiles = async () => {
        let promises = [];

        if (this.uploadedImages && this.uploadedImages.length) {
            promises = this.uploadedImages.map(async ({ img, originFileObj }) => {
                try {
                    const formData = new FormData();
                    formData.append('image', originFileObj);
                    formData.append('userId', `${this.props.userId}`);
                    formData.append('type', this.props.fileType);
                    formData.append('id', img.id);

                    const { data } = await axiosInstance.post('/api/images', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            ...getAxiosHeaders().headers,
                        },
                    });

                    data.src = `/images/${data.src}`;

                    const file: IUserImage = {
                        id: img.id,
                        src: data.src,
                        type: this.props.fileType,
                    };

                    this.props.addFile(file);
                } catch (error) {
                    console.error(error);
                }
            });
        }

        await Promise.all(promises);
        this.onClose();
    };

    render(): React.ReactNode {
        const { isModalOpen, closeModal, fileType } = this.props;

        return (
            <div className="file-upload">
                {
                    isModalOpen && (
                        <Modal
                            className="file-upload__modal"
                            destroyOnClose={true}
                            visible={isModalOpen}
                            onCancel={closeModal}
                            closable={true}
                            width={400}
                            title={null}
                            footer={null}
                            closeIcon={<CloseIcon />}
                        >
                            <div className="file-upload__header">
                                <UploadIcon />
                                <div>
                                    Upload Media
                                </div>
                            </div>
                            <div
                                className="file-upload__main"
                                style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <div className="file-upload__text">
                                    Upload your media assets
                                </div>
                                <Upload.Dragger
                                    className="file-upload__dragger"
                                    maxCount={fileType === ImageTypesEnum.Avatar ? 1 : 5}
                                    accept="image/*"
                                    name='image'
                                    multiple={fileType !== ImageTypesEnum.Avatar}
                                    showUploadList={false}
                                    beforeUpload={this.beforeUpload}
                                    onChange={this.handleChange}
                                >
                                    <div className="file-upload__upload-inner">
                                        <UploadIcon />
                                        <div className="file-upload__upload-inner-text">
                                            Click or drag image to this area to upload
                                        </div>
                                    </div>
                                </Upload.Dragger>
                            </div>
                            {
                                this.uploadedImages.length
                                    ? (
                                        <div className="file-upload__file-list">
                                            {
                                                this.uploadedImages.map(({ img, name }) => {
                                                    return (
                                                        <div className="file-upload__file" key={img.id}>
                                                            <div className="file-upload__file-left">
                                                                <div className="file-upload__file-icon">
                                                                    <PaperClipIcon />
                                                                </div>
                                                                <div className="file-upload__file-name">
                                                                    {name}
                                                                </div>
                                                            </div>
                                                            <div className="file-upload__file-remove" onClick={() => this.removeFile(img.id)}>
                                                                <TrashRedIcon />
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    ) : null
                            }
                            <div className="file-upload__footer">
                                <PurpleButton onClick={this.saveFiles}>
                                    Save Media
                                </PurpleButton>
                            </div>
                        </Modal>
                    )
                }
            </div>
        );
    }
}
