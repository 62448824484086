import React from 'react';
import { Drawer, Collapse, Switch } from 'antd';
import {
    PlusCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import { observer } from 'mobx-react';
import { IStreamDestination } from '@common-types';
import {
    CloseIcon,
    SearchIcon,
    RTMPIcon,
    TrashIcon,
    RTMPColorIcon,
    SettingsGrayIcon,
} from '@assets/icons';
import { DESTINATIONS, ADULT_DESTINATIONS } from '@constants';
import './styles.scss';

interface IProps {
    isDestinationsDrawerOpen: boolean;
    destinations: IStreamDestination[];
    closeDestinationsDrawer: () => void;
    removeDestination: (id: string | number) => void;
    toggleDestinationActive: (destination: IStreamDestination, active: boolean) => void;
    openDestinationsModal: () => void;
    selectDestination: (destination: IStreamDestination) => void;
}

@observer
export class DestinationsDrawer extends React.Component<IProps> {
    selectDestination = (destination: IStreamDestination) => {
        const { openDestinationsModal, selectDestination, destinations } = this.props;
        const savedDestination = destinations.find(({ id }) => {
            return id === destination.id;
        });

        openDestinationsModal();
        selectDestination(savedDestination ? savedDestination : destination);
    };

    render(): React.ReactNode {
        const {
            isDestinationsDrawerOpen,
            closeDestinationsDrawer,
            destinations,
            removeDestination,
            toggleDestinationActive,
        } = this.props;

        return (
            <Drawer
                closable={false}
                onClose={closeDestinationsDrawer}
                destroyOnClose={true}
                visible={isDestinationsDrawerOpen}
                width={window.innerWidth > 800 ? 408 : window.innerWidth}
                className="ant-destinations-drawer"
            >
                <div className='destinations-drawer'>
                    <div className="destinations-drawer__top">
                        <div className="destinations-drawer__header">
                            Add Stream Channels
                        </div>
                        <div className="destinations-drawer__actions">
                            <div className="destinations-drawer__icon">
                                <SearchIcon />
                            </div>
                            <div className="destinations-drawer__icon" onClick={closeDestinationsDrawer}>
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                    <div className="destinations-drawer__mid">
                        <Collapse
                            expandIconPosition='right'
                            defaultActiveKey={[
                                'Active',
                                'Custom',
                                'Popular',
                                'Adult',
                            ]}
                        >
                            <Collapse.Panel header={`Active Channels ${destinations.length ? destinations.length : ''}`} key={'Active'}>
                                <div className="destinations-drawer__active">
                                    {
                                        destinations.map((dest) => {
                                            const { id, colorIcon, name, active } = dest;

                                            return (
                                                <div className="destinations-drawer__active-item" key={id}>
                                                    <div className="destinations-drawer__active-icon">
                                                        {colorIcon}
                                                    </div>
                                                    <div className="destinations-drawer__active-name">
                                                        {name}
                                                    </div>
                                                    <div className="destinations-drawer__active-actions">
                                                        <Switch
                                                            checked={active}
                                                            onChange={(a: boolean) => toggleDestinationActive(dest, a)}
                                                        />
                                                        <div
                                                            className="destinations-drawer__active-settings"
                                                            onClick={() => this.selectDestination(dest)}
                                                        >
                                                            <SettingsGrayIcon />
                                                        </div>
                                                        <div
                                                            className="destinations-drawer__active-remove"
                                                            onClick={() => removeDestination(id)}
                                                        >
                                                            <TrashIcon />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </Collapse.Panel>
                            <Collapse.Panel header={`Custom Service`} key={'Custom'}>
                                <div className="destinations-drawer__custom">
                                    <div
                                        className="destinations-drawer__destination"
                                        title="Custom"
                                        onClick={() => {
                                            this.selectDestination({
                                                iconsId: 17,
                                                active: true,
                                                name: '',
                                                url: '',
                                                streamKey: '',
                                                isValid: true,
                                                icon: <RTMPIcon />,
                                                colorIcon: <RTMPColorIcon />,
                                            });
                                        }}
                                    >
                                        <RTMPIcon />
                                    </div>
                                    <div className="destinations-drawer__custom-text">
                                        You can easly add any custom service
                                        for free that you want
                                    </div>
                                </div>
                            </Collapse.Panel>
                            <Collapse.Panel header={`Popular Channels`} key={'Popular'}>
                                <div className="destinations-drawer__list">
                                    {
                                        DESTINATIONS.map((destination: IStreamDestination) => {
                                            const { name, icon } = destination;

                                            return (
                                                <div
                                                    onClick={() => this.selectDestination(destination)}
                                                    className="destinations-drawer__destination"
                                                    key={name}
                                                    title={name}
                                                >
                                                    {icon}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </Collapse.Panel>
                            <Collapse.Panel header={`Adult Channels`} key={'Adult'}>
                                <div className="destinations-drawer__list">
                                    {
                                        ADULT_DESTINATIONS.map((destination: IStreamDestination) => {
                                            const { name, icon } = destination;

                                            return (
                                                <div
                                                    onClick={() => this.selectDestination(destination)}
                                                    className="destinations-drawer__destination"
                                                    key={name}
                                                    title={name}
                                                >
                                                    {icon}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </Collapse.Panel>
                        </Collapse>
                    </div>
                </div>
            </Drawer>
        );
    }
}
