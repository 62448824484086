import React from 'react';

interface IProps {}

export const UnderIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 14.1667C12.7584 14.1667 15 11.925 15 9.16667V2.5H12.9167V9.16667C12.9167 10.775 11.6084 12.0833 10 12.0833C8.39169 12.0833 7.08335 10.775 7.08335 9.16667V2.5H5.00002V9.16667C5.00002 11.925 7.24169 14.1667 10 14.1667ZM4.16669 15.8333V17.5H15.8334V15.8333H4.16669Z" fill="#748499"/>
        </svg>
    );
};
