import React from 'react';
import { observable, makeObservable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PurpleButton } from '@components';
import { Routes } from '@common-types';
import { LogoIcon } from '@assets/icons';
import { AppStore } from '@services';
import { isValidEmail } from '@utils';
import bgTop from '../../assets/images/bg-top.png';
import bgBottom from '../../assets/images/bg-bottom.png';
import './style.scss';

interface IProps {
    AppStore?: AppStore;
}

@inject('AppStore')
@observer
export class RequestPasswordResetPage extends React.Component<IProps> {
    emailError: string = '';

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            emailError: observable,
            onRequestPasswordReset: action,
            onEmailChange: action,
        });
    }

    onKeyDown = (e) => {
        if (e.code === 'Enter') {
            this.onRequestPasswordReset();
        }
    };

    async componentDidMount() {
        document.body.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount(): void {
        document.body.removeEventListener('keydown', this.onKeyDown);
        this.props.AppStore.clearForm();
    }

    onEmailChange = (e) => {
        this.emailError = '';
        this.props.AppStore!.setEmail(e.target.value);
    };

    onRequestPasswordReset = () => {
        const { email, requestPasswordReset } = this.props.AppStore!;
        let isValid = true;

        if (!email || !isValidEmail(email)) {
            this.emailError = 'Please, input valid email';
            isValid = false;
        }

        if (isValid) {
            requestPasswordReset();
        }
    };

    render(): React.ReactNode {
        const { email } = this.props.AppStore!;

        return (
            <div className="login-page">
                <img className='login-page__bg-top' src={bgTop} alt="background" />
                <img className='login-page__bg-bottom' src={bgBottom} alt="background" />
                <div className="login-page__wrapper">
                    <div className="login-page__logo">
                        <Link to={Routes.Main}>
                            <LogoIcon />
                        </Link>
                    </div>
                    <div className="login-page__form">
                        <div className="login-page__text-wrapper">
                            <div className="login-page__text-header">
                                Password Recovery
                            </div>
                            <div className="login-page__text">
                                You can recovery password if you forgot yours
                            </div>
                        </div>
                        <div className='login-page__input-wrapper'>
                            <div className='login-page__label'>
                                <FormattedMessage id='email' />
                            </div>
                            <div className='login-page__input'>
                                <Input
                                    placeholder={'Email'}
                                    value={email}
                                    type={'email'}
                                    onChange={this.onEmailChange}
                                />
                            </div>
                            {
                                this.emailError && (
                                    <div className="login-page__error">
                                        {this.emailError}
                                    </div>
                                )
                            }
                        </div>
                        <div className="login-page__links">
                            <PurpleButton onClick={this.onRequestPasswordReset}>
                                RECOVERY PASSWORD
                            </PurpleButton>
                            <div className="login-page__links-footer">
                                Don't have an account?{' '}
                                <Link to={`${Routes.Register}${location.search}`}>
                                    Sign Up
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
