import React from 'react';

interface IProps {}

export const SteamIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="63" height="22" viewBox="0 0 63 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M62.0939 14.5805H60.7991V10.4426L59.2886 13.1366H58.4254L57.1306 10.4426V14.5805H55.6201V7.4685H57.1306L58.857 11.3047L60.7991 7.4685H62.0939V14.5805ZM53.6779 14.5805H52.1673L51.7357 13.3305H49.362L48.9304 14.5805H47.4198L50.0094 7.4685H51.0884L53.6779 14.5805ZM51.5199 12.1452L50.6568 9.559L49.7936 12.1452H51.5199ZM45.2619 14.5805H40.5144V7.4685H45.2619V8.69694H42.025V10.378H44.8303V11.6064H42.025V13.3521H45.2619V14.5805ZM38.3565 8.69694H36.6301V14.5805H35.1196V8.69694H33.1774V7.4685H38.3565V8.69694ZM31.451 12.49C31.451 13.9555 30.1563 14.6236 28.8615 14.6452C27.5667 14.6452 26.7036 14.3434 26.272 13.6969L27.1352 12.8133C27.5667 13.2012 27.9983 13.4167 28.8615 13.4167C29.7247 13.4167 30.1563 13.115 30.1563 12.5331C30.1563 11.9943 29.7247 11.7573 29.2931 11.6711L28.4299 11.5633C27.1352 11.3909 26.4878 10.7228 26.4878 9.559C26.4878 8.91245 26.7036 8.39522 27.1352 7.98574C27.5667 7.59781 28.2141 7.40385 28.8615 7.40385C29.9405 7.40385 30.5879 7.68402 31.2352 8.22281L30.3721 9.10642C29.9405 8.78314 29.5089 8.61073 28.8615 8.61073C28.2141 8.65384 27.7825 8.8909 27.7825 9.5159C27.7825 10.0116 28.2141 10.2486 28.6457 10.2918L29.5089 10.4211C30.5879 10.5504 31.451 11.2831 31.451 12.49Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.504457 14.2084C1.87667 18.7187 6.06679 22 11.0229 22C17.0942 22 22.0162 17.075 22.0162 11C22.0162 4.92488 17.0942 0 11.0229 0C5.19685 0 0.429731 4.53493 0.0532227 10.2692C0.0547513 10.2718 0.0562779 10.2743 0.0578026 10.2769C0.0578173 10.2766 0.0578296 10.2764 0.0578393 10.2763L0.0578656 10.2758L5.96489 12.709C6.12559 12.6033 6.29686 12.5117 6.47964 12.4371C6.89428 12.2662 7.33155 12.1897 7.76732 12.2095L10.4574 8.29938C10.4565 8.28201 10.4565 8.2635 10.4565 8.245C10.4565 5.93736 12.3296 4.06009 14.632 4.06009C16.9354 4.06009 18.8076 5.93736 18.8076 8.245C18.8076 10.5524 16.9354 12.4314 14.632 12.4314C14.6009 12.4314 14.5688 12.4305 14.5377 12.4295L10.6886 15.1819C10.6999 15.6068 10.6216 16.0326 10.4536 16.4386C9.8082 17.9918 8.01918 18.7312 6.46757 18.0825C5.51922 17.6869 4.87642 16.8645 4.66674 15.9269L0.504457 14.2084ZM6.73518 17.4385C7.93321 17.938 9.31148 17.3687 9.81104 16.1686C10.0526 15.5888 10.0535 14.9468 9.81481 14.3652C9.57607 13.7835 9.12558 13.3284 8.54497 13.0867C7.96907 12.8469 7.35269 12.8544 6.81048 13.0603L8.21564 13.6428C9.09869 14.0111 9.51662 15.027 9.14823 15.9118C8.78097 16.7955 7.76732 17.2148 6.8838 16.8465L5.5245 16.2828C5.76522 16.7861 6.1825 17.2082 6.73518 17.4385ZM11.8502 8.245C11.8502 9.78292 13.0986 11.034 14.632 11.034C16.1664 11.034 17.4148 9.78292 17.4148 8.245C17.4148 6.7083 16.1664 5.45685 14.632 5.45685C13.0986 5.45685 11.8502 6.7083 11.8502 8.245ZM14.6368 6.14546C13.4836 6.14546 12.5476 7.08381 12.5476 8.24075C12.5476 9.39778 13.4836 10.3353 14.6368 10.3353C15.7908 10.3353 16.7269 9.39778 16.7269 8.24075C16.7269 7.08381 15.7908 6.14546 14.6368 6.14546Z" fill="white"/>
        </svg>
    );
};