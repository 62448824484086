import React from 'react';
import * as process from 'process';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { APP_SELECTOR } from '@constants';
import { App } from '@components';
import { RootStore } from '@services';
import 'antd/dist/antd.css';
import './styles.scss';

(window as any).global = window;
(window as any).process = process;
(window as any).Buffer = [];

const appNode: HTMLDivElement = document.querySelector(APP_SELECTOR) as HTMLDivElement;
const rootStore = new RootStore();
const stores = {
    AppStore: rootStore.appStore,
    GuestStore: rootStore.guestStore,
    UserMediaStore: rootStore.userMediaStore,
    SimpleModeStore: rootStore.simpleModeStore,
    UsersConferenceStore: rootStore.usersConferenceStore,
    StreamViewStore: rootStore.streamViewStore,
    ShareWebCamStore: rootStore.shareWebCamStore,
    ShareWebCamConferenceStore: rootStore.shareWebCamConferenceStore,
    AdvanceModeStore: rootStore.advanceModeStore,
};

ReactDOM.render(
    <Provider {...stores}>
        <BrowserRouter
            getUserConfirmation={(message, callback) => {
                const allowTransition = window.confirm(message);
                callback(allowTransition);
            }}
        >
            <App />
        </BrowserRouter>
    </Provider>,
    appNode,
);
