import React from 'react';

interface IProps {}

export const FontIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.32001 2.3999H19.68C20.736 2.3999 21.6 3.2639 21.6 4.31992V19.6799C21.6 20.7359 20.736 21.5999 19.68 21.5999H4.32001C3.26399 21.5999 2.39999 20.7359 2.39999 19.6799V4.31992C2.39999 3.2639 3.26399 2.3999 4.32001 2.3999ZM10.3373 14.7843L9.7023 16.7999H7.19999L10.4502 7.1999H13.5498L16.8 16.7999H14.2977L13.6627 14.7843H10.3373ZM11.96 9.61865L10.8923 13.0218H13.1077L12.0353 9.61865H11.96Z" fill="#748499"/>
        </svg>
    );
};
