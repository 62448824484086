import React from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

interface IProps {

}

export class LiveLabel extends React.Component<IProps> {
    render(): React.ReactNode {
        return (
            <div className="live-label">
                <FormattedMessage id="live" />
            </div>
        );
    }
}
