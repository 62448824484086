import React from 'react';
import { Dropdown, Input, Menu } from 'antd';
import { v4 } from 'uuid';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DeleteGrayIcon, EditGrayIcon, PlusCircleIcon, ThreeDotsIcon } from '@assets/icons';
import { IAudioSource, ILayer, IScene, LayerActionsEnum, LayerTypeEnum } from '@common-types';
import { getLayerIconByType, getLayerNameByType, isFirefox } from '@utils';
import './styles.scss';

interface IProps {
    audioSources: IAudioSource[];
    scene: IScene;
    updateId: string;
    addLayer: (scene: IScene, layer: ILayer) => Promise<void>;
    removeLayer: (scene: IScene, layerId: string) => Promise<void>;
    renameLayer: (layer: ILayer, name: string) => Promise<void>;
    swapLayers: (scene: IScene, startIndex: number, endIndex: number) => Promise<void>;
    selectedLayer: ILayer;
    selectLayer: (layer: ILayer) => void;
    openImageDrawer: () => void;
    openCameraDrawer: () => void;
    openMicroDrawer: () => void;
}

@observer
export class AdvanceLayers extends React.Component<IProps> {
    layerToRename: ILayer = null;
    name: string = '';

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            layerToRename: observable,
            name: observable,
            handleLayersMenuClick: action,
            onNameBlur: action,
            onNameChange: action,
        });
    }

    handleMenuClick = async (e) => {
        const { scene } = this.props;
        const sameTypeLayersCount = scene.layers.reduce((res: number, layer: ILayer) => {
            if (layer.type === e.key as LayerTypeEnum) {
                res++;
            }

            return res;
        }, 0);
        const layer = {
            index: 0,
            name: `${getLayerNameByType(e.key as LayerTypeEnum)} ${sameTypeLayersCount ? sameTypeLayersCount : ''}`,
            node: null,
            specificData: null,
            type: e.key as LayerTypeEnum,
            id: v4(),
        };

        if (e.key === LayerTypeEnum.Text) {
            this.addTextLayer(layer);
        } else if (e.key === LayerTypeEnum.Image) {
            this.addImageLayer();
        } else if (e.key === LayerTypeEnum.Camera) {
            this.addCameraLayer();
        } else if (e.key === LayerTypeEnum.SlideShow) {
            this.addSlideShowLayer(layer);
        } else if (e.key === LayerTypeEnum.BrowserWindow) {
            this.addBrowserWindowLayer(layer);
        } else if (e.key === LayerTypeEnum.Micro) {
            this.addMicro();
        }
    };

    addMicro = () => {
        this.props.openMicroDrawer();
    };

    addBrowserWindowLayer = async (layer: ILayer) => {
        try {
            const constraint: MediaStreamConstraints = {
                video: {
                    // @ts-ignore
                    cursor: 'always',
                },
                audio: true,
            };

            if (!isFirefox()) {
                (constraint.video as MediaTrackConstraints).frameRate = {
                    max: 25,
                };
            }

            // @ts-ignore
            const stream: MediaStream = await navigator.mediaDevices.getDisplayMedia(constraint);
            const track = stream.getVideoTracks()[0];

            const { addLayer, scene, selectLayer } = this.props;

            await addLayer(scene, {
                ...layer,
                specificData: {
                    stream,
                },
            });

            const createdLayer = scene.layers.find(({ id }) => {
                return id === layer.id;
            });

            track.onended = () => {
                this.handleLayersMenuClick(createdLayer, LayerActionsEnum.Delete);
            };

            if (createdLayer) {
                selectLayer(createdLayer);
            }
        } catch (e) {
            console.error(e);
        }
    };

    addTextLayer = async (layer: ILayer) => {
        const { addLayer, scene, selectLayer } = this.props;

        await addLayer(scene, {
            ...layer,
            specificData: {
                text: 'Double-click to add text...'
            },
        });
        const createdLayer = scene.layers.find(({ id }) => {
            return id === layer.id;
        });

        if (createdLayer) {
            selectLayer(createdLayer);
        }
    };

    addImageLayer = () => {
        this.props.openImageDrawer();
    };

    addCameraLayer = () => {
        this.props.openCameraDrawer();
    };

    addSlideShowLayer = (layer: ILayer) => {

    };

    getLayersMenu = () => {
        const types = [
            LayerTypeEnum.Text,
            LayerTypeEnum.Image,
            // LayerTypeEnum.SlideShow,
            LayerTypeEnum.Camera,
            LayerTypeEnum.BrowserWindow,
            LayerTypeEnum.Micro,
        ];

        return (
            <Menu onClick={this.handleMenuClick}>
                {
                    types.map((type: LayerTypeEnum) => {
                        return (
                            <Menu.Item key={type}>
                                {getLayerIconByType(type)} {getLayerNameByType(type)}
                            </Menu.Item>
                        );
                    })
                }
            </Menu>
        );
    };

    createLayerMenu = (layer: ILayer) => {
        return () => {
            return (
                <Menu onClick={(e) => {
                    setTimeout(() => {
                        this.handleLayersMenuClick(layer, e.key as LayerActionsEnum);
                    });
                }}>
                    <Menu.Item key={LayerActionsEnum.Delete}>
                        Delete
                    </Menu.Item>
                    <Menu.Item key={LayerActionsEnum.Rename}>
                        Rename
                    </Menu.Item>
                </Menu>
            );
        };
    };

    handleLayersMenuClick = (layer: ILayer, action: LayerActionsEnum) => {
        const { removeLayer, scene } = this.props;

        if (action === LayerActionsEnum.Delete) {
            removeLayer(scene, layer.id);
        } else if (action === LayerActionsEnum.Rename) {
            this.layerToRename = layer;
            this.name = layer.name;
        }
    };

    onDoubleClick = (layer: ILayer) => {
        this.layerToRename = layer;
        this.name = layer.name;
    };

    onDragEnd = (result) => {
        const { scene, swapLayers } = this.props;

        if (!result.destination || result.source.index === result.destination.index) {
            return;
        }

        this.onNameBlur();
        swapLayers(scene, result.source.index, result.destination.index);
    };

    onNameBlur = () => {
        if (this.layerToRename) {
            this.props.renameLayer(this.layerToRename, this.name);
            this.layerToRename = null;
            this.name = '';
        }
    };

    onNameChange = (e) => {
        this.name = e.target.value;
    };

    onKeyDown = (e) => {
        if (e.code === 'Enter') {
            this.onNameBlur();
        }
    };

    deselectLayer = () => {
        this.props.selectLayer(null);
    };

    handleClickOutside = (e: MouseEvent) => {
        if (!(
            (e.target as HTMLDivElement)?.classList?.contains('advance-layers__layer') &&
            (e.target as HTMLDivElement)?.closest('.advance-layers__layer')
        )) {
            this.deselectLayer();
        }
    };

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    render(): React.ReactNode {
        if (!this.props.scene) {
            return null;
        }

        const { scene, updateId, selectedLayer, selectLayer, audioSources = [] } = this.props;
        const { layers } = scene;
        this.name;
        this.layerToRename;

        return (
            <div className='advance-layers'>
                <Dropdown
                    overlay={this.getLayersMenu()}
                    trigger={['click']}
                    placement="bottomRight"
                    overlayClassName={'layers-dd'}
                >
                    <div className='advance-layers__top-section'>
                        <div className='advance-layers__top-text'>
                            Layers List
                        </div>
                        <div className='advance-layers__button'>
                            <div className='advance-layers__button-text'>
                                <PlusCircleIcon /> Add New Layer
                            </div>
                        </div>
                    </div>

                </Dropdown>
                <div
                    className={`advance-layers__list ${audioSources.length > 0 ? 'with-sources' : ''}`}
                >
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="layers">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="advance-layers__list-wrapper"
                                >
                                    {
                                        layers.map((layer: ILayer) => {
                                            const { id, index, name, type } = layer;

                                            return (
                                                <Draggable key={id} draggableId={`${id}`} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={`advance-layers__layer ${selectedLayer?.id === layer.id ? 'selected' : ''}`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                this.onNameBlur();
                                                                selectLayer(layer);
                                                            }}
                                                        >
                                                            <div
                                                                className="advance-layers__layer-left"
                                                                onDoubleClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    this.onDoubleClick(layer);
                                                                }}>
                                                                {getLayerIconByType(type)}
                                                                {
                                                                    this.layerToRename === layer
                                                                        ? <Input
                                                                            onKeyDown={this.onKeyDown}
                                                                            className="advance-name-input"
                                                                            autoFocus={true}
                                                                            value={this.name}
                                                                            onChange={this.onNameChange}
                                                                            onBlur={this.onNameBlur}
                                                                        />
                                                                        : name
                                                                }
                                                            </div>
                                                            <div className="advance-layers__layer-right-wrap">
                                                                <div className="advance-layers__icon-wrap" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    this.handleLayersMenuClick(layer, LayerActionsEnum.Rename);
                                                                }}>
                                                                    <EditGrayIcon />
                                                                </div>
                                                                <div className="advance-layers__icon-wrap" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    this.handleLayersMenuClick(layer, LayerActionsEnum.Delete);
                                                                }}>
                                                                    <DeleteGrayIcon />
                                                                </div>
                                                                <Dropdown
                                                                    overlay={this.createLayerMenu(layer)()}
                                                                    trigger={['click']}
                                                                >
                                                                    <div className="advance-layers__layer-right">
                                                                        <ThreeDotsIcon />
                                                                    </div>
                                                                </Dropdown>
                                                            </div>


                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        );
    }
}
