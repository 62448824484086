import React from 'react';
import { ILayer, IScene } from '@common-types';
import './styles.scss';
import { DeleteGrayIcon, StretchToCanvasIcon, ToBottomIcon, ToTopIcon } from '@assets/icons';

interface IProps {
    selectedScene: IScene;
    layer: ILayer;
    moveToTop: () => void;
    moveToBottom: () => void;
    removeLayer: () => void;
    stretchToCanvas: () => void;
}

export class ImageMenu extends React.Component<IProps> {
    render(): React.ReactNode {
        const {
            layer,
            selectedScene,
            moveToTop,
            moveToBottom,
            removeLayer,
            stretchToCanvas,
        } = this.props;

        return (
            <div className="advance-top-menu__wrapper image-menu">
                <div
                    className={`advance-top-menu__button ${layer.index < selectedScene?.layers.length - 1 ? '' : 'disabled'}`}
                    onClick={moveToBottom}
                >
                    <ToBottomIcon />
                </div>
                <div
                    className={`advance-top-menu__button ${layer.index > 0 ? '' : 'disabled'}`}
                    onClick={moveToTop}
                >
                    <ToTopIcon />
                </div>
                <div className="advance-top-menu__button" onClick={removeLayer}>
                    <DeleteGrayIcon />
                </div>
                <div className="advance-top-menu__button" onClick={stretchToCanvas}>
                    <StretchToCanvasIcon />
                </div>
            </div>
        );
    }
}
