import React from 'react';
import { ConfigProvider } from 'antd';
import { observer, inject } from 'mobx-react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { IntlProvider } from 'react-intl'
import { getAntLocale, getMessages, Locales } from '@i18n';
import {
    DependencyInjector,
    SimpleMode,
    AdvancedMode,
    SelectMedia,
    Guest,
    LoginPage,
    ShareWebCamPage,
    SharedWebCamPage,
    RegisterPage,
    RequestPasswordResetPage,
    PasswordResetPage,
    MainPage,
} from '@components';
import { Routes } from '@common-types';
import { AppStore, UserMediaStore } from '@services';
import './styles.scss';

interface IProps {
    AppStore?: AppStore;
    UserMediaStore?: UserMediaStore;
}

@inject('AppStore', 'UserMediaStore')
@observer
export class App extends React.Component<IProps> {
    isUserSelectMedia = (): boolean => {
        const { name, selectedMicro, selectedCamera, videoResolution } = this.props.UserMediaStore;

        return !!(
            name && videoResolution && (selectedCamera || selectedMicro)
        );
    };

    render(): React.ReactNode {
        const { locale } = this.props.AppStore!;

        return (
            <ConfigProvider locale={getAntLocale(locale)}>
                <IntlProvider
                    messages={getMessages(locale)}
                    locale={locale}
                    defaultLocale={Locales.English}
                >
                    <DependencyInjector />
                    <div className='app'>
                        <Switch>
                            <Route path={Routes.Login} exact={true}>
                                <LoginPage />
                            </Route>
                            <Route path={Routes.Register} exact={true}>
                                <RegisterPage />
                            </Route>
                            <Route path={Routes.SelectMedia} exact={true}>
                                <SelectMedia />
                            </Route>
                            <Route path={Routes.PasswordReset} exact={true}>
                                <PasswordResetPage />
                            </Route>
                            <Route path={Routes.SharedWebCam} exact={true}>
                                <SharedWebCamPage />
                            </Route>
                            <Route path={Routes.RequestPasswordReset} exact={true}>
                                <RequestPasswordResetPage />
                            </Route>
                            <Route path={Routes.GuestMode}>
                                {
                                    this.isUserSelectMedia()
                                        ? <Guest />
                                        : <Redirect to={`${Routes.SelectMedia}${location.search}`} />
                                }
                            </Route>
                            <Route path={Routes.SimpleMode} exact={true}>
                                {
                                    this.isUserSelectMedia()
                                        ? <SimpleMode />
                                        : <Redirect to={`${Routes.SelectMedia}${location.search}`} />
                                }
                            </Route>
                            <Route path={Routes.ShareWebCam} exact={true}>
                                {
                                    this.isUserSelectMedia()
                                        ? <ShareWebCamPage />
                                        : <Redirect to={`${Routes.SelectMedia}${location.search}`} />
                                }
                            </Route>
                            <Route path={Routes.AdvancedMode} exact={true}>
                                {
                                    this.isUserSelectMedia()
                                        ? <AdvancedMode />
                                        : <Redirect to={`${Routes.SelectMedia}${location.search}`} />
                                }
                            </Route>
                            <Route path='/'>
                                <MainPage />
                            </Route>
                            <Route path='*'>
                                <MainPage />
                            </Route>
                        </Switch>
                    </div>
                </IntlProvider>
            </ConfigProvider>
        );
    }
}
