import React from 'react';
import { Link } from 'react-router-dom';
import { PurpleButton } from '@components';
import { Routes } from '@common-types';
import { LogoIcon } from '@assets/icons';
import bgTop from '../../assets/images/bg-top.png';
import bgBottom from '../../assets/images/bg-bottom.png';
import './style.scss';

interface IProps {

}

export class MainPage extends React.Component<IProps> {
    render(): React.ReactNode {
        return (
            <div className="main-page">
                <img className='main-page__bg-top' src={bgTop} alt="background" />
                <img className='main-page__bg-bottom' src={bgBottom} alt="background" />
                <div style={{ position: 'relative', zIndex: 3 }}>
                    <div className="main-page__wrapper">
                        <div className="main-page__logo">
                            <LogoIcon />
                        </div>
                    </div>
                    <div className="main-page__text">
                        Welcome to Go.Live
                    </div>
                    <div className="main-page__actions">
                        <PurpleButton>
                            <Link to={Routes.Login}>
                                Sign In
                            </Link>
                        </PurpleButton>
                        <PurpleButton>
                            <Link to={Routes.SelectMedia}>
                                Start conference
                            </Link>
                        </PurpleButton>
                    </div>
                </div>
            </div>
        );
    }
}
