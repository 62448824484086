import React from 'react';

interface IProps {}

export const SlideShowIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 9.04V14.96C10 15.38 10.48 15.61 10.81 15.35L14.51 12.39C14.76 12.19 14.76 11.81 14.51 11.61L10.81 8.65C10.48 8.39 10 8.62 10 9.04ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H18C18.55 5 19 5.45 19 6V18C19 18.55 18.55 19 18 19Z" fill="#748499"/>
        </svg>
    );
};
