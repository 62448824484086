import React from 'react';

interface IProps {}

export const MFCColorIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z" fill="#068B26"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.82 20.8649L9.132 29.9639H6V18.0959H10.962L13.203 25.5479H13.314L15.444 18.0959H20.532V29.9639H17.4L17.712 20.8649H17.601L14.631 29.9639H11.919L8.91 20.8649H8.814H8.82ZM25.461 20.6909V23.0189H30.255V25.6139H25.461V29.9639H22.332V18.0959H30.66V20.6909H25.461ZM38.772 25.4699H42V25.8179C42 26.2469 41.991 26.5979 41.976 26.8679C41.958 27.1379 41.916 27.4139 41.85 27.6929C41.7829 27.9705 41.6747 28.2364 41.529 28.4819C41.3654 28.7492 41.1586 28.9874 40.917 29.1869C40.587 29.4569 40.227 29.6549 39.837 29.7839C39.4589 29.9089 39.067 29.9875 38.67 30.0179C38.1776 30.0493 37.6843 30.0633 37.191 30.0599C36.228 30.0599 35.439 30.0239 34.827 29.9489C34.2472 29.8843 33.6872 29.6994 33.183 29.4059C32.6719 29.0894 32.2772 28.6157 32.058 28.0559C31.794 27.4379 31.662 26.6099 31.662 25.5659V22.4939C31.662 21.2339 31.875 20.2829 32.298 19.6289C32.724 18.9749 33.327 18.5399 34.107 18.3239C34.887 18.1049 35.913 17.9969 37.191 17.9969C38.283 17.9969 39.174 18.1169 39.87 18.3479C40.5231 18.5485 41.0836 18.9749 41.451 19.5509C41.805 20.1209 41.985 20.9009 41.985 21.8909V22.3499H38.757V21.8909C38.7463 21.6155 38.6439 21.3515 38.466 21.1409C38.2995 20.9729 38.0843 20.8617 37.851 20.8229C37.5188 20.7688 37.1825 20.7447 36.846 20.7509C36.255 20.7509 35.823 20.8199 35.55 20.9549C35.4173 21.0185 35.3003 21.1106 35.2075 21.2247C35.1146 21.3388 35.0482 21.4721 35.013 21.6149C34.9155 22.0692 34.8742 22.5336 34.89 22.9979V24.8489C34.89 25.3739 34.908 25.7729 34.941 26.0519C34.9657 26.3023 35.0478 26.5435 35.181 26.7569C35.304 26.9489 35.496 27.0869 35.757 27.1739C36.018 27.2609 36.381 27.3029 36.846 27.3029C37.338 27.3029 37.716 27.2729 37.977 27.2129C38.099 27.1891 38.2149 27.1406 38.3175 27.0704C38.4202 27.0003 38.5074 26.9099 38.574 26.8049C38.706 26.5949 38.772 26.2649 38.772 25.8149V25.4699Z" fill="white"/>
        </svg>
    );
};
