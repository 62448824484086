import React from 'react';

interface IProps {}

export const TicTokIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="58" height="13" viewBox="0 0 58 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M0.460449 0.200909H10.0992L9.21426 2.95455H6.71488V13H3.58767V2.95455H0.466429L0.460449 0.200909Z" fill="white"/>
                <path d="M25.8848 0.200909H35.7448L34.8598 2.95455H32.1452V13H29.0239V2.95455H25.8907L25.8848 0.200909Z" fill="white"/>
                <path d="M10.6851 4.27227H13.7764V13H10.709L10.6851 4.27227Z" fill="white"/>
                <path d="M15.0024 0.165455H18.0878V6.12773L21.1492 3.17318H24.8325L20.9639 6.88409L25.2989 13H21.8967L19.0026 8.75136L18.0878 9.62591V13H15.0024V0.165455Z" fill="white"/>
                <path d="M47.7036 0.165455H50.8249V6.12773L53.8803 3.17318H57.5636L53.695 6.88409L58.0001 13H54.5978L51.7098 8.75136L50.8249 9.62591V13H47.7335L47.7036 0.165455Z" fill="white"/>
                <path d="M12.2338 3.27364C13.0924 3.27364 13.7885 2.58578 13.7885 1.73727C13.7885 0.888762 13.0924 0.200909 12.2338 0.200909C11.3752 0.200909 10.6792 0.888762 10.6792 1.73727C10.6792 2.58578 11.3752 3.27364 12.2338 3.27364Z" fill="white"/>
                <path opacity="0.7" d="M35.3504 7.77636C35.3513 5.05025 37.4734 2.78324 40.2235 2.57045C40.086 2.57045 39.9007 2.57045 39.7631 2.57045C36.9682 2.73156 34.7852 5.01844 34.7852 7.78523C34.7852 10.552 36.9682 12.8389 39.7631 13C39.9007 13 40.086 13 40.2235 13C37.4665 12.7867 35.3419 10.5093 35.3504 7.77636Z" fill="white"/>
                <path opacity="0.7" d="M41.4254 2.55273C41.2819 2.55273 41.0965 2.55273 40.959 2.55273C43.7002 2.77648 45.8097 5.04041 45.8097 7.75864C45.8097 10.4769 43.7002 12.7408 40.959 12.9645C41.0965 12.9645 41.2819 12.9645 41.4254 12.9645C44.3446 12.9645 46.7111 10.6258 46.7111 7.74091C46.7111 4.85597 44.3446 2.51727 41.4254 2.51727V2.55273Z" fill="white"/>
                <path d="M40.588 10.3173C39.168 10.3173 38.0169 9.17967 38.0169 7.77637C38.0169 6.37306 39.168 5.23546 40.588 5.23546C42.008 5.23546 43.1592 6.37306 43.1592 7.77637C43.1559 9.17832 42.0067 10.314 40.588 10.3173ZM40.588 2.55273C37.6688 2.55273 35.3022 4.89143 35.3022 7.77637C35.3022 10.6613 37.6688 13 40.588 13C43.5073 13 45.8738 10.6613 45.8738 7.77637C45.8738 6.39097 45.3169 5.06232 44.3256 4.0827C43.3344 3.10308 41.9899 2.55273 40.588 2.55273Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="58" height="13" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};