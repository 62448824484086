import React from 'react';
import { inject, observer } from 'mobx-react';
import { action, makeObservable, observable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';
import { Drawer, Modal, Tabs, Tooltip, Typography } from 'antd';
import { AdvanceModeStore, AppStore } from '@services';
import { AdvanceModeTabs, ILayer, ImageTypesEnum, IProject, IUserImage, LayerTypeEnum, Routes } from '@common-types';
import {
    BlackButton,
    CustomFileUpload,
    DestinationsDrawer,
    ProjectCreateModal,
    PurpleButton,
    SecondaryButton,
} from '@components';
import { getLayerNameByType, showWarnMessage, preventPageReload } from '@utils';
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    BarsIcon,
    BroadcastIcon,
    CloseIcon,
    EditPenIcon,
    LogoIcon,
    PlusCircleIcon,
    TrashRedIcon
} from '@assets/icons';
import { SIMPLE_MODE_LABEL } from '@constants';
import { AdvancedCanvasWrapper } from '../canvas-wrapper';
import { AdvanceLayers } from '../layers';
import { AddDestinationDrawer } from '../../destinations-drawer/add-destination-drawer';
import { ProjectDrawer } from '../../simple-mode/projects-drawer';
import { AdvanceScenes } from '../scenes';
import { ImageDrawer } from '../image-drawer';
import { CameraDrawer } from '../camera-drawer';
import { MicrosDrawer } from '../micros-drawer';
import { AudioControls } from '../audio-controls';
import { BrowserCanvas } from '../browser-canvas';
import { AdvanceUsers } from '../users';
import './styles.scss';

interface IProps {
    AdvanceModeStore?: AdvanceModeStore;
    AppStore?: AppStore;
}

@inject('AdvanceModeStore', 'AppStore')
@observer
export class AdvancedMode extends React.Component<IProps> {
    isDrawerOpen: boolean = true;
    isLayersOpen: boolean = false;
    isMenuOpen: boolean = false;

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            isDrawerOpen: observable,
            isMenuOpen: observable,
            isLayersOpen: observable,
            openDrawer: action,
            closeDrawer: action,
            openLayersDrawer: action,
            closeLayersDrawer: action,
            selectMicro: action,
        });
    }

    toggleMenu = () => {
        this.isMenuOpen = !this.isMenuOpen;
    };

    openLayersDrawer = () => {
        this.isLayersOpen = true;
    };

    closeLayersDrawer = () => {
        this.isLayersOpen = false;
    };

    openDrawer = () => {
        this.isDrawerOpen = true;
    };

    closeDrawer = () => {
        this.isDrawerOpen = false;
    };

    onTabChange = (tab: string) => {
        this.props.AdvanceModeStore!.changeTab(tab);
    };

    componentDidMount(): void {
        // window.addEventListener('beforeunload', preventPageReload);
    }

    componentWillUnmount(): void {
        // window.removeEventListener('beforeunload', preventPageReload);
        location.reload();
    }

    selectProject = (project: IProject) => {
        const { setSelectedToEditProject, openProjectCreateModal } = this.props.AdvanceModeStore;

        setSelectedToEditProject(project);
        openProjectCreateModal();
    };

    deleteProject = (id?: number) => {
        const { selectedProject, deleteProject } = this.props.AdvanceModeStore!;

        Modal.confirm({
            className: 'delete-project-confirm-modal',
            content: "Are you sure you want to delete your project? You will lose all your settings and data.",
            title: (
                <div className="custom-confirm-popup-header">
                    <TrashRedIcon />
                    <div className="custom-confirm-popup-title-text">
                        Delete Project
                    </div>
                </div>
            ),
            okText: `YES, I'm Sure`,
            cancelText: 'No, Cancel',
            okButtonProps: {
                className: 'secondary-button'
            },
            cancelButtonProps: {
                className: 'purple-button'
            },
            onCancel: () => {
                Modal.destroyAll();
            },
            onOk: () => {
                deleteProject(id ? id : selectedProject?.id);
                Modal.destroyAll();
            },
            closeIcon: <CloseIcon />,
            closable: true,
            width: 400,
        });
    };

    addImageLayer = async (img: IUserImage) => {
        const { selectedScene, addLayer, selectLayer } = this.props.AdvanceModeStore;
        const sameTypeLayersCount = selectedScene.layers.reduce((res: number, layer: ILayer) => {
            if (layer.type === LayerTypeEnum.Image) {
                res++;
            }

            return res;
        }, 0);
        const layer = {
            index: 0,
            name: `${getLayerNameByType(LayerTypeEnum.Image)} ${sameTypeLayersCount ? sameTypeLayersCount : ''}`,
            node: null,
            specificData: {
                img,
            },
            type: LayerTypeEnum.Image,
            id: v4(),
        };

        await addLayer(selectedScene, layer);

        const createdLayer = selectedScene.layers.find(({ id }) => {
            return id === layer.id;
        });

        if (createdLayer) {
            selectLayer(createdLayer);
        }
    };

    addImage = (img: IUserImage) => {
        this.props.AdvanceModeStore.addImage(img);
        this.addImageLayer(img);
        this.props.AdvanceModeStore.closeImageDrawer();
    };

    selectImage = (img: IUserImage) => {
        this.addImageLayer(img);
        this.props.AdvanceModeStore.closeImageDrawer();
    };

    selectMicro = async (device: MediaDeviceInfo) => {
        try {
            const { addAudioSource, closeMicroDrawer, selectedProject } = this.props.AdvanceModeStore;

            addAudioSource({
                id: v4(),
                deviceId: device.deviceId,
                muted: false,
                deviceInfo: device,
                name: device.label || `Source ${selectedProject.audioSources.length || 1}`
            });
            closeMicroDrawer();
        } catch (e) {
            console.error(e);
        }
    };

    selectCamera = async (device: MediaDeviceInfo) => {
        const { selectedScene, addLayer, selectLayer } = this.props.AdvanceModeStore;
        const sameTypeLayersCount = selectedScene.layers.reduce((res: number, layer: ILayer) => {
            if (layer.type === LayerTypeEnum.Camera) {
                res++;
            }

            return res;
        }, 0);
        const layer = {
            index: 0,
            name: `${getLayerNameByType(LayerTypeEnum.Camera)} ${sameTypeLayersCount ? sameTypeLayersCount : ''}`,
            node: null,
            specificData: {
                device,
            },
            type: LayerTypeEnum.Camera,
            id: v4(),
        };

        await addLayer(selectedScene, layer);

        const createdLayer = selectedScene.layers.find(({ id }) => {
            return id === layer.id;
        });

        if (createdLayer) {
            selectLayer(createdLayer);
        }

        this.props.AdvanceModeStore.closeCameraDrawer();
    };

    selectSimpleMode = () => {
        this.props.AppStore.selectMode(SIMPLE_MODE_LABEL);
        location.reload();

    };

    render(): React.ReactNode {
        const {
            streamDate,
            streamName,
            tab,
            openDestinationsDrawer,
            stopStream,
            isStreaming,
            destinations,
            startStream,
            isDestinationsModalShown,
            closeDestinationsModal,
            selectedDestination,
            addDestination,
            selectedProject,
            openProjectCreateModal,
            isProjectCreateModalOpen,
            closeProjectCreateModal,
            user,
            selectedToEditProject,
            selectProject,
            createProject,
            updateProject,
            removeImage,
            closeDestinationsDrawer,
            isDestinationsDrawerOpen,
            openDestinationsModal,
            removeDestination,
            selectDestination,
            toggleDestinationActive,
            selectedScene,
            addLayer,
            removeLayer,
            renameLayer,
            swapLayers,
            layerUpdateId,
            selectedLayer,
            selectLayer,
            isUploadImageModalOpen,
            closeUploadImageModal,
            isImageDrawerOpen,
            closeImageDrawer,
            openUploadImageModal,
            openImageDrawer,
            openCameraDrawer,
            isCameraDrawerOpen,
            closeCameraDrawer,
            isMicroDrawerOpen,
            closeMicroDrawer,
            openMicroDrawer,
            changeSourceDevice,
            toggleAudioSource,
            removeAudioSource,
            setCanvas,
        } = this.props.AdvanceModeStore!;
        const { isUserLoggedIn } = this.props.AppStore!;
        const projects = user ? user.projects : [];

        return (
            <div className='advanced-mode'>
                <div className='advanced-mode__left'>
                    <Link to={`${Routes.SelectMedia}${location.search}`}>
                        <PurpleButton>
                            <LogoIcon />
                        </PurpleButton>
                    </Link>
                    <div className="advanced-mode__projects-list">
                        {
                            isUserLoggedIn ? projects.map((project: IProject) => {
                                const { avatar, id, name } = project;

                                return (
                                    <div
                                        className={`advanced-mode__projects-wrapper ${id === selectedProject?.id ? 'selected' : ''}`}
                                        key={id}
                                        onClick={() => selectProject(project)}
                                    >
                                        <Tooltip title={name}>
                                            <div className="advanced-mode__projects-inner">
                                                {
                                                    avatar
                                                        ? <img className="advanced-mode__projects-img" src={avatar} />
                                                        : <div className="advanced-mode__projects-latter">{name[0]}</div>
                                                }
                                            </div>
                                        </Tooltip>
                                    </div>
                                );
                            }) : null
                        }
                    </div>
                    <Tooltip title={'Create project'} placement={'right'}>
                        <div className="advanced-mode__create-project" onClick={openProjectCreateModal}>
                            <PlusCircleIcon />
                        </div>
                    </Tooltip>
                </div>
                <div className='advanced-mode__middle'>
                    <div className='advanced-mode__header'>
                        <div className='advanced-mode__mobile-open-menu' onClick={this.toggleMenu}>
                            <BarsIcon />
                        </div>
                        <div className='advanced-mode__header-left'>
                            <div className='advanced-mode__text'>
                                <div className='advanced-mode__name'>
                                    <Typography.Paragraph>
                                        {selectedProject ? selectedProject.name : streamName}
                                    </Typography.Paragraph>
                                </div>
                                <div className='advanced-mode__date'>
                                    {
                                        selectedProject
                                            ? format(new Date(selectedProject.createdAt), 'MMM dd - HH:mm:ss')
                                            : streamDate
                                    }
                                </div>
                            </div>
                            {
                                isUserLoggedIn && (
                                    <div className='advanced-mode__header-icons'>
                                        <div className='advanced-mode__header-edit' onClick={() => this.selectProject(selectedProject)}>
                                            <EditPenIcon />
                                        </div>
                                        {
                                            !selectedProject?.isInitial && (
                                                <div className='advanced-mode__header-delete' onClick={() => this.deleteProject()}>
                                                    <TrashRedIcon />
                                                </div>
                                            )
                                        }

                                    </div>
                                )
                            }
                        </div>
                        <div className='advanced-mode__button'>
                            <BlackButton onClick={this.selectSimpleMode}>
                                Simple Mode
                            </BlackButton>
                            <BlackButton onClick={openDestinationsDrawer}>
                                Channels
                            </BlackButton>
                            {
                                isStreaming
                                    ? (
                                        <SecondaryButton onClick={stopStream}>
                                            <BroadcastIcon/> Stop stream
                                        </SecondaryButton>
                                    )
                                    : (
                                        <PurpleButton onClick={() => {
                                            if (destinations.length) {
                                                startStream();
                                            } else {
                                                if (isUserLoggedIn) {
                                                    showWarnMessage('Add destinations first!');
                                                }

                                                openDestinationsDrawer();
                                            }
                                        }}>
                                            <BroadcastIcon/> <FormattedMessage id='go.live'/>
                                        </PurpleButton>
                                    )
                            }

                        </div>
                        <div className='advanced-mode__mobile-open-drawer' onClick={this.openLayersDrawer}>
                            <ArrowLeftIcon />
                        </div>
                    </div>
                    <div className='advanced-mode__middle-wrap'>
                        <AdvancedCanvasWrapper />
                        <AdvanceScenes />
                    </div>
                    <AdvanceUsers />
                </div>
                <div className={`advanced-mode__right ${this.isDrawerOpen ? 'open' : 'close'}`}>
                    <div
                        className={`advanced-mode__close-drawer ${this.isDrawerOpen ? 'left' : 'right'}`}
                        onClick={() => {
                            if (this.isDrawerOpen) {
                                this.closeDrawer();
                            } else {
                                this.openDrawer();
                            }
                        }}
                    >
                        <ArrowLeftIcon />
                    </div>
                    <div className='advanced-mode__right-border' />
                    <div className='advanced-mode__mobile-close-drawer' onClick={this.closeLayersDrawer}>
                        <ArrowRightIcon />
                    </div>
                    <Tabs defaultActiveKey={tab} onChange={this.onTabChange}>
                        <Tabs.TabPane
                            tab={'Layers'}
                            key={AdvanceModeTabs.Layers}
                        >
                            <AdvanceLayers
                                audioSources={selectedProject?.audioSources}
                                updateId={layerUpdateId}
                                scene={selectedScene}
                                addLayer={addLayer}
                                removeLayer={removeLayer}
                                renameLayer={renameLayer}
                                swapLayers={swapLayers}
                                selectedLayer={selectedLayer}
                                selectLayer={selectLayer}
                                openCameraDrawer={openCameraDrawer}
                                openImageDrawer={openImageDrawer}
                                openMicroDrawer={openMicroDrawer}
                            />
                            <AudioControls
                                toggleSource={toggleAudioSource}
                                removeSource={removeAudioSource}
                                changeSourceDevice={changeSourceDevice}
                                sources={selectedProject?.audioSources}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                    {
                        this.isLayersOpen && (
                            <Drawer
                                className="layers-drawer"
                                onClose={this.closeLayersDrawer}
                                visible={this.isLayersOpen}
                                closable={false}
                                width={window.innerWidth > 800 ? 408 : window.innerWidth}
                            >
                                <div className='advanced-mode__mobile-close-drawer' onClick={this.closeLayersDrawer}>
                                    <ArrowRightIcon />
                                </div>
                                <Tabs defaultActiveKey={tab} onChange={this.onTabChange}>
                                    <Tabs.TabPane
                                        tab={'Layers'}
                                        key={AdvanceModeTabs.Layers}
                                    >
                                        <AdvanceLayers
                                            audioSources={selectedProject?.audioSources}
                                            updateId={layerUpdateId}
                                            scene={selectedScene}
                                            addLayer={addLayer}
                                            removeLayer={removeLayer}
                                            renameLayer={renameLayer}
                                            swapLayers={swapLayers}
                                            selectedLayer={selectedLayer}
                                            selectLayer={selectLayer}
                                            openCameraDrawer={openCameraDrawer}
                                            openImageDrawer={openImageDrawer}
                                            openMicroDrawer={openMicroDrawer}
                                        />
                                        <AudioControls
                                            toggleSource={toggleAudioSource}
                                            removeSource={removeAudioSource}
                                            changeSourceDevice={changeSourceDevice}
                                            sources={selectedProject?.audioSources}
                                        />
                                    </Tabs.TabPane>
                                </Tabs>
                            </Drawer>
                        )
                    }
                </div>
                <DestinationsDrawer
                    destinations={destinations}
                    closeDestinationsDrawer={closeDestinationsDrawer}
                    isDestinationsDrawerOpen={isDestinationsDrawerOpen}
                    openDestinationsModal={openDestinationsModal}
                    removeDestination={removeDestination}
                    selectDestination={selectDestination}
                    toggleDestinationActive={toggleDestinationActive}
                />
                {
                    isDestinationsModalShown && (
                        <AddDestinationDrawer
                            selectedDestination={selectedDestination}
                            destinations={destinations}
                            onClose={closeDestinationsModal}
                            onSave={addDestination}
                            name={selectedDestination ? selectedDestination.name : ''}
                            url={selectedDestination ? selectedDestination.url : ''}
                            streamKey={selectedDestination ? selectedDestination.streamKey : ''}
                            isModalShown={isDestinationsModalShown}
                        />
                    )
                }
                {
                    isProjectCreateModalOpen && isUserLoggedIn ? (
                        <ProjectCreateModal
                            project={selectedToEditProject}
                            isModalOpen={isProjectCreateModalOpen}
                            closeModal={closeProjectCreateModal}
                            updateProject={updateProject}
                            removeImage={removeImage}
                            user={user}
                            createProject={createProject}
                        />
                    ) : null
                }
                {
                    this.isMenuOpen && isUserLoggedIn ? (
                        <ProjectDrawer
                            onClose={this.toggleMenu}
                            isDrawerOpen={this.isMenuOpen}
                            deleteProject={this.deleteProject}
                            editProject={this.selectProject}
                        />
                    ) : null
                }
                <CustomFileUpload
                    userId={user?.id}
                    fileType={ImageTypesEnum.AdvanceImage}
                    addFile={this.addImage}
                    isModalOpen={isUploadImageModalOpen}
                    closeModal={closeUploadImageModal}
                />
                {
                    isImageDrawerOpen ? (
                        <ImageDrawer
                            isDrawerOpen={isImageDrawerOpen}
                            closeDrawer={closeImageDrawer}
                            selectImage={this.selectImage}
                            images={user?.images}
                            openUploadImageModal={openUploadImageModal}
                        />
                    ) : null
                }
                {
                    isCameraDrawerOpen ? (
                        <CameraDrawer
                            isDrawerOpen={isCameraDrawerOpen}
                            closeDrawer={closeCameraDrawer}
                            selectCamera={this.selectCamera}
                            addAudioSource={this.selectMicro}
                        />
                    ) : null
                }
                {
                    isMicroDrawerOpen ? (
                        <MicrosDrawer
                            isDrawerOpen={isMicroDrawerOpen}
                            closeDrawer={closeMicroDrawer}
                            selectMicro={this.selectMicro}
                        />
                    ) : null
                }
                <BrowserCanvas setCanvasRef={setCanvas} />
            </div>
        );
    }
}
