import React from 'react';
import { ILayer } from '@common-types';
import { getImageSrc } from '@utils';
import './styles.scss';

interface IProps {
    layer: ILayer;
    setImgRatio: (width: number, height: number) => void;
}

export class ImageElement extends React.Component<IProps> {
    onImageLoad = (e) => {
        this.props.setImgRatio(e.target.naturalWidth, e.target.naturalHeight);
    };

    render(): React.ReactNode {
        const { layer } = this.props;

        return (
            <div className="image-element">
                <img
                    id={layer.id}
                    onLoad={this.onImageLoad}
                    className="image-element__image"
                    src={getImageSrc(layer.specificData?.img?.src)}
                />
            </div>
        );
    }
}
