import React from 'react';
import { action, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import { RootStore } from '@services';
import { isFirefox, showScreenShareErrorMessage, getCameraConstraint, setSinkId } from '@utils';
import { STORAGE_CAMERA_LABEL, STORAGE_MICRO_LABEL, FULL_SCREEN_CONSTRAINTS, STORAGE_AUDIO_LABEL } from '@constants';
import { StreamViewActionsEnum } from '@common-types';
import { Base } from './base-store';

// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext || null;

export class GuestStore extends Base {
    video: HTMLVideoElement;
    updateId: string = v4();
    isCameraView: boolean = true;
    stream: MediaStream = null;
    isVideoMuted: boolean = false;
    isMicroMuted: boolean = false;
    isAudioMuted: boolean = false;
    isAudioOff: boolean = false;
    isVideoOff: boolean = true;
    isMicroOff: boolean = true;
    screenShareStream: MediaStream;
    resultMediaStream: MediaStream;
    audioStream: MediaStream;
    isSettingsModalShown: boolean = false;
    currentCamera: string = null;
    currentMicro: string = null;
    currentAudio: string = null;
    isMutedByAdmin: boolean = false;
    isScreenShare: boolean = false;
    currentVideoMediaTrackConstraints: MediaTrackConstraints = { ...FULL_SCREEN_CONSTRAINTS };
    defaultSSMediaTrackConstraints: MediaTrackConstraints;

    constructor(rootStore: RootStore) {
        super(rootStore);

        makeObservable(this, {
            isScreenShare: observable,
            stream: observable,
            isCameraView: observable,
            updateId: observable,
            isMutedByAdmin: observable,
            isMicroMuted: observable,
            isVideoMuted: observable,
            isVideoOff: observable,
            isMicroOff: observable,
            isSettingsModalShown: observable,
            isAudioMuted: observable,
            isAudioOff: observable,
            currentCamera: observable,
            currentMicro: observable,
            currentAudio: observable,
            setIsCameraView: action,
            updateCurrentAudio: action,
            setCurrentAudio: action,
            setUpdateId: action,
            toggleVideo: action,
            toggleSound: action,
            addCamera: action,
            toggleAudio: action,
            openSettingsModal: action,
            closeSettingsModal: action,
            updateCamera: action,
            updateMicro: action,
            stopScreenShare: action,
            muteByAdmin: action,
            addScreenShare: action,
            onScreenShareEnd: action,
        });
    }

    updateSteamConstraints = (stream: MediaStream) => {
        stream.getVideoTracks().forEach((track: MediaStreamTrack) => {
            track.applyConstraints(this.currentVideoMediaTrackConstraints);
        });
    };

    setCurrentCamera = (camera: string) => {
        this.currentCamera = camera;
        localStorage.setItem(STORAGE_CAMERA_LABEL, camera);
    };

    setCurrentMicro = (micro: string) => {
        this.currentMicro = micro;
        localStorage.setItem(STORAGE_MICRO_LABEL, micro);
    };

    setCurrentAudio = (audio: string) => {
        this.currentAudio = audio;
        localStorage.setItem(STORAGE_AUDIO_LABEL, audio);
    };

    updateCurrentAudio = (deviceId: string) => {
        if (deviceId && deviceId !== this.currentAudio) {
            this.currentAudio = deviceId;
            setSinkId(deviceId);
        }
    };

    openSettingsModal = () => {
        this.isSettingsModalShown = true;
    };

    closeSettingsModal = () => {
        this.isSettingsModalShown = false;
    };

    setUpdateId = () => {
        this.updateId = v4();
    };

    setIsCameraView = (isCameraView: boolean) => {
        this.isCameraView = isCameraView;
    };

    init = async (videoElement: HTMLVideoElement) => {
        this.video = videoElement;

        if (this.rootStore.userMediaStore.selectedMicro || this.rootStore.userMediaStore.selectedCamera) {
            await this.addCamera();
        }
    };

    stopScreenShare = () => {
        this.isScreenShare = false;

        if (this.screenShareStream) {
            this.screenShareStream.getTracks().forEach((track: MediaStreamTrack) => {
                track.stop();
            });
            this.screenShareStream = undefined;
        }
    };

    addCamera = async (cameraId?: string, microId?: string) => {
        try {
            this.setIsCameraView(true);
            this.stopScreenShare();

            const config: MediaStreamConstraints = {
                audio: {
                    deviceId: 'default',
                },
                video: {
                    deviceId: 'default',
                },
            };

            if (microId || this.rootStore.userMediaStore.selectedMicro?.deviceId) {
                this.isMicroOff = false;
                config.audio = {
                    deviceId: microId || this.rootStore.userMediaStore.selectedMicro?.deviceId || 'default',
                };
            }

            if (cameraId || this.rootStore.userMediaStore.selectedCamera?.deviceId) {
                this.isVideoOff = false;
                config.video = {
                    ...getCameraConstraint(),
                    deviceId: cameraId || this.rootStore.userMediaStore.selectedCamera.deviceId || 'default',
                };

                if (!isFirefox()) {
                    config.video.frameRate = { max: 25 };
                }
            }

            if (this.stream) {
                this.stream.getTracks().forEach((track) => {
                    track.stop();
                });
            }

            const stream = await navigator.mediaDevices.getUserMedia(config);

            if (stream && stream.getVideoTracks() && stream.getVideoTracks().length) {
                stream.getVideoTracks()[0].enabled = this.rootStore.userMediaStore.isCameraEnabled;
                this.isVideoMuted = !this.rootStore.userMediaStore.isCameraEnabled;
            }

            if (stream && stream.getAudioTracks() && stream.getAudioTracks().length) {
                stream.getAudioTracks()[0].enabled = this.rootStore.userMediaStore.isMicroEnabled;
                this.isMicroMuted = !this.rootStore.userMediaStore.isMicroEnabled;
            }

            this.video.srcObject = stream;
            this.video.play();
            this.stream = stream;
            this.audioStream = stream;

            this.applyEnabled();

            this.updateSteamConstraints(this.stream);
        } catch (error) {
            console.error(error);
        }
    };

    updateCamera = async (camera: string) => {
        try {
            if (camera !== this.currentCamera) {
                this.stopScreenShare();
                this.currentCamera = camera;

                const constraint: MediaStreamConstraints = {
                    audio: {
                        deviceId: this.currentMicro || 'default',
                    },
                    video: {
                        ...getCameraConstraint(),
                        deviceId: this.currentCamera || 'default',
                    }
                };

                if (!isFirefox()) {
                    (constraint.video as MediaTrackConstraints).frameRate = {
                        max: 25,
                    };
                }

                if (this.stream) {
                    this.stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                }

                const stream = await navigator.mediaDevices.getUserMedia(constraint);

                this.video.srcObject = stream;
                this.video.play();
                this.stream = stream;
                this.audioStream = stream;

                this.applyEnabled();
                this.rootStore.usersConferenceStore.changeTrack(stream.getVideoTracks()[0]);
                this.rootStore.usersConferenceStore.changeTrack(stream.getAudioTracks()[0]);
                this.rootStore.usersConferenceStore.localStream = stream;
                this.updateSteamConstraints(this.rootStore.usersConferenceStore.localStream);
            }
        } catch (error) {
            console.error(error);
        }
    };

    updateMicro = async (micro: string) => {
        try {
            if (micro !== this.currentMicro) {
                this.stopScreenShare();
                this.currentMicro = micro;

                const constraint: MediaStreamConstraints = {
                    audio: {
                        deviceId: this.currentMicro || 'default',
                    },
                    video: {
                        ...getCameraConstraint(),
                        deviceId: this.currentCamera || 'default',
                    }
                };

                if (!isFirefox()) {
                    (constraint.video as MediaTrackConstraints).frameRate = {
                        max: 25,
                    };
                }

                if (this.stream) {
                    this.stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                }

                const stream = await navigator.mediaDevices.getUserMedia(constraint);

                this.video.srcObject = stream;
                this.video.play();
                this.stream = stream;
                this.audioStream = stream;

                this.applyEnabled();
                this.rootStore.usersConferenceStore.changeTrack(stream.getVideoTracks()[0]);
                this.rootStore.usersConferenceStore.changeTrack(stream.getAudioTracks()[0]);
                this.rootStore.usersConferenceStore.localStream = stream;
                this.updateSteamConstraints(this.rootStore.usersConferenceStore.localStream);
            }
        } catch (error) {
            console.error(error)
        }
    };

    addScreenShare = async () => {
        try {
            const constraint: MediaStreamConstraints = {
                audio: true,
                video: {
                    // @ts-ignore
                    cursor: 'always',
                },
            };

            if (!isFirefox()) {
                (constraint.video as MediaTrackConstraints).frameRate = {
                    max: 25,
                };
            }

            // @ts-ignore
            const stream: MediaStream = await navigator.mediaDevices.getDisplayMedia(constraint);

            this.stream.getTracks().forEach((track) => {
                track.stop();
            });

            this.setIsCameraView(false);

            this.video.srcObject = stream;
            this.video.play();
            this.screenShareStream = stream;

            const audioTracks = stream.getAudioTracks();

            if (audioTracks && audioTracks[0] && AudioContext) {
                const audioSteam = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: this.currentMicro || 'default',
                    },
                });
                const audioContext = new AudioContext();
                const destination = audioContext.createMediaStreamDestination();
                const audioScreenShare = audioContext.createMediaStreamSource(stream);
                const audioCamera = audioContext.createMediaStreamSource(audioSteam);

                audioScreenShare.connect(destination);
                audioCamera.connect(destination);

                this.rootStore.usersConferenceStore.changeTrack(destination.stream.getAudioTracks()[0]);
                this.audioStream = destination.stream;
            } else {
                const audioSteam = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: this.currentMicro || 'default',
                    },
                });
                this.rootStore.usersConferenceStore.changeTrack(audioSteam.getAudioTracks()[0]);
                this.audioStream = audioSteam;
            }

            this.rootStore.usersConferenceStore.changeTrack(stream.getVideoTracks()[0]);

            if (stream.getVideoTracks() && stream.getVideoTracks().length) {
                const track = stream.getVideoTracks()[0];
                let w = 1280;
                let h = 720;

                try {
                    const { width, height } = track.getCapabilities();
                    w = width.max;
                    h = height.max;
                } catch (e) {
                    console.error(e);
                }

                this.defaultSSMediaTrackConstraints = {
                    width: w,
                    height: h,
                };

                track.onended = () => {
                    this.onScreenShareEnd();
                };
            }

            this.rootStore.usersConferenceStore.localStream = new MediaStream([
                this.screenShareStream.getVideoTracks()[0],
                this.audioStream.getAudioTracks()[0],
            ]);

            this.isScreenShare = true;
            this.applyEnabled();
            this.updateSteamConstraints(this.rootStore.usersConferenceStore.localStream);
            this.rootStore.streamViewStore.checkActionsAndApplyConstraints(StreamViewActionsEnum.SwapUsers);
        } catch (error) {
            console.error(error);
            showScreenShareErrorMessage(this.dependencies.intl);
        }
    };

    onScreenShareEnd = async () => {
        try {
            this.stopScreenShare();
            this.setIsCameraView(true);
            this.isScreenShare = false;

            const constraint: MediaStreamConstraints = {
                audio: {
                    deviceId: this.currentMicro || 'default',
                },
                video: {
                    ...getCameraConstraint(),
                    deviceId: this.currentCamera || 'default',
                }
            };

            if (!isFirefox()) {
                (constraint.video as MediaTrackConstraints).frameRate = {
                    max: 25,
                };
            }

            if (this.stream) {
                this.stream.getTracks().forEach((track) => {
                    track.stop();
                });
            }

            const stream = await navigator.mediaDevices.getUserMedia(constraint);

            this.video.srcObject = stream;
            this.video.play();
            this.stream = stream;
            this.audioStream = stream;
            this.screenShareStream = undefined;
            this.rootStore.usersConferenceStore.changeTrack(stream.getVideoTracks()[0]);
            this.rootStore.usersConferenceStore.changeTrack(stream.getAudioTracks()[0]);
            this.rootStore.usersConferenceStore.localStream = stream;
            this.updateSteamConstraints(this.rootStore.usersConferenceStore.localStream);

            this.setUpdateId();
        } catch (error) {
            console.error(error);
        }
    };

    getResultedMediaStream = () => {
        try {
            const videoStream: MediaStream = this.video.srcObject as MediaStream;

            const result = new MediaStream([
                videoStream?.getVideoTracks()[0],
                this.audioStream?.getAudioTracks()[0],
            ]);
            this.resultMediaStream = result;

            return result;
        } catch (error) {
            console.error(error);
        }

        return null;
    };

    toggleVideo = () => {
        const cameraTracks = this.stream?.getVideoTracks();
        const screenShareTracks = this.screenShareStream?.getVideoTracks();

        if (cameraTracks && cameraTracks.length) {
            this.isVideoMuted = cameraTracks[0].enabled;
            cameraTracks[0].enabled = !(cameraTracks[0].enabled);
        }

        if (screenShareTracks && screenShareTracks.length) {
            this.isVideoMuted = screenShareTracks[0].enabled;
            screenShareTracks[0].enabled = !(screenShareTracks[0].enabled);
        }
    };

    toggleSound = () => {
        if (!this.isMutedByAdmin) {
            this.toggleMicros();
        }
    };

    toggleMicros = () => {
        const cameraTracks = this.stream?.getAudioTracks();
        const screenShareTracks = this.screenShareStream?.getAudioTracks();
        const audioStreamTracks = this.audioStream?.getAudioTracks();
        this.isMicroMuted = !this.isMicroMuted;

        if (cameraTracks && cameraTracks.length) {
            cameraTracks[0].enabled = !this.isMicroMuted;
        }

        if (screenShareTracks && screenShareTracks.length) {
            screenShareTracks[0].enabled = !this.isMicroMuted;
        }

        if (audioStreamTracks && audioStreamTracks.length) {
            audioStreamTracks[0].enabled = !this.isMicroMuted;
        }
    };

    toggleAudio = () => {
        this.isAudioMuted = !this.isAudioMuted;
    };

    muteByAdmin = () => {
        this.isMutedByAdmin = !this.isMutedByAdmin;

        const cameraTracks = this.stream?.getAudioTracks();
        const screenShareTracks = this.screenShareStream?.getAudioTracks();
        const audioStreamTracks = this.audioStream?.getAudioTracks();

        this.isMicroMuted = this.isMutedByAdmin;

        if (cameraTracks && cameraTracks.length) {
            cameraTracks[0].enabled = !this.isMutedByAdmin;
        }

        if (screenShareTracks && screenShareTracks.length) {
            screenShareTracks[0].enabled = !this.isMutedByAdmin;
        }

        if (audioStreamTracks && audioStreamTracks.length) {
            audioStreamTracks[0].enabled = !this.isMutedByAdmin;
        }
    };

    applyEnabled = () => {
        const cameraVideoTracks = this.stream?.getVideoTracks();
        const cameraAudioTracks = this.stream?.getAudioTracks();
        const screenShareVideoTracks = this.screenShareStream?.getVideoTracks();
        const screenShareAudioTracks = this.screenShareStream?.getAudioTracks();
        const audioStreamTracks = this.audioStream?.getAudioTracks();

        if (cameraVideoTracks && cameraVideoTracks.length) {
            cameraVideoTracks[0].enabled = !this.isVideoMuted;
        }

        if (cameraAudioTracks && cameraAudioTracks.length) {
            cameraAudioTracks[0].enabled = !this.isMicroMuted;
        }

        if (screenShareVideoTracks && screenShareVideoTracks.length) {
            screenShareVideoTracks[0].enabled = !this.isVideoMuted;
        }

        if (screenShareAudioTracks && screenShareAudioTracks.length) {
            screenShareAudioTracks[0].enabled = !this.isMicroMuted;
        }

        if (audioStreamTracks && audioStreamTracks.length) {
            audioStreamTracks[0].enabled = !this.isMicroMuted;
        }
    };
}
