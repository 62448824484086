import React from 'react';
import { Drawer } from 'antd';
import { inject, observer } from 'mobx-react';
import format from 'date-fns/format';
import { SimpleModeStore } from '@services';
import { CloseIcon, EditPenIcon, TrashRedIcon } from '@assets/icons';
import { IProject } from '@common-types';
import './styles.scss';

interface IProps {
    SimpleModeStore?: SimpleModeStore;
    isDrawerOpen: boolean;
    onClose: () => void;
    editProject: (project: IProject) => void;
    deleteProject: (id: number) => void;
}

@inject('SimpleModeStore')
@observer
export class ProjectDrawer extends React.Component<IProps> {
    render(): React.ReactNode {
        const { isDrawerOpen, onClose, editProject, deleteProject } = this.props;
        const {
            user,
            selectedProject,
            selectProject,
        } = this.props.SimpleModeStore!;
        const { projects } = user;

        return (
            <Drawer
                className='projects-drawer'
                onClose={onClose}
                visible={isDrawerOpen}
                closable={true}
                destroyOnClose={true}
                width={window.innerWidth}
                closeIcon={<CloseIcon />}
                placement='left'
            >
                <div className='projects-drawer__title'>
                    Project List
                </div>
                <div className='projects-drawer__main'>
                    {
                        projects.map((project: IProject) => {
                            const { id, avatar, name, createdAt } = project;

                            return (
                                <div
                                    className='projects-drawer__project'
                                    key={id}
                                >
                                    <div className='projects-drawer__img-wrapper'>
                                        {
                                            avatar
                                                ? <img className='projects-drawer__img' src={avatar} alt="avatar"/>
                                                : <div className="simple-mode__projects-latter">{name[0]}</div>
                                        }
                                    </div>
                                    <div className='projects-drawer__name' onClick={() => selectProject(project)}>
                                        <div className={`projects-drawer__top ${selectedProject?.id === project.id ? 'selected' : ''}`}>
                                            {name}
                                        </div>
                                        <div className='projects-drawer__bottom'>
                                            <div>
                                                {format(new Date(createdAt), 'MMM dd - HH:mm:ss')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='projects-drawer__actions'>
                                        <div className='projects-drawer__edit' onClick={() => editProject(project)}>
                                            <EditPenIcon />
                                        </div>
                                        {
                                            !project.isInitial && (
                                                <div className='projects-drawer__remove' onClick={() => deleteProject(project.id)}>
                                                    <TrashRedIcon />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </Drawer>
        );
    }
}
