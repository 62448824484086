import { action, makeObservable, observable } from 'mobx';
import SimplePeer, { Instance } from 'simple-peer';
import { v4 } from 'uuid';
import { io, Socket } from 'socket.io-client';
import {
    ADMIN_SS_ID,
    DEFAULT_AUDIO_BITS_PER_SECOND,
    DEFAULT_CODEC,
    DEFAULT_MIME_TYPE,
    DEFAULT_TIME_SLICE,
    DEFAULT_VIDEO_BITS_PER_SECOND,
    DEFAULT_WEB_RTC_CONFIG,
    DESTINATIONS_ICONS_MAP,
} from '@constants';
import {
    getBitrateForConstraint,
    isFirefox,
    isMobile,
    setMaxBitrate,
    showInfoMessage,
    showWarnMessage,
    updateSDP,
} from '@utils';
import { ConferenceRolesEnum, IUserConnection, RolesEnum, StreamTypeEnum, } from '@common-types';
import { Base } from './base-store';

const MAX_RETRIES = 2;
const RETRY_TIME = 5000;
const sessionDescription = window.RTCSessionDescription;
const userId = v4();
const socket: Socket = io({
    transports: ['websocket'],
    query: {
        USERID: userId,
    }
});

console.log('INITIAL USER ID', userId);


export class UsersConferenceStore extends Base {
    mediaRecorder;
    mediaStream: MediaStream;
    perConnectionsMap: { [key: string]: RTCPeerConnection } = {};
    ssPerConnectionsMap: { [key: string]: RTCPeerConnection } = {};
    streams: { [key: string]: IUserConnection } = {};
    localStream: MediaStream;
    localScreenShareStream: MediaStream;
    toggleUserSound: () => void;
    shownPopups: string[] = [];
    retriesCount = 0;
    timerId;
    currentUserId: string = '';
    role: RolesEnum;
    socket: Socket;
    userWasDisconnected: boolean = false;
    savedRoomId: string = '';
    savedName: string = '';
    simplePeerForSS: Instance;
    userRole: ConferenceRolesEnum = ConferenceRolesEnum.User;

    constructor(rootStore) {
        super(rootStore);

        this.socket = socket;
        socket.on('connect', () => {
            socket.on('reconnect_attempt', () => {
                socket.io.opts.query = {
                    USERID: userId,
                }
            });

            console.log('client connect', socket.id);
            const prevId = this.currentUserId;

            if (!this.currentUserId) {
                this.currentUserId = socket.id;
            }


            if (this.userWasDisconnected) {
                console.log('start reconnect');
                socket.emit('start-reconnect', {
                    roomId: this.savedRoomId,
                    id: this.currentUserId,
                    name: this.rootStore.userMediaStore.name,
                    role: this.userRole,
                });
                /*this.createSimplePeerConnection();

                if (this.rootStore.appStore.isUserAuthorized) {
                    this.rootStore.streamViewStore.removerUser(prevId);
                    this.rootStore.streamViewStore.insertUser({
                        id: this.currentUserId,
                        name: this.rootStore.userMediaStore.name,
                        streamType: StreamTypeEnum.Camera,
                        position: 0,
                        isEnabled: true,
                    }, 0);
                }*/
            }

            this.userWasDisconnected = false;
        });

        makeObservable(this, {
            streams: observable,
            currentUserId: observable,
            addStream: action,
            removeStream: action,
            muteUser: action,
        });
    }

    addStream = (userConnection: IUserConnection) => {
        console.log('addStream', userConnection);
        this.streams[userConnection.id] = userConnection;

        this.rootStore.streamViewStore.addUser({
            id: userConnection.id,
            isEnabled: false,
            name: userConnection.name,
            position: this.rootStore.streamViewStore.state.users.length,
            streamType: StreamTypeEnum.Camera,
        });

        if (this.rootStore.appStore.isUserAuthorized) {
            if (this.rootStore.appStore.isSimpleMode) {
                this.rootStore.simpleModeStore.addUser(userConnection.id);
            } else {
                this.rootStore.advanceModeStore.addUser(userConnection.id);
            }
        }

        if (!this.shownPopups.includes(userConnection.id) && userConnection.id !== ADMIN_SS_ID) {
            this.shownPopups.push(userConnection.id);
            showInfoMessage(`User ${userConnection.name} joined the conference`);
        }
    };

    removeStream = (id: string) => {
        console.log('removeStream', id);
        if (this.streams[id]) {
            const name = this.streams[id].name;
            showInfoMessage(`User ${name} leaved the conference`);

            this.streams[id].isOut = true;

            //this.rootStore.streamViewStore.removerUser(id);

            /*if (this.rootStore.appStore.isUserAuthorized) {
                if (this.rootStore.appStore.isSimpleMode) {
                    this.rootStore.simpleModeStore.removeUser(id);
                } else {
                    this.rootStore.advanceModeStore.removeUser(id);
                }
            }

            this.streams[id].stream.getTracks().forEach((track) => {
                track.stop();
            });
            this.streams[id] = undefined;
            delete this.streams[id];*/
        }
    };

    init = () => {
        console.log('init socket.id', socket.id);

        socket.on('disconnect', () => {
            this.userWasDisconnected = true;
            console.log('client disconnect');

            /*Object.keys(this.perConnectionsMap).forEach((key: string) => {
                if (this.perConnectionsMap[key]) {
                    this.perConnectionsMap[key].close();
                    this.perConnectionsMap[key] = undefined;
                }

                this.removeStream(key);
            });

            Object.keys(this.ssPerConnectionsMap).forEach((key: string) => {
                if (this.ssPerConnectionsMap[key]) {
                    this.ssPerConnectionsMap[key].close();
                    this.ssPerConnectionsMap[key] = undefined;
                }
            });

            this.simplePeerForSS.destroy();*/
        });

        socket.on('add-users', (data) => {
            clearTimeout(this.timerId);
            console.log('add-users', data);
            for (var i = 0; i < data.users.length; i++) {
                const id = data.users[i].id;
                const name = data.users[i].name;
                const userRole = data.users[i].userRole;

                this.createOffer(id, name, userRole);

                if (this.rootStore.appStore.isUserAuthorized && this.localScreenShareStream) {
                    socket.emit('admin-start-ss-add-user', {
                        id: ADMIN_SS_ID,
                        name: 'Screen Share',
                        userRole: ConferenceRolesEnum.User,
                        userId: id,
                    });
                }
            }
        });

        socket.on('reconnect-user', (id) => {
            console.log('reconnect-user', id);

            try {
                this.streams[id].isOut = false;
            } catch (error) {
                console.error('Error during reconnect user', error);
            }
        });

        socket.on('remove-user', (id) => {
            clearTimeout(this.timerId);
            console.log('remove-user', id);

            try {
                /*if (this.perConnectionsMap[id]) {
                    this.perConnectionsMap[id].close();
                    this.perConnectionsMap[id] = undefined;
                }*/

                this.removeStream(id);
            } catch (error) {
                console.error('Error during removing user', error);
            }
        });

        socket.on('ice-candidate', async (data) => {
            try {
                console.log('Add ice candidate from', data.id);
                if (data.isSS && this.perConnectionsMap[ADMIN_SS_ID]) {
                    await this.perConnectionsMap[ADMIN_SS_ID].addIceCandidate(data.candidate);
                } else {
                    await this.perConnectionsMap[data.id].addIceCandidate(data.candidate);
                }
            } catch (e) {
                console.error('Error adding received ice candidate', e);
            }
        });

        socket.on('answer-made', async (data) => {
            clearTimeout(this.timerId);
            console.log('answer-made', data);
            try {
                await this.perConnectionsMap[data.socket].setRemoteDescription(new sessionDescription(data.answer));
            } catch (e) {
                this.error(e);
            }
        });

        socket.on('offer-made', async (data) => {
            clearTimeout(this.timerId);
            console.log('offer-made', data);
            try {
                if (!this.perConnectionsMap[data.socket]) {
                    this.perConnectionsMap[data.socket] = new RTCPeerConnection(DEFAULT_WEB_RTC_CONFIG);

                    this.perConnectionsMap[data.socket].addEventListener('icecandidate', event => {
                        if (event.candidate) {
                            socket.emit('new-ice-candidate', {
                                id: data.socket,
                                candidate: event.candidate,
                            });
                        }
                    });

                    this.perConnectionsMap[data.socket].addEventListener('connectionstatechange', () => {
                        if (this.perConnectionsMap[data.socket].connectionState === "failed") {
                            // @ts-ignore
                            this.perConnectionsMap[data.socket].restartIce();
                            console.warn('connectionState failed restart ice');
                        }
                    });

                    this.perConnectionsMap[data.socket].addEventListener('iceconnectionstatechange', () => {
                        if (this.perConnectionsMap[data.socket].iceConnectionState === "failed") {
                            // @ts-ignore
                            this.perConnectionsMap[data.socket].restartIce();
                            console.warn('addEventListener failed restart ice');
                        }
                    });

                    this.addLocalStreamToPeerConnection(this.perConnectionsMap[data.socket]);

                    this.perConnectionsMap[data.socket].ontrack = (event) => {
                        console.log('ontrack event', event);

                        const stream = this.streams[data.socket]?.stream || new MediaStream();

                        stream.addTrack(event.track);

                        this.addStream({
                            stream,
                            name: data.name,
                            id: data.socket,
                            userRole: data.userRole,
                            isMuted: false,
                            mediaStreamAudioSourceNode: null,
                            isOut: false,
                        });

                        this.setMaxBitrate(this.perConnectionsMap[data.socket]);
                    };
                }

                await this.perConnectionsMap[data.socket].setRemoteDescription(new sessionDescription(data.offer));
                let answer;

                try {
                    answer = await this.perConnectionsMap[data.socket].createAnswer();

                    if (!isMobile()) {
                        answer.sdp = updateSDP(answer.sdp).replace(/VP8/g, "H264");
                    }

                    await this.perConnectionsMap[data.socket].setLocalDescription(answer);
                } catch (e) {
                    answer = await this.perConnectionsMap[data.socket].createAnswer();
                    await this.perConnectionsMap[data.socket].setLocalDescription(answer);
                    console.error(e);
                }

                socket.emit('make-answer', {
                    answer: answer,
                    to: data.socket,
                });
            } catch (e) {
                this.error(e);
            }
        });

        socket.on('mute', () => {
            clearTimeout(this.timerId);
            console.log('mute');
            try {
                if (this.toggleUserSound) {
                    this.toggleUserSound();
                }
            } catch (e) {
                this.error(e);
            }
        });

        socket.on('destination-add', (data) => {
            console.log('destination-add', data);

            if (data && data.name) {
                showInfoMessage(`Destination ${data?.name} added`);
            } else {
                showInfoMessage(`Destination added`);
            }
        });

        socket.on('destination-added', (data) => {
            let pushDestination = this.rootStore.appStore.isSimpleMode
                ? this.rootStore.simpleModeStore.pushDestination
                : this.rootStore.advanceModeStore.pushDestination;
            const { icon, colorIcon } = DESTINATIONS_ICONS_MAP.find(({ id }) => {
                return +id === +data.iconsId;
            });

            pushDestination({
                ...data,
                icon,
                colorIcon,
            });
        });

        socket.on('destination-add-failure', (data) => {
            console.log('destination-add-failure', data);
            const destinations = this.rootStore.appStore.isSimpleMode
                ? this.rootStore.simpleModeStore.destinations
                : this.rootStore.advanceModeStore.destinations;
            const destination = destinations.find(({ id }) => {
                return id === data.destinationId;
            });

            showWarnMessage(`Failed to add destination. Reason '${data.description}'`);
        });

        socket.on('destination-remove', (data) => {
            console.log('destination-remove', data);
            showWarnMessage(`Destination removed`);
        });

        socket.on('users-in-room', (data) => {
            if (data.usersInRoom && !data.usersInRoom.length) {
                showWarnMessage(`Room empty`);
            }
        });

        socket.on('invalid-room-id', () => {
            console.log('invalid-room-id');
            showWarnMessage(`Room id is invalid`);
        });

        socket.on('more-then-max-destinations', () => {
            console.log('more-then-max-destinations');
            showWarnMessage(`You can't add more destinations, exceed limit`);
        });

        socket.on('media-server-info', (data) => {
            console.log('media-server-info', data);
            showInfoMessage(`Server info: ${data.description}`);
        });

        socket.on('stream-state-updated', (data) => {
            console.log('stream-state-updated', data);
            this.rootStore.streamViewStore.setUpdatedStreamViewState(data);
        });

        socket.on('admin-ss-removed', () => {
            this.removeStream(ADMIN_SS_ID);
        });

        socket.on('sp-signal-be', (data) => {
            console.log('sp-signal-be');
            this.simplePeerForSS.signal(data);
        });

        this.createSimplePeerConnection();
    };

    createSimplePeerConnection = () => {
        this.simplePeerForSS = new SimplePeer({
            initiator: true,
            config: DEFAULT_WEB_RTC_CONFIG,
        });

        this.simplePeerForSS.on('signal', (data) => {
            console.log('simplePeerForSS SIGNAL');
            socket.emit('fe-sp-signal', data);
        });

        this.simplePeerForSS.on('stream', (data) => {
            console.log('SP STREAM', data);

            this.addStream({
                id: ADMIN_SS_ID,
                name: 'Screen Share',
                userRole: ConferenceRolesEnum.User,
                stream: data,
                isMuted: false,
                mediaStreamAudioSourceNode: null,
                isOut: false,
            });
        });
    };

    muteUser = (userId: string) => {
        socket.emit('mute-user', {
            to: userId,
        });

        const userConnection = this.streams[userId];

        if (userConnection) {
            userConnection.isMuted = !userConnection.isMuted;
        }
    };

    setMaxBitrate = (peerConnection: RTCPeerConnection, isSS: boolean = false) => {
        let constraint: MediaTrackConstraints;

        if (this.rootStore.appStore.isUserAuthorized) {
            if (isSS) {
                constraint = this.rootStore.appStore.isSimpleMode
                    ? this.rootStore.simpleModeStore.currentSSVideoMediaTrackConstraints
                    : this.rootStore.advanceModeStore.currentSSVideoMediaTrackConstraints;
                console.log('SS BITRATE', getBitrateForConstraint(constraint.width as number));
            } else {
                constraint = this.rootStore.appStore.isSimpleMode
                    ? this.rootStore.simpleModeStore.currentVideoMediaTrackConstraints
                    : this.rootStore.advanceModeStore.currentVideoMediaTrackConstraints;
                console.log('BITRATE', getBitrateForConstraint(constraint.width as number));
            }
        } else {
            constraint = this.rootStore.guestStore.currentVideoMediaTrackConstraints;
            console.log('BITRATE', getBitrateForConstraint(constraint.width as number));
        }

        setMaxBitrate(peerConnection, getBitrateForConstraint(constraint.width as number));
    };

    error = (err) => {
        console.error('Error', err);
    };

    createOffer = async (id: string, name: string, userRole: string, socketId: string = '') => {
        try {
            if (!this.perConnectionsMap[id]) {
                this.perConnectionsMap[id] = new RTCPeerConnection(DEFAULT_WEB_RTC_CONFIG);

                this.perConnectionsMap[id].addEventListener('icecandidate', event => {
                    if (event.candidate) {
                        socket.emit('new-ice-candidate', {
                            id,
                            socketId,
                            candidate: event.candidate,
                        });
                    }
                });

                if (id !== ADMIN_SS_ID) {
                    this.addLocalStreamToPeerConnection(this.perConnectionsMap[id]);
                }

                this.perConnectionsMap[id].addEventListener('connectionstatechange', () => {
                    if (this.perConnectionsMap[id].connectionState === "failed") {
                        // @ts-ignore
                        this.perConnectionsMap[id].restartIce();
                        console.warn('connectionState failed restart ice');
                    }
                });

                this.perConnectionsMap[id].addEventListener('iceconnectionstatechange', () => {
                    if (this.perConnectionsMap[id].iceConnectionState === "failed") {
                        // @ts-ignore
                        this.perConnectionsMap[id].restartIce();
                        console.warn('addEventListener failed restart ice');
                    }
                });

                this.perConnectionsMap[id].ontrack = (event) => {
                    console.log('ontrack event', event);
                    const stream = this.streams[id]?.stream || new MediaStream();

                    stream.addTrack(event.track);

                    this.addStream({
                        id,
                        name,
                        userRole,
                        stream,
                        isMuted: false,
                        mediaStreamAudioSourceNode: null,
                        isOut: false,
                    });

                    this.setMaxBitrate(this.perConnectionsMap[id]);
                };
            }

            let offer;

            try {
                offer = await this.perConnectionsMap[id].createOffer({ offerToReceiveVideo: true, offerToReceiveAudio: true });

                if (!isMobile()) {
                    offer.sdp = updateSDP(offer.sdp).replace(/VP8/g, "H264");
                }

                await this.perConnectionsMap[id].setLocalDescription(offer);
            } catch (e) {
                offer = await this.perConnectionsMap[id].createOffer({ offerToReceiveVideo: true, offerToReceiveAudio: true });
                await this.perConnectionsMap[id].setLocalDescription(offer);
                console.error(e);
            }

            socket.emit('make-offer', {
                offer,
                socketId,
                to: id,
            });
        } catch (e) {
            this.error(e);
        }
    };

    addLocalSSStreamToPeerConnection = (peerConnection: RTCPeerConnection) => {
        console.log('addLocalSSStreamToPeerConnection', this.localScreenShareStream.getAudioTracks());
        this.localScreenShareStream.getTracks().forEach((track) => {
            peerConnection.addTrack(track, this.localScreenShareStream);
        });
    };

    addLocalStreamToPeerConnection = (peerConnection: RTCPeerConnection) => {
        this.localStream.getTracks().forEach((track) => {
            peerConnection.addTrack(track, this.localStream);
        });
    };

    start = (
        stream: MediaStream,
        name: string,
        roomId = v4(),
        isCreator = true,
        toggleUserSound: () => void | Promise<void>,
    ) => {
        this.localStream = stream;
        this.toggleUserSound = toggleUserSound;

        console.log('start', name, roomId);

        this.savedRoomId = roomId;
        this.savedName = name;

        if (isCreator) {
            this.role = RolesEnum.Streamer;
            socket.emit('create-room', { roomId, name });
            this.userRole = ConferenceRolesEnum.Admin;
        } else {
            this.role = RolesEnum.User;
            socket.emit('start', { roomId, name });

            if (this.retriesCount < MAX_RETRIES) {
                this.retriesCount++;

                this.timerId = setTimeout(() => {
                    this.start(stream, name, roomId, isCreator, toggleUserSound);
                }, RETRY_TIME);
            }
        }
    };

    addAdminScreenShare = (stream: MediaStream) => {
        this.localScreenShareStream = stream;

        try {
            console.log('SP add stream');
            this.simplePeerForSS.addStream(stream);
        } catch (e) {
            console.error(e);
        }
    };

    removeScreenShare = () => {};

    changeTrack = (newTrack: MediaStreamTrack) => {
        try {
            Object.keys(this.perConnectionsMap).forEach((id: string) => {
                const connection: RTCPeerConnection = this.perConnectionsMap[id];

                if (connection) {
                    const sender = connection.getSenders().find((senderItem: RTCRtpSender) => {
                        return senderItem.track.kind == newTrack.kind;
                    });

                    if (sender) {
                        sender.replaceTrack(newTrack);
                    }
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    startStream = async (stream: MediaStream) => {
        socket.emit('start-ffmpeg');
        console.log('Start stream');

        setTimeout(() => {
            console.log('Create media recorder');
            this.mediaStream = stream;
            let codec = DEFAULT_CODEC;

            if (isFirefox()) {
                codec = '';
            }

            try {
                // @ts-ignore
                this.mediaRecorder = new MediaRecorder(this.mediaStream, {
                    mimeType: `${DEFAULT_MIME_TYPE};${codec}`,
                    audioBitsPerSecond : DEFAULT_AUDIO_BITS_PER_SECOND,
                    videoBitsPerSecond : DEFAULT_VIDEO_BITS_PER_SECOND,
                });
            } catch (e) {
                console.error(e);
                // @ts-ignore
                this.mediaRecorder = new MediaRecorder(this.mediaStream, {
                    mimeType: `${DEFAULT_MIME_TYPE};`,
                    audioBitsPerSecond : DEFAULT_AUDIO_BITS_PER_SECOND,
                    videoBitsPerSecond : DEFAULT_VIDEO_BITS_PER_SECOND,
                });
            }

            this.mediaRecorder.addEventListener('dataavailable', (e) => {
                socket.emit('data', {
                    message: e.data,
                });
            });

            this.mediaRecorder.addEventListener('stop', () => {
                if (this.rootStore.appStore.isSimpleMode) {
                    this.rootStore.simpleModeStore.stopStream();
                } else {
                    this.rootStore.advanceModeStore.stopStream();
                }

            });


            this.mediaRecorder.addEventListener('start', () => {
                console.log('mediaRecorder start');
            });

            this.mediaRecorder.start(DEFAULT_TIME_SLICE);

        }, 2000);

    };

    stopStream = () => {
        socket.emit('stop-stream');

        if (this.mediaRecorder && this.mediaRecorder.stop && this.mediaRecorder.state !== 'inactive') {
            this.mediaRecorder.stop();
        }

        if (this.mediaStream) {
            this.mediaStream.getVideoTracks().forEach((track) => {
                track.stop();
            });
        }
    };

    removeDestination = (id: string | number) => {
        socket.emit('remove-destination', {
            id,
        });
    };

    applyConstraint = (constraint: MediaTrackConstraints) => {
        /*const videoTracks = this.localStream.getVideoTracks();

        if (videoTracks && videoTracks.length) {
            videoTracks.forEach((track: MediaStreamTrack) => {
                track.applyConstraints(constraint);
            });

            Object.keys(this.perConnectionsMap).forEach((key) => {
                const connection = this.perConnectionsMap[key];

                if (connection) {
                    this.setMaxBitrate(connection);
                }
            });
        }*/
    };

    applyConstraintSS = (constraint: MediaTrackConstraints) => {

    };

    createSession = () => {
        socket.emit('create-session', {
            isUserAuthorized: this.rootStore.appStore.isUserLoggedIn,
            userId: this.rootStore.appStore.currentUserId,
        });
    };

    onDestination = (id: string | number, url: string, streamKey: string) => {
        socket.emit('on-destination', { id, url, streamKey });
    };

    offDestination = (id: string | number) => {
        socket.emit('off-destination', { id });
    };

    addDestination = (name: string, url: string, streamKey: string, iconsId: number) => {
        socket.emit('add-destination', {
            name,
            url,
            streamKey,
            iconsId,
            userId: this.rootStore.appStore.currentUserId,
        });
    };

    updateDestinationDestination = (id: number, name: string, url: string, streamKey: string) => {
        socket.emit('update-destination', {
            id,
            name,
            url,
            streamKey,
        });
    };
}
