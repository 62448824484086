import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';
import { withRouter, RouteComponentProps, Prompt } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';
import { Tooltip } from 'antd';
import { GuestStore, UsersConferenceStore, UserMediaStore } from '@services';
import {
    SettingsSection,
    UserItem,
    Spinner,
    SecondaryButton,
    SettingsModal,
    StreamView,
} from '@components';
import { Routes, ConferenceRolesEnum } from '@common-types';
import {
    TextLogoIcon,
    AudioIcon,
    AudioOffIcon,
    MicroOffIcon,
    ScreenShareOffIcon,
    ArrowBottomIcon,
} from '@assets/icons';
import { isMobile, preventPageReload, stopStream } from '@utils';
import './styles.scss';


interface IProps extends RouteComponentProps {
    GuestStore?: GuestStore;
    UsersConferenceStore?: UsersConferenceStore;
    UserMediaStore?: UserMediaStore;
}

@inject('GuestStore', 'UsersConferenceStore', 'UserMediaStore')
@observer
class GuestComponent extends React.Component<IProps> {
    youVideoRef: React.RefObject<HTMLVideoElement> = React.createRef();
    videoContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
    isFullScreen: boolean = false;
    isUsersSectionOpen: boolean = true;
    zoomUpdateId: string = v4();

    constructor(props: IProps) {
        super(props);

        const roomId = this.getRoomId();

        if (!roomId) {
            props.history.push(Routes.Login);
        }

        makeObservable(this, {
            isFullScreen: observable,
            zoomUpdateId: observable,
            isUsersSectionOpen: observable,
            onFullScreenChange: action,
            openUsersSection: action,
            closeUsersSection: action,
            changeZoomUpdateId: action,
        });
    }

    changeZoomUpdateId = () => {
        this.zoomUpdateId = v4();
    };

    openUsersSection = () => {
        this.isUsersSectionOpen = true;
        this.changeZoomUpdateId();
    };

    closeUsersSection = () => {
        this.isUsersSectionOpen = false;
        this.changeZoomUpdateId();
    };

    componentWillUnmount(): void {
        const {
            audioStream,
            screenShareStream,
            resultMediaStream,
            stream,
        } = this.props.GuestStore!;
        const { perConnectionsMap } = this.props.UsersConferenceStore;

        stopStream(audioStream);
        stopStream(screenShareStream);
        stopStream(resultMediaStream);
        stopStream(stream);

        Object.keys(perConnectionsMap).forEach((id: string) => {
            if (perConnectionsMap[id]) {
                perConnectionsMap[id].close();
            }
        });

        document.removeEventListener('fullscreenchange', this.onFullScreenChange);
        window.removeEventListener('beforeunload', preventPageReload);
        this.youVideoRef.current.removeEventListener('resize', this.setVideoFit);
    }

    onFullScreenChange = () => {
        this.isFullScreen = !!document.fullscreenElement;
    };

    onFullScreen = () => {
        try {
            this.videoContainerRef?.current?.requestFullscreen();
        } catch (error) {
            console.error(error);
        }
    };

    onExitFullScreen = () => {
        try {
            document.exitFullscreen();
        } catch (error) {
            console.error(error);
        }
    };

    onMount = async () => {
        try {
            const { selectedMicro, selectedCamera } = this.props.UserMediaStore!;
            const { init, setCurrentCamera, setCurrentMicro } = this.props.GuestStore!;

            await init(this.youVideoRef.current);
            setCurrentCamera(selectedCamera ? selectedCamera.deviceId : null);
            setCurrentMicro(selectedMicro ? selectedMicro.deviceId : null);
            this.props.UsersConferenceStore!.init();
            this.join();

            document.addEventListener('fullscreenchange', this.onFullScreenChange);
            window.addEventListener('beforeunload', preventPageReload);
        } catch (error) {
            console.error(error);
        }
    };

    setVideoFit = () => {
        if (this.youVideoRef && this.youVideoRef.current) {
            const { videoHeight, videoWidth } = this.youVideoRef.current;

            if (videoWidth > videoHeight) {
                this.youVideoRef.current.style.objectFit = 'cover';
            } else {
                this.youVideoRef.current.style.objectFit = 'contain';
            }
        }
    };

    componentDidMount(): void {
        const roomId = this.getRoomId();

        if (roomId) {
            this.setVideoFit();
            this.youVideoRef.current.addEventListener('resize', this.setVideoFit);

            if (isMobile()) {
                setTimeout(() => {
                    this.onMount();
                }, 2000);
            } else {
                this.onMount();
            }
        }
    }

    getRoomId = (): string => {
        const paramsArr = location.search.slice(1).split('&');
        let roomId = '';

        paramsArr.forEach((param: string) => {
            const [name, value] = param.split('=');

            if (name.toLowerCase() === 'roomid' && value) {
                roomId = value;
            }
        });

        return roomId;
    };

    join = () => {
        try {
            const { getResultedMediaStream, muteByAdmin } = this.props.GuestStore!;
            const { start } = this.props.UsersConferenceStore!;
            const { name } = this.props.UserMediaStore!;
            const roomId = this.getRoomId();

            if (roomId) {
                const resultStream = getResultedMediaStream();

                start(resultStream, name, roomId, false, muteByAdmin);
            }
        } catch (error) {
            console.error(error);
        }
    };

    leave = () => {
        this.props.history.push(`${Routes.SelectMedia}${location.search}`);

        setTimeout(() => {
            location.reload();
        });
    };

    getStreamerStream = () => {
        const { streams } = this.props.UsersConferenceStore;
        let streamerStream: MediaStream = null;

        Object.keys(streams).forEach((key: string) => {
            if (streams[key] && streams[key].userRole === ConferenceRolesEnum.Admin) {
                streamerStream = streams[key].stream;
            }
        });

        return streamerStream;
    };

    onSettingsSave = async (
        camera: string,
        micro: string,
        audio: string,
    ) => {
        const { updateMicro, updateCamera, updateCurrentAudio } = this.props.GuestStore!;

        await updateCamera(camera);
        await updateMicro(micro);
        updateCurrentAudio(audio);
    };

    render(): React.ReactNode {
        const {
            addScreenShare,
            toggleSound,
            toggleVideo,
            isMicroMuted,
            isVideoMuted,
            isVideoOff,
            isMicroOff,
            isAudioMuted,
            isAudioOff,
            toggleAudio,
            isSettingsModalShown,
            openSettingsModal,
            closeSettingsModal,
            currentMicro,
            currentCamera,
            isMutedByAdmin,
            audioStream,
            isScreenShare,
            onScreenShareEnd,
            updateId,
          currentAudio,
        } = this.props.GuestStore;
        const { streams } = this.props.UsersConferenceStore;
        const streamerStream = this.getStreamerStream();
        updateId;
        audioStream;


        return (
            <div className='guest'>
                <Prompt message="Are you sure you want to leave?" />
                <div className="guest__left">
                    <div className="guest__header">
                        <div className="guest__logo">
                            <TextLogoIcon />
                        </div>
                        <div className="guest__header-actions">
                            <SecondaryButton onClick={this.leave}>
                                <FormattedMessage id='leave.conference' />
                            </SecondaryButton>
                        </div>
                    </div>
                    <div className="guest__main-section">
                        {
                            isMutedByAdmin && (
                                <div className='guest__muted-warning'>
                                    <FormattedMessage id='you.are.muted.by.admin' />
                                </div>
                            )
                        }
                        <div
                            className={`guest__video-wrapper ${this.isFullScreen ? 'full' : ''} ${!this.isUsersSectionOpen ? 'no-users' : ''}`}
                            ref={this.videoContainerRef}
                        >
                            {
                                streamerStream ? (
                                    <>
                                        <StreamView zoomUpdateId={this.zoomUpdateId} />
                                        {
                                            this.isFullScreen && (
                                                <div className="guest__settings">
                                                    <SettingsSection
                                                        isFullScreen={this.isFullScreen}
                                                        onExitFullScreen={this.onExitFullScreen}
                                                        onFullScreen={this.onFullScreen}
                                                        onScreenShareClick={addScreenShare}
                                                        onCameraClick={toggleVideo}
                                                        onMicroClick={toggleSound}
                                                        onAudioClick={toggleAudio}
                                                        showSettings={false}
                                                        showScreenShare={false}
                                                        onSettingsClick={openSettingsModal}
                                                        buttonOptions={{
                                                            isVideoMuted,
                                                            isMicroMuted,
                                                            isVideoOff,
                                                            isMicroOff,
                                                            isAudioMuted,
                                                            isAudioOff,
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }
                                    </>
                                ) : (
                                    <div className="guest__spinner-wrapper">
                                        <Spinner />
                                    </div>
                                )
                            }
                        </div>
                        <div className="guest__settings">
                            <SettingsSection
                                isFullScreen={this.isFullScreen}
                                onExitFullScreen={this.onExitFullScreen}
                                onFullScreen={this.onFullScreen}
                                onScreenShareClick={addScreenShare}
                                onCameraClick={toggleVideo}
                                onMicroClick={toggleSound}
                                onAudioClick={toggleAudio}
                                onSettingsClick={openSettingsModal}
                                buttonOptions={{
                                    isVideoMuted,
                                    isMicroMuted,
                                    isVideoOff,
                                    isMicroOff,
                                    isAudioMuted,
                                    isAudioOff,
                                }}
                            />
                        </div>
                    </div>
                    <div className={`guest__users ${!this.isUsersSectionOpen ? 'no-users' : ''}`}>
                        <div
                            className={`guest__hide-users ${this.isUsersSectionOpen ? 'close' : 'open'}`}
                            onClick={() => {
                                if (this.isUsersSectionOpen) {
                                    this.closeUsersSection();
                                } else {
                                    this.openUsersSection();
                                }
                            }}
                        >
                            <ArrowBottomIcon />
                        </div>
                        <div className="guest__you-wrapper">
                            <div className="guest__you-top">
                                <div className="guest__you-name">
                                    <FormattedMessage id='you' />
                                </div>
                                <div className="guest__you-signal">

                                </div>
                            </div>
                            <div className="guest__you-bottom">
                                <video
                                    className="guest__you-video"
                                    ref={this.youVideoRef}
                                    muted={true}
                                />
                                {
                                    isScreenShare && (
                                        <div className='guest__off-screen-share'>
                                            <div onClick={() => onScreenShareEnd()} className='guest__off-screen-icon'>
                                                <ScreenShareOffIcon />
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="guest__you-audio-icon" onClick={toggleAudio}>
                                    {isAudioMuted ? <AudioOffIcon /> : <AudioIcon />}
                                </div>
                                {
                                    isMicroMuted && (
                                        <Tooltip title={<FormattedMessage id="you.are.muted" />}>
                                            <div className="guest__you-micro-icon">
                                                <MicroOffIcon />
                                            </div>
                                        </Tooltip>
                                    )
                                }
                            </div>
                        </div>
                        {
                            Object.keys(streams).map((connectionId: string) => {
                                const { stream, name, id } = streams[connectionId];

                                return (
                                    <UserItem
                                        soundOnly={true}
                                        key={id}
                                        stream={stream}
                                        name={name}
                                        signal={100}
                                        soundOff={isAudioMuted}
                                    />
                                );
                            })
                        }
                        <div className="guest__backstage-message">
                            <div className="guest__backstage-header">
                                <FormattedMessage id='you.backstage' />
                            </div>
                            <div className="guest__backstage-text">
                                <FormattedMessage id='backstage.message' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="guest__right">

                </div>
                {
                    isSettingsModalShown && (
                        <SettingsModal
                          selectedAudio={currentAudio}
                            closeModal={closeSettingsModal}
                            showModal={isSettingsModalShown}
                            selectedCamera={currentCamera}
                            selectedMicro={currentMicro}
                            saveDevices={this.onSettingsSave}
                        />
                    )
                }
            </div>
        );
    }
}

export const Guest = withRouter(GuestComponent);
