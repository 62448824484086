import React from 'react';
import { Modal, Input, Switch } from 'antd';
import { v4 } from 'uuid';
import { CopyOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { Routes } from '@common-types';
import {
    SettingsSection,
    PatternsSection,
    UserItem,
    SettingsModal,
    PurpleButton,
    LiveLabel,
    MediaStreamVideo,
    StreamView,
} from '@components';
import { SimpleModeStore, UsersConferenceStore, UserMediaStore } from '@services';
import { AddUserIcon, ArrowBottomIcon, AudioIcon, AudioOffIcon } from '@assets/icons';
import { showInfoMessage, isMobile } from '@utils';
import './styles.scss';

interface IProps {
    SimpleModeStore?: SimpleModeStore;
    UsersConferenceStore?: UsersConferenceStore;
    UserMediaStore?: UserMediaStore;
}

@inject('SimpleModeStore', 'UsersConferenceStore', 'UserMediaStore')
@observer
export class SimpleMainSection extends React.Component<IProps> {
    canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();
    youCameraRef: React.RefObject<HTMLVideoElement> = React.createRef();
    videoContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
    ssVideoRef: React.RefObject<HTMLVideoElement> = React.createRef();
    isFullScreen: boolean = false;
    isInit: boolean = false;
    isUsersSectionOpen: boolean = true;
    zoomUpdateId: string = v4();

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            isFullScreen: observable,
            zoomUpdateId: observable,
            isInit: observable,
            isUsersSectionOpen: observable,
            onFullScreenChange: action,
            setInit: action,
            openUsersSection: action,
            closeUsersSection: action,
            changeZoomUpdateId: action,
        });
    }

    changeZoomUpdateId = () => {
        this.zoomUpdateId = v4();
    };

    openUsersSection = () => {
        this.isUsersSectionOpen = true;
        this.changeZoomUpdateId();
    };

    closeUsersSection = () => {
        this.isUsersSectionOpen = false;
        this.changeZoomUpdateId();
    };

    setInit = (init: boolean) => {
        this.isInit = init;
    };

    onMount = async () => {
        try {
            const { init, setCurrentCamera, setCurrentMicro } = this.props.SimpleModeStore!;
            const { selectedCamera, selectedMicro } = this.props.UserMediaStore!;

            await init(this.canvasRef.current, this.youCameraRef.current);
            setCurrentCamera(selectedCamera ? selectedCamera.deviceId : null);
            setCurrentMicro(selectedMicro ? selectedMicro.deviceId : null);
            this.props.UsersConferenceStore!.init();
            this.setInit(true);

            document.addEventListener('fullscreenchange', this.onFullScreenChange);
            this.setState({});
        } catch (error) {
            console.error(error);
        }
    };

    setSSVideoRef = (ref: React.RefObject<HTMLVideoElement>) => {
        this.ssVideoRef = ref;

        if (this.ssVideoRef && this.ssVideoRef.current) {
            this.ssVideoRef.current.addEventListener('resize', this.setSSVideoFit);
        }
    };

    componentDidMount() {
        this.youCameraRef.current.addEventListener('resize', this.setYouVideoFit);

        if (isMobile()) {
            setTimeout(() => {
                this.onMount();
            }, 2000);
        } else {
            this.onMount();
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('fullscreenchange', this.onFullScreenChange);
        this.youCameraRef.current.removeEventListener('resize', this.setYouVideoFit);

        if (this.ssVideoRef && this.ssVideoRef.current) {
            this.ssVideoRef.current.removeEventListener('resize', this.setSSVideoFit);
        }
    }

    onFullScreenChange = () => {
        this.isFullScreen = !!document.fullscreenElement;
        this.changeZoomUpdateId();
    };

    selectLink = () => {
        const input: HTMLInputElement = document.querySelector('.copy-link-input');

        if (input) {
            input.select();
        }
    };

    copyLink = () => {
        this.selectLink();
        document.execCommand('copy');
        showInfoMessage('Link copied');
        window.getSelection().removeAllRanges();
        Modal.destroyAll();
    };

    onConferenceStart = async (): Promise<void> => {
        try {
            const { name } = this.props.UserMediaStore!;
            const { roomId, cameraStream } = this.props.SimpleModeStore;
            const streamHasAudio = this.props.SimpleModeStore!.cameraStream?.getAudioTracks();
            const stream = new MediaStream([
                cameraStream.getVideoTracks()[0],
                streamHasAudio ? streamHasAudio[0] : undefined,
            ]);

            this.props.UsersConferenceStore!.start(stream, name, roomId, true, () => {});

            const link = `${location.origin}${Routes.SelectMedia}/?roomid=${roomId}`;

            Modal.info({
                className: 'add-user-modal',
                width: 400,
                content: (
                    <div className="add-user-modal__content">
                        <div style={{ width: '100%' }}>
                            <div className="add-user-modal__text">
                                Your Invite Link
                            </div>
                            <div className="add-user-modal__content-input">
                                <Input
                                    className='copy-link-input'
                                    value={link}
                                    readOnly={true}
                                    onFocus={this.selectLink}
                                    style={{ marginRight: '12px' }}
                                />
                            </div>
                        </div>
                        <div className="add-user-modal__content-action">
                            <PurpleButton onClick={this.copyLink}>
                                Copy Invite Link
                            </PurpleButton>
                        </div>
                    </div>
                ),
                title: (
                    <div className="add-user-modal__title">
                        <div className="add-user-modal__title-icon">
                            <AddUserIcon />
                        </div>
                        <div className="add-user-modal__title-text">
                            Invite Friends
                        </div>

                    </div>
                ),
                closable: true,
            });
        } catch (error) {
            console.error(error);
        }
    };

    onSettingsSave = async (camera: string, micro: string, audio: string) => {
        const { updateMicro, updateCamera, updateCurrentAudio } = this.props.SimpleModeStore!;

        await updateCamera(camera);
        await updateMicro(micro);
        updateCurrentAudio(audio);
    };

    onFullScreen = () => {
        try {
            this.videoContainerRef?.current?.requestFullscreen();
        } catch (error) {
            console.error(error);
        }
    };

    onExitFullScreen = () => {
        try {
            document.exitFullscreen();
        } catch (error) {
            console.error(error);
        }
    };

    setYouVideoFit = () => {
        if (this.youCameraRef && this.youCameraRef.current) {
            const { videoHeight, videoWidth } = this.youCameraRef.current;

            if (videoWidth > videoHeight) {
                this.youCameraRef.current.style.objectFit = 'cover';
            } else {
                this.youCameraRef.current.style.objectFit = 'contain';
            }
        }
    };

    setSSVideoFit = () => {
        if (this.ssVideoRef && this.ssVideoRef.current) {
            const { videoHeight, videoWidth } = this.ssVideoRef.current;

            if (videoWidth > videoHeight) {
                this.ssVideoRef.current.style.objectFit = 'cover';
            } else {
                this.ssVideoRef.current.style.objectFit = 'contain';
            }
        }
    };

    render(): React.ReactNode {
        const {
            addScreenShare,
            selectPattern,
            usersOrder,
            toggleUser,
            currentPattern,
            isAudioMuted,
            toggleAudio,
            isMicroMuted,
            isVideoMuted,
            toggleMicros,
            toggleVideo,
            isSettingsModalShown,
            closeSettingsModal,
            openSettingsModal,
            currentCamera,
            currentAudio,
            currentMicro,
            isScreenShare,
            isAdminEnabled,
            toggleAdmin,
            screenShareStream,
            isScreenShareEnabled,
            toggleScreenShare,
            isScreenShareMuted,
            toggleScreenShareSound,
        } = this.props.SimpleModeStore!;
        const { streams, muteUser } = this.props.UsersConferenceStore!;
        isScreenShare;

        return (
            <div className='simple-main-section'>
                <div className='simple-main-section__top'>
                    <div
                        className={`simple-main-section__wrapper ${this.isFullScreen ? 'full' : ''}`}
                        ref={this.videoContainerRef}
                    >
                        <StreamView
                            zoomUpdateId={this.zoomUpdateId}
                            className={!this.isUsersSectionOpen ? 'no-users' : ''}
                        />
                        {
                            this.isFullScreen && (
                                <div className='simple-main-section__actions'>
                                    <SettingsSection
                                        isFullScreen={this.isFullScreen}
                                        onFullScreen={this.onFullScreen}
                                        onExitFullScreen={this.onExitFullScreen}
                                        onSettingsClick={() => openSettingsModal()}
                                        onAudioClick={() => toggleAudio()}
                                        showAddUser={false}
                                        showScreenShare={false}
                                        showSettings={false}
                                        onCameraClick={() => toggleVideo()}
                                        onMicroClick={() => {
                                            if (isMicroMuted) {
                                                toggleMicros(true);
                                            } else {
                                                toggleMicros(false);
                                            }
                                        }}
                                        onScreenShareClick={() => addScreenShare()}
                                        onAddUserClick={() => this.onConferenceStart()}
                                        buttonOptions={{
                                            isAudioMuted,
                                            isVideoMuted,
                                            isMicroMuted,
                                            isAudioOff: false,
                                            isVideoOff: false,
                                            isMicroOff: false,
                                        }}
                                    />
                                    <PatternsSection
                                        onPatternSelect={selectPattern}
                                        selectedPattern={currentPattern}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <div className='simple-main-section__actions'>
                        {
                            this.isInit
                                ? (
                                    <>
                                        <SettingsSection
                                            isFullScreen={this.isFullScreen}
                                            onFullScreen={this.onFullScreen}
                                            onExitFullScreen={this.onExitFullScreen}
                                            onSettingsClick={() => openSettingsModal()}
                                            onAudioClick={() => toggleAudio()}
                                            showAddUser={true}
                                            showScreenShare={false}
                                            onCameraClick={() => toggleVideo()}
                                            onMicroClick={() => {
                                                if (isMicroMuted) {
                                                    toggleMicros(true);
                                                } else {
                                                    toggleMicros(false);
                                                }
                                            }}
                                            onScreenShareClick={() => addScreenShare()}
                                            onAddUserClick={() => this.onConferenceStart()}
                                            buttonOptions={{
                                                isAudioMuted,
                                                isVideoMuted,
                                                isMicroMuted,
                                                isAudioOff: false,
                                                isVideoOff: false,
                                                isMicroOff: false,
                                            }}
                                        />
                                        <PatternsSection
                                            onPatternSelect={selectPattern}
                                            selectedPattern={currentPattern}
                                        />
                                    </>
                                )
                                : null
                        }
                    </div>
                </div>
                <div className={`simple-main-section__bottom ${!this.isUsersSectionOpen ? 'no-users' : ''}`}>
                    <div
                        className={`simple-main-section__hide-users ${this.isUsersSectionOpen ? 'close' : 'open'}`}
                        onClick={() => {
                            if (this.isUsersSectionOpen) {
                                this.closeUsersSection();
                            } else {
                                this.openUsersSection();
                            }
                        }}
                    >
                        <ArrowBottomIcon />
                    </div>
                    <div className='simple-main-section__you-wrapper'>
                        <div className='simple-main-section__you-top'>
                            <div className='simple-main-section__you-name'>
                                <FormattedMessage id='you' />
                            </div>
                        </div>
                        <div className='simple-main-section__you-video-wrapper'>
                            {
                                !isAdminEnabled && (
                                    <div className='simple-main-section__you-disabled-bg' />
                                )
                            }
                            <video
                                className='simple-main-section__you-video'
                                autoPlay={true}
                                muted={true}
                                ref={this.youCameraRef}
                            />
                            {
                                isAdminEnabled && (
                                    <div className='simple-main-section__live-label'>
                                        <LiveLabel />
                                    </div>
                                )
                            }
                            <div className='simple-main-section__you-switch-wrapper'>
                                <Switch
                                    checked={isAdminEnabled}
                                    onChange={() => toggleAdmin()}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        screenShareStream && (
                            <div className='simple-main-section__ss-wrapper'>
                                <div className='simple-main-section__ss-top'>
                                    <div className='simple-main-section__ss-name'>
                                        <FormattedMessage id='ss' />
                                    </div>
                                </div>
                                <div className='simple-main-section__ss-video-wrapper'>
                                    {
                                        !isScreenShareEnabled && (
                                            <div className='simple-main-section__ss-disabled-bg' />
                                        )
                                    }
                                    <MediaStreamVideo
                                        setRef={this.setSSVideoRef}
                                        stream={screenShareStream}
                                    />
                                    {
                                        isScreenShareEnabled && (
                                            <div className='simple-main-section__live-label'>
                                                <LiveLabel />
                                            </div>
                                        )
                                    }
                                    {
                                        screenShareStream?.getAudioTracks()?.length && (
                                            <div className='simple-main-section__ss-mute' onClick={() => {
                                                toggleScreenShareSound();
                                            }}>
                                                {isScreenShareMuted ? <AudioOffIcon /> : <AudioIcon /> }
                                            </div>
                                        )
                                    }
                                    <div className='simple-main-section__ss-switch-wrapper'>
                                        <Switch
                                            checked={isScreenShareEnabled}
                                            onChange={() => toggleScreenShare()}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        usersOrder.map((order) => {
                            const { stream, name, id, isMuted } = streams[order.id];

                            return (
                                <UserItem
                                    soundOff={isAudioMuted}
                                    key={id}
                                    stream={stream}
                                    name={name}
                                    mute={() => muteUser(id)}
                                    isMuted={isMuted}
                                    withMute={true}
                                    signal={100}
                                    isEnabled={order.isEnabled}
                                    withEnabled={true}
                                    toggleUser={() => toggleUser(order.id)}
                                />
                            );
                        })
                    }
                </div>
                {
                    isSettingsModalShown && (
                        <SettingsModal
                            selectedAudio={currentAudio}
                            closeModal={closeSettingsModal}
                            showModal={isSettingsModalShown}
                            selectedCamera={currentCamera}
                            selectedMicro={currentMicro}
                            saveDevices={this.onSettingsSave}
                        />
                    )
                }
                <div className='simple-main-section__canvas-wrapper'>
                    <canvas className='simple-main-section__canvas' ref={this.canvasRef} />
                </div>
            </div>
        );
    }
}
