import React from 'react';
import { LayerTypeEnum } from '@common-types';
import {
    CameraIcon,
    FontIcon,
    ImageIcon,
    SlideShowIcon,
    BrowserWindowIcon,
    SoundGreyIcon,
} from '@assets/icons';

export const getLayerNameByType = (type: LayerTypeEnum): string => {
    const namesMap: { [key: string]: string } = {
        [LayerTypeEnum.Text]: 'Text',
        [LayerTypeEnum.Camera]: 'Camera',
        [LayerTypeEnum.Image]: 'Image',
        [LayerTypeEnum.SlideShow]: 'Image Slideshow',
        [LayerTypeEnum.BrowserWindow]: 'Browser Window',
        [LayerTypeEnum.Micro]: 'Micro',
    };

    return namesMap[type];
};

export const getLayerIconByType = (type: LayerTypeEnum): React.ReactNode => {
    const namesMap: { [key: string]: React.ReactNode } = {
        [LayerTypeEnum.Text]: <FontIcon />,
        [LayerTypeEnum.Camera]: <CameraIcon />,
        [LayerTypeEnum.Image]: <ImageIcon />,
        [LayerTypeEnum.SlideShow]: <SlideShowIcon />,
        [LayerTypeEnum.BrowserWindow]: <BrowserWindowIcon />,
        [LayerTypeEnum.Micro]: <SoundGreyIcon />,
    };

    return namesMap[type];
};
