import React from 'react';

interface IProps {}

export const ToTopIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6667 3.33342L16.6667 13.3334L6.66666 13.3334L6.66667 3.33341L16.6667 3.33342ZM16.6667 1.66675L6.66667 1.66675C5.75 1.66675 5 2.41675 5 3.33341L5 13.3334C5 14.2501 5.75 15.0001 6.66666 15.0001L16.6667 15.0001C17.5833 15.0001 18.3333 14.2501 18.3333 13.3334L18.3333 3.33342C18.3333 2.41675 17.5833 1.66675 16.6667 1.66675Z" fill="#748499"/>
            <path opacity="0.5" d="M1.66663 5L3.33329 5L3.33329 16.6667L15 16.6667L15 18.3333L3.33329 18.3333C2.41662 18.3333 1.66662 17.5833 1.66662 16.6667L1.66663 5Z" fill="#748499"/>
            <path d="M10.8333 11.6667L10.8333 8.33333L8.33325 8.33333L11.6666 5L14.9999 8.33333L12.4999 8.33333L12.4999 11.6667L10.8333 11.6667Z" fill="#748499"/>
        </svg>
    );
};
