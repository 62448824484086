import React from 'react';
import {
    YouTubeIcon,
    YouTubeColorIcon,
    FacebookIcon,
    FacebookColorIcon,
    TwitchIcon,
    TwitchColorIcon,
    PeriscopeColorIcon,
    TicTocColorIcon,
    LinkedinColorIcon,
    SteamColorIcon,
    DliveColorIcon,
    DliveIcon,
    PeriscopeIcon,
    TicTokIcon,
    LinkedinIcon,
    SteamIcon,
    MFCIcon,
    MFCColorIcon,
    OnlyFansIcon,
    OnlyFansColorIcon,
    ChaturbateColorIcon,
    ChaturbateIcon,
    StreepChatIcon,
    StripchatColorIcon,
    CamSodeColorIcon,
    CamSodeIcon,
    Cam4tIcon,
    Cam4ColorIcon,
    BongaIcon,
    BongoColorIcon,
    StreamateColorIcon,
    StreamateIcon,
    RTMPIcon,
    RTMPColorIcon,
} from '@assets/icons';
import { IStreamDestination } from '@common-types';

export const DESTINATIONS_ICONS_MAP: {
    id: number;
    colorIcon: React.ReactElement;
    icon: React.ReactElement;
}[] = [
    {
        id: 1,
        colorIcon: <YouTubeColorIcon />,
        icon: <YouTubeIcon />,
    },
    {
        id: 2,
        colorIcon: <TwitchColorIcon />,
        icon: <TwitchIcon />,
    },
    {
        id: 3,
        colorIcon: <FacebookColorIcon />,
        icon: <FacebookIcon />,
    },
    {
        id: 4,
        colorIcon: <LinkedinColorIcon />,
        icon: <LinkedinIcon />,
    },
    {
        id: 5,
        colorIcon: <TicTocColorIcon />,
        icon: <TicTokIcon />,
    },
    {
        id: 6,
        colorIcon: <PeriscopeColorIcon />,
        icon: <PeriscopeIcon />,
    },
    {
        id: 7,
        colorIcon: <SteamColorIcon />,
        icon: <SteamIcon />,
    },
    {
        id: 8,
        colorIcon: <DliveColorIcon />,
        icon: <DliveIcon />,
    },
    {
        id: 9,
        colorIcon: <MFCColorIcon />,
        icon: <MFCIcon />,
    },
    {
        id: 10,
        colorIcon: <OnlyFansColorIcon />,
        icon: <OnlyFansIcon />,
    },
    {
        id: 11,
        colorIcon: <ChaturbateColorIcon />,
        icon: <ChaturbateIcon />,
    },
    {
        id: 12,
        colorIcon: <StripchatColorIcon />,
        icon: <StreepChatIcon />,
    },
    {
        id: 13,
        colorIcon: <CamSodeColorIcon />,
        icon: <CamSodeIcon />,
    },
    {
        id: 14,
        colorIcon: <Cam4ColorIcon />,
        icon: <Cam4tIcon />,
    },
    {
        id: 15,
        colorIcon: <BongoColorIcon />,
        icon: <BongaIcon />,
    },
    {
        id: 16,
        colorIcon: <StreamateColorIcon />,
        icon: <StreamateIcon />,
    },
    {
        id: 17,
        icon: <RTMPIcon />,
        colorIcon: <RTMPColorIcon />,
    },
];

export const DESTINATIONS: IStreamDestination[] = [
    {
        iconsId: 1,
        isValid: true,
        streamKey: '',
        url: 'rtmp://a.rtmp.youtube.com/live2/',
        name: 'YouTube',
        colorIcon: <YouTubeColorIcon />,
        icon: <YouTubeIcon />,
    },
    {
        iconsId: 2,
        isValid: true,
        streamKey: '',
        url: '',
        name: 'Twitch',
        colorIcon: <TwitchColorIcon />,
        icon: <TwitchIcon />,
    },
    {
        iconsId: 3,
        isValid: true,
        streamKey: '',
        url: '',
        name: 'Facebook',
        colorIcon: <FacebookColorIcon />,
        icon: <FacebookIcon />,
    },
    {
        iconsId: 4,
        isValid: true,
        streamKey: '',
        url: '',
        name: 'Linkedin',
        colorIcon: <LinkedinColorIcon />,
        icon: <LinkedinIcon />,
    },
    {
        iconsId: 5,
        isValid: true,
        streamKey: '',
        url: '',
        name: 'Tic Toc',
        colorIcon: <TicTocColorIcon />,
        icon: <TicTokIcon />,
    },
    {
        iconsId: 6,
        isValid: true,
        streamKey: '',
        url: '',
        name: 'Periscope',
        colorIcon: <PeriscopeColorIcon />,
        icon: <PeriscopeIcon />,
    },
    {
        iconsId: 7,
        isValid: true,
        streamKey: '',
        url: '',
        name: 'Steam',
        colorIcon: <SteamColorIcon />,
        icon: <SteamIcon />,
    },
    {
        iconsId: 8,
        isValid: true,
        streamKey: '',
        url: '',
        name: 'Dlive',
        colorIcon: <DliveColorIcon />,
        icon: <DliveIcon />,
    },
];

export const ADULT_DESTINATIONS: IStreamDestination[] = [
    {
        iconsId: 9,
        isValid: true,
        streamKey: '',
        url: '',
        name: 'MFC',
        colorIcon: <MFCColorIcon />,
        icon: <MFCIcon />,
    },
    {
        iconsId: 10,
        isValid: true,
        streamKey: '',
        url: '',
        name: 'OnlyFans',
        colorIcon: <OnlyFansColorIcon />,
        icon: <OnlyFansIcon />,
    },
    {
        iconsId: 11,
        isValid: true,
        streamKey: '',
        url: '',
        name: 'Chaturbate',
        colorIcon: <ChaturbateColorIcon />,
        icon: <ChaturbateIcon />,
    },
    {
        iconsId: 12,
        isValid: true,
        streamKey: '',
        url: '',
        name: 'StripChat',
        colorIcon: <StripchatColorIcon />,
        icon: <StreepChatIcon />,
    },
    {
        iconsId: 13,
        isValid: true,
        streamKey: '',
        url: '',
        name: 'CamSode',
        colorIcon: <CamSodeColorIcon />,
        icon: <CamSodeIcon />,
    },
    {
        iconsId: 14,
        isValid: true,
        streamKey: '',
        url: '',
        name: 'Cam4',
        colorIcon: <Cam4ColorIcon />,
        icon: <Cam4tIcon />,
    },
    {
        iconsId: 15,
        isValid: true,
        streamKey: '',
        url: '',
        name: 'BongaCams',
        colorIcon: <BongoColorIcon />,
        icon: <BongaIcon />,
    },
    {
        iconsId: 16,
        isValid: true,
        streamKey: '',
        url: '',
        name: 'Streamate',
        colorIcon: <StreamateColorIcon />,
        icon: <StreamateIcon />,
    },
];
