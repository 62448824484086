import React from 'react';

interface IProps {}

export const PeriscopeColorIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="4" fill="#40A4C4"/>
            <path d="M24 10C17.9201 10 12.9913 14.9287 12.9913 21.0086C12.9913 28.2352 21.943 38 24 38C26.057 38 35.0086 28.5102 35.0086 21.0086C35.0087 14.9287 30.08 10 24 10ZM23.9503 29.2487C19.6226 29.2487 16.1145 25.7404 16.1145 21.4128C16.1145 17.0853 19.6227 13.577 23.9503 13.577C28.2779 13.577 31.786 17.0853 31.786 21.4128C31.786 25.7404 28.2779 29.2487 23.9503 29.2487Z" fill="white"/>
            <path d="M23.9501 15.0907C23.7556 15.0907 23.5635 15.1009 23.3735 15.118C23.8402 15.657 24.1231 16.3594 24.1231 17.1283C24.1231 18.8258 22.7471 20.2018 21.0496 20.2018C19.8609 20.2018 18.8306 19.5266 18.3193 18.5392C17.8783 19.4016 17.6279 20.3777 17.6279 21.4129C17.6279 24.9045 20.4584 27.735 23.95 27.735C27.4416 27.735 30.2721 24.9045 30.2721 21.4129C30.2722 17.9212 27.4417 15.0907 23.9501 15.0907Z" fill="#FF4B30"/>
        </svg>
    );
};