import React from 'react';

interface IProps {}

export const FacebookColorIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="4" fill="#0078FF"/>
            <path d="M20.9398 25.6531C20.8394 25.6531 18.7315 25.6538 17.7594 25.6528C17.2589 25.6525 17.0803 25.4727 17.0803 24.969C17.0797 23.6752 17.0794 22.3815 17.0803 21.0877C17.0806 20.5904 17.2694 20.4007 17.7635 20.4003C18.7357 20.3997 20.832 20.4 20.9398 20.4C20.9398 20.3114 20.9395 18.4435 20.9398 17.5834C20.9405 16.3117 21.1674 15.0944 21.8134 13.9799C22.4749 12.8391 23.4374 12.0576 24.671 11.6058C25.4611 11.3162 26.2813 11.2007 27.1197 11.2C28.1686 11.1994 29.2176 11.2003 30.2669 11.2023C30.7178 11.2029 30.9178 11.4023 30.9187 11.8563C30.9206 13.0733 30.9206 14.2903 30.9187 15.5069C30.9181 15.9658 30.7264 16.1501 30.2653 16.1552C29.4054 16.1645 28.545 16.1587 27.6861 16.1933C26.8186 16.1933 26.3622 16.617 26.3622 17.5149C26.3414 18.4647 26.3536 19.4154 26.3536 20.3997C26.4349 20.3997 28.9037 20.3994 30.0576 20.3997C30.5818 20.3997 30.761 20.5799 30.761 21.1069C30.761 22.3936 30.7606 23.6807 30.7597 24.9674C30.7594 25.4867 30.591 25.6525 30.0637 25.6528C28.9098 25.6535 26.4506 25.6531 26.343 25.6531V36.0675C26.343 36.6227 26.1683 36.7997 25.6205 36.7997C24.2848 36.7997 22.9488 36.8 21.6131 36.7997C21.129 36.7997 20.9402 36.6115 20.9402 36.1274C20.9398 32.7357 20.9398 25.7722 20.9398 25.6531Z" fill="white"/>
        </svg>
    );
};