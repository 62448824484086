import React from 'react';

interface IProps {}

export const BongoColorIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z" fill="#90194F"/>
            <path d="M41.7598 21.489C41.7598 27.189 36.1498 31.326 31.8868 30.714C30.9568 33.372 27.1228 37.938 24.0688 36.714C26.7208 33.648 27.3208 29.982 27.0478 28.713C26.8288 27.678 23.9068 27.981 21.0868 30.141C19.1608 33.693 14.8858 37.947 12.5548 37.947C11.3038 37.947 10.8538 35.679 10.8538 35.487C10.8538 34.08 17.8978 29.046 19.4008 28.332C19.4008 28.332 20.6308 26.478 20.6308 24.942C20.6308 23.607 19.9648 23.256 19.5568 23.256C17.5798 23.256 14.6368 26.073 13.1548 29.061C12.9898 29.397 12.6988 29.511 12.3238 29.511C11.0218 29.511 10.2178 27.711 10.4248 27.471C10.8328 27.003 11.3248 26.589 11.6398 26.052C14.3848 21.393 14.5828 16.494 13.5958 16.494C12.0508 16.494 9.12584 21.549 9.12584 29.685C9.12584 33.477 9.96884 35.64 10.5418 36.186C10.7308 36.366 10.5418 36.975 9.86384 37.2C8.54984 37.635 6.38984 36.186 6.10784 32.304C5.45684 23.376 10.3258 13.785 14.6578 14.544C15.5998 14.706 17.0968 16.608 15.6178 22.17C15.6178 22.17 16.4518 21.396 17.7718 21.069C20.6818 20.334 23.5498 22.074 22.2118 27.075C22.2118 27.075 26.4298 25.332 27.9838 26.226C29.4628 27.075 29.3638 30.417 27.7588 33.336C30.4888 31.662 31.6528 27.366 31.6528 27.366C33.3088 27.702 38.2948 25.581 38.2948 20.478C38.2948 16.722 35.1838 13.668 30.8098 13.668C26.4838 13.668 23.2768 16.86 21.3988 20.493C21.2608 20.76 20.1448 20.214 20.1448 19.425C20.1448 16.926 24.7498 12.273 30.6988 12.06C35.3818 11.892 41.7628 13.872 41.7628 21.489H41.7598ZM26.2168 17.412C25.5868 18.165 25.8868 20.181 26.3308 20.532C26.5918 20.415 32.4868 19.509 33.5458 18.972C33.776 18.8629 33.9771 18.7009 34.1325 18.499C34.2879 18.2972 34.3932 18.0614 34.4398 17.811C31.701 17.6367 28.9597 17.5036 26.2168 17.412ZM27.5308 21.318C26.9998 21.948 27.2548 23.643 27.6268 23.94C27.8428 23.844 32.7988 23.085 33.6868 22.629C33.8792 22.537 34.0473 22.4011 34.1776 22.2323C34.3078 22.0635 34.3966 21.8664 34.4368 21.657C32.1369 21.5059 29.8346 21.3929 27.5308 21.318Z" fill="white"/>
        </svg>
    );
};
