import React from 'react';
import { Input, Modal } from 'antd';
import { inject, observer } from 'mobx-react';
import { Prompt } from 'react-router';
import { ArrowRightOutlined, CopyOutlined } from '@ant-design/icons';
import { ShareWebCamStore, UserMediaStore, ShareWebCamConferenceStore } from '@services';
import { Routes } from '@common-types';
import { PurpleButton, SettingsSection, SettingsModal } from '@components';
import { isMobile, preventPageReload, showInfoMessage, stopStream } from '@utils';
import './style.scss';

interface IProps {
    ShareWebCamStore?: ShareWebCamStore;
    UserMediaStore?: UserMediaStore;
    ShareWebCamConferenceStore?: ShareWebCamConferenceStore;
}

@inject('ShareWebCamStore', 'UserMediaStore', 'ShareWebCamConferenceStore')
@observer
export class ShareWebCamPage extends React.Component<IProps> {
    videoRef: React.RefObject<HTMLVideoElement> = React.createRef();

    onMount = async () => {
        try {
            const { selectedMicro, selectedCamera } = this.props.UserMediaStore!;
            const { setCurrentCamera, setCurrentMicro, start } = this.props.ShareWebCamStore!;

            setCurrentCamera(selectedCamera ? selectedCamera.deviceId : null);
            setCurrentMicro(selectedMicro ? selectedMicro.deviceId : null);
            await start(this.videoRef?.current);
            window.addEventListener('beforeunload', preventPageReload);
        } catch (e) {
            console.error(e);
        }
    };

    setVideoFit = () => {
        if (this.videoRef && this.videoRef.current) {
            const { videoHeight, videoWidth } = this.videoRef.current;

            if (
                (videoWidth > videoHeight && window.innerWidth > window.innerHeight) ||
                (videoHeight > videoWidth && window.innerHeight > window.innerWidth)
            ) {
                this.videoRef.current.style.objectFit = 'cover';
            } else {
                this.videoRef.current.style.objectFit = 'contain';
            }
        }
    };

    componentDidMount(): void {
        this.videoRef.current.addEventListener('resize', this.setVideoFit);
        window.addEventListener('resize', this.setVideoFit);

        if (isMobile()) {
            setTimeout(() => {
                this.onMount();
            }, 2000);
        } else {
            this.onMount();
        }
    }

    componentWillUnmount(): void {
        try {
            const {
                cameraStream,
                audioStream,
                screenShareStream,
            } = this.props.ShareWebCamStore;
            const { perConnectionsMap } = this.props.ShareWebCamConferenceStore;

            stopStream(cameraStream);
            stopStream(audioStream);
            stopStream(screenShareStream);

            Object.keys(perConnectionsMap).forEach((id: string) => {
                if (perConnectionsMap[id]) {
                    perConnectionsMap[id].destroy();
                }
            });

            window.removeEventListener('beforeunload', preventPageReload);
            window.removeEventListener('resize', this.setVideoFit);
            this.videoRef.current.removeEventListener('resize', this.setVideoFit);
        } catch (error) {
            console.error(error);
        }
    }

    getLink = (): string => {
        const { roomId } = this.props.ShareWebCamStore!;
        return `${location.origin}${Routes.SharedWebCam}/?shareid=${roomId}`;
    };

    selectLink = (isMobile: boolean = false) => {
        let input: HTMLInputElement;

        if (isMobile) {
            input = document.querySelector('.copy-link-input.share-web-cam-page__link-input');
        } else {
            input = document.querySelector('.share-web-cam-page__link-input');
        }

        if (input) {
            input.select();
        }
    };

    copyLink = (isMobile: boolean = false) => {
        this.selectLink(isMobile);
        document.execCommand('copy');
        showInfoMessage('Link copied');
        window.getSelection().removeAllRanges();
    };

    onSettingsSave = async (
        camera: string,
        micro: string,
        audio: string,
    ) => {
        const { updateMicro, updateCamera, updateCurrentAudio } = this.props.ShareWebCamStore!;

        await updateCamera(camera);
        await updateMicro(micro);
        updateCurrentAudio(audio);
    };

    render(): React.ReactNode {
        const {
            addScreenShare,
            toggleVideo,
            toggleMicros,
            openSettingsModal,
            isMicroMuted,
            isVideoMuted,
            closeSettingsModal,
            isSettingsModalShown,
            currentCamera,
            currentMicro,
            currentAudio,
        } = this.props.ShareWebCamStore!;

        return (
            <div className="share-web-cam-page">
                <Prompt message="Are you sure you want to leave?" />
                <div className="share-web-cam-page__wrapper">
                    <video
                        className="share-web-cam-page__video"
                        autoPlay={true}
                        muted={true}
                        ref={this.videoRef}
                    />
                    <div className="share-web-cam-page__link-wrapper">
                        <div className="share-web-cam-page__text">
                            Copy this URL into an SplitCam, OBS "Browser Source"
                            <span className="share-web-cam-page__text-icon"><ArrowRightOutlined/></span>
                        </div>
                        <div className="share-web-cam-page__input">
                            <Input
                                className='share-web-cam-page__link-input'
                                value={this.getLink()}
                                readOnly={true}
                                onFocus={() => this.selectLink()}
                                style={{ marginRight: '12px' }}
                            />
                        </div>
                        <div className="share-web-cam-page__input-action">
                            <PurpleButton onClick={() => this.copyLink()}>
                                <CopyOutlined />
                            </PurpleButton>
                        </div>
                    </div>
                    <div className="share-web-cam-page__settings">
                        <SettingsSection
                            onScreenShareClick={addScreenShare}
                            onCameraClick={toggleVideo}
                            onMicroClick={toggleMicros}
                            showSettings={true}
                            showScreenShare={true}
                            onSettingsClick={openSettingsModal}
                            showAudio={false}
                            showAddUser={false}
                            buttonOptions={{
                                isVideoMuted,
                                isMicroMuted,
                                isVideoOff: false,
                                isMicroOff: false,
                                isAudioMuted: false,
                                isAudioOff: false,
                            }}
                        />
                    </div>
                </div>
                {
                    isSettingsModalShown && (
                        <SettingsModal
                          selectedAudio={currentAudio}
                            closeModal={closeSettingsModal}
                            showModal={isSettingsModalShown}
                            selectedCamera={currentCamera}
                            selectedMicro={currentMicro}
                            saveDevices={this.onSettingsSave}
                        />
                    )
                }
            </div>
        );
    }
}
