import React from 'react';

interface IProps {}

export const ChevronRightIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.71043 10.29L2.83043 6.41L6.71043 2.53C7.10043 2.14 7.10043 1.51 6.71043 1.12C6.32043 0.730003 5.69043 0.730003 5.30043 1.12L0.71043 5.71C0.32043 6.1 0.32043 6.73 0.71043 7.12L5.30043 11.71C5.69043 12.1 6.32043 12.1 6.71043 11.71C7.09043 11.32 7.10043 10.68 6.71043 10.29Z" fill="#748499"/>
        </svg>
    );
};
