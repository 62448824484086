import React from 'react';

interface IProps {}

export const Cam4ColorIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z" fill="#F15D1D"/>
            <g clipPath="url(#clip0)">
                <path d="M23.3564 29.3323C22.1193 30.9436 20.3463 31.5723 18.3776 31.8139C16.3095 32.0671 14.2371 32.0729 12.1732 31.7624C10.8495 31.5636 9.60462 31.1464 8.526 30.355C7.28838 29.4476 6.58254 28.2146 6.25793 26.7753C5.90923 25.2277 5.90923 23.6708 6.28142 22.126C6.87523 19.6606 8.51576 18.164 11.0061 17.4745C12.1708 17.1517 13.3693 17.0411 14.5726 17.0132C16.1679 16.9762 17.7633 16.9953 19.3303 17.3476C21.1178 17.7497 22.6566 18.5232 23.6719 20.0679C24.229 20.9151 24.5193 21.8514 24.6632 22.8353C24.6699 22.8804 24.6638 22.928 24.6638 22.9847C24.5687 22.9894 24.4898 22.9958 24.4109 22.9963C22.5247 22.9969 20.6384 22.9969 18.7516 22.9963C18.6167 22.9963 18.4788 23.0317 18.439 22.8271C18.1578 21.3937 17.1104 20.4764 15.2941 20.5373C13.4939 20.5975 12.3123 21.5043 12.01 23.213C11.892 23.8799 11.9173 24.5741 11.9227 25.2555C11.9251 25.5782 12.0088 25.9079 12.1021 26.2214C12.6014 27.8982 13.8637 28.542 15.544 28.4377C17.1442 28.338 17.9271 27.7111 18.4113 26.2376C18.4721 26.0533 18.5697 26.0296 18.7371 26.0302C20.2723 26.0348 21.8074 26.0336 23.3425 26.0342C23.3473 27.134 23.3521 28.2331 23.3564 29.3323Z" fill="white"/>
                <path d="M23.3569 29.3323C23.3527 28.2332 23.3478 27.134 23.3436 26.0343C23.4039 25.901 23.431 25.737 23.5291 25.6397C26.2127 22.9657 28.9018 20.2969 31.5987 17.6356C31.7004 17.5354 31.8926 17.4716 32.0425 17.471C34.3509 17.4606 36.6594 17.4687 38.9672 17.4577C39.243 17.4566 39.3008 17.5452 39.2996 17.7897C39.2918 20.3774 39.2948 22.9651 39.2948 25.5528C39.2948 25.6663 39.2948 25.7799 39.2948 25.9306C40.2108 25.9306 41.0973 25.9306 42.0001 25.9306C42.0001 27.0824 42.0001 28.1978 42.0001 29.3491C41.4442 29.3491 40.8962 29.3491 40.3481 29.3491C40.0771 29.3491 39.8061 29.3561 39.5351 29.3468C39.3526 29.3404 39.2882 29.4088 39.2906 29.5838C39.2984 30.1244 39.284 30.6656 39.2972 31.2056C39.3026 31.4171 39.2406 31.4924 39.0111 31.4912C37.4453 31.4825 35.88 31.482 34.3142 31.4901C34.0805 31.4912 34.0311 31.4153 34.0384 31.2033C34.0564 30.6922 34.0317 30.1794 34.0504 29.6684C34.06 29.4134 33.9769 29.3422 33.7107 29.3428C30.3689 29.3509 27.027 29.3485 23.6845 29.3474C23.5767 29.3474 23.4665 29.3381 23.3569 29.3323ZM34.048 20.9707C34.0239 20.9568 33.9998 20.9429 33.9763 20.9284C32.272 22.6053 30.5676 24.2827 28.8837 25.9392C28.8506 25.9329 28.8885 25.9468 28.9265 25.9468C30.5525 25.9479 32.1786 25.945 33.8047 25.952C34.0444 25.9532 34.051 25.8303 34.0504 25.6582C34.0462 24.3934 34.048 23.1285 34.048 21.863C34.048 21.5658 34.048 21.2686 34.048 20.9707Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="36" height="15" fill="white" transform="translate(6 17)"/>
                </clipPath>
            </defs>
        </svg>
    );
};