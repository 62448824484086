import React from 'react';
import './style.scss';

interface IProps {
    stream: MediaStream;
}

// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext || null;

export class SoundMeter extends React.Component<IProps> {
    volumeRef: React.RefObject<HTMLDivElement> = React.createRef();
    node: ScriptProcessorNode;
    analyser: AnalyserNode;

    isStreamWithSound = (): boolean => {
        const { stream } = this.props;

        return !!(stream && stream.getAudioTracks() && stream.getAudioTracks().length);
    };

    changeSound = () => {
        const array = new Uint8Array(this.analyser.frequencyBinCount);
        this.analyser.getByteFrequencyData(array);
        let values = 0;
        const length = array.length;

        for (let i = 0; i < length; i++) {
            values += (array[i]);
        }

        const average = values / length;
        this.volumeRef.current.style.width = `${average}%`;
    };

    componentDidMount(): void {
       this.addEventListener();
    }

    addEventListener = () => {
        try {
            const { stream } = this.props;

            if (this.isStreamWithSound() && AudioContext) {
                const audioContext = new AudioContext();
                const analyser = audioContext.createAnalyser();
                const microphone = audioContext.createMediaStreamSource(stream);
                const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

                analyser.smoothingTimeConstant = 0.8;
                analyser.fftSize = 1024;

                microphone.connect(analyser);
                analyser.connect(javascriptNode);
                javascriptNode.connect(audioContext.destination);

                this.node = javascriptNode;
                this.analyser = analyser;

                javascriptNode.addEventListener('audioprocess', this.changeSound);
            }
        } catch (error) {
            console.error(error);
        }
    };

    removeEventListener = () => {
        if (this.node) {
            this.node.removeEventListener('audioprocess', this.changeSound);
        }
    };

    componentWillUnmount(): void {
        this.removeEventListener();
    }

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        if (prevProps.stream !== this.props.stream) {
            this.removeEventListener();
            this.addEventListener();
        }
    }

    render(): React.ReactNode {
        if (!this.isStreamWithSound()) {
            return null;
        }

        return (
            <div className="sound-meter">
                <div className='sound-meter__wrapper'>
                    <div className='sound-meter__meter' />
                    <div className='sound-meter__volume' ref={this.volumeRef} />
                </div>
            </div>
        );
    }
}