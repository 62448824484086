import { action, observable, makeObservable } from 'mobx';
import debounce from 'lodash/debounce';
import { ApiRoutes, VideoResolutions } from '@common-types';
import {
    STORAGE_CAMERA_LABEL,
    STORAGE_MICRO_LABEL,
    STORAGE_NAME_LABEL,
} from '@constants';
import { isFirefox, showWarnMessage, axiosInstance, getAxiosHeaders } from '@utils';
import { Base } from './base-store';

export class UserMediaStore extends Base {
    name: string = '';
    cameras: MediaDeviceInfo[] = [];
    micros: MediaDeviceInfo[] = [];
    selectedCamera: MediaDeviceInfo = null;
    selectedMicro: MediaDeviceInfo = null;
    videoElement: HTMLVideoElement = null;
    videoResolution: VideoResolutions = VideoResolutions.High;
    stream: MediaStream = null;
    isCameraEnabled: boolean = true;
    isMicroEnabled: boolean = true;

    constructor(rootStore) {
        super(rootStore);

        makeObservable(this, {
            isCameraEnabled: observable,
            isMicroEnabled: observable,
            stream: observable,
            name: observable,
            cameras: observable,
            micros: observable,
            selectedCamera: observable,
            selectedMicro: observable,
            videoResolution: observable,
            changeName: action,
            setCameras: action,
            setMicros: action,
            selectCamera: action,
            selectMicro: action,
            init: action,
            setStream: action,
            toggleCamera: action,
            toggleMicro: action,
        });
    }

    toggleCamera = () => {
        this.isCameraEnabled = !this.isCameraEnabled;
        const cameraTracks = this.stream?.getVideoTracks();

        if (cameraTracks && cameraTracks.length) {
            cameraTracks[0].enabled = this.isCameraEnabled;
        }
    };

    toggleMicro = () => {
        this.isMicroEnabled = !this.isMicroEnabled;
        const audioTracks = this.stream?.getAudioTracks();

        if (audioTracks && audioTracks.length) {
            audioTracks[0].enabled = this.isMicroEnabled;
        }
    };

    init = async (videoElement: HTMLVideoElement) => {
        this.name = this.name ? this.name : localStorage.getItem(STORAGE_NAME_LABEL) || '';
        const cameraId = localStorage.getItem(STORAGE_CAMERA_LABEL) || '';
        const microId = localStorage.getItem(STORAGE_MICRO_LABEL) || '';

        if (cameraId && this.cameras) {
            const selectedCamera = this.cameras.find(({ deviceId }) => {
                return deviceId === cameraId;
            });

            if (selectedCamera) {
                this.selectedCamera = selectedCamera;
            }
        }

        if (microId && this.micros) {
            const selectedMicro = this.micros.find(({ deviceId }) => {
                return deviceId === microId;
            });

            if (selectedMicro) {
                this.selectedMicro = selectedMicro;
            }
        }

        if (videoElement) {
            this.videoElement = videoElement;
            await this.setStream();
        }
    };

    setStream = async () => {
        try {
            if (this.selectedCamera && this.videoElement) {
                if (this.stream) {
                    this.stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                }

                const constraint: MediaStreamConstraints = {
                    audio: {
                        deviceId: this.selectedMicro.deviceId || 'default',
                    },
                    video: {
                        deviceId: this.selectedCamera.deviceId || 'default',
                    }
                };

                if (!isFirefox()) {
                    (constraint.video as MediaTrackConstraints).frameRate = {
                        max: 25,
                    };
                }

                const stream = await navigator.mediaDevices.getUserMedia(constraint);

                this.stream = stream;
                this.applyEnabled();
                this.videoElement.srcObject = stream;
            }
        } catch (error) {
            this.selectCamera(null);
            showWarnMessage('Warn, you have some problems with selected micro (it can be blocked by other apps)');
            console.error(error);
        }
    };

    applyEnabled = () => {
        const audioTracks = this.stream?.getAudioTracks();
        const videoTracks = this.stream?.getVideoTracks();

        if (videoTracks && videoTracks.length) {
            videoTracks[0].enabled = this.isCameraEnabled;
        }

        if (audioTracks && audioTracks.length) {
            audioTracks[0].enabled = this.isMicroEnabled;
        }
    };

    changeName = async (name: string) => {
        try {
            this.name = name;

            localStorage.setItem(STORAGE_NAME_LABEL, name);

            this.saveName();
        } catch (error) {
            console.error(error);
        }
    };

    saveName = debounce(async () => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.put(`${ApiRoutes.User}/${this.rootStore.appStore.currentUserId}`, {
                    name: this.name,
                }, getAxiosHeaders());
            }
        } catch (error) {
            console.error(error);
        }
    }, 1000);

    selectCamera = async (camera: MediaDeviceInfo) => {
        this.selectedCamera = camera;

        if (camera) {
            localStorage.setItem(STORAGE_CAMERA_LABEL, camera.deviceId);
            await this.setStream();
        } else {
            localStorage.setItem(STORAGE_CAMERA_LABEL, null);
        }

    };

    selectMicro = async (micro: MediaDeviceInfo) => {
        this.selectedMicro = micro;

        if (micro) {
            localStorage.setItem(STORAGE_MICRO_LABEL, micro.deviceId);
            await this.setStream();
        } else {
            localStorage.setItem(STORAGE_MICRO_LABEL, null);
        }
    };

    setCameras = (items: MediaDeviceInfo[]) => {
        this.cameras = items;
        const defaultCamera = items.find(({ deviceId }) => deviceId === 'default');

        if (defaultCamera) {
            this.selectedCamera = defaultCamera;

            if (!localStorage.getItem(STORAGE_CAMERA_LABEL)) {
                localStorage.setItem(STORAGE_CAMERA_LABEL, this.selectedCamera.deviceId);
            }
        }

        if (items.length === 1 && !defaultCamera) {
            this.selectedCamera = items[0];

            if (!localStorage.getItem(STORAGE_CAMERA_LABEL)) {
                localStorage.setItem(STORAGE_CAMERA_LABEL, this.selectedCamera.deviceId);
            }
        }
    };

    setMicros = (items: MediaDeviceInfo[]) => {
        this.micros = items;

        const defaultMicro = items.find(({ deviceId }) => deviceId === 'default');

        if (defaultMicro) {
            this.selectedMicro = defaultMicro;

            if (!localStorage.getItem(STORAGE_MICRO_LABEL)) {
                localStorage.setItem(STORAGE_MICRO_LABEL, this.selectedMicro.deviceId);
            }
        }

        if (items.length === 1 && !defaultMicro) {
            this.selectedMicro = items[0];

            if (!localStorage.getItem(STORAGE_MICRO_LABEL)) {
                localStorage.setItem(STORAGE_MICRO_LABEL, this.selectedMicro.deviceId);
            }
        }
    };
}
