import { AppStore } from './app-store';
import { GuestStore } from './guest-store';
import { UserMediaStore } from './user-media-store';
import { SimpleModeStore } from './simple-mode-store';
import { UsersConferenceStore } from './users-conference-store';
import { StreamViewStore } from './stream-view-store';
import { ShareWebCamStore } from './share-web-cam';
import { ShareWebCamConferenceStore } from './share-web-cam-conference';
import { AdvanceModeStore } from './advance-mode';

export class RootStore {
    readonly appStore: AppStore;
    readonly guestStore: GuestStore;
    readonly userMediaStore: UserMediaStore;
    readonly simpleModeStore: SimpleModeStore;
    readonly usersConferenceStore: UsersConferenceStore;
    readonly streamViewStore: StreamViewStore;
    readonly shareWebCamStore: ShareWebCamStore;
    readonly shareWebCamConferenceStore: ShareWebCamConferenceStore;
    readonly advanceModeStore: AdvanceModeStore;

    constructor() {
        this.appStore = new AppStore(this);
        this.guestStore = new GuestStore(this);
        this.userMediaStore = new UserMediaStore(this);
        this.simpleModeStore = new SimpleModeStore(this);
        this.usersConferenceStore = new UsersConferenceStore(this);
        this.streamViewStore = new StreamViewStore(this);
        this.shareWebCamStore = new ShareWebCamStore(this);
        this.shareWebCamConferenceStore = new ShareWebCamConferenceStore(this);
        this.advanceModeStore = new AdvanceModeStore(this);
    }
}
