import React from 'react';
import { inject, observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { AdvanceModeStore, UsersConferenceStore } from '@services';
import { isMobile } from '@utils';
import { ILayer } from '@common-types';
import { AdvanceControl } from '../control';
import './styles.scss';

interface IProps {
    AdvanceModeStore?: AdvanceModeStore;
    UsersConferenceStore?: UsersConferenceStore;
}

interface IState {
    zoom: number;
}

@inject('AdvanceModeStore', 'UsersConferenceStore')
@observer
export class AdvancedCanvasWrapper extends React.Component<IProps, IState> {
    wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
    state: IState = {
        zoom: 0,
    };

    recalculateZoom = () => {
        if (this.wrapperRef && this.wrapperRef.current) {
            const { offsetHeight, offsetWidth } = this.wrapperRef.current;
            const width = offsetWidth - 48;
            const height = offsetHeight - 48;

            if (width < 1280 || height < 720) {
                const widthRatio = width / 1280;
                const heightRation = height / 720;

                this.setState({ zoom: heightRation > widthRatio ? widthRatio : heightRation });
            } else {
                this.setState({ zoom: 1 });
            }
        }
    };

    componentDidMount(): void {
        if (isMobile()) {
            setTimeout(() => {
                this.onMount();
            }, 2000);
        } else {
            this.onMount();
        }

        this.recalculateZoom();

        window.addEventListener('resize', this.onResize);
        document.addEventListener('resize', this.onResize);
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.onResize);
        document.removeEventListener('resize', this.onResize);
    }

    onResize = debounce(() => {
        this.recalculateZoom();
    }, 300);

    onMount = async () => {
        try {
            await this.props.AdvanceModeStore.init();
            this.props.UsersConferenceStore!.init();
            this.setState({});
        } catch (e) {
            console.error(e);
        }
    };

    onControlMouseDown = (layer: ILayer) => {
        const { selectLayer, selectedLayer } = this.props.AdvanceModeStore;

        if (selectedLayer?.id !== layer.id) {
            selectLayer(layer);
        }
    };

    render(): React.ReactNode {
        const { zoom } = this.state;
        const {
            selectedScene,
            sceneUpdateId,
            layerUpdateId,
            selectedLayer,
            changeLayerData,
            removeLayer,
            swapLayers,
            saveLayerPosition,
            saveLayerSize,
        } = this.props.AdvanceModeStore;
        sceneUpdateId;
        layerUpdateId;

        return (
            <div className='advanced-canvas-wrapper' ref={this.wrapperRef}>
                <div className='advanced-canvas-wrapper__main-wrapper'>
                    <div
                        className='advanced-canvas-wrapper__scene'
                        style={{
                            width: '1280px',
                            height: '720px',
                            transform: `scale(${zoom})`,
                            top: `${(720 * zoom - 720) / 2}px`,
                            left: `${(1280 * zoom - 1280) / 2}px`,
                        }}
                    >
                        {
                            selectedScene?.layers.slice().reverse().map((layer: ILayer) => {
                                return (
                                    <AdvanceControl
                                        saveLayerPosition={saveLayerPosition}
                                        saveLayerSize={saveLayerSize}
                                        zoom={zoom}
                                        selectedScene={selectedScene}
                                        moveToTop={() => {
                                            if (layer.index > 0) {
                                                swapLayers(selectedScene, layer.index, layer.index - 1);
                                            }
                                        }}
                                        moveToBottom={() => {
                                            if (layer.index < selectedScene?.layers.length - 1) {
                                                swapLayers(selectedScene, layer.index, layer.index + 1);
                                            }
                                        }}
                                        removeLayer={() => removeLayer(selectedScene, layer.id)}
                                        changeLayerData={changeLayerData}
                                        key={layer.id}
                                        selectedLayer={selectedLayer}
                                        layer={layer}
                                        onMouseDown={this.onControlMouseDown}
                                    />
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}
