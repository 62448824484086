import React from 'react';
import { Drawer, Select } from 'antd';
import { v4 } from 'uuid';
import { observer } from 'mobx-react';
import { observable, action, makeObservable, runInAction } from 'mobx';
import { CloseIcon, ReloadIcon } from '@assets/icons';
import { DeviceTypes } from '@common-types';
import { VideoWithDevice } from '@components';
import './styles.scss';

interface IProps {
    isDrawerOpen: boolean;
    closeDrawer: () => void;
    selectCamera: (device: MediaDeviceInfo) => Promise<void>;
    addAudioSource: (device: MediaDeviceInfo) => void;
}

@observer
export class CameraDrawer extends React.Component<IProps> {
    resultCameras: { id: string; camera: MediaDeviceInfo; micro: MediaDeviceInfo; }[] = [];
    cameras: MediaDeviceInfo[] = [];
    micros: MediaDeviceInfo[] = [];

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            cameras: observable,
            resultCameras: observable,
            micros: observable,
            setDevices: action,
            setMicro: action,
        });
    }

    componentDidMount(): void {
        this.setDevices();
    }

    setDevices = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();

            runInAction(() => {
                this.cameras = devices.filter(({ kind }) => {
                    return kind === DeviceTypes.Video;
                });
                this.micros = devices.filter(({ kind }) => {
                    return kind === DeviceTypes.Audio;
                });

                const defaultMicro = this.micros.find(({ deviceId }) => {
                    return deviceId === 'default';
                });

                this.resultCameras  = this.cameras.map((camera: MediaDeviceInfo) => {
                   return {
                       id: v4(),
                       camera: camera,
                       micro: defaultMicro,
                   };
                });
            });

        } catch (e) {
            console.error(e);
        }
    };

    setMicro = (id: string, microId: string) => {
        const cameraObj = this.resultCameras.find((obj) => {
            return obj.id === id;
        });
        const micro = this.micros.find(({ deviceId }) => deviceId === microId);

        if (cameraObj && micro) {
            cameraObj.micro = micro;
        }
    };

    reloadList = () => {
        this.setDevices();
    };

    selectCamera = (cameraObj: { id: string; camera: MediaDeviceInfo; micro: MediaDeviceInfo; }) => {
        this.props.selectCamera(cameraObj.camera);
        this.props.addAudioSource(cameraObj.micro);
    };

    render(): React.ReactNode {
        const { isDrawerOpen, closeDrawer } = this.props;

        return (
            <div className="camera-drawer">
                <Drawer
                    className='camera-drawer__drawer'
                    onClose={closeDrawer}
                    visible={isDrawerOpen}
                    closable={true}
                    destroyOnClose={true}
                    width={380}
                    closeIcon={<CloseIcon />}
                    placement='right'
                    title={(
                        <div className="camera-drawer__title">
                            Camera
                        </div>
                    )}
                >
                    <div className="camera-drawer__reload">
                        <div>
                            Browser
                        </div>
                        <div onClick={this.reloadList} style={{ cursor: 'pointer' }}>
                            <ReloadIcon />
                        </div>
                    </div>
                    <div className="camera-drawer__list">
                        {
                            this.resultCameras.map((cameraObj) => {
                                const { camera, micro, id } = cameraObj;

                                return (
                                    <div
                                        key={camera.deviceId}
                                        className="camera-drawer__device"
                                    >
                                        <div className="camera-drawer__device-name">
                                            <div className="camera-drawer__name">
                                                {camera.label || 'Camera'}
                                            </div>
                                        </div>
                                        <div className="camera-drawer__video-wrapper" onClick={() => this.selectCamera(cameraObj)}>
                                            <VideoWithDevice device={camera} />
                                        </div>
                                        <div className="camera-drawer__select">
                                            <Select
                                              value={micro.deviceId}
                                              onChange={(microId: string) => this.setMicro(id, microId)}
                                            >
                                                {
                                                    this.micros.map(({ deviceId, label }) => {
                                                        return (
                                                          <Select.Option value={deviceId} key={deviceId}>
                                                              {label || 'Default'}
                                                          </Select.Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </Drawer>
            </div>
        );
    }
}
