import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { DragItemTypes } from '@common-types';
import { MediaStreamVideo } from '@components';
import { isFirefox, isMobile } from '@utils';
import './styles.scss';

interface IProps {
    stream: MediaStream;
    name: string;
    id: string;
    primaryColor: string;
    showNames: boolean;
    swapUsers: (id: string, position: number) => void;
    canDrag: boolean;
    zoom: number;
}

const getStylesForUnsupportedZoomName = (zoom: number): React.CSSProperties => {
    let padding = 8;
    let fontSize = 16;

    if (isFirefox()) {
        padding = padding * zoom;
        fontSize = fontSize * zoom;
    }

    return {
        padding: `${padding}px`,
        fontSize: `${fontSize}px`,
    };
};

const getStylesForUnsupportedZoomNameBorder = (zoom: number): React.CSSProperties => {
    let width = 6;

    if (isFirefox()) {
        width = width * zoom;
    }

    return { width: `${width}px` };
};

let userVideoRef: React.RefObject<HTMLVideoElement> = null;

const setUserVideoRef = (ref: React.RefObject<HTMLVideoElement>) => {
    userVideoRef = ref;

    setYouVideoFit();
    userVideoRef.current.addEventListener('resize', setYouVideoFit);
};

const setYouVideoFit = () => {
    if (userVideoRef && userVideoRef.current) {
        const { videoHeight, videoWidth } = userVideoRef.current;
        const wrapper: HTMLDivElement = userVideoRef.current.closest('.stream-view-user-wrapper');

        if (wrapper) {
            const { offsetWidth, offsetHeight } = wrapper;


            if (
                (videoWidth > videoHeight) ||
                (videoHeight > videoWidth && offsetHeight > offsetWidth)
            ) {
                userVideoRef.current.style.objectFit = 'cover';
            } else {
                userVideoRef.current.style.objectFit = 'contain';
            }
        }
    }
};

export const StreamViewUser: React.FC<IProps> = (props: IProps) => {
    const { stream, name, primaryColor, showNames, id, zoom } = props;
    const [{ isDragging }, drag] = useDrag(() => ({
        type: DragItemTypes.User,
        item: { id },
        canDrag: props.canDrag,
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult<{ dropEffect: string; index: number; }>();

            if (dropResult?.dropEffect === 'move' && dropResult?.index >= 0) {
                props.swapUsers(id, dropResult.index);
            }
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        })
    }), [id]);

    setYouVideoFit();

    useEffect(() => {
        return () => {
            if (userVideoRef && userVideoRef.current) {
                userVideoRef.current.removeEventListener('resize', setYouVideoFit);
            }
        };
    }, []);

    return (
        <div
            className='stream-view-user'
            ref={drag}
            style={{
                opacity: isDragging ? 0.5 : 1,
            }}
        >
            <div className='stream-view-user__wrapper'>
                <MediaStreamVideo setRef={setUserVideoRef} stream={stream} />
                {
                    showNames && (
                        <div className='stream-view-user__name-wrapper' style={{ zoom }}>
                            <div
                                className='stream-view-user__name-border'
                                style={{
                                    background: primaryColor,
                                    ...getStylesForUnsupportedZoomNameBorder(zoom),
                                }}
                            />
                            <div className='stream-view-user__name' style={{
                                ...getStylesForUnsupportedZoomName(zoom),
                            }}>
                                {name}
                            </div>
                        </div>

                    )
                }
            </div>
        </div>
    );
};

