import React from 'react';
import { Button } from 'antd';
import './styles.scss';

interface IProps {
    className?: string;
    onClick?: () => void;
    htmlType?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    style?: React.CSSProperties;
}

export const SecondaryButton: React.FunctionComponent<IProps> = (props): React.ReactElement => {
    const {
        children,
        onClick = () => void 0,
        className = '',
        htmlType = 'button',
        disabled = false,
        style = {},
    } = props;

    return (
        <Button
            className={`secondary-button ${className}`}
            onClick={onClick}
            htmlType={htmlType}
            disabled={disabled}
            style={style}
        >
            {children}
        </Button>
    );
};
