import React from 'react';

interface IProps {}

export const PlusCircleIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.4443 3.6853C8.08879 2.58649 10.0222 2 12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7363 6.95991 21.2388 8.17316C21.7413 9.38642 22 10.6868 22 12C22 13.9778 21.4135 15.9112 20.3147 17.5557C19.2159 19.2002 17.6541 20.4819 15.8268 21.2388C13.9996 21.9957 11.9889 22.1937 10.0491 21.8079C8.10929 21.422 6.32746 20.4696 4.92894 19.0711C3.53041 17.6725 2.578 15.8907 2.19215 13.9509C1.8063 12.0111 2.00433 10.0004 2.76121 8.17316C3.51809 6.3459 4.79981 4.78412 6.4443 3.6853ZM15.9999 11H12.9999V8C12.9999 7.73478 12.8945 7.48043 12.707 7.29289C12.5194 7.10536 12.2651 7 11.9999 7C11.7347 7 11.4803 7.10536 11.2928 7.29289C11.1052 7.48043 10.9999 7.73478 10.9999 8V11H7.99987C7.73465 11 7.4803 11.1054 7.29276 11.2929C7.10523 11.4804 6.99987 11.7348 6.99987 12C6.99987 12.2652 7.10523 12.5196 7.29276 12.7071C7.4803 12.8946 7.73465 13 7.99987 13H10.9999V16C10.9999 16.2652 11.1052 16.5196 11.2928 16.7071C11.4803 16.8946 11.7347 17 11.9999 17C12.2651 17 12.5194 16.8946 12.707 16.7071C12.8945 16.5196 12.9999 16.2652 12.9999 16V13H15.9999C16.2651 13 16.5194 12.8946 16.707 12.7071C16.8945 12.5196 16.9999 12.2652 16.9999 12C16.9999 11.7348 16.8945 11.4804 16.707 11.2929C16.5194 11.1054 16.2651 11 15.9999 11Z" fill="white"/>
        </svg>
    );
};
