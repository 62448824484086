import React from 'react';
import { Switch } from 'antd';
import { AudioIcon, AudioOffIcon, PlusCircleIcon } from '@assets/icons';
import { LiveLabel } from '@components';
import './styles.scss';

interface IProps {
    name: string;
    signal: number;
    stream: MediaStream;
    soundOff?: boolean;
    withMute?: boolean;
    withAddToCanvas?: boolean;
    isMuted?: boolean;
    mute?: () => void;
    soundOnly?: boolean;
    withEnabled?: boolean;
    isEnabled?: boolean;
    toggleUser?: () => void;
    addToCanvas?: () => void;
}

export class UserItem extends React.Component<IProps> {
    videoRef: React.RefObject<HTMLVideoElement> = React.createRef();

    componentDidMount(): void {
        this.videoRef.current.addEventListener('resize', this.setVideoFit);
        this.videoRef.current.srcObject = this.props.stream;
        this.setVideoFit();
    }

    componentWillUnmount(): void {
        this.videoRef.current.removeEventListener('resize', this.setVideoFit);
    }

    setVideoFit = () => {
        if (this.videoRef && this.videoRef.current) {
            const { videoHeight, videoWidth } = this.videoRef.current;

            if (videoWidth > videoHeight) {
                this.videoRef.current.style.objectFit = 'cover';
            } else {
                this.videoRef.current.style.objectFit = 'contain';
            }
        }
    };

    render(): React.ReactNode {
        const {
            name,
            mute,
            toggleUser,
            addToCanvas,
            soundOff = false,
            withMute = false,
            soundOnly = false,
            isMuted = false,
            withEnabled = false,
            isEnabled = true,
            withAddToCanvas = false,
        } = this.props;

        return (
            <div className={`user-item ${soundOnly ? 'user-hidden' : ''} ${!isEnabled ? 'user-disabled' : ''}`}>
                <div className='user-item__top-section'>
                    <div className='user-item__name'>
                        {name}
                    </div>
                    <div className='user-item__signal'>

                    </div>
                </div>
                <div className='user-item__main'>
                    <div className='user-item__bg' />
                    <div className='user-item__wrapper'>
                        <video
                            ref={this.videoRef}
                            className={`user-item__video ${soundOnly ? 'sound-only' : ''}`}
                            autoPlay={true}
                            muted={soundOff}
                        />
                        {
                            isEnabled && (
                                <div className='user-item__live-label'>
                                    <LiveLabel />
                                </div>
                            )
                        }
                    </div>
                    <div className='user-item__actions'>
                        {
                            withEnabled && (
                                <div className='user-item__switch'>
                                    <Switch
                                        checked={isEnabled}
                                        onChange={() => toggleUser()}
                                    />
                                </div>
                            )
                        }
                        {
                            withMute && (
                                <div className="user-item__mute" onClick={() => {
                                    if (mute) {
                                        mute();
                                    }
                                }}>
                                    {isMuted ? <AudioOffIcon /> : <AudioIcon /> }
                                </div>
                            )
                        }
                        {
                            withAddToCanvas && (
                                <div className='user-item__add-to-canvas' onClick={() => addToCanvas()}>
                                    <PlusCircleIcon />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}
