import React from 'react';

interface IProps {}

export const ToBottomIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33333 16.6668L3.33333 6.66683L13.3333 6.66683L13.3333 16.6668L3.33333 16.6668ZM3.33333 18.3335L13.3333 18.3335C14.25 18.3335 15 17.5835 15 16.6668L15 6.66683C15 5.75016 14.25 5.00016 13.3333 5.00016L3.33334 5.00016C2.41667 5.00016 1.66667 5.75016 1.66667 6.66683L1.66667 16.6668C1.66667 17.5835 2.41667 18.3335 3.33333 18.3335Z" fill="#748499"/>
            <path opacity="0.5" d="M18.3334 15L16.6667 15L16.6667 3.33333L5.00004 3.33333L5.00004 1.66666L16.6667 1.66667C17.5834 1.66667 18.3334 2.41667 18.3334 3.33333L18.3334 15Z" fill="#748499"/>
            <path d="M9.16663 8.33333L9.16663 11.6667L11.6666 11.6667L8.33329 15L4.99996 11.6667L7.49996 11.6667L7.49996 8.33333L9.16663 8.33333Z" fill="#748499"/>
        </svg>
    );
};
