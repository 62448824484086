import React from 'react';

interface IProps {}

export const YouTubeColorIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="4" fill="#EB3223"/>
            <path d="M38.3662 16.5016C38.0205 15.2164 37.0072 14.2033 35.7222 13.8572C33.3746 13.2149 23.984 13.2149 23.984 13.2149C23.984 13.2149 14.5938 13.2149 12.2462 13.8329C10.9859 14.1786 9.94788 15.2166 9.60217 16.5016C8.98438 18.8491 8.98438 23.7173 8.98438 23.7173C8.98438 23.7173 8.98438 28.61 9.60217 30.9329C9.94824 32.2179 10.9612 33.2311 12.2464 33.5771C14.6185 34.2196 23.9844 34.2196 23.9844 34.2196C23.9844 34.2196 33.3746 34.2196 35.7222 33.6017C37.0074 33.2558 38.0205 32.2426 38.3666 30.9576C38.9842 28.61 38.9842 23.742 38.9842 23.742C38.9842 23.742 39.0089 18.8491 38.3662 16.5016ZM20.9943 28.2147V19.2198L28.803 23.7173L20.9943 28.2147Z" fill="white"/>
        </svg>
    );
};
