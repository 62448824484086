import React from 'react';
import { observer } from 'mobx-react';
import { ILayer, IScene, LayerTypeEnum, ITextSettings } from '@common-types';
import { TextMenu } from '../text-menu';
import { ImageMenu } from '../image-menu';
import { CameraMenu } from '../camera-menu';
import { ScreenShareMenu } from '../screen-share-menu';
import { SlideShowMenu } from '../slide-show-menu';
import './styles.scss';

interface IProps {
    zoom: number;
    textClasses: string[];
    layer: ILayer;
    selectedScene: IScene;
    moveToTop: () => void;
    moveToBottom: () => void;
    removeLayer: () => void;
    stretchToCanvas: () => void;
    makeBold: () => void;
    makeItalic: () => void;
    makeUnder: () => void;
    makeStrike: () => void;
    changeFontSettings: (settings: ITextSettings) => void;
    textSettings: ITextSettings;
}

@observer
export class AdvanceTopMenu extends React.Component<IProps> {
    getMenuElement = (): React.ReactNode => {
        const {
            textClasses,
            layer,
            stretchToCanvas,
            removeLayer,
            moveToTop,
            moveToBottom,
            makeBold,
            makeItalic,
            makeStrike,
            makeUnder,
            changeFontSettings,
            selectedScene,
            textSettings,
        } = this.props;
        const { type } = layer;

        switch (type) {
            case LayerTypeEnum.Text: {
                return (
                    <TextMenu
                        textSettings={textSettings}
                        textClasses={textClasses}
                        selectedScene={selectedScene}
                        layer={layer}
                        makeBold={makeBold}
                        makeItalic={makeItalic}
                        makeStrike={makeStrike}
                        makeUnder={makeUnder}
                        changeFontSettings={changeFontSettings}
                        removeLayer={removeLayer}
                        moveToBottom={moveToBottom}
                        moveToTop={moveToTop}
                    />
                );
            }
            case LayerTypeEnum.Image: {
                return (
                    <ImageMenu
                        selectedScene={selectedScene}
                        layer={layer}
                        removeLayer={removeLayer}
                        moveToBottom={moveToBottom}
                        moveToTop={moveToTop}
                        stretchToCanvas={stretchToCanvas}
                    />
                );
            }
            case LayerTypeEnum.SlideShow: {
                return (
                    <SlideShowMenu
                        selectedScene={selectedScene}
                        layer={layer}
                        removeLayer={removeLayer}
                        moveToBottom={moveToBottom}
                        moveToTop={moveToTop}
                        stretchToCanvas={stretchToCanvas}
                    />
                );
            }
            case LayerTypeEnum.Camera: {
                return (
                    <CameraMenu
                        selectedScene={selectedScene}
                        layer={layer}
                        removeLayer={removeLayer}
                        moveToBottom={moveToBottom}
                        moveToTop={moveToTop}
                        stretchToCanvas={stretchToCanvas}
                    />
                );
            }
            case LayerTypeEnum.BrowserWindow: {
                return (
                    <ScreenShareMenu
                        selectedScene={selectedScene}
                        layer={layer}
                        removeLayer={removeLayer}
                        moveToBottom={moveToBottom}
                        moveToTop={moveToTop}
                        stretchToCanvas={stretchToCanvas}
                    />
                );
            }
            default: return null;
        }
    };

    render(): React.ReactNode {
        const { zoom } = this.props;

        return (
            <div className="advance-top-menu" style={{ zoom: 1 / (zoom || 1) }}>
                {this.getMenuElement()}
            </div>
        );
    }
}
