import React from 'react';

interface IProps {}

export const OnlyFansColorIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z" fill="#00AFF0"/>
            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M23.961 9.00001C20.9956 9.00772 18.0989 9.89424 15.6371 11.5476C13.1753 13.2009 11.2588 15.5468 10.1297 18.289C9.00068 21.0311 8.70975 24.0464 9.29371 26.9538C9.87767 29.8612 11.3103 32.5303 13.4106 34.6238C15.5109 36.7173 18.1846 38.1413 21.0939 38.7158C24.0032 39.2903 27.0175 38.9896 29.756 37.8517C32.4944 36.7137 34.8341 34.7896 36.4795 32.3224C38.1248 29.8553 39.0019 26.9558 39 23.9903C38.9923 20.0086 37.4038 16.1928 34.5837 13.3819C31.7636 10.571 27.9428 8.99484 23.961 9.00001ZM23.961 37.4319C21.3055 37.4254 18.7114 36.6322 16.5065 35.1523C14.3015 33.6725 12.5845 31.5723 11.5725 29.1172C10.5604 26.6621 10.2986 23.9621 10.8201 21.3583C11.3416 18.7544 12.6231 16.3636 14.5027 14.4876C16.3822 12.6117 18.7756 11.3348 21.3804 10.8183C23.9852 10.3018 26.6847 10.5688 29.1379 11.5857C31.591 12.6025 33.6878 14.3235 35.1634 16.5313C36.639 18.7391 37.4273 21.3347 37.4286 23.9903C37.4234 27.5575 36.0018 30.9767 33.4764 33.4961C30.9509 36.0155 27.5283 37.4288 23.961 37.4254V37.4319Z" fill="white"/>
            <path d="M29.8419 22.7737V21.5442C29.8413 20.8143 29.7014 20.0919 29.4306 19.4197C29.1597 18.7475 28.7634 18.1392 28.265 17.6307C27.2576 16.5843 25.8977 15.9983 24.4811 16H23.5189C22.1034 15.9991 20.7447 16.5851 19.7381 17.6307C19.2395 18.1389 18.8433 18.7473 18.573 19.4196C18.3026 20.0919 18.1636 20.8144 18.1643 21.5442V22.7737L17.5 24.0326V25.8459C17.5012 26.6566 17.6567 27.4588 17.9573 28.2054C18.258 28.952 18.6977 29.6278 19.2507 30.1932C20.3769 31.3535 21.8919 32.0024 23.4692 32H24.5401C26.1134 32.0004 27.6238 31.3515 28.7462 30.1932C29.3001 29.6285 29.7407 28.9528 30.042 28.2061C30.3432 27.4594 30.4989 26.6569 30.5 25.8459V24.0326L29.8419 22.7737ZM24.627 28.3375V29.7888C24.6278 29.8952 24.6028 30.0001 24.5545 30.0937C24.5062 30.1872 24.4361 30.2663 24.3508 30.3237H24.09C24.0592 30.3286 24.0278 30.3286 23.9969 30.3237H23.9348C23.909 30.3266 23.883 30.3266 23.8572 30.3237H23.7393C23.6523 30.265 23.5806 30.1845 23.5307 30.0894C23.4808 29.9943 23.4544 29.8876 23.4537 29.779V28.3375C23.0939 28.1908 22.7947 27.9158 22.6084 27.5606C22.4221 27.2055 22.3605 26.7929 22.4345 26.3949C22.5085 25.9969 22.7133 25.639 23.0131 25.3836C23.3129 25.1282 23.6886 24.9917 24.0745 24.998H24.1086C24.4945 24.9917 24.8703 25.1282 25.1701 25.3836C25.4699 25.639 25.6747 25.9969 25.7486 26.3949C25.8226 26.7929 25.7611 27.2055 25.5748 27.5606C25.3885 27.9158 25.0892 28.1908 24.7295 28.3375H24.627ZM27.4487 22.8129H20.5544V21.5442C20.5552 21.1409 20.6328 20.7418 20.7826 20.3705C20.9324 19.9991 21.1514 19.663 21.4267 19.382C21.9833 18.8019 22.7348 18.475 23.5189 18.4721H24.4811C25.2652 18.4747 26.0168 18.8016 26.5733 19.382C26.8481 19.6635 27.0667 19.9997 27.2165 20.3709C27.3662 20.7422 27.4441 21.141 27.4456 21.5442L27.4487 22.8129Z" fill="white"/>
        </svg>
    );
};
