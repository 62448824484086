import React from 'react';

interface IProps {}

export const ArrowRightIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.29006 10.29L5.17006 6.41L1.29006 2.53C0.900058 2.14 0.900058 1.51 1.29006 1.12C1.68006 0.73 2.31006 0.729999 2.70006 1.12L7.29006 5.71C7.68006 6.1 7.68006 6.73 7.29006 7.12L2.70006 11.71C2.31006 12.1 1.68006 12.1 1.29006 11.71C0.910059 11.32 0.900059 10.68 1.29006 10.29Z" fill="#748499"/>
        </svg>
    );
};
