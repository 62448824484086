import React from 'react';
import { Collapse, Select, Switch } from 'antd';
import { BlockPicker } from 'react-color';
import { inject, observer } from 'mobx-react';
import { action, makeObservable, observable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { CollapseIcon } from '@assets/icons';
import { SimpleModeStore, AppStore } from '@services';
import { CustomFileUpload, ImagesList, PatternsSection } from '@components';
import { IUserImage, ImageTypesEnum, LogoPositionsEnum } from '@common-types';
import './styles.scss';

interface IProps {
    SimpleModeStore?: SimpleModeStore;
    AppStore?: AppStore;
}

enum BrandingPanels {
    BrandingSettings = 'BrandingSettings',
    Logotype = 'Logotype',
    Screensavers = 'Screensavers',
    Backgrounds = 'ScreeBackgroundsnsavers',
    Patterns = 'Patterns',
}

const { Option } = Select;

@inject('SimpleModeStore', 'AppStore')
@observer
export class Branding extends React.Component<IProps> {
    showColorPicker: boolean = false;
    isLogoUploadModalOpen: boolean = false;
    isBackgroundUploadModalOpen: boolean = false;

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            showColorPicker: observable,
            isLogoUploadModalOpen: observable,
            isBackgroundUploadModalOpen: observable,
            toggleColorPicker: action,
            openLogoUploadModal: action,
            closeLogoUploadModal: action,
            openBackgroundUploadModal: action,
            closeBackgroundUploadModal: action,
        });
    }

    openLogoUploadModal = () => {
        if (!this.props.AppStore.isUserLoggedIn) {
            this.props.SimpleModeStore.showSignUpModal();
        } else {
            this.isLogoUploadModalOpen = true;
        }
    };

    closeLogoUploadModal = () => this.isLogoUploadModalOpen = false;

    openBackgroundUploadModal = () => {
        if (!this.props.AppStore.isUserLoggedIn) {
            this.props.SimpleModeStore.showSignUpModal();
        } else {
            this.isBackgroundUploadModalOpen = true;
        }
    };

    closeBackgroundUploadModal = () => this.isBackgroundUploadModalOpen = false;

    toggleColorPicker = () => {
        this.showColorPicker = !this.showColorPicker;
    };

    selectLogoPosition = (value: string) => {
        this.props.SimpleModeStore!.setLogoPosition(value as LogoPositionsEnum);
    };

    addLogo = (file: IUserImage) => {
        this.props.SimpleModeStore.addLogoImage(file);
    };

    addBackground = (file: IUserImage) => {
        this.props.SimpleModeStore.addBackgroundImage(file);
    };

    render(): React.ReactNode {
        const {
            addLogo,
            addBg,
            showParticipantNames,
            primaryColor,
            setPrimaryColor,
            toggleParticipantNames,
            logoPosition,
            selectedBg,
            selectedLogo,
            removeBg,
            removeLogo,
            currentPattern,
            selectPattern,
            user,
            logoImagesList,
            backgroundImagesList,
            removeImage,
        } = this.props.SimpleModeStore!;

        return (
            <div className='branding'>
                <Collapse
                    expandIcon={(panelProps) => {
                        return (
                            <span style={{ transform: `rotate(${panelProps.isActive ? '0' : '180deg'})` }}>
                                <CollapseIcon />
                            </span>
                        );
                    }}
                    expandIconPosition='right'
                    defaultActiveKey={[
                        BrandingPanels.BrandingSettings,
                        BrandingPanels.Logotype,
                        BrandingPanels.Screensavers,
                        BrandingPanels.Backgrounds,
                        BrandingPanels.Patterns,
                    ]}
                >
                    <Collapse.Panel
                        className='branding__patterns'
                        header={'Patterns'}
                        key={BrandingPanels.Patterns}
                    >
                        <PatternsSection
                            onPatternSelect={selectPattern}
                            selectedPattern={currentPattern}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel
                        header={<FormattedMessage id='branding.settings' />}
                        key={BrandingPanels.BrandingSettings}
                    >
                        <div className='branding__primary-color' style={{ marginTop: '20px' }}>
                            <div style={{ marginBottom: ' ' }}>
                                Primary Color
                            </div>
                            <div style={{
                                display: 'flex',
                                padding: '8px',
                                background: '#222B33',
                                borderRadius: '4px',
                            }} onClick={this.toggleColorPicker}>
                                <div className='branding__color-preview' style={{
                                    background: primaryColor,
                                    borderRadius: '4px',
                                    width: '24px',
                                    height: '24px',
                                }} />
                                <div style={{ marginLeft: '10px' }}>
                                    {primaryColor}
                                </div>
                            </div>
                            {
                                this.showColorPicker && (
                                    <BlockPicker
                                        color={primaryColor}
                                        onChangeComplete={(color) => {
                                            this.toggleColorPicker();
                                            setPrimaryColor(color.hex)
                                        }}
                                    />
                                )
                            }

                        </div>
                        <div
                            className='branding__switch'
                            style={{ marginTop: '12px', marginBottom: '25px' }}
                        >
                            <Switch
                                onChange={() => toggleParticipantNames()}
                                checked={showParticipantNames}
                            />
                            <div className='branding__label'>
                                Participiant Names
                            </div>
                        </div>
                    </Collapse.Panel>
                    <Collapse.Panel
                        header={<FormattedMessage id='logotype' />}
                        key={BrandingPanels.Logotype}
                    >
                        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <ImagesList
                                deselectImage={removeLogo}
                                selectedSrc={selectedLogo}
                                images={logoImagesList}
                                addNewImage={this.openLogoUploadModal}
                                selectImage={(img: IUserImage) => addLogo(img)}
                                removeImage={removeImage}
                            />
                            <Select
                                style={{ marginTop: '12px' }}
                                value={logoPosition}
                                onChange={this.selectLogoPosition}
                            >
                                <Option
                                    value={LogoPositionsEnum.TopRight}
                                    key={LogoPositionsEnum.TopRight}
                                >
                                    Top Right
                                </Option>
                                <Option
                                    value={LogoPositionsEnum.TopLeft}
                                    key={LogoPositionsEnum.TopLeft}
                                >
                                    Top Left
                                </Option>
                                <Option
                                    value={LogoPositionsEnum.BottomRight}
                                    key={LogoPositionsEnum.BottomRight}
                                >
                                    Bottom Right
                                </Option>
                                <Option
                                    value={LogoPositionsEnum.BottomLeft}
                                    key={LogoPositionsEnum.BottomLeft}
                                >
                                    Bottom Left
                                </Option>
                            </Select>
                        </div>
                    </Collapse.Panel>
                    <Collapse.Panel
                        header={<FormattedMessage id='backgrounds' />}
                        key={BrandingPanels.Backgrounds}
                    >
                        <div style={{ marginTop: '20px', }}>
                            <ImagesList
                                deselectImage={removeBg}
                                selectedSrc={selectedBg}
                                images={backgroundImagesList}
                                addNewImage={this.openBackgroundUploadModal}
                                selectImage={(img: IUserImage) => addBg(img)}
                                removeImage={removeImage}
                            />
                        </div>
                    </Collapse.Panel>
                </Collapse>
                {
                    this.isLogoUploadModalOpen && (
                        <CustomFileUpload
                            closeModal={this.closeLogoUploadModal}
                            isModalOpen={this.isLogoUploadModalOpen}
                            userId={user?.id}
                            fileType={ImageTypesEnum.Logo}
                            addFile={this.addLogo}
                        />
                    )
                }
                {
                    this.isBackgroundUploadModalOpen && (
                        <CustomFileUpload
                            closeModal={this.closeBackgroundUploadModal}
                            isModalOpen={this.isBackgroundUploadModalOpen}
                            userId={user?.id}
                            fileType={ImageTypesEnum.Background}
                            addFile={this.addBackground}
                        />
                    )
                }
            </div>
        );
    }
}
