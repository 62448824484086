export interface IScene {
    id: string;
    name: string;
    index: number;
    layers: ILayer[];
    node?: any;
    previewImage?: string;
}

export interface ITextSettings {
    fontName?: string;
    fontSize?: string;
    color?: string;
}

export enum AdvanceModeTabs {
    Chat = 'chat',
    Captions = 'captions',
    Layers = 'layers',
}

export interface ILayer {
    id: string;
    index: number;
    name: string;
    type: LayerTypeEnum;
    specificData?: any;
    node?: any;
    size?: {
        width: number;
        height: number;
    };
    position?: {
        x: number;
        y: number;
    };
}

export enum LayerTypeEnum {
    Text = 'Text',
    Image = 'Image',
    SlideShow = 'SlideShow',
    Camera = 'Camera',
    BrowserWindow = 'BrowserWindow',
    Micro = 'Micro',
}

export interface IAudioSource {
    name: string;
    id: string;
    deviceId: string;
    muted: boolean;
    deviceInfo?: MediaDeviceInfo;
    stream?: MediaStream;
    mediaStreamAudioSourceNode?: MediaStreamAudioSourceNode;
}

export enum LayerActionsEnum {
    Delete = 'Delete',
    Rename = 'Rename',
}

export enum TextEditClasses {
    Bold = 'bold',
    Italic = 'italic',
    Under = 'under',
    Strike = 'strike',
}
