import React from 'react';

interface IProps {}

export const TrashRedIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 4H10.6667V3.33333C10.6667 2.8029 10.456 2.29419 10.0809 1.91912C9.70581 1.54405 9.1971 1.33333 8.66667 1.33333H7.33333C6.8029 1.33333 6.29419 1.54405 5.91912 1.91912C5.54405 2.29419 5.33333 2.8029 5.33333 3.33333V4H2.66667C2.48986 4 2.32029 4.07024 2.19526 4.19526C2.07024 4.32029 2 4.48986 2 4.66667C2 4.84348 2.07024 5.01305 2.19526 5.13807C2.32029 5.2631 2.48986 5.33333 2.66667 5.33333H3.33333V12.6667C3.33333 13.1971 3.54405 13.7058 3.91912 14.0809C4.29419 14.456 4.8029 14.6667 5.33333 14.6667H10.6667C11.1971 14.6667 11.7058 14.456 12.0809 14.0809C12.456 13.7058 12.6667 13.1971 12.6667 12.6667V5.33333H13.3333C13.5101 5.33333 13.6797 5.2631 13.8047 5.13807C13.9298 5.01305 14 4.84348 14 4.66667C14 4.48986 13.9298 4.32029 13.8047 4.19526C13.6797 4.07024 13.5101 4 13.3333 4ZM6.66667 3.33333C6.66667 3.15652 6.7369 2.98695 6.86193 2.86193C6.98695 2.73691 7.15652 2.66667 7.33333 2.66667H8.66667C8.84348 2.66667 9.01305 2.73691 9.13807 2.86193C9.2631 2.98695 9.33333 3.15652 9.33333 3.33333V4H6.66667V3.33333ZM11.3333 12.6667C11.3333 12.8435 11.2631 13.013 11.1381 13.1381C11.013 13.2631 10.8435 13.3333 10.6667 13.3333H5.33333C5.15652 13.3333 4.98695 13.2631 4.86193 13.1381C4.7369 13.013 4.66667 12.8435 4.66667 12.6667V5.33333H11.3333V12.6667Z" fill="#C24242"/>
        </svg>
    );
};
