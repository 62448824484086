import axios from 'axios';
import { STORAGE_TOKEN_LABEL } from '@constants';



export const axiosInstance = axios.create({

});

export const getAxiosHeaders = () => {
    const { token } = JSON.parse(localStorage.getItem(STORAGE_TOKEN_LABEL));

    return {
        headers: { Authorization: `Bearer ${token}` }
    };
};
