import React from 'react';
import { action, makeObservable, observable } from 'mobx';
import { fabric } from 'fabric';
import debounce from 'lodash/debounce';
import format from 'date-fns/format';
import { v4 } from 'uuid';
import {
    ADMIN_SS_ID,
    DEFAULT_CANVAS_BG_COLOR,
    DEFAULT_CONSTRAINTS,
    DEFAULT_FRAME_RATE,
    DESTINATIONS_ICONS_MAP,
    FULL_SCREEN_CONSTRAINTS,
    STORAGE_CAMERA_LABEL,
    STORAGE_MICRO_LABEL,
    STORAGE_AUDIO_LABEL,
} from '@constants';
import {
    ApiRoutes,
    ConferenceRolesEnum,
    ImageTypesEnum,
    IProject,
    IStreamDestination,
    IUser,
    IUserDestination,
    IUserImage,
    LogoPositionsEnum,
    NavBarTabs,
    PatternsEnum,
    Routes,
    StreamTypeEnum,
} from '@common-types';
import { Base } from './base-store';
import {
    axiosInstance,
    getAxiosHeaders,
    getCameraConstraint,
    isFirefox,
    showScreenShareErrorMessage,
    showWarnMessage,
    setSinkId,
} from '@utils';
import { Locales } from '@i18n';
import { Modal } from 'antd';
import { CloseIcon } from '@assets/icons';

const padding = 20;
const doublePadding = padding * 2;
const halfPadding = padding / 2;
// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext || null;

export class SimpleModeStore extends Base {
    canvas: fabric.StaticCanvas;
    tab: NavBarTabs = NavBarTabs.Branding;
    streamName: string = 'Stream name';
    streamDate: string = format(new Date(), 'MMM dd - HH:mm:ss');
    prevLogo: fabric.Image;
    prevBg: fabric.Image;
    cameraNode: fabric.Image;
    screenShareNode: fabric.Image;
    resultSteamNodes: fabric.Image[] = [];
    streamNodesHandlers: {
        element: HTMLVideoElement;
        handler: () => void;
    }[] = [];
    logoNode: fabric.Image;
    namesNodes: {
        colorNode: fabric.Rect,
        groupNode: fabric.Group,
    }[] = [];
    cameraStream: MediaStream;
    screenShareStream: MediaStream = null;
    showParticipantNames: boolean = true;
    primaryColor: string = '#4745CE';
    logoPosition: LogoPositionsEnum = LogoPositionsEnum.TopRight;
    usersOrder: { id: string; isEnabled: boolean; }[] = [];
    currentPattern: PatternsEnum = PatternsEnum.OneUser;
    video: HTMLVideoElement;
    isAudioMuted: boolean = false;
    isMicroMuted: boolean = false;
    isVideoMuted: boolean = false;
    isSettingsModalShown: boolean = false;
    currentCamera: string = null;
    currentMicro: string = null;
    currentAudio: string = null;
    isStreaming: boolean = false;
    selectedLogo: string = '';
    selectedBg: string = '';
    isScreenShare: boolean = false;
    destinations: IStreamDestination[] = [];
    isDestinationsModalShown: boolean = false;
    isDestinationsDrawerOpen: boolean = false;
    selectedDestination: IStreamDestination = null;
    roomId: string = v4();
    isAdminEnabled: boolean = true;
    canvasStream: MediaStream;
    audioContext: AudioContext | null;
    mediaStreamAudioDestination: MediaStreamAudioDestinationNode;
    mediaStreamAudioSourceNode: MediaStreamAudioSourceNode;
    mediaStreamScreenShareAudioSourceNode: MediaStreamAudioSourceNode;
    isScreenShareEnabled: boolean = false;
    isScreenShareMuted: boolean = false;
    renderInterval;
    currentVideoMediaTrackConstraints: MediaTrackConstraints = { ...FULL_SCREEN_CONSTRAINTS };
    currentSSVideoMediaTrackConstraints: MediaTrackConstraints = { ...DEFAULT_CONSTRAINTS };
    defaultSSVideoMediaTrackConstraints: MediaTrackConstraints;
    user: IUser = null;
    selectedProject: IProject = null;
    logoImagesList: IUserImage[] = [
        {
            id: '2',
            type: ImageTypesEnum.Logo,
            src: '/default/logo.svg',
            isInitial: true,
        },
    ];
    backgroundImagesList: IUserImage[] = [
        {
            id: '1',
            type: ImageTypesEnum.Background,
            src: '/default/hand-painted-watercolor-background-with-sky-and-clouds-shape_24972-1095.jpg',
            isInitial: true,
        },
        {
            id: '2',
            type: ImageTypesEnum.Background,
            src: '/default/w-qjCHPZbeXCQ-unsplash.jpg',
            isInitial: true,
        },
    ];
    isProjectCreateModalOpen: boolean = false;
    selectedToEditProject: IProject = null;

    constructor(rootStore) {
        super(rootStore);

        makeObservable(this, {
            isProjectCreateModalOpen: observable,
            selectedToEditProject: observable,
            logoImagesList: observable,
            backgroundImagesList: observable,
            user: observable,
            selectedProject: observable,
            isAdminEnabled: observable,
            isScreenShareMuted: observable,
            isScreenShareEnabled: observable,
            isDestinationsDrawerOpen: observable,
            selectedDestination: observable,
            isDestinationsModalShown: observable,
            destinations: observable,
            tab: observable,
            isScreenShare: observable,
            selectedLogo: observable,
            selectedBg: observable,
            isStreaming: observable,
            usersOrder: observable,
            isSettingsModalShown: observable,
            isMicroMuted: observable,
            isVideoMuted: observable,
            streamName: observable,
            streamDate: observable,
            currentCamera: observable,
            screenShareStream: observable,
            currentMicro: observable,
            currentAudio: observable,
            isAudioMuted: observable,
            showParticipantNames: observable,
            currentPattern: observable,
            primaryColor: observable,
            logoPosition: observable,
            changeTab: action,
            toggleParticipantNames: action,
            setPrimaryColor: action,
            setLogoPosition: action,
            addLogo: action,
            addUser: action,
            removeUser: action,
            toggleUser: action,
            selectPattern: action,
            toggleAudio: action,
            toggleMicros: action,
            toggleVideo: action,
            openSettingsModal: action,
            closeSettingsModal: action,
            setCurrentCamera: action,
            setCurrentMicro: action,
            updateCamera: action,
            updateMicro: action,
            startStream: action,
            setStreamStatus: action,
            addBg: action,
            stopScreenShare: action,
            addScreenShare: action,
            openDestinationsModal: action,
            closeDestinationsModal: action,
            addDestination: action,
            selectDestination: action,
            setCurrentAudio: action,
            openDestinationsDrawer: action,
            closeDestinationsDrawer: action,
            removeDestination: action,
            stopStream: action,
            toggleAdmin: action,
            changeStreamName: action,
            removeBg: action,
            removeLogo: action,
            toggleDestinationActive: action,
            setInitialDestinations: action,
            toggleScreenShare: action,
            toggleScreenShareSound: action,
            init: action,
            addLogoImage: action,
            deleteLogoImage: action,
            addBackgroundImage: action,
            deleteBackgroundImage: action,
            openProjectCreateModal: action,
            closeProjectCreateModal: action,
            setSelectedToEditProject: action,
            deleteProject: action,
            selectProject: action,
            pushDestination: action,
            updateCurrentAudio: action,
        });
    }

    selectProject = async (project: IProject) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.put(`${ApiRoutes.User}/${this.user.id}`, {
                    lastSelectedProject: project.id,
                }, getAxiosHeaders());

                this.selectedProject = project;
                this.streamName = this.selectedProject.name;
                this.selectPattern(this.selectedProject.selectedPattern as PatternsEnum, true);
                this.setLogoPosition(this.selectedProject.logoPosition as LogoPositionsEnum, true);
                this.showParticipantNames = this.selectedProject.showNames;
                this.setPrimaryColor(this.selectedProject.color, true);

                this.removeLogo();
                if (this.selectedProject.selectedLogo) {
                    this.addLogo({
                        id: v4(),
                        type: ImageTypesEnum.Logo,
                        src: this.selectedProject.selectedLogo,
                    }, true);
                }

                this.removeBg();
                if (this.selectedProject.selectedBg) {
                    this.addBg({
                        id: v4(),
                        type: ImageTypesEnum.Background,
                        src: this.selectedProject.selectedBg,
                    }, true);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    removeImage = async (img: IUserImage) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.delete(`${ApiRoutes.Images}/${img.id}`, getAxiosHeaders());

                if (img.type !== ImageTypesEnum.Avatar) {
                    this.user.images = this.user.images.filter((image: IUserImage) => image.id !== img.id);
                    const updatedProjects = this.user.projects.filter(({selectedLogo, selectedBg}) => {
                        return selectedLogo === img.src || selectedBg === img.src;
                    });

                    if (img.type === ImageTypesEnum.Logo) {
                        this.logoImagesList = this.logoImagesList.filter(({id}) => img.id !== id);

                        if (this.selectedLogo && this.selectedLogo === img.src) {
                            this.removeLogo();
                        }
                    } else if (img.type === ImageTypesEnum.Background) {
                        this.backgroundImagesList = this.backgroundImagesList.filter(({id}) => img.id !== id);

                        if (this.selectedBg && this.selectedBg === img.src) {
                            this.removeBg();
                        }
                    }

                    if (updatedProjects.length) {
                        updatedProjects.forEach(async (project: IProject) => {
                            const field = img.type === ImageTypesEnum.Logo ? 'selectedLogo' : 'selectedBg';
                            await axiosInstance.put(`${ApiRoutes.Projects}/${project.id}`, {
                                [field]: '',
                            }, getAxiosHeaders());
                            project[field] = '';
                        });
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    deleteProject = async (id: number) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.delete(`${ApiRoutes.Projects}/${id}`, getAxiosHeaders());
                this.user.projects = this.user.projects.filter((project: IProject) => project.id !== id);

                if (id === this.selectedProject?.id) {
                    this.selectProject(this.user.projects.find(({ isInitial }) => isInitial));
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    setSelectedToEditProject = (project: IProject | null) => {
        this.selectedToEditProject = project;
    };

    createProject = async (project: IProject) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                const {data} = await axiosInstance.post(`${ApiRoutes.Projects}`, {
                    ...project,
                    userId: this.user?.id,
                }, getAxiosHeaders());
                this.user.projects.push({
                    ...data
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    updateProject = async (project: IProject) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.put(`${ApiRoutes.Projects}/${project.id}`, {
                    name: project.name,
                    description: project.description,
                    avatar: project.avatar,
                    avatarId: project.avatarId,
                }, getAxiosHeaders());
                const updatedProjectPosition = this.user.projects.findIndex(({id}) => id === project.id);

                const newProject = {
                    ...this.user.projects[updatedProjectPosition],
                    ...project,
                };
                this.user.projects[updatedProjectPosition] = newProject;
                this.selectProject(newProject);
            }

        } catch (error) {
            console.error(error);
        }
    };

    showSignUpModal = () => {
        Modal.confirm({
            className: 'delete-project-confirm-modal confirm-action-custom',
            content: "For use this functionality you should first Sign In",
            title: (
                <div className="custom-confirm-popup-header">
                    <div className="custom-confirm-popup-title-text">
                        Please Sign In
                    </div>
                </div>
            ),
            okText: `Sign In`,
            cancelText: 'Cancel',
            okButtonProps: {
                className: 'purple-button'
            },
            cancelButtonProps: {
                className: 'secondary-button'
            },
            onCancel: () => {
                Modal.destroyAll();
            },
            onOk: () => {
                Modal.destroyAll();
                setTimeout(() => {
                    this.dependencies?.history?.push(Routes.Login);
                });
            },
            closeIcon: <CloseIcon />,
            closable: true,
            width: 400,
        });
    };

    openProjectCreateModal = () => {
        if (!this.rootStore.appStore.isUserLoggedIn) {
            this.showSignUpModal();
        } else {
            this.isProjectCreateModalOpen = true;
        }
    };

    closeProjectCreateModal = () => {
        this.isProjectCreateModalOpen = false;
        this.selectedToEditProject = null;
    };

    addLogoImage = (img: IUserImage) => {
        this.logoImagesList.push(img);
    };

    deleteLogoImage = (id: string) => {
        this.logoImagesList = this.logoImagesList.filter((img: IUserImage) => {
            return img.id !== id;
        });
    };

    addBackgroundImage = (img: IUserImage) => {
        this.backgroundImagesList.push(img);
    };

    deleteBackgroundImage = (id: string) => {
        this.backgroundImagesList = this.backgroundImagesList.filter((img: IUserImage) => {
            return img.id !== id;
        });
    };

    toggleScreenShare = async () => {
        this.isScreenShareEnabled = !this.isScreenShareEnabled;

        if (this.isScreenShareEnabled) {
            this.rootStore.streamViewStore.enableUser(ADMIN_SS_ID);
        } else {
            this.rootStore.streamViewStore.disableUser(ADMIN_SS_ID);
        }

        if (
            this.audioContext &&
            this.mediaStreamAudioDestination &&
            this.screenShareStream &&
            this.screenShareStream.getAudioTracks()?.length
        ) {
            if (!this.mediaStreamScreenShareAudioSourceNode) {
                this.mediaStreamScreenShareAudioSourceNode = this.audioContext.createMediaStreamSource(this.screenShareStream);
            }

            if (this.isScreenShareEnabled) {
                this.mediaStreamScreenShareAudioSourceNode.connect(this.mediaStreamAudioDestination);
            } else {
                this.mediaStreamScreenShareAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
            }
        }

        this.applyPattern(this.currentPattern);
    };

    setInitialDestinations = (destinations: IStreamDestination[]) => {
        try {
            if (destinations && destinations.length) {
                destinations.forEach((destination) => {
                    const { icon, colorIcon } = DESTINATIONS_ICONS_MAP.find(({ id }) => {
                        return id === destination.iconsId;
                    });

                    this.pushDestination({
                        ...destination,
                        icon,
                        colorIcon,
                    });
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    setCanvasStream = (stream: MediaStream) => {
        this.canvasStream = stream;
    };

    changeStreamName = (name: string, isInit: boolean = false) => {
        this.streamName = name;

        if (this.selectedProject) {
            this.selectedProject.name = name;
        }

        if (!isInit) {
            this.saveProjectName();
        }
    };

    saveProjectName = debounce(async () => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                if (this.selectedProject) {
                    await axiosInstance.put(`${ApiRoutes.Projects}/${this.selectedProject.id}`, {
                        name: this.streamName,
                    }, getAxiosHeaders());
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, 500);

    toggleAdmin = () => {
        this.isAdminEnabled = !this.isAdminEnabled;
        const userId = this.rootStore.usersConferenceStore.currentUserId;

        if (this.isAdminEnabled) {
            this.rootStore.streamViewStore.enableUser(userId);
        } else {
            this.rootStore.streamViewStore.disableUser(userId);
        }

        if (this.audioContext && this.mediaStreamAudioDestination && this.mediaStreamAudioSourceNode) {
            if (this.isAdminEnabled) {
                this.mediaStreamAudioDestination?.stream?.getAudioTracks().forEach((track) => {
                    track.enabled = true;
                });
            } else {
                this.mediaStreamAudioDestination?.stream?.getAudioTracks().forEach((track) => {
                    track.enabled = false;
                });
            }
        }

        this.applyPattern(this.currentPattern);
    };

    openDestinationsDrawer = () => {
        if (!this.rootStore.appStore.isUserLoggedIn) {
            this.showSignUpModal();
        } else {
            this.isDestinationsDrawerOpen = true;
        }
    };

    closeDestinationsDrawer = () => {
        this.isDestinationsDrawerOpen = false;
    };

    selectDestination = (destination: IStreamDestination) => {
        this.selectedDestination = destination;
    };

    pushDestination = (destination: IStreamDestination) => {
        this.destinations.push(destination);
    };

    addDestination = (
        id: number,
        iconsId: number,
        name: string,
        url: string,
        streamKey: string,
    ) => {
        try {
            const destination = this.destinations.find((dest) => {
                return dest.id === id;
            });

            if (destination) {
                destination.name = name;
                destination.url = url;
                destination.streamKey = streamKey;

                setTimeout(() => {
                    this.rootStore.usersConferenceStore.updateDestinationDestination(destination.id as number, name, url, streamKey);
                }, 1000);

            } else {
                if (this.destinations.length >= 4) {
                    showWarnMessage(`You can't add more destinations, exceed limit`);
                } else {
                    this.rootStore.usersConferenceStore.addDestination(name, url, streamKey, iconsId);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    toggleDestinationActive = (destination: IStreamDestination, active: boolean) => {
        destination.active = active;

        if (destination.active) {
            this.rootStore.usersConferenceStore.onDestination(
                destination.id,
                destination.url,
                destination.streamKey,
            );
        } else {
            this.rootStore.usersConferenceStore.offDestination(destination.id);
        }
    };

    removeDestination = (id: string | number) => {
        this.destinations = this.destinations.filter((destination) => {
            return destination.id !== id;
        });

        this.rootStore.usersConferenceStore.removeDestination(id);
    };

    openDestinationsModal = () => {
        this.isDestinationsModalShown = true;
    };

    closeDestinationsModal = () => {
        this.isDestinationsModalShown = false;
    };

    setCurrentCamera = (camera: string) => {
        this.currentCamera = camera;
        localStorage.setItem(STORAGE_CAMERA_LABEL, camera);
    };

    setCurrentMicro = (micro: string) => {
        this.currentMicro = micro;
        localStorage.setItem(STORAGE_MICRO_LABEL, micro);
    };

    setCurrentAudio = (audio: string) => {
        this.currentAudio = audio;
        localStorage.setItem(STORAGE_AUDIO_LABEL, audio);
    };

    updateCurrentAudio = (deviceId: string) => {
        if (deviceId && deviceId !== this.currentAudio) {
            this.currentAudio = deviceId;
            setSinkId(deviceId);
        }
    };

    openSettingsModal = () => {
        this.isSettingsModalShown = true;
    };

    closeSettingsModal = () => {
        this.isSettingsModalShown = false;
    };

    toggleScreenShareSound = () => {
        const screenShareTracks = this.screenShareStream?.getAudioTracks();
        this.isScreenShareMuted = !this.isScreenShareMuted;

        if (screenShareTracks && screenShareTracks.length) {
            screenShareTracks[0].enabled = !this.isScreenShareMuted;
        }
    };

    toggleMicros = (enabled: boolean) => {
        const cameraTracks = this.cameraStream?.getAudioTracks();
        const screenShareTracks = this.screenShareStream?.getAudioTracks();

        this.isMicroMuted = !enabled;
        this.isScreenShareMuted = this.isMicroMuted;

        if (cameraTracks && cameraTracks.length) {
            cameraTracks[0].enabled = enabled;
        }

        if (screenShareTracks && screenShareTracks.length) {
            screenShareTracks[0].enabled = enabled;
        }
    };

    toggleVideo = () => {
        const cameraTracks = this.cameraStream?.getVideoTracks();
        const screenShareTracks = this.screenShareStream?.getVideoTracks();

        if (cameraTracks && cameraTracks.length) {
            this.isVideoMuted = cameraTracks[0].enabled;
            cameraTracks[0].enabled = !(cameraTracks[0].enabled);
        }

        if (screenShareTracks && screenShareTracks.length) {
            this.isVideoMuted = screenShareTracks[0].enabled;
            screenShareTracks[0].enabled = !(screenShareTracks[0].enabled);
        }
    };

    toggleAudio = () => {
        this.isAudioMuted = !this.isAudioMuted;
    };

    selectPattern = (pattern: PatternsEnum, isInit: boolean = false) => {
        const prevPattern = this.currentPattern;
        this.currentPattern = pattern;
        this.rootStore.streamViewStore.changeSelectedPattern(pattern);
        this.applyPattern(this.currentPattern);

        if (this.selectedProject) {
            this.selectedProject.selectedPattern = pattern;
        }

        if (!isInit && prevPattern !== this.currentPattern) {
            this.savePattern();
        }
    };

    savePattern = debounce(async () => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                if (this.selectedProject) {
                    await axiosInstance.put(`${ApiRoutes.Projects}/${this.selectedProject.id}`, {
                        selectedPattern: this.currentPattern,
                    }, getAxiosHeaders());
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, 500);

    addUser = (id: string) => {
        if (this.usersOrder.every((order) => order.id !== id)) {
            this.usersOrder.push({ id, isEnabled: false });
        }

        this.applyPattern(this.currentPattern);
    };

    removeUser = (id: string) => {
        this.usersOrder = this.usersOrder.filter((user) => {
            return user.id !== id;
        });

        this.applyPattern(this.currentPattern);

        if (this.audioContext && this.mediaStreamAudioDestination) {
            const streamObj = this.rootStore.usersConferenceStore.streams[id];

            if (streamObj && streamObj.mediaStreamAudioSourceNode) {
                streamObj.mediaStreamAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
            }
        }
    };

    toggleUser = (id: string) => {
        const user = this.usersOrder.find((user) => {
            return user.id === id;
        });

        if (user) {
            user.isEnabled = !user.isEnabled;

            if (user.isEnabled) {
                this.rootStore.streamViewStore.enableUser(id);
            } else {
                this.rootStore.streamViewStore.disableUser(id);
            }

            this.applyPattern(this.currentPattern);

            if (this.audioContext && this.mediaStreamAudioDestination) {
                const streamObj = this.rootStore.usersConferenceStore.streams[id];

                if (streamObj && streamObj.mediaStreamAudioSourceNode) {
                    if (user.isEnabled) {
                        streamObj.mediaStreamAudioSourceNode.connect(this.mediaStreamAudioDestination);
                    } else {
                        streamObj.mediaStreamAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
                    }
                }
            }
        }
    };

    setLogoPosition = (position: LogoPositionsEnum, isInit: boolean = false) => {
        const prevLogoPosition = this.logoPosition;
        this.logoPosition = position;
        this.rootStore.streamViewStore.changeLogoPosition(position);

        if (this.selectedProject) {
            this.selectedProject.logoPosition = position;
        }


        if (this.logoNode) {
            const canvasWidth = this.canvas.getWidth();
            const canvasHeight = this.canvas.getHeight();
            const padding = 20;

            switch (this.logoPosition) {
                case LogoPositionsEnum.TopRight: {
                    this.logoNode.left = canvasWidth - (this.logoNode.getScaledWidth() + padding);
                    this.logoNode.top = padding;
                    break;
                }
                case LogoPositionsEnum.TopLeft: {
                    this.logoNode.left = padding;
                    this.logoNode.top = padding;
                    break;
                }
                case LogoPositionsEnum.BottomRight: {
                    this.logoNode.left = canvasWidth - (this.logoNode.getScaledWidth() + padding);
                    this.logoNode.top = canvasHeight - (this.logoNode.getScaledHeight() + padding);
                    break;
                }
                case LogoPositionsEnum.BottomLeft: {
                    this.logoNode.left = padding;
                    this.logoNode.top = canvasHeight - (this.logoNode.getScaledHeight() + padding);
                    break;
                }
            }

            this.logoNode.setCoords();
        }

        if (!isInit && prevLogoPosition !== this.logoPosition) {
            this.saveLogoPosition();
        }
    };

    saveLogoPosition = debounce(async () => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                if (this.selectedProject) {
                    await axiosInstance.put(`${ApiRoutes.Projects}/${this.selectedProject.id}`, {
                        logoPosition: this.logoPosition,
                    }, getAxiosHeaders());
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, 500);

    getEnabledUsers = (): { id: string; isEnabled: boolean; }[] => {
        return this.usersOrder.filter(({ isEnabled }) => isEnabled);
    };

    toggleParticipantNames = () => {
        this.showParticipantNames = !this.showParticipantNames;
        this.rootStore.streamViewStore.changeShowNames(this.showParticipantNames);

        if (this.selectedProject) {
            this.selectedProject.showNames = this.showParticipantNames;
        }

        this.namesNodes.forEach(({ groupNode }) => {
            groupNode.visible = this.showParticipantNames;
        });

        this.saveShowParticipantNames();
    };

    saveShowParticipantNames = debounce(async () => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                if (this.selectedProject) {
                    await axiosInstance.put(`${ApiRoutes.Projects}/${this.selectedProject.id}`, {
                        showNames: this.showParticipantNames,
                    }, getAxiosHeaders());
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, 500);

    setPrimaryColor = (color: string, isInit: boolean = false) => {
        this.primaryColor = color;
        this.rootStore.streamViewStore.changePrimaryColor(color);

        if (this.selectedProject) {
            this.selectedProject.color = color;
        }


        this.namesNodes.forEach(({ colorNode, groupNode }) => {
            colorNode.fill = this.primaryColor;
        });

        if (!isInit) {
            this.saveColor();
        }

    };

    saveColor = debounce(async () => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                if (this.selectedProject) {
                    await axiosInstance.put(`${ApiRoutes.Projects}/${this.selectedProject.id}`, {
                        color: this.primaryColor,
                    }, getAxiosHeaders());
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, 500);

    setCanvasSize = () => {
        const lowerCanvas = document.querySelector('.simple-main-section__canvas.lower-canvas');
        const upperCanvas = document.querySelector('.simple-main-section__canvas.upper-canvas');

        if (lowerCanvas) {
            lowerCanvas.setAttribute('width', '1280');
            lowerCanvas.setAttribute('height', '720');
        }

        if (upperCanvas) {
            upperCanvas.setAttribute('width', '1280');
            upperCanvas.setAttribute('height', '720');
        }
    };

    init = async (canvasInstance: HTMLCanvasElement, video: HTMLVideoElement) => {
        if (!this.user) {
            this.user = {
                name: '',
                destinations: [],
                projects: [],
                images: [],
                lastSelectedProject: 1,
                id: null,
                locale: Locales.English,
                role: 'User',
                email: '',
            };
        }

        const { lastSelectedProject, projects, images, destinations, name } = this.user;
        const project = projects.find(({ id }) => id === lastSelectedProject);
        this.canvas = new fabric.StaticCanvas(canvasInstance, {
            width: 1280,
            height: 720,
            backgroundColor: DEFAULT_CANVAS_BG_COLOR,
            selection: false,
            interactive: false,
            controlsAboveOverlay: false
        });
        this.video = video;

        this.setCanvasSize();

        if (project) {
            const logos = images.filter(({ type }) => {
                return type === ImageTypesEnum.Logo;
            });
            const bgs = images.filter(({ type }) => {
                return type === ImageTypesEnum.Background;
            });
            this.selectedProject = project;
            this.changeStreamName(project.name, true);
            this.showParticipantNames = project.showNames;
            this.setLogoPosition(project.logoPosition as LogoPositionsEnum, true);
            this.setPrimaryColor(project.color, true);
            this.currentPattern = project.selectedPattern as PatternsEnum;
            this.logoImagesList = [
                ...this.logoImagesList,
                ...logos.map((img) => {
                    return {
                        ...img,
                        src: `/images/${img.src}`
                    }
                }),
            ];
            this.backgroundImagesList = [
                ...this.backgroundImagesList,
                ...bgs.map((img) => {
                    return {
                        ...img,
                        src: `/images/${img.src}`
                    }
                }),
            ];
            this.setInitialDestinations(destinations.map((dest: IUserDestination): IStreamDestination => {
                const res: IStreamDestination = ({ ...dest } as IStreamDestination);

                return res;
            }));
        }

        if (this.rootStore.userMediaStore.selectedMicro && this.rootStore.userMediaStore.selectedCamera) {
            await this.addCamera(true);

            if (this.cameraStream && this.cameraStream.getVideoTracks() && this.cameraStream.getVideoTracks().length) {
                this.cameraStream.getVideoTracks()[0].enabled = this.rootStore.userMediaStore.isCameraEnabled;
                this.isVideoMuted = !this.rootStore.userMediaStore.isCameraEnabled;
            }

            if (this.cameraStream && this.cameraStream.getAudioTracks() && this.cameraStream.getAudioTracks().length) {
                this.cameraStream.getAudioTracks()[0].enabled = this.rootStore.userMediaStore.isMicroEnabled;
                this.isMicroMuted = !this.rootStore.userMediaStore.isMicroEnabled;
            }
        }

        if (project) {
            if (project.selectedLogo) {
                this.addLogo({
                    id: v4(),
                    type: ImageTypesEnum.Logo,
                    src: project.selectedLogo,
                }, true);
            }

            if (project.selectedBg) {
                this.addBg({
                    id: v4(),
                    type: ImageTypesEnum.Background,
                    src: project.selectedBg,
                }, true);
            }

            this.rootStore.streamViewStore.addUser({
                id: this.rootStore.usersConferenceStore.currentUserId,
                name: name,
                streamType: StreamTypeEnum.Camera,
                position: 0,
                isEnabled: true,
            });
            this.rootStore.streamViewStore.changePrimaryColor(project.color);
            this.rootStore.streamViewStore.changeShowNames(this.showParticipantNames);
            this.selectPattern(project.selectedPattern as PatternsEnum, true);
        } else {
            this.addLogo({
                id: v4(),
                type: ImageTypesEnum.Logo,
                src: '/default/logo.svg',
                isInitial: true,
            }, true);
            this.rootStore.streamViewStore.addUser({
                id: this.rootStore.usersConferenceStore.currentUserId,
                name: this.rootStore.userMediaStore.name,
                streamType: StreamTypeEnum.Camera,
                position: 0,
                isEnabled: true,
            });
            this.rootStore.streamViewStore.changePrimaryColor(this.primaryColor);
            this.rootStore.streamViewStore.changeShowNames(this.showParticipantNames);
            this.applyPattern(this.currentPattern);
        }
    };

    stopScreenShare = () => {
        this.isScreenShare = false;
        this.isScreenShareEnabled = false;
        this.isScreenShareMuted = false;

        if (this.screenShareNode) {
            this.canvas.remove(this.screenShareNode);
            this.screenShareNode = undefined;
        }

        if (this.screenShareStream) {
            this.screenShareStream.getTracks().map((track) => {
                track.stop();
            });
            this.screenShareStream = null;
        }

        if (this.mediaStreamScreenShareAudioSourceNode && this.mediaStreamAudioDestination) {
            this.mediaStreamScreenShareAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
            this.mediaStreamScreenShareAudioSourceNode = undefined;
        }

        this.rootStore.usersConferenceStore.removeScreenShare();
        this.rootStore.streamViewStore.removerUser(ADMIN_SS_ID);

        this.applyPattern(this.currentPattern);
        this.currentSSVideoMediaTrackConstraints = { ...DEFAULT_CONSTRAINTS };
    };

    addCamera = async (isInit: boolean = false) => {
        const constraint: MediaStreamConstraints = {
            audio: {
                deviceId: this.currentMicro || this.rootStore.userMediaStore.selectedMicro.deviceId || 'default',
            },
            video: {
                ...getCameraConstraint(),
                deviceId: this.currentCamera || this.rootStore.userMediaStore.selectedCamera.deviceId || 'default',
            }
        };

        if (!isFirefox()) {
            (constraint.video as MediaTrackConstraints).frameRate = {
                max: 25,
            };
        }

        if (this.cameraStream) {
            this.cameraStream.getTracks().forEach((track) => {
                track.stop();
            });
        }

        const stream = await navigator.mediaDevices.getUserMedia(constraint);

        if (this.cameraNode) {
            this.canvas.remove(this.cameraNode);
        }

        const video = document.createElement('video');
        video.srcObject = stream;
        video.muted = true;
        video.width = 1280;
        video.height = 720;

        const node = new fabric.Image(video, {
            left: 0,
            top: 0,
            objectCaching: false,
            selectable: false,
            evented: false,
        });
        node.scaleToWidth(this.canvas.getWidth());

        this.canvas.add(node);
        this.cameraNode = node;
        this.cameraStream = stream;

        if (
          this.rootStore.userMediaStore.selectedCamera &&
          this.rootStore.userMediaStore.selectedCamera.label?.toLowerCase().includes('splitcam')
        ) {
            this.updateLocalStreamForConference(false);
        } else {
            this.updateLocalStreamForConference(isInit);
        }

        this.updateStreamUserAudio();

        this.applyEnabled();
        this.rootStore.usersConferenceStore.changeTrack(stream.getVideoTracks()[0]);
        this.rootStore.usersConferenceStore.changeTrack(stream.getAudioTracks()[0]);
        (node.getElement() as HTMLVideoElement).play();

        this.video.srcObject = stream;
        this.video.play();

        this.selectPattern(this.currentPattern);

        if (this.selectedLogo) {
            this.removeLogo();
            this.addLogo({
                id: v4(),
                type: ImageTypesEnum.Logo,
                src: '/default/logo.svg',
                isInitial: true,
            }, true);
        }
    };

    updateLocalStreamForConference = (withApplyConstraints: boolean = true) => {
        if (
            this.cameraStream &&
            this.cameraStream.getTracks() &&
            this.cameraStream.getTracks().length
        ) {
            this.rootStore.usersConferenceStore.localStream = new MediaStream([
                ...this.cameraStream.getTracks(),
            ]);

            if (withApplyConstraints) {
                this.rootStore.usersConferenceStore.localStream.getVideoTracks().forEach((track) => {
                    track.applyConstraints(this.currentVideoMediaTrackConstraints);
                });
            }
        }
    };

    updateCamera = async (camera: string) => {
        try {
            if (camera !== this.currentCamera) {
                this.currentCamera = camera;
                await this.addCamera();
            }
        } catch (error) {
            console.error(error);
        }
    };

    updateMicro = async (micro: string) => {
        try {
            if (micro !== this.currentMicro) {
                this.currentMicro = micro;

                const constraint: MediaStreamConstraints = {
                    audio: {
                        deviceId: this.currentMicro || 'default',
                    },
                    video: {
                        ...getCameraConstraint(),
                        deviceId: this.currentCamera || this.rootStore.userMediaStore.selectedCamera.deviceId || 'default',
                    }
                };

                if (!isFirefox()) {
                    (constraint.video as MediaTrackConstraints).frameRate = {
                        max: 25,
                    };
                }

                const stream = await navigator.mediaDevices.getUserMedia(constraint);

                this.applyEnabled();
                this.rootStore.usersConferenceStore.changeTrack(stream.getVideoTracks()[0]);
                this.rootStore.usersConferenceStore.changeTrack(stream.getAudioTracks()[0]);
                this.cameraStream = stream;

                this.updateLocalStreamForConference();
                this.updateStreamUserAudio();
            }
        } catch (error) {
            console.error(error)
        }
    };

    removeLogo = () => {
        if (this.prevLogo) {
            this.canvas.remove(this.prevLogo);
            this.selectedLogo = '';
            this.prevLogo = undefined;
            this.rootStore.streamViewStore.changeLogoImage('');
            this.saveLogo();
        }
    };

    addLogo = (img: IUserImage, isInit: boolean = false) => {
        if (this.prevLogo) {
            this.canvas.remove(this.prevLogo);

            if (this.selectedLogo === img.src) {
                this.rootStore.streamViewStore.changeLogoImage('');
                this.selectedLogo = '';
                this.prevLogo = undefined;

                if (!isInit) {
                    this.saveLogo();
                }

                return;
            }
        }

        this.selectedLogo = img.src;

        if (!isInit) {
            this.saveLogo();
        }

        this.rootStore.streamViewStore.changeLogoImage(this.selectedLogo);
        fabric.Image.fromURL(img.src, (node) => {
            const canvasWidth = this.canvas.getWidth();
            const padding = 20;
            const maxImageSize = 140;

            node.scaleToWidth(maxImageSize);

            if (node.getScaledHeight() > maxImageSize) {
                node.scaleToHeight(maxImageSize);
            }

            node.left = canvasWidth - (node.getScaledWidth() + padding);
            node.top = padding;

            node.selectable = false;
            node.setCoords();
            node.evented = false;

            this.canvas.add(node);
            this.canvas.moveTo(node, 10);
            this.prevLogo = node;
            this.logoNode = node;
            this.setLogoPosition(this.logoPosition);
        });
    };

    saveLogo = debounce(async () => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                if (this.selectedProject) {
                    await axiosInstance.put(`${ApiRoutes.Projects}/${this.selectedProject.id}`, {
                        selectedLogo: this.selectedLogo,
                    }, getAxiosHeaders());
                    this.selectedProject.selectedLogo = this.selectedLogo;
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, 500);

    removeBg = () => {
        if (this.prevBg) {
            this.canvas.remove(this.prevBg);
            this.selectedBg = '';
            this.prevBg = undefined;
            this.rootStore.streamViewStore.changeBackgroundImage('');
            this.saveBackground();
        }
    };

    addBg = (img: IUserImage, isInit: boolean = false) => {
        if (this.prevBg) {
            this.canvas.remove(this.prevBg);

            if (this.selectedBg === img.src) {
                this.rootStore.streamViewStore.changeBackgroundImage('');
                this.selectedBg = '';
                this.prevBg = undefined;

                if (!isInit) {
                    this.saveBackground();
                }

                return;
            }
        }

        this.selectedBg = img.src;

        if (!isInit) {
            this.saveBackground();
        }

        this.rootStore.streamViewStore.changeBackgroundImage(this.selectedBg);
        fabric.Image.fromURL(img.src, (node) => {
            node.scaleToWidth(this.canvas.getWidth());

            if (node.getScaledHeight() < this.canvas.getHeight()) {
                node.scaleToHeight(this.canvas.getHeight());
            }

            node.left = 0;
            node.top = 0;
            node.setCoords();
            node.selectable = false;
            node.evented = false;

            this.canvas.add(node);
            this.canvas.moveTo(node, 0);
            this.prevBg = node;
        });
    };

    saveBackground = debounce(async () => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                if (this.selectedProject) {
                    await axiosInstance.put(`${ApiRoutes.Projects}/${this.selectedProject.id}`, {
                        selectedBg: this.selectedBg,
                    }, getAxiosHeaders());
                    this.selectedProject.selectedBg = this.selectedBg;
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, 500);

    addScreenShare = async () => {
        try {
            this.stopScreenShare();

            const constraint: MediaStreamConstraints = {
                video: {
                    // @ts-ignore
                    cursor: 'always',
                },
                audio: true,
            };

            if (!isFirefox()) {
                (constraint.video as MediaTrackConstraints).frameRate = {
                    max: 25,
                };
            }

            // @ts-ignore
            const stream: MediaStream = await navigator.mediaDevices.getDisplayMedia(constraint);

            this.screenShareStream = stream;

            if (stream.getVideoTracks() && stream.getVideoTracks().length) {
                const track = stream.getVideoTracks()[0];
                let w = 1280;
                let h = 720;

                try {
                    const { width, height } = track.getCapabilities();
                    w = width.max;
                    h = height.max;
                } catch (e) {
                    console.error(e);
                }


                this.defaultSSVideoMediaTrackConstraints = {
                    width: w,
                    height: h,
                };

                track.onended = () => {
                    this.stopScreenShare();
                };
            }

            this.rootStore.usersConferenceStore.addAdminScreenShare(this.screenShareStream);

            this.rootStore.streamViewStore.insertUser({
                id: ADMIN_SS_ID,
                name: this.rootStore.userMediaStore.name,
                streamType: StreamTypeEnum.ScreenShare,
                position: 1,
                isEnabled: false,
            }, 1);
        } catch (e) {
            console.error(e);
            showScreenShareErrorMessage(this.dependencies.intl);
        }
    };

    updateStreamUserAudio = () => {
        if (this.audioContext && this.mediaStreamAudioDestination && this.mediaStreamAudioSourceNode) {
            this.mediaStreamAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
            this.mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(this.cameraStream);
            this.mediaStreamAudioSourceNode.connect(this.mediaStreamAudioDestination);
        }
    };

    changeTab = (tab: string) => {
        this.tab = tab as NavBarTabs;
    };

    getNameNodeGroup = (name: string, color: string, top: number, left: number) => {
        const brandingWidth = 6;
        const padding = 8;
        const doublePadding = padding * 2;
        const text = new fabric.Text(name, {
            fill: '#ffffff',
            fontSize: 16,
            top: padding,
            left: padding + brandingWidth,
            fontFamily: 'Inter',
        });
        const bg = new fabric.Rect({
            fill: 'rgba(22, 28, 33, 0.9)',
            width: text.getScaledWidth() + doublePadding,
            height: text.getScaledHeight() + doublePadding,
            top: 0,
            left: brandingWidth,
        });
        const colorNode = new fabric.Rect({
            fill: color,
            height: text.getScaledHeight() + doublePadding,
            width: brandingWidth,
            left: 0,
            top: 0,
            objectCaching: false,
        });
        const groupNode = new fabric.Group([ colorNode, bg, text ], {
            left,
            top,
            objectCaching: false,
            selectable: false,
            evented: false,
        });


        return { colorNode, groupNode };
    };

    addNameNode = (name: string, top: number, left: number) => {
        const nameObj = this.getNameNodeGroup(
            name,
            this.primaryColor,
            top,
            left,
        );

        nameObj.groupNode.top = top - Math.floor(nameObj.groupNode.getScaledHeight());
        nameObj.groupNode.setCoords();
        this.canvas.add(nameObj.groupNode);
        this.namesNodes.push(nameObj);

        return nameObj;
    };

    getVideoElement = ( // todo сделать размеры видео чтобы подстраивались под паттерн а не были по 1280
        pattern: PatternsEnum,
        stream: MediaStream | undefined,
        createNode: (video: HTMLVideoElement) => void,
    ) => {
        const video = document.createElement('video');

        video.muted = true;
        video.width = 1280;
        video.height = 720;
        video.style.backgroundColor = '#000';

        if (stream) {
            video.srcObject = stream;
        }

        createNode(video);

        const listener = () => {
            if (this.currentPattern === pattern) {
                video.width = video.videoWidth;
                video.height = video.videoHeight;

                createNode(video);
            }
        };

        this.streamNodesHandlers.push({
            element: video,
            handler: listener,
        });

        video.addEventListener('resize', listener);
    };

    // todo подумать про логику скейлов растягивать их на мксимум
    resizeNodeHelper = (node: fabric.Image) => {
        try {
            const { videoHeight, videoWidth } = node.getElement() as HTMLVideoElement;

            if (videoWidth && videoHeight) {
                if (videoWidth > videoHeight) {
                    node.height = videoHeight;
                    node.scaleToWidth(1280);
                } else {
                    node.width = videoWidth;
                    node.scaleToHeight(720);
                }

                node.center();
                node.setCoords();
            }
        } catch (e) {
            console.error(e);
        }
    };

    resizeNodeRegardingVideo = (node: fabric.Image) => {
        this.resizeNodeHelper(node);

        node.getElement().addEventListener('resize', () => {
            this.resizeNodeHelper(node);
        });
    };

    applyPattern = (pattern: PatternsEnum) => {
        const { state } = this.rootStore.streamViewStore;
        const { users } = state;
        let enabledUsers = users.filter(({ isEnabled }) => isEnabled);
        const streams = {
            ...this.rootStore.usersConferenceStore.streams,
            [this.rootStore.usersConferenceStore.currentUserId]: {
                id: this.rootStore.usersConferenceStore.currentUserId,
                stream: this.cameraStream,
                name: this.rootStore.userMediaStore.name,
                userRole: ConferenceRolesEnum.Admin,
                isMuted: false,
                mediaStreamAudioSourceNode: null,
            },
            [ADMIN_SS_ID]: {
                id: ADMIN_SS_ID,
                stream: this.screenShareStream,
                name: this.rootStore.userMediaStore.name,
                userRole: ConferenceRolesEnum.User,
                isMuted: false,
                mediaStreamAudioSourceNode: null,
            }
        };

        this.resultSteamNodes.forEach((node: fabric.Image) => {
            this.canvas.remove(node);
        });
        this.resultSteamNodes = [];
        this.streamNodesHandlers.forEach(({ element, handler }) => {
            if (element) {
                element.removeEventListener('resize', handler);
            }
        });
        this.streamNodesHandlers = [];
        this.namesNodes.forEach(({ groupNode }) => {
            this.canvas.remove(groupNode);
        });
        this.namesNodes = [];

        const canvasWidth = this.canvas.getWidth();
        const canvasHeight = this.canvas.getHeight();
        const canvasHalfWidth = canvasWidth / 2;
        const canvasHalfHeight = canvasHeight / 2;
        const mainNode = this.screenShareNode ? this.screenShareNode : this.cameraNode;

        if (!this.isAdminEnabled && enabledUsers.length === 0) {
            return;
        }

        if (pattern === PatternsEnum.OneUser) {
            mainNode.visible = false;
            const isStreamExist = enabledUsers[0] && streams[enabledUsers[0].id];
            const stream = isStreamExist ? streams[enabledUsers[0].id].stream : undefined;
            let prevNode = null;

            this.getVideoElement(PatternsEnum.OneUser, stream, (video: HTMLVideoElement) => {
                    if (!enabledUsers[0] || !enabledUsers[0].isEnabled) {
                        return;
                    }

                    if (prevNode) {
                        this.canvas.remove(prevNode);
                    }

                    const node = new fabric.Image(video, {
                        left: 0,
                        top: 0,
                        objectCaching: false,
                        selectable: false,
                        evented: false,
                    });
                    node.scaleToWidth(canvasWidth);

                    node.left = 0;
                    node.top = 0;
                    node.setCoords();
                    this.canvas.add(node);
                    (node.getElement() as HTMLVideoElement).play();
                    this.resultSteamNodes.push(node);
                    prevNode = node;
                    node.moveTo(1);

                    this.resizeNodeRegardingVideo(node);
                });
        } else if (pattern === PatternsEnum.TwoUsers) {
            let streamsCounter = 0;
            const clip1 = new fabric.Rect({
                width: canvasHalfWidth - (doublePadding - halfPadding),
                height: canvasHeight - padding * 2,
                top: padding,
                left: padding,
                absolutePositioned: true
            });
            const clip2 = new fabric.Rect({
                width: canvasHalfWidth - (doublePadding - halfPadding),
                height: canvasHeight - padding * 2,
                top: padding,
                left: canvasHalfWidth + halfPadding,
                absolutePositioned: true
            });

            mainNode.visible = false;
            const isStreamExist = enabledUsers[0] && streams[enabledUsers[0].id];
            const stream = isStreamExist ? streams[enabledUsers[0].id].stream : undefined;
            let prevNode = null;

            this.getVideoElement(PatternsEnum.TwoUsers, stream, (video: HTMLVideoElement) => {
                if (!enabledUsers[0] || !enabledUsers[0].isEnabled) {
                    return;
                }

                if (prevNode) {
                    this.canvas.remove(prevNode);
                }

                const node = new fabric.Image(video, {
                    left: 0,
                    top: 0,
                    objectCaching: false,
                    selectable: false,
                    evented: false,
                });
                node.scaleToHeight(canvasHeight - doublePadding);
                node.clipPath = clip1;
                const nodeWidth1 = node.getScaledWidth();
                node.left = -((nodeWidth1 - canvasHalfWidth - (doublePadding - halfPadding)) / 2);
                node.top = padding;
                node.setCoords();
                this.canvas.add(node);
                (node.getElement() as HTMLVideoElement).play();
                this.resultSteamNodes.push(node);
                prevNode = node;
                node.moveTo(1);
            });

            if (isStreamExist) {
                this.addNameNode(streams[enabledUsers[0].id].name, canvasHeight - padding, padding);
            }
            streamsCounter++;

            const isStreamExist1 = enabledUsers[streamsCounter] && streams[enabledUsers[streamsCounter].id];
            const stream1 = isStreamExist1 ? streams[enabledUsers[streamsCounter].id].stream : undefined;
            let prevNode1 = null;

            this.getVideoElement(PatternsEnum.TwoUsers, stream1, (video: HTMLVideoElement) => {
                if (!enabledUsers[streamsCounter] || !enabledUsers[streamsCounter].isEnabled) {
                    return;
                }

                if (prevNode1) {
                    this.canvas.remove(prevNode1);
                }

                const node = new fabric.Image(video, {
                    left: 0,
                    top: 0,
                    objectCaching: false,
                    selectable: false,
                    evented: false,
                });
                node.scaleToHeight(canvasHeight - doublePadding);
                node.clipPath = clip2;
                const nodeWidth1 = node.getScaledWidth();
                node.top = padding;
                node.left = canvasHalfWidth - ((nodeWidth1 - canvasHalfWidth - (doublePadding - halfPadding)) / 2);
                node.setCoords();
                this.canvas.add(node);
                (node.getElement() as HTMLVideoElement).play();
                this.resultSteamNodes.push(node);
                prevNode1 = node;
                node.moveTo(1);
            });

            if (isStreamExist1) {
                this.addNameNode(streams[enabledUsers[streamsCounter].id].name, canvasHeight - padding, canvasHalfWidth + halfPadding);
            }

        } else if (pattern === PatternsEnum.ThreeUsers) {
            let streamsCounter = 0;
            const clip1 = new fabric.Rect({
                width: canvasHalfWidth - (doublePadding - halfPadding),
                height: canvasHeight - padding * 2,
                top: padding,
                left: padding,
                absolutePositioned: true
            });
            const clip2 = new fabric.Rect({
                width: canvasHalfWidth - (doublePadding - halfPadding),
                height: canvasHalfHeight - (doublePadding - halfPadding),
                top: padding,
                left: canvasHalfWidth + halfPadding,
                absolutePositioned: true
            });
            const clip3 = new fabric.Rect({
                width: canvasHalfWidth - (doublePadding - halfPadding),
                height: canvasHalfHeight - (doublePadding - halfPadding),
                top: canvasHalfHeight + halfPadding,
                left: canvasHalfWidth + halfPadding,
                absolutePositioned: true
            });

            mainNode.visible = false;
            const isStreamExistA = enabledUsers[0] && streams[enabledUsers[0].id];
            const streamA = isStreamExistA ? streams[enabledUsers[0].id].stream : undefined;
            let prevNodeA = null;

            this.getVideoElement(PatternsEnum.ThreeUsers, streamA, (video: HTMLVideoElement) => {
                if (!enabledUsers[0] || !enabledUsers[0].isEnabled) {
                    return;
                }

                if (prevNodeA) {
                    this.canvas.remove(prevNodeA);
                }

                const node = new fabric.Image(video, {
                    left: 0,
                    top: 0,
                    objectCaching: false,
                    selectable: false,
                    evented: false,
                });

                node.scaleToHeight(canvasHeight - doublePadding);
                node.clipPath = clip1;
                const nodeWidth = node.getScaledWidth();
                node.left = -((nodeWidth - canvasHalfWidth - (doublePadding - halfPadding)) / 2);
                node.top = padding;
                node.setCoords();

                this.canvas.add(node);
                (node.getElement() as HTMLVideoElement).play();
                this.resultSteamNodes.push(node);
                prevNodeA = node;
                node.moveTo(1);

                if (isStreamExistA) {
                    this.addNameNode(
                        streams[enabledUsers[0].id].name,
                        canvasHeight - padding,
                        padding
                    );
                }
            });

            streamsCounter++;

            const isStreamExist = enabledUsers[streamsCounter] && streams[enabledUsers[streamsCounter].id];
            const stream = isStreamExist ? streams[enabledUsers[streamsCounter].id].stream : undefined;
            let prevNode = null;

            this.getVideoElement(PatternsEnum.ThreeUsers, stream, (video: HTMLVideoElement) => {
                if (!enabledUsers[streamsCounter] || !enabledUsers[streamsCounter].isEnabled) {
                    return;
                }

                if (prevNode) {
                    this.canvas.remove(prevNode);
                }

                const node = new fabric.Image(video, {
                    left: 0,
                    top: 0,
                    objectCaching: false,
                    selectable: false,
                    evented: false,
                });
                node.scaleToHeight(canvasHalfHeight - (doublePadding - halfPadding));

                if (node.getScaledWidth() < canvasHalfWidth - (doublePadding - halfPadding)) {
                    node.scaleToWidth(canvasHalfWidth);
                }

                node.clipPath = clip2;

                const nodeWidth1 = node.getScaledWidth();
                node.top = padding;
                node.left = canvasHalfWidth - ((nodeWidth1 - canvasHalfWidth - (doublePadding - halfPadding)) / 2);
                node.setCoords();
                this.canvas.add(node);
                (node.getElement() as HTMLVideoElement).play();
                this.resultSteamNodes.push(node);
                prevNode = node;
                node.moveTo(1);

                if (isStreamExist) {
                    this.addNameNode(
                        streams[enabledUsers[streamsCounter].id].name,
                        clip2.top + clip2.getScaledHeight(),
                        clip2.left
                    );
                }
            });



            const isStreamExist1 = enabledUsers[streamsCounter + 1] && streams[enabledUsers[streamsCounter + 1].id];
            const stream1 = isStreamExist1 ? streams[enabledUsers[streamsCounter + 1].id].stream : undefined;
            let prevNode1 = null;

            this.getVideoElement(PatternsEnum.ThreeUsers, stream1, (video1: HTMLVideoElement) => {
                if (!enabledUsers[streamsCounter + 1] || !enabledUsers[streamsCounter + 1].isEnabled) {
                    return;
                }

                if (prevNode1) {
                    this.canvas.remove(prevNode1);
                }

                const node1 = new fabric.Image(video1, {
                    left: 0,
                    top: 0,
                    objectCaching: false,
                    selectable: false,
                    evented: false,
                });
                node1.scaleToHeight(canvasHalfHeight - (doublePadding - halfPadding));

                if (node1.getScaledWidth() < canvasHalfWidth - (doublePadding - halfPadding)) {
                    node1.scaleToWidth(canvasHalfWidth);
                }

                node1.clipPath = clip3;

                const nodeWidth2 = node1.getScaledWidth();
                node1.top = canvasHalfHeight + halfPadding;
                node1.left = canvasHalfWidth - ((nodeWidth2 - canvasHalfWidth - (doublePadding - halfPadding)) / 2);
                node1.setCoords();
                this.canvas.add(node1);

                (node1.getElement() as HTMLVideoElement).play();
                this.resultSteamNodes.push(node1);
                prevNode1 = node1;
                node1.moveTo(1);

                if (isStreamExist1) {
                    this.addNameNode(
                        streams[enabledUsers[streamsCounter + 1].id].name,
                        clip3.top + clip3.getScaledHeight(),
                        clip3.left
                    );
                }
            });

        } else if (pattern === PatternsEnum.TwoUsersOneSmall) {
            let streamsCounter = 0;
            const clip1 = new fabric.Rect({
                width: canvasWidth - padding * 2,
                height: canvasHeight - padding * 2,
                top: padding,
                left: padding,
                absolutePositioned: true
            });


            mainNode.visible = false;
            const isStreamExist = enabledUsers[0] && streams[enabledUsers[0].id];
            const stream = isStreamExist ? streams[enabledUsers[0].id].stream : undefined;
            let prevNode = null;
            let prevName = null;

            this.getVideoElement(
                PatternsEnum.TwoUsersOneSmall,
                stream,
                (video: HTMLVideoElement) => {
                    if (!enabledUsers[0] || !enabledUsers[0].isEnabled) {
                        return;
                    }

                    if (prevNode) {
                        this.canvas.remove(prevNode);
                    }

                    if (prevName) {
                        this.canvas.remove(prevName);
                    }

                    const node = new fabric.Image(video, {
                        left: 0,
                        top: 0,
                        objectCaching: false,
                        selectable: false,
                        evented: false,
                    });

                    node.scaleToWidth(canvasWidth - padding * 2);
                    node.clipPath = clip1;
                    node.center();
                    node.setCoords();

                    this.canvas.add(node);
                    (node.getElement() as HTMLVideoElement).play();
                    this.resultSteamNodes.push(node);
                    prevNode = node;

                    if (isStreamExist) {
                        const { groupNode } = this.addNameNode(streams[enabledUsers[0].id].name, canvasHeight - padding, padding);
                        prevName = groupNode;
                    }
                }
            );
            streamsCounter++;

            const isStreamExist1 = enabledUsers[streamsCounter] && streams[enabledUsers[streamsCounter].id];
            const stream1 = isStreamExist1 ? streams[enabledUsers[streamsCounter].id].stream : undefined;
            let prevNode1 = null;
            let prevName1 = null;

            this.getVideoElement(
                PatternsEnum.TwoUsersOneSmall,
                stream1,
                (video: HTMLVideoElement) => {
                    if (!enabledUsers[streamsCounter] || !enabledUsers[streamsCounter].isEnabled) {
                        return;
                    }

                    if (prevNode1) {
                        this.canvas.remove(prevNode1);
                    }

                    if (prevName1) {
                        this.canvas.remove(prevName1);
                    }

                    const node = new fabric.Image(video, {
                        left: 0,
                        top: 0,
                        objectCaching: false,
                        selectable: false,
                        evented: false,
                    });

                    const width = canvasWidth / 4;
                    node.scaleToWidth(width);
                    const height = node.getScaledHeight();
                    node.top = canvasHeight - height - padding * 2;
                    node.left = canvasWidth - width - padding * 2;
                    node.setCoords();
                    this.canvas.add(node);
                    (node.getElement() as HTMLVideoElement).play();
                    this.resultSteamNodes.push(node);
                    prevNode1 = node;

                    if (isStreamExist1) {
                        const { groupNode } = this.addNameNode(streams[enabledUsers[streamsCounter].id].name, node.top + node.getScaledHeight(), node.left);
                        prevName1 = groupNode;
                    }
                }
            );
        } else if (pattern === PatternsEnum.FiveUsers) {
            let streamsCounter = 0;
            const mainUserWidth = canvasWidth * 0.74;
            const secondaryUserWidth = (canvasWidth - mainUserWidth) - (padding * 3);
            const secondaryUserHeight = (canvasHeight - padding * 5) / 4;
            const secondaryUserLeft = mainUserWidth + doublePadding;
            const clip1 = new fabric.Rect({
                width: mainUserWidth,
                height: canvasHeight - doublePadding,
                top: padding,
                left: padding,
                absolutePositioned: true
            });
            const clip2 = new fabric.Rect({
                width: secondaryUserWidth,
                height: secondaryUserHeight,
                top: padding,
                left: secondaryUserLeft,
                absolutePositioned: true
            });
            const clip3 = new fabric.Rect({
                width: secondaryUserWidth,
                height: secondaryUserHeight,
                top: secondaryUserHeight + doublePadding,
                left: secondaryUserLeft,
                absolutePositioned: true
            });
            const clip4 = new fabric.Rect({
                width: secondaryUserWidth,
                height: secondaryUserHeight,
                top: secondaryUserHeight * 2 + padding * 3,
                left: secondaryUserLeft,
                absolutePositioned: true
            });
            const clip5 = new fabric.Rect({
                width: secondaryUserWidth,
                height: secondaryUserHeight,
                top: secondaryUserHeight * 3 + padding * 4,
                left: secondaryUserLeft,
                absolutePositioned: true
            });


            mainNode.visible = false;
            const isStreamExist = enabledUsers[0] && streams[enabledUsers[0].id];
            const stream = isStreamExist ? streams[enabledUsers[0].id].stream : undefined;
            let prevNode = null;

            this.getVideoElement(PatternsEnum.FiveUsers, stream, (video: HTMLVideoElement) => {
                if (!enabledUsers[0] || !enabledUsers[0].isEnabled) {
                    return;
                }

                if (prevNode) {
                    this.canvas.remove(prevNode);
                }

                const node = new fabric.Image(video, {
                    left: 0,
                    top: 0,
                    objectCaching: false,
                    selectable: false,
                    evented: false,
                });
                node.scaleToHeight(canvasHeight - doublePadding);
                node.clipPath = clip1;
                const nodeWidth1 = node.getScaledWidth();
                node.left = -((nodeWidth1 - mainUserWidth + padding) / 2);
                node.top = padding;
                node.setCoords();
                this.canvas.add(node);
                (node.getElement() as HTMLVideoElement).play();
                this.resultSteamNodes.push(node);
                prevNode = node;
                node.moveTo(1);
            });

            if (isStreamExist) {
                this.addNameNode(streams[enabledUsers[0].id].name, canvasHeight - padding, padding);
            }
            streamsCounter++;

            const isStreamExist2 = enabledUsers[streamsCounter] && streams[enabledUsers[streamsCounter].id];
            const stream2 = isStreamExist2 ? streams[enabledUsers[streamsCounter].id].stream : undefined;
            let prevNode2 = null;

            this.getVideoElement(
                PatternsEnum.FiveUsers,
                stream2,
                (video: HTMLVideoElement) => {
                    if (!enabledUsers[streamsCounter] || !enabledUsers[streamsCounter].isEnabled) {
                        return;
                    }

                    if (prevNode2) {
                        this.canvas.remove(prevNode2);
                    }

                    const node = new fabric.Image(video, {
                        left: 0,
                        top: 0,
                        objectCaching: false,
                        selectable: false,
                        evented: false,
                    });

                    node.scaleToWidth(secondaryUserWidth);
                    node.clipPath = clip2;
                    node.top = clip2.top;
                    node.left = clip2.left;
                    node.setCoords();
                    this.canvas.add(node);
                    (node.getElement() as HTMLVideoElement).play();
                    this.resultSteamNodes.push(node);
                    prevNode2 = node;

                    if (isStreamExist2) {
                        this.addNameNode(streams[enabledUsers[streamsCounter].id].name, node.top + node.getScaledHeight(), node.left);
                    }
                }
            );


            const isStreamExist3 = enabledUsers[streamsCounter + 1] && streams[enabledUsers[streamsCounter + 1].id];
            const stream3 = isStreamExist3 ? streams[enabledUsers[streamsCounter + 1].id].stream : undefined;
            let prevNode3 = null;

            this.getVideoElement(
                PatternsEnum.FiveUsers,
                stream3,
                (video: HTMLVideoElement) => {
                    if (!enabledUsers[streamsCounter + 1] || !enabledUsers[streamsCounter + 1].isEnabled) {
                        return;
                    }

                    if (prevNode3) {
                        this.canvas.remove(prevNode3);
                    }

                    const node = new fabric.Image(video, {
                        left: 0,
                        top: 0,
                        objectCaching: false,
                        selectable: false,
                        evented: false,
                    });

                    node.scaleToWidth(secondaryUserWidth);
                    node.clipPath = clip3;
                    node.top = clip3.top;
                    node.left = clip3.left;
                    node.setCoords();
                    this.canvas.add(node);
                    (node.getElement() as HTMLVideoElement).play();
                    this.resultSteamNodes.push(node);
                    prevNode3 = node;

                    if (isStreamExist3) {
                        this.addNameNode(streams[enabledUsers[streamsCounter + 1].id].name, node.top + node.getScaledHeight(), node.left);
                    }
                }
            );


            const isStreamExist4 = enabledUsers[streamsCounter + 2] && streams[enabledUsers[streamsCounter + 2].id];
            const stream4 = isStreamExist4 ? streams[enabledUsers[streamsCounter + 2].id].stream : undefined;
            let prevNode4 = null;

            this.getVideoElement(
                PatternsEnum.FiveUsers,
                stream4,
                (video: HTMLVideoElement) => {
                    if (!enabledUsers[streamsCounter + 2] || !enabledUsers[streamsCounter + 2].isEnabled) {
                        return;
                    }

                    if (prevNode4) {
                        this.canvas.remove(prevNode4);
                    }

                    const node = new fabric.Image(video, {
                        left: 0,
                        top: 0,
                        objectCaching: false,
                        selectable: false,
                        evented: false,
                    });

                    node.scaleToWidth(secondaryUserWidth);
                    node.clipPath = clip4;
                    node.top = clip4.top;
                    node.left = clip4.left;
                    node.setCoords();
                    this.canvas.add(node);
                    (node.getElement() as HTMLVideoElement).play();
                    this.resultSteamNodes.push(node);
                    prevNode4 = node;

                    if (isStreamExist4) {
                        this.addNameNode(streams[enabledUsers[streamsCounter + 2].id].name, node.top + node.getScaledHeight(), node.left);
                    }
                }
            );


            const isStreamExist5 = enabledUsers[streamsCounter + 3] && streams[enabledUsers[streamsCounter + 3].id];
            const stream5 = isStreamExist5 ? streams[enabledUsers[streamsCounter + 3].id].stream : undefined;
            let prevNode5 = null;

            this.getVideoElement(
                PatternsEnum.FiveUsers,
                stream5,
                (video: HTMLVideoElement) => {
                    if (!enabledUsers[streamsCounter + 3] || !enabledUsers[streamsCounter + 3].isEnabled) {
                        return;
                    }

                    if (prevNode5) {
                        this.canvas.remove(prevNode5);
                    }

                    const node = new fabric.Image(video, {
                        left: 0,
                        top: 0,
                        objectCaching: false,
                        selectable: false,
                        evented: false,
                    });

                    node.scaleToWidth(secondaryUserWidth);
                    node.clipPath = clip5;
                    node.top = clip5.top;
                    node.left = clip5.left;
                    node.setCoords();
                    this.canvas.add(node);
                    (node.getElement() as HTMLVideoElement).play();
                    this.resultSteamNodes.push(node);
                    prevNode5 = node;

                    if (isStreamExist5) {
                        this.addNameNode(streams[enabledUsers[streamsCounter + 3].id].name, node.top + node.getScaledHeight(), node.left);
                    }
                }
            );


        } else if (pattern === PatternsEnum.SixUsers) {
            let streamsCounter = 0;
            const mainUserWidth = canvasWidth * 0.75;
            const secondaryUserHeight = canvasHeight * 0.18;
            const secondaryUserWidth = canvasWidth * 0.15;
            const secondaryUserLeft = (canvasWidth - (secondaryUserWidth * 5) - (padding * 4)) / 2;
            const mainUserHeight = canvasHeight - (padding * 3) - secondaryUserHeight;
            const secondaryUserTop = mainUserHeight + padding * 2;
            const clip1 = new fabric.Rect({
                width: mainUserWidth,
                height: mainUserHeight,
                top: padding,
                left: (canvasWidth - mainUserWidth) / 2,
                absolutePositioned: true
            });
            const clip2 = new fabric.Rect({
                width: secondaryUserWidth,
                height: secondaryUserHeight,
                top: secondaryUserTop,
                left: secondaryUserLeft,
                absolutePositioned: true
            });
            const clip3 = new fabric.Rect({
                width: secondaryUserWidth,
                height: secondaryUserHeight,
                top: secondaryUserTop,
                left: secondaryUserLeft + secondaryUserWidth + padding,
                absolutePositioned: true
            });
            const clip4 = new fabric.Rect({
                width: secondaryUserWidth,
                height: secondaryUserHeight,
                top: secondaryUserTop,
                left: secondaryUserLeft + secondaryUserWidth * 2 + padding * 2,
                absolutePositioned: true
            });
            const clip5 = new fabric.Rect({
                width: secondaryUserWidth,
                height: secondaryUserHeight,
                top: secondaryUserTop,
                left: secondaryUserLeft + secondaryUserWidth * 3 + padding * 3,
                absolutePositioned: true
            });
            const clip6 = new fabric.Rect({
                width: secondaryUserWidth,
                height: secondaryUserHeight,
                top: secondaryUserTop,
                left: secondaryUserLeft + secondaryUserWidth * 4 + padding * 4,
                absolutePositioned: true
            });

            mainNode.visible = false;
            const isStreamExist = enabledUsers[0] && streams[enabledUsers[0].id];
            const stream = isStreamExist ? streams[enabledUsers[0].id].stream : undefined;
            let prevNode = null;

            this.getVideoElement(PatternsEnum.SixUsers, stream, (video: HTMLVideoElement) => {
                if (!enabledUsers[0] || !enabledUsers[0].isEnabled) {
                    return;
                }

                if (prevNode) {
                    this.canvas.remove(prevNode);
                }

                const node = new fabric.Image(video, {
                    left: 0,
                    top: 0,
                    objectCaching: false,
                    selectable: false,
                    evented: false,
                });
                node.scaleToHeight(clip1.height);
                node.clipPath = clip1;
                node.left = clip1.left;
                node.top = clip1.top;
                node.setCoords();
                this.canvas.add(node);
                (node.getElement() as HTMLVideoElement).play();
                this.resultSteamNodes.push(node);
                prevNode = node;
                node.moveTo(1);
            });

            if (isStreamExist) {
                this.addNameNode(streams[enabledUsers[0].id].name, clip1.top + mainUserHeight, clip1.left);
            }

            streamsCounter++;

            const isStreamExist2 = enabledUsers[streamsCounter] && streams[enabledUsers[streamsCounter].id];
            const stream2 = isStreamExist2 ? streams[enabledUsers[streamsCounter].id].stream : undefined;
            let prevNode2 = null;

            this.getVideoElement(
                PatternsEnum.SixUsers,
                stream2,
                (video: HTMLVideoElement) => {
                    if (!enabledUsers[streamsCounter] || !enabledUsers[streamsCounter].isEnabled) {
                        return;
                    }

                    if (prevNode2) {
                        this.canvas.remove(prevNode2);
                    }

                    const node = new fabric.Image(video, {
                        left: 0,
                        top: 0,
                        objectCaching: false,
                        selectable: false,
                        evented: false,
                    });

                    node.scaleToWidth(secondaryUserWidth);
                    node.clipPath = clip2;
                    node.top = clip2.top;
                    node.left = clip2.left;
                    node.setCoords();
                    this.canvas.add(node);
                    (node.getElement() as HTMLVideoElement).play();
                    this.resultSteamNodes.push(node);
                    prevNode2 = node;

                    if (isStreamExist2) {
                        this.addNameNode(streams[enabledUsers[streamsCounter].id].name, node.top + node.getScaledHeight(), node.left);
                    }
                }
            );

            const isStreamExist3 = enabledUsers[streamsCounter + 1] && streams[enabledUsers[streamsCounter + 1].id];
            const stream3 = isStreamExist3 ? streams[enabledUsers[streamsCounter + 1].id].stream : undefined;
            let prevNode3 = null;

            this.getVideoElement(
                PatternsEnum.SixUsers,
                stream3,
                (video: HTMLVideoElement) => {
                    if (!enabledUsers[streamsCounter + 1] || !enabledUsers[streamsCounter + 1].isEnabled) {
                        return;
                    }

                    if (prevNode3) {
                        this.canvas.remove(prevNode3);
                    }

                    const node = new fabric.Image(video, {
                        left: 0,
                        top: 0,
                        objectCaching: false,
                        selectable: false,
                        evented: false,
                    });

                    node.scaleToWidth(secondaryUserWidth);
                    node.clipPath = clip3;
                    node.top = clip3.top;
                    node.left = clip3.left;
                    node.setCoords();
                    this.canvas.add(node);
                    (node.getElement() as HTMLVideoElement).play();
                    this.resultSteamNodes.push(node);
                    prevNode3 = node;

                    if (isStreamExist3) {
                        this.addNameNode(streams[enabledUsers[streamsCounter + 1].id].name, node.top + node.getScaledHeight(), node.left);
                    }
                }
            );


            const isStreamExist4 = enabledUsers[streamsCounter + 2] && streams[enabledUsers[streamsCounter + 2].id];
            const stream4 = isStreamExist4 ? streams[enabledUsers[streamsCounter + 2].id].stream : undefined;
            let prevNode4 = null;

            this.getVideoElement(
                PatternsEnum.SixUsers,
                stream4,
                (video: HTMLVideoElement) => {
                    if (!enabledUsers[streamsCounter + 2] || !enabledUsers[streamsCounter + 2].isEnabled) {
                        return;
                    }

                    if (prevNode4) {
                        this.canvas.remove(prevNode4);
                    }

                    const node = new fabric.Image(video, {
                        left: 0,
                        top: 0,
                        objectCaching: false,
                        selectable: false,
                        evented: false,
                    });

                    node.scaleToWidth(secondaryUserWidth);
                    node.clipPath = clip4;
                    node.top = clip4.top;
                    node.left = clip4.left;
                    node.setCoords();
                    this.canvas.add(node);
                    (node.getElement() as HTMLVideoElement).play();
                    this.resultSteamNodes.push(node);
                    prevNode4 = node;

                    if (isStreamExist4) {
                        this.addNameNode(streams[enabledUsers[streamsCounter + 2].id].name, node.top + node.getScaledHeight(), node.left);
                    }
                }
            );


            const isStreamExist5 = enabledUsers[streamsCounter + 3] && streams[enabledUsers[streamsCounter + 3].id];
            const stream5 = isStreamExist5 ? streams[enabledUsers[streamsCounter + 3].id].stream : undefined;
            let prevNode5 = null;

            this.getVideoElement(
                PatternsEnum.SixUsers,
                stream5,
                (video: HTMLVideoElement) => {
                    if (!enabledUsers[streamsCounter + 3] || !enabledUsers[streamsCounter + 3].isEnabled) {
                        return;
                    }

                    if (prevNode5) {
                        this.canvas.remove(prevNode5);
                    }

                    const node = new fabric.Image(video, {
                        left: 0,
                        top: 0,
                        objectCaching: false,
                        selectable: false,
                        evented: false,
                    });

                    node.scaleToWidth(secondaryUserWidth);
                    node.clipPath = clip5;
                    node.top = clip5.top;
                    node.left = clip5.left;
                    node.setCoords();
                    this.canvas.add(node);
                    (node.getElement() as HTMLVideoElement).play();
                    this.resultSteamNodes.push(node);
                    prevNode5 = node;

                    if (isStreamExist5) {
                        this.addNameNode(streams[enabledUsers[streamsCounter + 3].id].name, node.top + node.getScaledHeight(), node.left);
                    }
                }
            );


            const isStreamExist6 = enabledUsers[streamsCounter + 4] && streams[enabledUsers[streamsCounter + 4].id];
            const stream6 = isStreamExist6 ? streams[enabledUsers[streamsCounter + 4].id].stream : undefined;
            let prevNode6 = null;

            this.getVideoElement(
                PatternsEnum.SixUsers,
                stream6,
                (video: HTMLVideoElement) => {
                    if (!enabledUsers[streamsCounter + 4] || !enabledUsers[streamsCounter + 4].isEnabled) {
                        return;
                    }

                    if (prevNode6) {
                        this.canvas.remove(prevNode6);
                    }

                    const node = new fabric.Image(video, {
                        left: 0,
                        top: 0,
                        objectCaching: false,
                        selectable: false,
                        evented: false,
                    });

                    node.scaleToWidth(secondaryUserWidth);
                    node.clipPath = clip6;
                    node.top = clip6.top;
                    node.left = clip6.left;
                    node.setCoords();
                    this.canvas.add(node);
                    (node.getElement() as HTMLVideoElement).play();
                    this.resultSteamNodes.push(node);
                    prevNode6 = node;

                    if (isStreamExist6) {
                        this.addNameNode(streams[enabledUsers[streamsCounter + 4].id].name, node.top + node.getScaledHeight(), node.left);
                    }
                }
            );
        }
    };

    setStreamStatus = (status: boolean) => {
        this.isStreaming = status;
    };

    startStream = async () => {
        if (!this.rootStore.appStore.isUserLoggedIn) {
            this.showSignUpModal();
        } else {
            try {
                const self = this;

                if (this.renderInterval) {
                    clearInterval(this.renderInterval);
                }

                this.renderInterval = setInterval(() => {
                    self.canvas.renderAll();
                }, 40);

                // @ts-ignore
                const canvasCapturedStream: MediaStream = await this.canvas.getElement().captureStream(DEFAULT_FRAME_RATE);
                this.setCanvasStream(canvasCapturedStream);
                this.isStreaming = true;
                const enabledUsers = this.getEnabledUsers();
                const { streams } = this.rootStore.usersConferenceStore;
                const canvasStream = new MediaStream(this.canvasStream.getTracks().map((track) => track.clone()));

                if (AudioContext) {
                    this.audioContext = new AudioContext();
                }

                if (this.audioContext) {
                    this.mediaStreamAudioDestination = this.audioContext.createMediaStreamDestination();
                    this.mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(this.cameraStream);
                    this.mediaStreamAudioSourceNode.connect(this.mediaStreamAudioDestination);
                }

                if (
                    this.screenShareStream &&
                    this.screenShareStream.getAudioTracks()?.length &&
                    this.isScreenShareEnabled &&
                    this.audioContext
                ) {
                    this.mediaStreamScreenShareAudioSourceNode = this.audioContext.createMediaStreamSource(this.screenShareStream);
                    this.mediaStreamScreenShareAudioSourceNode.connect(this.mediaStreamAudioDestination);
                }

                enabledUsers.forEach(({ id }) => {
                    const streamObj = streams[id];
                    const { stream } = streamObj;

                    if (stream && stream.getAudioTracks() && stream.getAudioTracks().length && this.audioContext) {
                        streamObj.mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(stream);
                        streamObj.mediaStreamAudioSourceNode.connect(this.mediaStreamAudioDestination);
                    }
                });

                if (
                    this.mediaStreamAudioDestination.stream.getAudioTracks() &&
                    this.mediaStreamAudioDestination.stream.getAudioTracks().length
                ) {
                    canvasStream.addTrack(this.mediaStreamAudioDestination.stream.getAudioTracks()[0]);
                }

                this.rootStore.usersConferenceStore.startStream(canvasStream);
            } catch (e) {
                console.error(e);
            }
        }
    };

    stopStream = () => {
        if (this.renderInterval) {
            clearInterval(this.renderInterval);
        }

        this.isStreaming = false;
        this.rootStore.usersConferenceStore.stopStream();

        if (this.mediaStreamAudioSourceNode) {
            this.mediaStreamAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
        }

        if (this.mediaStreamScreenShareAudioSourceNode) {
            this.mediaStreamScreenShareAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
        }

        Object.keys(this.rootStore.usersConferenceStore.streams).forEach((key) => {
            const streamObj = this.rootStore.usersConferenceStore.streams[key];

            if (streamObj.mediaStreamAudioSourceNode) {
                streamObj.mediaStreamAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
                streamObj.mediaStreamAudioSourceNode = undefined;
            }
        });

        this.mediaStreamAudioSourceNode = undefined;
        this.mediaStreamScreenShareAudioSourceNode = undefined;
        this.audioContext = undefined;
        this.mediaStreamAudioDestination = undefined;
    };

    applyEnabled = () => {
        const cameraVideoTracks = this.cameraStream?.getVideoTracks();
        const cameraAudioTracks = this.cameraStream?.getAudioTracks();
        const screenShareVideoTracks = this.screenShareStream?.getVideoTracks();
        const screenShareAudioTracks = this.screenShareStream?.getAudioTracks();

        if (cameraVideoTracks && cameraVideoTracks.length) {
            cameraVideoTracks[0].enabled = !this.isVideoMuted;
        }

        if (cameraAudioTracks && cameraAudioTracks.length) {
            cameraAudioTracks[0].enabled = !this.isMicroMuted;
        }

        if (screenShareVideoTracks && screenShareVideoTracks.length) {
            screenShareVideoTracks[0].enabled = !this.isVideoMuted;
        }

        if (screenShareAudioTracks && screenShareAudioTracks.length) {
            screenShareAudioTracks[0].enabled = !this.isMicroMuted;
        }
    };
}
