import React from 'react';
import './styles.scss';

interface IProps {
    id?: string;
    stream: MediaStream;
    setRef: (ref: React.RefObject<HTMLVideoElement>) => void;
}

export class MediaStreamVideo extends React.Component<IProps> {
    videoRef: React.RefObject<HTMLVideoElement> = React.createRef();

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        if (prevProps.stream.id !== this.props.stream.id) {
            this.setSrcObject();
        }
    }

    setSrcObject = () => {
        const { stream } = this.props;

        if (
            this.videoRef &&
            this.videoRef.current &&
            stream &&
            stream.getTracks() &&
            stream.getTracks().length
        ) {
            this.videoRef.current.srcObject = stream;
        }
    };

    componentDidMount(): void {
        this.props.setRef(this.videoRef);
        this.setSrcObject();
    }

    render(): React.ReactNode {
        const { id = '' } = this.props;

        return (
            <video
                id={id}
                className='media-stream-video'
                autoPlay={true}
                muted={true}
                ref={this.videoRef}
            />
        );
    }
}
