import React from 'react';
import { IUserImage } from '@common-types';
import { BigPlusIcon, CloseIcon } from '@assets/icons';
import './styles.scss';

interface IProps {
    images: IUserImage[];
    selectedSrc: string;
    deselectImage: () => void;
    addNewImage: () => void;
    selectImage: (img: IUserImage) => void;
    removeImage: (img: IUserImage) => void;
}

export class ImagesList extends React.Component<IProps> {
    render(): React.ReactNode {
        const { images, selectImage, selectedSrc, deselectImage, addNewImage, removeImage } = this.props;
        const isNone: boolean = !selectedSrc;

        return (
            <div className='images-list'>
                <div className={`images-list__none-item ${isNone ? 'selected' : ''}`} onClick={deselectImage}>
                    None
                </div>
                {
                    images.map((img: IUserImage) => {
                        const isSelected: boolean = img.src === selectedSrc;

                        return (
                            <div
                                className={`images-list__item ${isSelected ? 'selected' : ''}`}
                                key={img.id}
                                onClick={() => selectImage(img)}
                            >
                                <img className='images-list__img' src={img.src} />
                                {
                                    !img.isInitial && (
                                        <div className='images-list__remove' onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            removeImage(img);
                                        }}>
                                            <CloseIcon />
                                        </div>
                                    )
                                }
                            </div>
                        );
                    })
                }
                <div
                    className="images-list__item new"
                    key="new"
                    onClick={() => addNewImage()}
                >
                    <BigPlusIcon />
                </div>
            </div>
        );
    }
}
