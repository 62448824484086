import React from 'react';

interface IProps {}

export const LinkedinColorIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="4" fill="#0A66C2"/>
            <path d="M33.8573 33.8579H29.7086V27.3606C29.7086 25.8112 29.681 23.8168 27.5508 23.8168C25.39 23.8168 25.0594 25.5048 25.0594 27.2478V33.8575H20.9107V20.4964H24.8935V22.3223H24.9492C25.3478 21.6408 25.9238 21.0801 26.6158 20.7001C27.3078 20.32 28.0899 20.1348 28.8789 20.164C33.0838 20.164 33.8591 22.9299 33.8591 26.5282L33.8573 33.8579ZM16.2296 18.67C15.7534 18.6701 15.2879 18.529 14.8919 18.2645C14.4959 18 14.1873 17.624 14.005 17.1841C13.8227 16.7442 13.7749 16.2601 13.8678 15.7931C13.9606 15.326 14.1898 14.897 14.5264 14.5602C14.8631 14.2234 15.292 13.9941 15.759 13.9011C16.226 13.8081 16.7101 13.8557 17.1501 14.0378C17.59 14.22 17.9661 14.5285 18.2307 14.9244C18.4953 15.3203 18.6366 15.7858 18.6367 16.2619C18.6368 16.5781 18.5745 16.8912 18.4536 17.1833C18.3326 17.4755 18.1554 17.7409 17.9318 17.9645C17.7083 18.1881 17.4429 18.3655 17.1509 18.4866C16.8588 18.6076 16.5457 18.67 16.2296 18.67ZM18.3039 33.8579H14.1509V20.4964H18.3039V33.8579ZM35.9256 10.0019H12.0662C11.5246 9.9958 11.0028 10.2049 10.6153 10.5834C10.2279 10.9618 10.0066 11.4786 10 12.0202V35.9794C10.0064 36.5212 10.2276 37.0384 10.615 37.4172C11.0024 37.796 11.5243 38.0056 12.0662 37.9998H35.9256C36.4685 38.0066 36.9919 37.7977 37.3809 37.4189C37.7698 37.04 37.9925 36.5223 38 35.9794V12.0184C37.9923 11.4758 37.7694 10.9584 37.3805 10.58C36.9915 10.2016 36.4683 9.99302 35.9256 10.0002" fill="white"/>
        </svg>
    );
};