import React from 'react';
import { Drawer } from 'antd';
import { CloseIcon } from '@assets/icons';
import { PurpleButton } from '@components';
import { IUserImage } from '@common-types';
import './styles.scss';

interface IProps {
    isDrawerOpen: boolean;
    closeDrawer: () => void;
    images: IUserImage[];
    selectImage: (img: IUserImage) => void;
    openUploadImageModal: () => void;
}

export class ImageDrawer extends React.Component<IProps> {
    render(): React.ReactNode {
        const {
            isDrawerOpen,
            closeDrawer,
            openUploadImageModal,
            images,
            selectImage,
        } = this.props;

        return (
            <div className="image-drawer">
                <Drawer
                    className='image-drawer__drawer'
                    onClose={closeDrawer}
                    visible={isDrawerOpen}
                    closable={true}
                    destroyOnClose={true}
                    width={380}
                    closeIcon={<CloseIcon />}
                    placement='right'
                    title={(
                        <div className="image-drawer__title">
                            Images
                        </div>
                    )}
                >
                    <div className="image-drawer__button">
                        <PurpleButton onClick={() => openUploadImageModal()}>
                            Upload Image
                        </PurpleButton>
                    </div>
                    <div className="image-drawer__image-list">
                        {
                            images.map((img: IUserImage) => {
                                return (
                                    <div
                                        key={img.id}
                                        className="image-drawer__image-wrapper"
                                        onClick={() => selectImage(img)}
                                    >
                                        <img
                                            src={
                                                img.src.includes('images')
                                                    ? img.src
                                                    : `/images/${img.src}`
                                            }
                                            className="image-drawer__image"
                                        />
                                    </div>
                                );
                            })
                        }
                    </div>
                </Drawer>
            </div>
        );
    }
}
