import React from 'react';
import { useDrop } from 'react-dnd';
import { DragItemTypes } from '@common-types';
import './styles.scss';

interface IProps {
    index: number;
    children?: React.ReactNode | React.ReactNode[];
}

export const StreamViewUserWrapper: React.FC<IProps> = (props: IProps) => {
    const [, drop] = useDrop(
        () => ({
            accept: DragItemTypes.User,
            drop: () => ({ index: props.index }),
        }),
    );

    return (
        <div className="stream-view-user-wrapper" ref={drop}>
            {props.children}
        </div>
    );
};
