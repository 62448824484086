import React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import format from 'date-fns/format';
import { FormattedMessage } from 'react-intl';
import { Link, Prompt } from 'react-router-dom';
import { Tabs, Typography, Drawer, Tooltip, Modal } from 'antd';
import { PurpleButton, DestinationsDrawer, SecondaryButton, BlackButton, ProjectCreateModal } from '@components';
import {
    BroadcastIcon,
    LogoIcon,
    ArrowLeftIcon,
    BarsIcon,
    ArrowRightIcon,
    PlusCircleIcon,
    TrashRedIcon,
    EditPenIcon,
    CloseIcon,
} from '@assets/icons';
import { ADVANCE_MODE_LABEL } from '@constants';
import { IProject, NavBarTabs, Routes } from '@common-types';
import { SimpleModeStore, AppStore } from '@services';
import { showWarnMessage, preventPageReload } from '@utils';
import { Branding } from '../branding';
import { SimpleMainSection } from '../main-section';
import { AddDestinationDrawer } from '../../destinations-drawer/add-destination-drawer';
import { ProjectDrawer } from '../projects-drawer';
import './styles.scss';

interface IProps {
    SimpleModeStore?: SimpleModeStore;
    AppStore?: AppStore;
}

@inject('SimpleModeStore', 'AppStore')
@observer
export class SimpleMode extends React.Component<IProps> {
    isDrawerOpen: boolean = true;
    isBrandingOpen: boolean = false;
    isMenuOpen: boolean = false;

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            isDrawerOpen: observable,
            isMenuOpen: observable,
            isBrandingOpen: observable,
            openDrawer: action,
            closeDrawer: action,
            openBrandingDrawer: action,
            closeBrandingDrawer: action,
        });
    }

    toggleMenu = () => {
        this.isMenuOpen = !this.isMenuOpen;
    };

    openBrandingDrawer = () => {
        this.isBrandingOpen = true;
    };

    closeBrandingDrawer = () => {
        this.isBrandingOpen = false;
    };

    openDrawer = () => {
        this.isDrawerOpen = true;
    };

    closeDrawer = () => {
        this.isDrawerOpen = false;
    };

    onTabChange = (tab: string) => {
        this.props.SimpleModeStore!.changeTab(tab);
    };

    componentDidMount(): void {
        // window.addEventListener('beforeunload', preventPageReload);
    }

    componentWillUnmount(): void {
        // window.removeEventListener('beforeunload', preventPageReload);
        location.reload();
    }

    selectProject = (project: IProject) => {
        const { setSelectedToEditProject, openProjectCreateModal } = this.props.SimpleModeStore;

        setSelectedToEditProject(project);
        openProjectCreateModal();
    };

    deleteProject = (id?: number) => {
        const { selectedProject, deleteProject } = this.props.SimpleModeStore!;

        Modal.confirm({
            className: 'delete-project-confirm-modal',
            content: "Are you sure you want to delete your project? You will lose all your settings and data.",
            title: (
                <div className="custom-confirm-popup-header">
                    <TrashRedIcon />
                    <div className="custom-confirm-popup-title-text">
                        Delete Project
                    </div>
                </div>
            ),
            okText: `YES, I'm Sure`,
            cancelText: 'No, Cancel',
            okButtonProps: {
                className: 'secondary-button'
            },
            cancelButtonProps: {
                className: 'purple-button'
            },
            onCancel: () => {
                Modal.destroyAll();
            },
            onOk: () => {
                deleteProject(id ? id : selectedProject?.id);
                Modal.destroyAll();
            },
            closeIcon: <CloseIcon />,
            closable: true,
            width: 400,
        });
    };

    selectAdvanceMode = () => {
        this.props.AppStore.selectMode(ADVANCE_MODE_LABEL);
        location.reload();
    };

    render(): React.ReactNode {
        const {
            streamDate,
            streamName,
            tab,
            openDestinationsDrawer,
            stopStream,
            isStreaming,
            destinations,
            startStream,
            isDestinationsModalShown,
            closeDestinationsModal,
            selectedDestination,
            addDestination,
            selectedProject,
            openProjectCreateModal,
            isProjectCreateModalOpen,
            closeProjectCreateModal,
            user,
            selectedToEditProject,
            selectProject,
            createProject,
            updateProject,
            removeImage,
            toggleDestinationActive,
            selectDestination,
            removeDestination,
            openDestinationsModal,
            isDestinationsDrawerOpen,
            closeDestinationsDrawer,
        } = this.props.SimpleModeStore!;
        const { isUserLoggedIn } = this.props.AppStore!;
        const projects = user ? user.projects : [];

        return (
            <div className='simple-mode'>
                <Prompt message="Are you sure you want to leave?" />
                <div className='simple-mode__left'>
                    <Link to={`${Routes.SelectMedia}${location.search}`}>
                        <PurpleButton>
                            <LogoIcon />
                        </PurpleButton>
                    </Link>
                    <div className="simple-mode__projects-list">
                        {
                            isUserLoggedIn ? projects.map((project: IProject) => {
                                const { avatar, id, name } = project;

                                return (
                                    <div
                                        className={`simple-mode__projects-wrapper ${id === selectedProject?.id ? 'selected' : ''}`}
                                        key={id}
                                        onClick={() => selectProject(project)}
                                    >
                                        <Tooltip title={name}>
                                            <div className="simple-mode__projects-inner">
                                                {
                                                    avatar
                                                        ? <img className="simple-mode__projects-img" src={avatar} />
                                                        : <div className="simple-mode__projects-latter">{name[0]}</div>
                                                }
                                            </div>
                                        </Tooltip>
                                    </div>
                                );
                            }) : null
                        }
                    </div>
                    <Tooltip title={'Create project'} placement={'right'}>
                        <div className="simple-mode__create-project" onClick={openProjectCreateModal}>
                            <PlusCircleIcon />
                        </div>
                    </Tooltip>
                </div>
                <div className='simple-mode__middle'>
                    <div className='simple-mode__header'>
                        <div className='simple-mode__mobile-open-menu' onClick={this.toggleMenu}>
                            <BarsIcon />
                        </div>
                        <div className='simple-mode__header-left'>
                            <div className='simple-mode__text'>
                                <div className='simple-mode__name'>
                                    <Typography.Paragraph>
                                        {streamName}
                                    </Typography.Paragraph>
                                </div>
                                <div className='simple-mode__date'>
                                    {
                                        selectedProject
                                            ? format(new Date(selectedProject.createdAt), 'MMM dd - HH:mm:ss')
                                            : streamDate
                                    }
                                </div>
                            </div>
                            {
                                isUserLoggedIn && (
                                    <div className='simple-mode__header-icons'>
                                        <div className='simple-mode__header-edit' onClick={() => this.selectProject(selectedProject)}>
                                            <EditPenIcon />
                                        </div>
                                        {
                                            !selectedProject?.isInitial && (
                                                <div className='simple-mode__header-delete' onClick={() => this.deleteProject()}>
                                                    <TrashRedIcon />
                                                </div>
                                            )
                                        }

                                    </div>
                                )
                            }
                        </div>
                        <div className='simple-mode__button'>
                            <BlackButton onClick={this.selectAdvanceMode}>
                                Advance Mode
                            </BlackButton>
                            <BlackButton onClick={openDestinationsDrawer}>
                                Channels
                            </BlackButton>
                            {
                                isStreaming
                                    ? (
                                        <SecondaryButton onClick={stopStream}>
                                            <BroadcastIcon/> Stop stream
                                        </SecondaryButton>
                                    )
                                    : (
                                        <PurpleButton onClick={() => {
                                            if (destinations.length) {
                                                startStream();
                                            } else {
                                                if (isUserLoggedIn) {
                                                    showWarnMessage('Add destinations first!');
                                                }

                                                openDestinationsDrawer();
                                            }
                                        }}>
                                            <BroadcastIcon/> <FormattedMessage id='go.live'/>
                                        </PurpleButton>
                                    )
                            }

                        </div>
                        <div className='simple-mode__mobile-open-drawer' onClick={this.openBrandingDrawer}>
                            <ArrowLeftIcon />
                        </div>
                    </div>
                    <SimpleMainSection />
                </div>
                <div className={`simple-mode__right ${this.isDrawerOpen ? 'open' : 'close'}`}>
                    <div
                        className={`simple-mode__close-drawer ${this.isDrawerOpen ? 'left' : 'right'}`}
                        onClick={() => {
                            if (this.isDrawerOpen) {
                                this.closeDrawer();
                            } else {
                                this.openDrawer();
                            }
                        }}
                    >
                        <ArrowLeftIcon />
                    </div>
                    <div className='simple-mode__right-border' />
                    <div className='simple-mode__mobile-close-drawer' onClick={this.closeBrandingDrawer}>
                        <ArrowRightIcon />
                    </div>
                    <Tabs defaultActiveKey={tab} onChange={this.onTabChange}>
                        <Tabs.TabPane
                            tab={<FormattedMessage id={NavBarTabs.Branding} />}
                            key={NavBarTabs.Branding}
                        >
                            <Branding />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key={NavBarTabs.Captions}
                            tab={<FormattedMessage id={NavBarTabs.Captions} />}
                        >
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key={NavBarTabs.Chat}
                            tab={<FormattedMessage id={NavBarTabs.Chat} />}
                        >
                        </Tabs.TabPane>
                    </Tabs>
                    {
                        this.isBrandingOpen && (
                            <Drawer
                                className="branding-drawer"
                                onClose={this.closeBrandingDrawer}
                                visible={this.isBrandingOpen}
                                closable={false}
                                width={window.innerWidth > 800 ? 408 : window.innerWidth}
                            >
                                <div className='simple-mode__mobile-close-drawer' onClick={this.closeBrandingDrawer}>
                                    <ArrowRightIcon />
                                </div>
                                <Tabs defaultActiveKey={tab} onChange={this.onTabChange}>
                                    <Tabs.TabPane
                                        tab={<FormattedMessage id={NavBarTabs.Branding} />}
                                        key={NavBarTabs.Branding}
                                    >
                                        <Branding />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane
                                        key={NavBarTabs.Captions}
                                        tab={<FormattedMessage id={NavBarTabs.Captions} />}
                                    >
                                    </Tabs.TabPane>
                                    <Tabs.TabPane
                                        key={NavBarTabs.Chat}
                                        tab={<FormattedMessage id={NavBarTabs.Chat} />}
                                    >
                                    </Tabs.TabPane>
                                </Tabs>
                            </Drawer>
                        )
                    }
                </div>
                <DestinationsDrawer
                    toggleDestinationActive={toggleDestinationActive}
                    selectDestination={selectDestination}
                    removeDestination={removeDestination}
                    openDestinationsModal={openDestinationsModal}
                    isDestinationsDrawerOpen={isDestinationsDrawerOpen}
                    closeDestinationsDrawer={closeDestinationsDrawer}
                    destinations={destinations}
                />
                {
                    isDestinationsModalShown && (
                        <AddDestinationDrawer
                            selectedDestination={selectedDestination}
                            destinations={destinations}
                            onClose={closeDestinationsModal}
                            onSave={addDestination}
                            name={selectedDestination ? selectedDestination.name : ''}
                            url={selectedDestination ? selectedDestination.url : ''}
                            streamKey={selectedDestination ? selectedDestination.streamKey : ''}
                            isModalShown={isDestinationsModalShown}
                        />
                    )
                }
                {
                    isProjectCreateModalOpen && isUserLoggedIn ? (
                        <ProjectCreateModal
                            project={selectedToEditProject}
                            isModalOpen={isProjectCreateModalOpen}
                            closeModal={closeProjectCreateModal}
                            createProject={createProject}
                            user={user}
                            removeImage={removeImage}
                            updateProject={updateProject}
                        />
                    ) : null
                }
                {
                    this.isMenuOpen && isUserLoggedIn ? (
                        <ProjectDrawer
                            onClose={this.toggleMenu}
                            isDrawerOpen={this.isMenuOpen}
                            deleteProject={this.deleteProject}
                            editProject={this.selectProject}
                        />
                    ) : null
                }
            </div>
        );
    }
}
