import React from 'react';
import { Select } from 'antd';
import { observer, inject } from 'mobx-react';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { DeviceTypes, IAudioSource } from '@common-types';
import { DeleteGrayIcon, SoundGreyIcon } from '@assets/icons';
import { AdvanceModeStore } from '@services';
import './styles.scss';

interface IProps {
    AdvanceModeStore?: AdvanceModeStore;
    sources: IAudioSource[];
    removeSource: (id: string) => void;
    toggleSource: (source: IAudioSource) => void;
    changeSourceDevice: (audioSource: IAudioSource, device: MediaDeviceInfo) => void;
}

@inject('AdvanceModeStore')
@observer
export class AudioControls extends React.Component<IProps> {
    micros: MediaDeviceInfo[] = [];

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            micros: observable,
            setDevices: action,
        });
    }

    componentDidMount(): void {
        this.setDevices();
    }

    setDevices = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();

            runInAction(() => {
                this.micros = devices.filter(({ kind }) => {
                    return kind === DeviceTypes.Audio;
                });
            });

        } catch (e) {
            console.error(e);
        }
    };

    changeSourceDevice = (source: IAudioSource, deviceId: string) => {
        const micro = this.micros.find((micro) => micro.deviceId === deviceId);

        if (micro) {
            this.props.changeSourceDevice(source, micro);
        }
    };

    render(): React.ReactNode {
        const {
            sources,
            removeSource,
            toggleSource,
        } = this.props;
        const { audioSourceUpdateId } = this.props.AdvanceModeStore!;
        audioSourceUpdateId;

        if (!sources || sources.length === 0) {
            return null;
        }

        return (
            <div className="audio-controls">
                <div className="audio-controls__header">
                    Audio Sources
                </div>
                {
                    sources.map((source: IAudioSource) => {
                        return (
                            <div className="audio-controls__source" key={source.id}>
                                <div className="audio-controls__source-name">
                                    <div className="audio-controls__name">
                                        {source.name}
                                    </div>
                                    <div className="audio-controls__actions">
                                        <div
                                            className={`audio-controls__toggle ${source.muted ? 'red' : ''}`}
                                            onClick={() => toggleSource(source)}
                                        >
                                            <SoundGreyIcon />
                                        </div>
                                        <div className="audio-controls__remove" onClick={() => removeSource(source.id)}>
                                            <DeleteGrayIcon />
                                        </div>
                                    </div>
                                </div>
                                <div className="audio-controls__select">
                                    <Select
                                        value={source.deviceId}
                                        onChange={(id: string) => this.changeSourceDevice(source, id)}
                                    >
                                        {
                                            this.micros.map(({ deviceId, label }) => {
                                                return (
                                                    <Select.Option value={deviceId} key={deviceId}>
                                                        {label}
                                                    </Select.Option>
                                                );
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}
