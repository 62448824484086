import React from 'react';

interface IProps {}

export const StripchatColorIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z" fill="#A2252D"/>
            <path d="M19.908 37.485C19.908 37.485 19.695 37.446 19.656 37.434C19.0696 37.3007 18.4549 37.3805 17.922 37.659L17.661 37.791C16.884 38.178 16.086 38.526 15.276 38.835C12.999 39.702 10.536 40.32 8.061 40.725C8.26971 40.4113 8.47175 40.0932 8.667 39.771C9.162 38.955 9.798 37.788 10.542 36.363C10.542 36.363 11.154 35.283 11.397 34.716C11.637 34.155 11.358 33.627 11.01 33.285C10.662 32.943 9.957 32.175 9.957 32.175C7.395 29.481 6 26.166 6 22.689C6 14.433 13.95 7.5 24 7.5C34.05 7.5 42 14.43 42 22.689C42 30.495 34.896 37.119 25.629 37.818C25.545 37.819 25.4617 37.8026 25.3843 37.77C25.3069 37.7374 25.237 37.6892 25.1791 37.6284C25.1211 37.5676 25.0762 37.4956 25.0473 37.4167C25.0184 37.3378 25.0061 37.2539 25.011 37.17C24.9871 34.144 24.9681 31.118 24.954 28.092C24.954 27.81 25.149 27.462 25.392 27.318L32.292 19.326C32.451 19.14 32.385 18.99 32.133 18.99L15.513 18.999C15.267 18.999 15.195 19.149 15.354 19.335L22.239 27.309C22.479 27.459 22.674 27.81 22.674 28.092C22.674 28.092 22.665 34.158 22.683 37.191C22.686 37.656 22.224 37.833 21.999 37.809C21.2973 37.7335 20.5996 37.6254 19.908 37.485Z" fill="white"/>
        </svg>
    );
};