import React from 'react';

interface IProps {}

export const ChaturbateIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="63" height="19" viewBox="0 0 63 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2529 11.0572C11.1829 11.0572 12.4813 9.80538 12.6859 8.5147C13.6535 8.5147 14.176 9.03304 14.176 9.89696C14.176 11.2524 12.7882 13.3949 10.6229 13.3949C7.69369 13.3949 6.42007 10.9941 6.42007 8.29786C6.42514 7.5941 6.50633 6.89302 6.66218 6.20719C3.33746 5.93592 0.865234 4.91651 0.865234 3.59991C0.866939 2.91483 1.54211 2.15113 3.12178 1.37707C3.19765 3.54548 4.77647 4.90182 6.87786 5.40375C7.65448 3.0159 9.38674 1.02805 11.7558 1.02805C13.5137 1.02805 14.4054 1.87986 14.4054 2.88632C14.4054 4.26858 12.737 5.95839 9.32366 6.22879C8.89059 7.13244 8.59734 8.17777 8.59734 9.06847C8.59734 10.1924 9.05598 11.0572 10.2529 11.0572ZM9.68 5.59727C11.385 5.46855 11.9817 4.92601 11.9817 4.53898C11.9817 4.31955 11.778 4.13899 11.4847 4.13899C10.865 4.13899 10.2026 4.74545 9.68 5.59727Z" fill="white"/>
            <path d="M14.7605 6.16573C14.0597 6.06206 13.9199 5.84349 13.9199 5.68798C13.9194 5.62808 13.9372 5.56949 13.9711 5.52039C14.2899 5.59814 14.5823 5.64911 14.85 5.68798C15.4357 2.77055 16.5055 1.10925 17.8559 1.10925C19.04 1.10925 19.6649 1.98699 19.6649 3.01936C19.6649 4.36188 18.6078 5.94889 16.3154 6.19424C16.2643 6.67198 16.2302 7.16182 16.2131 7.65252C16.8099 6.875 17.6018 6.51734 18.0468 6.51734C19.1423 6.51734 19.3972 7.25253 19.3972 8.24516C19.3972 9.13585 19.1934 10.2201 19.1934 11.1488C19.1934 12.1941 19.4611 13.0494 20.5941 13.1625C20.5941 13.1625 19.7152 13.9237 19.04 13.9237C17.7408 13.9237 17.4731 12.8913 17.4731 11.6654C17.4731 10.8645 17.5882 10.0006 17.5882 9.29049C17.5882 8.4646 17.4356 7.87109 16.7988 7.87109C16.5951 7.87109 16.3785 7.99981 16.1876 8.25812V8.81275C16.1876 10.5034 16.4041 12.3237 16.8244 12.8006C16.4204 12.8709 16.0117 12.9099 15.6019 12.9172C14.7102 12.9172 14.3539 12.582 14.3539 11.5617C14.353 9.49524 14.4928 7.68794 14.7605 6.16573ZM16.3802 5.56099C17.3487 5.22493 17.6411 4.38607 17.6411 3.70185C17.6411 3.15931 17.4501 2.72131 17.2719 2.72131C16.913 2.71958 16.5814 3.95843 16.3777 5.55926L16.3802 5.56099Z" fill="white"/>
            <path d="M23.4109 7.40716C23.6106 7.35418 23.8157 7.32518 24.0221 7.32077C24.3273 7.32077 24.5566 7.40716 24.5566 7.72076C24.5566 8.64947 24.5311 9.41144 24.5311 10.0533C24.5311 11.8338 24.7092 12.6451 25.9701 13.1634C25.9701 13.1634 25.0656 13.9124 24.3503 13.9124C23.2932 13.9124 23.1151 12.6217 23.0128 11.1375H22.9616C22.1842 12.7479 21.5354 13.0226 20.9617 13.0226C20.0069 13.0226 19.561 11.9859 19.561 10.7254C19.561 8.78942 20.5926 6.33678 22.3248 6.33678C22.9846 6.33332 23.2796 6.9657 23.4109 7.40716ZM22.7067 8.35747C22.672 8.20478 22.6296 8.05397 22.5797 7.90564C21.879 8.53802 21.4843 10.138 21.4843 10.9777C21.4843 11.2999 21.5482 11.5202 21.6752 11.5202C22.006 11.5202 22.7835 10.6684 22.7835 9.18768C22.7817 8.90921 22.756 8.63143 22.7067 8.35747Z" fill="white"/>
            <path d="M29.0613 5.82967C29.0613 6.16486 28.8448 6.4344 28.1824 6.4344H27.202C27.1509 7.27326 27.1168 8.18987 27.1168 9.0676C27.1168 10.487 27.231 11.8321 27.5626 12.6045C27.5626 12.6045 26.5695 13.1591 25.8943 13.1591C25.538 13.1591 25.2703 13.0174 25.2703 12.5543C25.2703 11.3025 25.526 8.6823 25.601 6.437C24.9642 6.437 24.48 6.42404 24.48 6.42404C24.2891 6.42404 23.9199 6.13981 23.9199 5.75278C23.9228 5.64936 23.949 5.54798 23.9966 5.45646C24.5388 5.43252 25.0794 5.38091 25.6164 5.30182C25.5968 4.04915 25.4953 3.02887 25.2012 2.65566C25.6982 2.48806 26.1185 2.39648 26.4621 2.39648C27.0989 2.39648 27.4433 2.71872 27.4433 3.45478C27.4433 3.62237 27.3793 4.18996 27.3162 4.96489C27.7044 4.88846 28.0839 4.77269 28.4492 4.61933C28.5396 4.61674 29.0613 5.30095 29.0613 5.82967Z" fill="white"/>
            <path d="M32.4609 14.0645C31.6331 14.0645 31.2129 12.9414 31.0347 11.5738C30.6783 12.5284 30.2069 13.0969 29.7611 13.0969C28.3118 13.0969 27.9785 11.3285 27.9785 9.72764C27.9873 8.92323 28.0562 8.12066 28.1848 7.32683C28.1848 7.32683 28.2991 6.875 29.2931 6.875C29.5922 6.8774 29.8906 6.90631 30.1848 6.96139C29.9934 7.72973 29.891 8.51803 29.8796 9.31037C29.8796 10.3946 30.1353 11.0382 30.4652 11.0382C30.6178 11.0382 30.796 10.8965 30.9622 10.5734C30.9494 10.341 30.9494 10.0965 30.9494 9.86327C30.9494 8.70131 31.0765 7.60501 31.2427 7.0236C31.2683 6.83008 31.6374 6.72641 32.0577 6.72641C32.5163 6.72641 33.0125 6.84304 33.1779 7.07197C32.9997 7.29141 32.6553 8.74969 32.6553 10.208C32.6553 11.6274 32.9741 13.0347 34.0815 13.3051C34.0789 13.3155 33.5819 14.0645 32.4609 14.0645Z" fill="white"/>
            <path d="M33.8873 5.06857C34.7151 5.06857 35.5309 5.6232 35.5309 6.97867C35.5309 7.2629 35.5181 7.55922 35.5053 7.84259C35.8745 6.01023 36.5624 4.43532 37.6706 4.43532C38.4856 4.43532 38.6638 4.96403 38.6638 5.41672C38.6637 5.61769 38.635 5.81759 38.5785 6.01023C35.8276 6.17783 35.4584 9.32765 35.4584 10.9665C35.4584 11.7146 35.7005 12.2701 36.0313 12.7989C35.6273 12.8692 35.2187 12.9082 34.8088 12.9155C33.9171 12.9155 33.5608 12.5803 33.5608 11.56C33.5608 10.4499 33.8541 9.09526 33.8541 8.07584C33.8541 7.14627 33.6119 6.47501 32.7083 6.47501C32.524 6.4795 32.3405 6.50119 32.1602 6.53981C32.2062 5.6232 33.0595 5.06857 33.8873 5.06857Z" fill="white"/>
            <path d="M39.3375 12.8377C38.9962 12.8922 38.6514 12.9211 38.306 12.9241C37.4535 12.9241 37.1977 12.5889 37.1977 11.5557C37.1977 9.49093 37.3375 7.68362 37.6052 6.16141C36.9045 6.05774 36.7647 5.83917 36.7647 5.68367C36.764 5.62376 36.7819 5.56515 36.8158 5.51607C37.1346 5.59382 37.4271 5.64479 37.6947 5.68367C38.2804 2.76624 39.3503 1.10493 40.7006 1.10493C41.8847 1.10666 42.5071 1.98353 42.5071 3.01591C42.5071 4.35843 41.45 5.94543 39.1576 6.19078C39.1065 6.70913 39.0724 7.26203 39.0425 7.81667C39.7578 6.7722 40.5728 6.62965 41.0698 6.62965C42.0246 6.62965 42.4448 7.61019 42.4448 8.78511C42.4448 10.6175 41.4389 12.9405 39.8592 12.9405C39.6798 12.9481 39.5011 12.9129 39.3375 12.8377ZM39.0314 9.46846C39.0442 10.449 39.0826 11.4304 39.2096 12.0887C39.999 12.0369 40.3306 10.5017 40.3306 9.3527C40.3306 8.66848 40.2164 8.12681 40.0118 8.12681C39.7578 8.12681 39.2608 8.60455 39.0323 9.46933L39.0314 9.46846ZM39.2224 5.5584C40.1908 5.22234 40.4832 4.38348 40.4832 3.69926C40.4832 3.15673 40.2923 2.71872 40.1141 2.71872C39.745 2.71959 39.427 3.95844 39.2224 5.55926V5.5584Z" fill="white"/>
            <path d="M46.3796 7.40716C46.5794 7.35421 46.7845 7.32521 46.9909 7.32077C47.2969 7.32077 47.5254 7.40716 47.5254 7.72076C47.5254 8.64947 47.4998 9.41144 47.4998 10.0533C47.4998 11.8338 47.678 12.6451 48.9388 13.1634C48.9388 13.1634 48.0343 13.9124 47.3191 13.9124C46.262 13.9124 46.0838 12.6217 45.9815 11.1375H45.9304C45.1563 12.7479 44.5076 13.0226 43.9338 13.0226C42.9791 13.0226 42.5332 11.9859 42.5332 10.7254C42.5332 8.78942 43.5647 6.33678 45.297 6.33678C45.9594 6.33332 46.2518 6.9657 46.3796 7.40716ZM45.6789 8.35747C45.6405 8.20283 45.6022 8.04818 45.5519 7.90564C44.8511 8.53802 44.4564 10.138 44.4564 10.9777C44.4564 11.2999 44.5195 11.5202 44.6474 11.5202C44.979 11.5202 45.7556 10.6684 45.7556 9.18768C45.7538 8.90922 45.7281 8.63144 45.6789 8.35747Z" fill="white"/>
            <path d="M45.5 5.14632C45.5 4.47506 46.685 4.2945 48.2126 4.2945H48.5818C48.5306 3.51698 48.4164 2.92606 48.2126 2.65307C48.7096 2.48547 49.1299 2.39389 49.4735 2.39389C50.1103 2.39389 50.4538 2.71613 50.4538 3.45219C50.4538 3.56795 50.4155 3.90401 50.378 4.36793C51.7027 4.44568 53.0147 4.56145 53.9311 4.56145C56.7444 4.56145 59.4587 4.0837 60.9489 2.13472C61.6743 2.54767 62.133 3.41245 62.133 4.22539C62.133 5.20679 61.4578 6.1355 59.7392 6.1355C56.5935 6.1355 53.0897 5.3096 50.3021 5.21888C50.2168 6.30309 50.1239 7.72423 50.1239 9.06502C50.1239 10.4844 50.239 11.8295 50.5698 12.6019C50.5698 12.6019 49.5766 13.1565 48.9014 13.1565C48.5451 13.1565 48.2774 13.0148 48.2774 12.5518C48.2774 11.1064 48.6184 7.84086 48.6184 5.41499V5.24739C47.5102 5.33379 46.6057 5.60851 46.0609 6.12513C45.6782 5.71391 45.5 5.40376 45.5 5.14632Z" fill="white"/>
            <path d="M52.7207 10.4119C52.7335 11.5349 53.2433 12.0377 53.7778 12.0377C54.2237 12.0377 54.6823 11.6922 54.8988 11.0702C55.5356 11.1341 55.8408 11.4701 55.8408 11.8572C55.8408 12.5025 54.7582 13.0831 53.5996 13.0831C52.2749 13.0831 50.8486 12.322 50.8486 10.0369C50.8486 8.1916 52.3516 6.51389 53.8801 6.51389C54.2666 6.51127 54.6461 6.61841 54.9756 6.82317C55.0898 6.74748 55.2213 6.70286 55.3575 6.69358C55.7522 6.69358 56.0326 7.33892 56.0326 8.10089C56.0326 9.21102 55.447 10.5786 53.8042 10.5786C53.4371 10.5745 53.0724 10.5184 52.7207 10.4119ZM54.1981 8.12767C53.4598 8.27022 52.8733 9.12117 52.7488 9.99891C53.9969 9.91252 54.3404 9.28963 54.3404 8.73414C54.3387 8.52341 54.2901 8.3158 54.1981 8.12681V8.12767Z" fill="white"/>
            <path d="M42.4879 15.3552C46.6558 15.3552 50.0581 15.0597 52.0725 14.1344C54.6428 14.5284 56.0323 15.2575 56.0323 15.9659C56.0323 17.0285 53.3231 17.1573 46.5858 17.1573C40.4044 17.1573 30.3331 15.9754 21.8593 15.9754C17.2056 15.9754 12.8297 17.0285 10.1904 18.092C7.55451 17.7015 6.23145 17.1694 6.23145 16.461C6.23145 15.3975 9.21517 14.3539 16.0249 14.3539C24.6367 14.3539 34.778 15.3552 42.4879 15.3552Z" fill="white"/>
        </svg>
    );
};