import React from 'react';

interface IProps {}

export const SteamColorIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="-0.00012207" width="48" height="48" rx="4" fill="url(#paint0_linear)"/>
            <path d="M22.1976 17.8056C22.1976 17.8445 22.1976 17.8835 22.1996 17.92L16.465 26.1475C15.5361 26.1058 14.6039 26.2667 13.72 26.6263C13.3304 26.7832 12.9653 26.9759 12.6227 27.1985L0 22C0 22 -3.59416e-05 26 0 30L9.8554 33.9692C10.3024 35.9421 11.6727 37.6725 13.6943 38.5049C17.0019 39.8698 20.8156 38.3142 22.1915 35.046C22.5496 34.1918 22.7165 33.2957 22.6924 32.4017L30.8976 26.6104C30.964 26.6124 31.0324 26.6144 31.0987 26.6144C36.009 26.6144 39.9999 22.6608 39.9999 17.8056C39.9999 12.95 36.009 9 31.0987 9C26.1905 9 22.1976 12.95 22.1976 17.8056ZM20.8217 34.4778C19.7567 37.003 16.8186 38.201 14.2648 37.15C13.0866 36.6652 12.1971 35.7772 11.6839 34.7182L14.5816 35.9043C16.465 36.6791 18.6258 35.797 19.4087 33.9375C20.194 32.0759 19.3031 29.9382 17.4207 29.1633L14.4253 27.9375C15.5811 27.5044 16.8951 27.4885 18.1227 27.9932C19.3605 28.5018 20.3208 29.4594 20.8297 30.6832C21.3386 31.907 21.3366 33.258 20.8217 34.4778ZM31.0987 23.674C27.83 23.674 25.1686 21.0416 25.1686 17.8056C25.1686 14.5722 27.83 11.939 31.0987 11.939C34.3695 11.939 37.0309 14.5722 37.0309 17.8056C37.0309 21.0416 34.3695 23.674 31.0987 23.674ZM26.6552 17.7966C26.6552 15.3623 28.6507 13.3879 31.1088 13.3879C33.5689 13.3879 35.5644 15.3623 35.5644 17.7966C35.5644 20.2312 33.5689 22.2038 31.1088 22.2038C28.6507 22.2038 26.6552 20.2312 26.6552 17.7966Z" fill="white"/>
            <defs>
                <linearGradient id="paint0_linear" x1="23.9999" y1="0" x2="23.9999" y2="48" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#111D2E"/>
                    <stop offset="0.21248" stop-color="#051839"/>
                    <stop offset="0.40695" stop-color="#0A1B48"/>
                    <stop offset="0.5811" stop-color="#132E62"/>
                    <stop offset="0.7376" stop-color="#144B7E"/>
                    <stop offset="0.87279" stop-color="#136497"/>
                    <stop offset="1" stop-color="#1387B8"/>
                </linearGradient>
            </defs>
        </svg>

    );
};