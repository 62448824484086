import { Locales } from '@i18n';
import { IScene, IAudioSource } from './advance';

export interface IProject {
    id?: number;
    name: string;
    color: string;
    showNames: boolean;
    selectedLogo: string;
    logoPosition: string;
    selectedPattern: string;
    selectedBg: string;
    createdAt?: string;
    isInitial?: boolean;
    description: string;
    avatar: string;
    avatarId?: string;
    lastSelectedScene?: string;
    scenes?: IScene[];
    audioSources?: IAudioSource[];
}

export interface IUser {
    id: number;
    lastSelectedProject: number;
    role: string;
    name: string;
    email: string;
    projects: IProject[];
    images: IUserImage[];
    destinations: IUserDestination[];
    locale: Locales;
}

export interface IUserImage {
    id: string;
    src: string;
    type: ImageTypesEnum;
    isInitial?: boolean;
}

export interface IUserDestination {
    id: number;
    url: string;
    name: string;
    streamKey: string;
    type: string;
    isValid?: boolean;
    active?: boolean;
    iconsId: number;
}

export enum ImageTypesEnum {
    Logo = 'Logo',
    Background = 'Background',
    Avatar = 'Avatar',
    AdvanceImage = 'AdvanceImage',
}
