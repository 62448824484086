import React from 'react';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { v4 } from 'uuid';
import { fabric } from 'fabric';
import format from 'date-fns/format';
import debounce from 'lodash/debounce';
import { Modal } from 'antd';
import html2canvas from 'html2canvas';
import {
    DEFAULT_ADVANCE_CANVAS_BG_COLOR,
    DEFAULT_FRAME_RATE,
    DESTINATIONS_ICONS_MAP,
    FULL_SCREEN_CONSTRAINTS,
    STORAGE_CAMERA_LABEL,
    STORAGE_MICRO_LABEL,
} from '@constants';
import {
    AdvanceModeTabs,
    ApiRoutes,
    IAudioSource,
    ILayer,
    IProject,
    IScene,
    IStreamDestination,
    IUser,
    IUserDestination,
    IUserImage,
    LayerTypeEnum,
    Routes,
    StreamTypeEnum,
} from '@common-types';
import { Locales } from '@i18n';
import { CloseIcon } from '@assets/icons';
import {
    axiosInstance,
    getAxiosHeaders,
    getCameraConstraint,
    getImageSrc,
    isFirefox,
    showScreenShareErrorMessage,
    showWarnMessage,
} from '@utils';
import { Base } from './base-store';

// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext || null;

export class AdvanceModeStore extends Base {
    canvasStream: MediaStream;
    renderInterval;
    canvas: fabric.StaticCanvas = null;
    selectedScene: IScene = null;
    tab: AdvanceModeTabs = AdvanceModeTabs.Layers;
    streamName: string = 'Stream name';
    streamDate: string = format(new Date(), 'MMM dd - HH:mm:ss');
    cameraStream: MediaStream;
    screenShareStream: MediaStream = null;
    usersOrder: { id: string; isEnabled: boolean; }[] = [];
    isAudioMuted: boolean = false;
    isMicroMuted: boolean = false;
    isVideoMuted: boolean = false;
    isSettingsModalShown: boolean = false;
    currentCamera: string = null;
    currentMicro: string = null;
    isStreaming: boolean = false;
    isScreenShare: boolean = false;
    destinations: IStreamDestination[] = [];
    isDestinationsModalShown: boolean = false;
    isDestinationsDrawerOpen: boolean = false;
    selectedDestination: IStreamDestination = null;
    roomId: string = v4();
    isAdminEnabled: boolean = true;
    audioContext: AudioContext | null;
    mediaStreamAudioDestination: MediaStreamAudioDestinationNode;
    mediaStreamAudioSourceNode: MediaStreamAudioSourceNode;
    mediaStreamScreenShareAudioSourceNode: MediaStreamAudioSourceNode;
    isScreenShareEnabled: boolean = false;
    isScreenShareMuted: boolean = false;
    currentVideoMediaTrackConstraints: MediaTrackConstraints = { ...FULL_SCREEN_CONSTRAINTS };
    currentSSVideoMediaTrackConstraints: MediaTrackConstraints = { ...FULL_SCREEN_CONSTRAINTS };
    defaultSSVideoMediaTrackConstraints: MediaTrackConstraints;
    user: IUser = null;
    selectedProject: IProject = null;
    layerUpdateId: string = v4();
    sceneUpdateId: string = v4();
    selectedLayer: ILayer = null;
    isProjectCreateModalOpen: boolean = false;
    selectedToEditProject: IProject = null;
    isImageDrawerOpen: boolean = false;
    isCameraDrawerOpen: boolean = false;
    isUploadImageModalOpen: boolean = false;
    isMicroDrawerOpen: boolean = false;
    audioSourceUpdateId: string = v4();
    isSlideShowDrawerOpen: boolean = false;
    isSlideShowFormOpen: boolean = false;

    constructor(rootStore) {
        super(rootStore);

        makeObservable(this, {
            canvas: observable,
            isSlideShowFormOpen: observable,
            isSlideShowDrawerOpen: observable,
            audioSourceUpdateId: observable,
            isMicroDrawerOpen: observable,
            isUploadImageModalOpen: observable,
            isImageDrawerOpen: observable,
            isCameraDrawerOpen: observable,
            selectedScene: observable,
            selectedLayer: observable,
            layerUpdateId: observable,
            sceneUpdateId: observable,
            isProjectCreateModalOpen: observable,
            selectedToEditProject: observable,
            user: observable,
            selectedProject: observable,
            isAdminEnabled: observable,
            isScreenShareMuted: observable,
            isScreenShareEnabled: observable,
            isDestinationsDrawerOpen: observable,
            selectedDestination: observable,
            isDestinationsModalShown: observable,
            destinations: observable,
            tab: observable,
            isScreenShare: observable,
            isStreaming: observable,
            usersOrder: observable,
            isSettingsModalShown: observable,
            isMicroMuted: observable,
            isVideoMuted: observable,
            streamName: observable,
            streamDate: observable,
            currentCamera: observable,
            screenShareStream: observable,
            currentMicro: observable,
            isAudioMuted: observable,
            changeTab: action,
            addUser: action,
            removeUser: action,
            toggleUser: action,
            toggleAudio: action,
            toggleMicros: action,
            toggleVideo: action,
            openSettingsModal: action,
            closeSettingsModal: action,
            setCurrentCamera: action,
            setCurrentMicro: action,
            updateCamera: action,
            updateMicro: action,
            startStream: action,
            setStreamStatus: action,
            stopScreenShare: action,
            addScreenShare: action,
            openDestinationsModal: action,
            closeDestinationsModal: action,
            addDestination: action,
            selectDestination: action,
            openDestinationsDrawer: action,
            closeDestinationsDrawer: action,
            removeDestination: action,
            stopStream: action,
            toggleAdmin: action,
            changeStreamName: action,
            toggleDestinationActive: action,
            setInitialDestinations: action,
            toggleScreenShare: action,
            toggleScreenShareSound: action,
            init: action,
            openProjectCreateModal: action,
            closeProjectCreateModal: action,
            setSelectedToEditProject: action,
            deleteProject: action,
            selectProject: action,
            pushDestination: action,
            createProject: action,
            updateProject: action,
            saveProjectName: action,
            addScene: action,
            removeScene: action,
            changeSceneName: action,
            addLayer: action,
            removeLayer: action,
            renameLayer: action,
            swapLayers: action,
            swapScenes: action,
            setIndexForScenes: action,
            setIndexForLayers: action,
            selectLayer: action,
            selectScene: action,
            changeLayerData: action,
            openImageDrawer: action,
            closeImageDrawer: action,
            openCameraDrawer: action,
            closeCameraDrawer: action,
            openUploadImageModal: action,
            closeUploadImageModal: action,
            addImage: action,
            setScenePreview: action,
            openMicroDrawer: action,
            closeMicroDrawer: action,
            addAudioSource: action,
            removeAudioSource: action,
            toggleAudioSource: action,
            changeSourceDevice: action,
            setCanvas: action,
            openSlideShowDrawer: action,
            closeSlideShowDrawer: action,
            openSlideShowFormDrawer: action,
            closeSlideShowFormDrawer: action,
        });
    }

    openSlideShowDrawer = () => {
        this.isSlideShowDrawerOpen = true;
    };

    closeSlideShowDrawer = () => {
        this.isSlideShowDrawerOpen = false;
    };

    openSlideShowFormDrawer = () => {
        this.isSlideShowFormOpen = true;
    };

    closeSlideShowFormDrawer = () => {
        this.isSlideShowFormOpen = false;
    };

    setCanvasSize = () => {
        const lowerCanvas = document.querySelector('.custom-browser-canvas__canvas.lower-canvas');
        const upperCanvas = document.querySelector('.custom-browser-canvas__canvas.upper-canvas');

        if (lowerCanvas) {
            lowerCanvas.setAttribute('width', '1280');
            lowerCanvas.setAttribute('height', '720');
        }

        if (upperCanvas) {
            upperCanvas.setAttribute('width', '1280');
            upperCanvas.setAttribute('height', '720');
        }
    };

    setCanvas = (canvas: HTMLCanvasElement) => {
        this.canvas = new fabric.StaticCanvas(canvas, {
            width: 1280,
            height: 720,
            backgroundColor: DEFAULT_ADVANCE_CANVAS_BG_COLOR,
            selection: false,
            interactive: false,
            controlsAboveOverlay: false
        });

        this.setCanvasSize();
    };

    changeSourceDevice = (audioSource: IAudioSource, device: MediaDeviceInfo) => {
        audioSource.deviceInfo = device;
        audioSource.deviceId = device.deviceId;
        audioSource.name = device.label || `Source ${this.selectedProject?.audioSources.length || 1}`;
        this.audioSourceUpdateId = v4();
    };

    toggleAudioSource = (audioSource: IAudioSource) => {
        audioSource.muted = !audioSource.muted;
        this.audioSourceUpdateId = v4();

        if (audioSource.stream) {
            audioSource.stream.getAudioTracks()[0].enabled = !audioSource.muted;
        }
    };

    addAudioSource = (audioSource: IAudioSource) => {
        if (this.selectedProject.audioSources.every(({ deviceId }) => deviceId !== audioSource.deviceId)) {
            this.selectedProject.audioSources.push(audioSource);
            this.audioSourceUpdateId = v4();
        }
    };

    removeAudioSource = (id: string) => {
        const audioSource = this.selectedProject.audioSources.find((source: IAudioSource) => source.id !== id);

        if (audioSource.stream) {
            audioSource.stream.getTracks().map((track) => track.stop());
        }

        if (this.audioContext && audioSource.mediaStreamAudioSourceNode) {
            if (audioSource.stream) {
                audioSource.mediaStreamAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
            }
        }

        this.selectedProject.audioSources = this.selectedProject.audioSources.filter((source: IAudioSource) => {
            return source.id !== id;
        });
        this.audioSourceUpdateId = v4();
    };

    addImage = (img: IUserImage) => {
        this.user?.images?.push(img);
    };

    openUploadImageModal = () => {
        this.isUploadImageModalOpen = true;
    };

    closeUploadImageModal = () => {
        this.isUploadImageModalOpen = false;
    };

    openMicroDrawer = () => {
        this.isMicroDrawerOpen = true;
    };

    closeMicroDrawer = () => {
        this.isMicroDrawerOpen = false;
    };

    openCameraDrawer = () => {
        this.isCameraDrawerOpen = true;
    };

    closeCameraDrawer = () => {
        this.isCameraDrawerOpen = false;
    };

    openImageDrawer = () => {
        this.isImageDrawerOpen = true;
    };

    closeImageDrawer = () => {
        this.isImageDrawerOpen = false;
    };

    setScenePreview = (scene: IScene): Promise<null> => {
        return new Promise((res, rej) => {
            html2canvas(document.querySelector('.advanced-canvas-wrapper__scene'))
                .then((canvas) => {
                    runInAction(() => {
                        scene.previewImage = canvas.toDataURL('image/png');
                    });
                    res(null);
                })
                .catch((e) => {
                    rej();
                    console.error(e);
                });
        });
    };

    setIndexForLayers = (scene: IScene) => {
        scene.layers.forEach((layer: ILayer, index: number) => {
            layer.index = index;
        });
        scene.layers.slice().reverse().forEach((layer: ILayer, index: number) => {
            layer?.node?.moveTo(index);
        });

        this.layerUpdateId = v4();

        if (scene.layers.length > 1) {
            this.updateLayersIndexes(scene);
        }
    };

    updateLayersIndexes = async (scene: IScene) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.post(`${ApiRoutes.Layers}/indexes`, {
                    indexes: scene.layers.map(({ id, index }) => ({ id, index })),
                }, getAxiosHeaders());
            }
        } catch (error) {
            console.error(error);
        }
    };

    setIndexForScenes = () => {
        this.selectedProject.scenes.forEach((scene: IScene, index: number) => {
            scene.index = index;
        });

        this.sceneUpdateId = v4();
        this.updateScenesIndexes();
    };

    updateScenesIndexes = async () => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.post(`${ApiRoutes.Scenes}/indexes`, {
                    indexes: this.selectedProject.scenes.map(({ id, index }) => ({ id, index })),
                }, getAxiosHeaders());
            }
        } catch (error) {
            console.error(error);
        }
    };

    resizeNodeHelper = (node: fabric.Image) => {
        try {
            const { videoHeight, videoWidth } = node.getElement() as HTMLVideoElement;

            if (videoWidth && videoHeight) {
                if (videoWidth > videoHeight) {
                    node.height = videoHeight;
                    node.scaleToWidth(1280);
                } else {
                    node.width = videoWidth;
                    node.scaleToHeight(720);
                }

                node.center();
                node.setCoords();
            }
        } catch (e) {
            console.error(e);
        }
    };

    resizeNodeRegardingVideo = (node: fabric.Image) => {
        this.resizeNodeHelper(node);

        node.getElement().addEventListener('resize', () => {
            this.resizeNodeHelper(node);
        });
    };

    getLayerNodeForCanvas = async (layer: ILayer): Promise<any> => {
        try {
            if (layer.type === LayerTypeEnum.Text) {
                return new fabric.Text(
                    layer.specificData?.text.replace(/<div>/g, `\n`).replace(/<\/div>/g, ''),
                    {
                        fontFamily: layer.specificData?.textSettings?.fontName || 'Arial, sans-serif',
                        fontSize:  parseInt(layer.specificData?.textSettings?.fontSize || 0) || 24,
                        fill: layer.specificData?.textSettings?.color || '#FFFFFF',
                        width: layer.size?.width || 320,
                        height: layer.size?.height || 40,
                        top: layer.position?.y || 0,
                        left: layer.position?.x || 0,
                    }
                );
            } else if (layer.type === LayerTypeEnum.Image) {
                return new Promise((res, rej) => {
                    fabric.Image.fromURL(getImageSrc(layer.specificData?.img?.src), (node) => {
                        node.set('top', layer.position?.y || 0);
                        node.set('left', layer.position?.x || 0);

                        res(node);
                    });
                });
            } else if (layer.type === LayerTypeEnum.Camera || layer.type === LayerTypeEnum.BrowserWindow) {
                const video = document.createElement('video');
                video.muted = true;
                video.width = 1280;
                video.height = 720;
                video.style.backgroundColor = '#000';

                if (layer?.specificData?.stream) {
                    video.srcObject = layer?.specificData?.stream;
                } else if (layer?.specificData?.device) {
                    const stream = await navigator.mediaDevices.getUserMedia({
                        audio: false,
                        video: {
                            deviceId: layer?.specificData?.device?.deviceId,
                        }
                    });

                    video.srcObject = stream;
                }

                const node = new fabric.Image(video, {
                    objectCaching: false,
                    selectable: false,
                    evented: false,
                    width: layer.size?.width || 0,
                    height: layer.size?.height || 0,
                    top: layer.position?.y || 0,
                    left: layer.position?.x || 0,
                });

                (node.getElement() as HTMLVideoElement).play();
                this.resizeNodeRegardingVideo(node);

                return node;
            }

            return null;
        } catch (e) {
            console.error(e);
        }

        return null;
    };

    addLayer = async (scene: IScene, layer: ILayer) => {
        try {
            const node = await this.getLayerNodeForCanvas(layer);

            runInAction(() => {
                scene.layers.unshift({
                    ...layer,
                    node,
                });
                this.canvas.add(node);
                this.setIndexForLayers(scene);
                this.layerUpdateId = v4();

                if (this.isSavableLayer(layer)) {
                    this.createLayer(scene, this.selectedScene.layers.find(({id}) => id === layer.id));
                }
            });
        } catch (e) {
            console.error(e);
        }
    };

    createLayer = async (scene: IScene, layer: ILayer) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.post(`${ApiRoutes.Layers}`, {
                    name: layer.name,
                    id: layer.id,
                    index: layer.index,
                    sceneId: scene.id,
                    type: layer.type,
                    size: JSON.stringify(layer.size || {}),
                    position: JSON.stringify(layer.position || {}),
                    specificData: JSON.stringify(layer.specificData || {}),
                    projectId: this.selectedProject?.id,
                }, getAxiosHeaders());
            }
        } catch (error) {
            console.error(error);
        }
    };

    removeLayer = async (scene: IScene, layerId: string) => {
        try {
            runInAction(() => {
                const layer = scene.layers.find(({id}) => id === layerId);

                if (layer && layer.node) {
                    this.canvas.remove(layer.node);
                }

                scene.layers = scene.layers.filter(({id}) => id !== layerId);
                this.setIndexForLayers(scene);
                this.layerUpdateId = v4();

                if (this.isSavableLayer(layer)) {
                    this.deleteLayer(layerId);
                }

            });
        } catch (e) {
            console.error(e);
        }
    };

    deleteLayer = async (layerId: string) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.delete(`${ApiRoutes.Layers}/${layerId}`, getAxiosHeaders());
            }
        } catch (error) {
            console.error(error);
        }
    };

    renameLayer = async (layer: ILayer, name: string) => {
        try {
            runInAction(() => {
                layer.name = name;
                this.layerUpdateId = v4();

                if (this.isSavableLayer(layer)) {
                    this.saveLayerName(layer, name);
                }
            });
        } catch (e) {
            console.error(e);
        }
    };

    saveLayerName = debounce(async (layer: ILayer, name: string) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.put(`${ApiRoutes.Layers}/${layer.id}`, {
                    name,
                }, getAxiosHeaders());
            }
        } catch (error) {
            console.error(error);
        }
    }, 500);

    selectLayer = async (layer: ILayer) => {
        try {
            runInAction(() => {
                this.setIndexForLayers(this.selectedScene);
                this.selectedLayer = layer;
                this.layerUpdateId = v4();

                if (layer?.node) {
                    layer.node.bringToFront();
                }
            });
        } catch (e) {
            console.error(e);
        }
    };

    isSavableLayer = (layer: ILayer): boolean => {
        return (
          layer.type === LayerTypeEnum.Image ||
          layer.type === LayerTypeEnum.Text ||
          layer.type === LayerTypeEnum.SlideShow
        );
    };

    changeLayerData = async (layer: ILayer, data: any) => {
        try {
            runInAction(() => {
                layer.specificData = data;
                this.layerUpdateId = v4();
                this.sceneUpdateId = v4();

                if (this.isSavableLayer(layer)) {
                    this.saveLayerData(layer, data);
                }

            });
        } catch (e) {
            console.error(e);
        }
    };

    saveLayerData = debounce(async (layer: ILayer, data: any) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.put(`${ApiRoutes.Layers}/${layer.id}`, {
                    specificData: JSON.stringify(data || {}),
                }, getAxiosHeaders());
            }
        } catch (error) {
            console.error(error);
        }
    }, 500);

    saveLayerSize = debounce(async (layer: ILayer, size: { width: number; height: number; }) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.put(`${ApiRoutes.Layers}/${layer.id}`, {
                    size: JSON.stringify(size || {}),
                }, getAxiosHeaders());
            }
        } catch (error) {
            console.error(error);
        }
    }, 500);

    saveLayerPosition = debounce(async (layer: ILayer, position: { x: number; y: number; }) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.put(`${ApiRoutes.Layers}/${layer.id}`, {
                    position: JSON.stringify(position || {}),
                }, getAxiosHeaders());
            }
        } catch (error) {
            console.error(error);
        }
    }, 500);

    selectScene = async (scene: IScene) => {
        try {
            await this.setScenePreview(this.selectedScene);

            axiosInstance.put(`${ApiRoutes.Projects}/${this.selectedProject.id}`, {
                lastSelectedScene: scene.id,
            }, getAxiosHeaders());

            runInAction(() => {
                this.selectedScene.layers.forEach(({ node }) => {
                    if (node) {
                        node.visible = false;
                    }
                });

                this.selectedScene = scene;
                this.user?.projects.forEach((project: IProject) => {
                    if (project?.scenes.some(({ id }) => id === scene.id)) {
                        project.lastSelectedScene = scene.id;
                    }
                });
                this.selectedScene.layers.forEach(({ node }) => {
                    if (node) {
                        node.visible = true;
                    }
                });
                this.layerUpdateId = v4();
                this.sceneUpdateId = v4();
            });
        } catch (e) {
            console.error(e);
        }
    };

    swapLayers = async (scene: IScene, startIndex: number, endIndex: number) => {
        try {
            runInAction(() => {
                const [removed] = scene.layers.splice(startIndex, 1);
                scene.layers.splice(endIndex, 0, removed);
                this.setIndexForLayers(scene);
                this.layerUpdateId = v4();
            });
        } catch (e) {
            console.error(e);
        }
    };

    swapScenes = async (startIndex: number, endIndex: number) => {
        try {
            runInAction(() => {
                const [removed] = this.selectedProject.scenes.splice(startIndex, 1);
                this.selectedProject.scenes.splice(endIndex, 0, removed);
                this.setIndexForScenes();
                this.sceneUpdateId = v4();
            });
        } catch (e) {
            console.error(e);
        }
    };

    addScene = async (scene: IScene) => {
        try {
            runInAction(() => {
                this.selectedProject.scenes.push(scene);
                this.sceneUpdateId = v4();
                this.createScene(scene);
            });
        } catch (e) {
            console.error(e);
        }
    };

    createScene = async (scene: IScene) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.post(`${ApiRoutes.Scenes}`, {
                    ...scene,
                    projectId: this.selectedProject?.id,
                }, getAxiosHeaders());
            }
        } catch (error) {
            console.error(error);
        }
    };

    removeScene = async (sceneId: string) => {
        try {
            runInAction(() => {
                const scene = this.selectedProject.scenes.find(({id}) => id === sceneId);

                if (scene) {
                    scene?.layers?.forEach(({ node }) => {
                        if (node) {
                            this.canvas.remove(node);
                        }
                    });
                }

                this.selectedProject.scenes = this.selectedProject.scenes.filter(({id}) => id !== sceneId);

                if (this.selectedScene.id === sceneId) {
                    this.selectScene(this.selectedProject.scenes[0]);
                }

                this.setIndexForScenes();
                this.sceneUpdateId = v4();
                this.deleteScene(sceneId);
            });
        } catch (e) {
            console.error(e);
        }
    };

    deleteScene = async (sceneId: string) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.delete(`${ApiRoutes.Scenes}/${sceneId}`, getAxiosHeaders());
            }
        } catch (error) {
            console.error(error);
        }
    };

    changeSceneName = async (scene: IScene, name: string) => {
        try {
            runInAction(() => {
                scene.name = name;
                this.sceneUpdateId = v4();
                this.saveSceneName(scene, name);
            });
        } catch (e) {
            console.error(e);
        }
    };

    saveSceneName = debounce(async (scene: IScene, name: string) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.put(`${ApiRoutes.Scenes}/${scene.id}`, {
                    name,
                }, getAxiosHeaders());
            }
        } catch (error) {
            console.error(error);
        }
    }, 500);

    init = async () => {
        // todo

        if (!this.user) {
            this.user = {
                name: '',
                destinations: [],
                projects: [],
                images: [],
                lastSelectedProject: 1,
                id: null,
                locale: Locales.English,
                role: 'User',
                email: '',
            };
        }

        const { lastSelectedProject, projects, destinations } = this.user;
        const project = projects.find(({ id }) => id === lastSelectedProject);

        if (project) {
            const scene = {
                id: v4(),
                index: 0,
                node: null,
                name: 'Scene 1',
                layers: [],
            };
            const isHaveScenes = project.scenes && project.scenes.length > 0;
            project.audioSources = project.audioSources || [];
            project.scenes = isHaveScenes ? project.scenes : [scene];
            project.lastSelectedScene = isHaveScenes ? project.lastSelectedScene : scene.id;

            this.selectedProject = project;
            this.selectedScene = this.selectedProject.scenes.find(({ id }) => {
                return id === this.selectedProject.lastSelectedScene;
            });

            projects.forEach(({ scenes }) => {
                scenes.forEach(({ id, layers }) => {
                    layers.forEach(async (layer) => {
                        const node = await this.getLayerNodeForCanvas(layer);
                        node.visible = id === this.selectedScene.id;
                        this.canvas.add(node);
                    });
                });
            });

            this.sceneUpdateId = v4();
            this.changeStreamName(project.name, true);

            this.setInitialDestinations(destinations.map((dest: IUserDestination): IStreamDestination => {
                const res: IStreamDestination = ({ ...dest } as IStreamDestination);

                return res;
            }));
        }

        if (this.rootStore.userMediaStore.selectedMicro && this.rootStore.userMediaStore.selectedCamera) {
            await this.addCamera(true);

            if (this.cameraStream && this.cameraStream.getVideoTracks() && this.cameraStream.getVideoTracks().length) {
                this.cameraStream.getVideoTracks()[0].enabled = this.rootStore.userMediaStore.isCameraEnabled;
                this.isVideoMuted = !this.rootStore.userMediaStore.isCameraEnabled;
            }

            if (this.cameraStream && this.cameraStream.getAudioTracks() && this.cameraStream.getAudioTracks().length) {
                this.cameraStream.getAudioTracks()[0].enabled = this.rootStore.userMediaStore.isMicroEnabled;
                this.isMicroMuted = !this.rootStore.userMediaStore.isMicroEnabled;
            }

            this.rootStore.streamViewStore.addUser({
                id: this.rootStore.usersConferenceStore.currentUserId,
                name: this.user?.name,
                streamType: StreamTypeEnum.Camera,
                position: 0,
                isEnabled: true,
            });
        }
    };

    selectProject = async (project: IProject) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.put(`${ApiRoutes.User}/${this.user.id}`, {
                    lastSelectedProject: project.id,
                }, getAxiosHeaders());

                runInAction(() => {
                    const scene = {
                        id: v4(),
                        layers: [],
                        name: 'Scene 1',
                        node: null,
                        index: 0,
                    };
                    const isHaveScenes = project.scenes && project.scenes.length > 0;
                    project.audioSources = project.audioSources || [];
                    project.scenes = isHaveScenes ? project.scenes : [scene];
                    project.lastSelectedScene = isHaveScenes ? project.lastSelectedScene : scene.id;

                    if (this.selectedProject) {
                        this.selectedProject.scenes.forEach(({ layers }) => {
                            layers.forEach(({ node }) => {
                                if (node) {
                                    node.visible = false;
                                }
                            });
                        });
                    }

                    this.selectedProject = project;
                    this.selectedScene = this.selectedProject.scenes.find(({ id }) => {
                        return id === this.selectedProject.lastSelectedScene;
                    });

                    if (this.selectedScene && this.selectedScene.layers) {
                        this.selectedScene.layers.forEach(({ node }) => {
                            if (node) {
                                node.visible = true;
                            }
                        });
                    }

                    this.sceneUpdateId = v4();
                    this.streamName = this.selectedProject.name;
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    deleteProject = async (id: number) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.delete(`${ApiRoutes.Projects}/${id}`, getAxiosHeaders());
                runInAction(() => {
                    this.user.projects = this.user.projects.filter((project: IProject) => project.id !== id);
                });

                if (id === this.selectedProject?.id) {
                    this.selectProject(this.user.projects.find(({ isInitial }) => isInitial));
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    setSelectedToEditProject = (project: IProject | null) => {
        this.selectedToEditProject = project;
    };

    removeImage = async (img: IUserImage) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.delete(`${ApiRoutes.Images}/${img.id}`, getAxiosHeaders());
            }
        } catch (error) {
            console.error(error);
        }
    };

    createProject = async (project: IProject) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                const {data} = await axiosInstance.post(`${ApiRoutes.Projects}`, {
                    ...project,
                    userId: this.user?.id,
                }, getAxiosHeaders());
                this.user.projects.push({
                    ...data
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    updateProject = async (project: IProject) => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                await axiosInstance.put(`${ApiRoutes.Projects}/${project.id}`, {
                    name: project.name,
                    description: project.description,
                    avatar: project.avatar,
                    avatarId: project.avatarId,
                }, getAxiosHeaders());
                const updatedProjectPosition = this.user.projects.findIndex(({id}) => id === project.id);

                runInAction(() => {
                    const newProject = {
                        ...this.user.projects[updatedProjectPosition],
                        ...project,
                    };
                    this.user.projects[updatedProjectPosition] = newProject;
                    this.selectProject(newProject);
                });
            }

        } catch (error) {
            console.error(error);
        }
    };

    showSignUpModal = () => {
        Modal.confirm({
            className: 'delete-project-confirm-modal confirm-action-custom',
            content: "For use this functionality you should first Sign In",
            title: (
                <div className="custom-confirm-popup-header">
                    <div className="custom-confirm-popup-title-text">
                        Please Sign In
                    </div>
                </div>
            ),
            okText: `Sign In`,
            cancelText: 'Cancel',
            okButtonProps: {
                className: 'purple-button'
            },
            cancelButtonProps: {
                className: 'secondary-button'
            },
            onCancel: () => {
                Modal.destroyAll();
            },
            onOk: () => {
                Modal.destroyAll();
                setTimeout(() => {
                    this.dependencies?.history?.push(Routes.Login);
                });
            },
            closeIcon: <CloseIcon />,
            closable: true,
            width: 400,
        });
    };

    openProjectCreateModal = () => {
        if (!this.rootStore.appStore.isUserLoggedIn) {
            this.showSignUpModal();
        } else {
            this.isProjectCreateModalOpen = true;
        }
    };

    closeProjectCreateModal = () => {
        this.isProjectCreateModalOpen = false;
        this.selectedToEditProject = null;
    };

    toggleScreenShare = async () => {
        this.isScreenShareEnabled = !this.isScreenShareEnabled;

        if (
            this.audioContext &&
            this.mediaStreamAudioDestination &&
            this.screenShareStream &&
            this.screenShareStream.getAudioTracks()?.length
        ) {
            if (!this.mediaStreamScreenShareAudioSourceNode) {
                this.mediaStreamScreenShareAudioSourceNode = this.audioContext.createMediaStreamSource(this.screenShareStream);
            }

            if (this.isScreenShareEnabled) {
                this.mediaStreamScreenShareAudioSourceNode.connect(this.mediaStreamAudioDestination);
            } else {
                this.mediaStreamScreenShareAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
            }
        }
    };

    setInitialDestinations = (destinations: IStreamDestination[]) => {
        try {
            if (destinations && destinations.length) {
                destinations.forEach((destination) => {
                    const { icon, colorIcon } = DESTINATIONS_ICONS_MAP.find(({ id }) => {
                        return id === destination.iconsId;
                    });

                    this.pushDestination({
                        ...destination,
                        icon,
                        colorIcon,
                    });
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    changeStreamName = (name: string, isInit: boolean = false) => {
        this.streamName = name;

        if (this.selectedProject) {
            this.selectedProject.name = name;
        }

        if (!isInit) {
            this.saveProjectName();
        }
    };

    saveProjectName = debounce(async () => {
        try {
            if (this.rootStore.appStore.isUserLoggedIn) {
                if (this.selectedProject) {
                    await axiosInstance.put(`${ApiRoutes.Projects}/${this.selectedProject.id}`, {
                        name: this.streamName,
                    }, getAxiosHeaders());
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, 500);

    toggleAdmin = () => {
        this.isAdminEnabled = !this.isAdminEnabled;
        const userId = this.rootStore.usersConferenceStore.currentUserId;

        if (this.audioContext && this.mediaStreamAudioDestination && this.mediaStreamAudioSourceNode) {
            if (this.isAdminEnabled) {
                this.mediaStreamAudioDestination?.stream?.getAudioTracks().forEach((track) => {
                    track.enabled = true;
                });
            } else {
                this.mediaStreamAudioDestination?.stream?.getAudioTracks().forEach((track) => {
                    track.enabled = false;
                });
            }
        }
    };

    openDestinationsDrawer = () => {
        if (!this.rootStore.appStore.isUserLoggedIn) {
            this.showSignUpModal();
        } else {
            this.isDestinationsDrawerOpen = true;
        }
    };

    closeDestinationsDrawer = () => {
        this.isDestinationsDrawerOpen = false;
    };

    selectDestination = (destination: IStreamDestination) => {
        this.selectedDestination = destination;
    };

    pushDestination = (destination: IStreamDestination) => {
        this.destinations.push(destination);
    };

    addDestination = (
        id: number,
        iconsId: number,
        name: string,
        url: string,
        streamKey: string,
    ) => {
        try {
            const destination = this.destinations.find((dest) => {
                return dest.id === id;
            });

            if (destination) {
                destination.name = name;
                destination.url = url;
                destination.streamKey = streamKey;

                setTimeout(() => {
                    this.rootStore.usersConferenceStore.updateDestinationDestination(destination.id as number, name, url, streamKey);
                }, 1000);

            } else {
                if (this.destinations.length >= 4) {
                    showWarnMessage(`You can't add more destinations, exceed limit`);
                } else {
                    this.rootStore.usersConferenceStore.addDestination(name, url, streamKey, iconsId);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    toggleDestinationActive = (destination: IStreamDestination, active: boolean) => {
        destination.active = active;

        if (destination.active) {
            this.rootStore.usersConferenceStore.onDestination(
                destination.id,
                destination.url,
                destination.streamKey,
            );
        } else {
            this.rootStore.usersConferenceStore.offDestination(destination.id);
        }
    };

    removeDestination = (id: string | number) => {
        this.destinations = this.destinations.filter((destination) => {
            return destination.id !== id;
        });

        this.rootStore.usersConferenceStore.removeDestination(id);
    };

    openDestinationsModal = () => {
        this.isDestinationsModalShown = true;
    };

    closeDestinationsModal = () => {
        this.isDestinationsModalShown = false;
    };

    setCurrentCamera = (camera: string) => {
        this.currentCamera = camera;
        localStorage.setItem(STORAGE_CAMERA_LABEL, camera);
    };

    setCurrentMicro = (micro: string) => {
        this.currentMicro = micro;
        localStorage.setItem(STORAGE_MICRO_LABEL, micro);
    };

    openSettingsModal = () => {
        this.isSettingsModalShown = true;
    };

    closeSettingsModal = () => {
        this.isSettingsModalShown = false;
    };

    toggleScreenShareSound = () => {
        const screenShareTracks = this.screenShareStream?.getAudioTracks();
        this.isScreenShareMuted = !this.isScreenShareMuted;

        if (screenShareTracks && screenShareTracks.length) {
            screenShareTracks[0].enabled = !this.isScreenShareMuted;
        }
    };

    toggleMicros = (enabled: boolean) => {
        const cameraTracks = this.cameraStream?.getAudioTracks();
        const screenShareTracks = this.screenShareStream?.getAudioTracks();

        this.isMicroMuted = !enabled;
        this.isScreenShareMuted = this.isMicroMuted;

        if (cameraTracks && cameraTracks.length) {
            cameraTracks[0].enabled = enabled;
        }

        if (screenShareTracks && screenShareTracks.length) {
            screenShareTracks[0].enabled = enabled;
        }
    };

    toggleVideo = () => {
        const cameraTracks = this.cameraStream?.getVideoTracks();
        const screenShareTracks = this.screenShareStream?.getVideoTracks();

        if (cameraTracks && cameraTracks.length) {
            this.isVideoMuted = cameraTracks[0].enabled;
            cameraTracks[0].enabled = !(cameraTracks[0].enabled);
        }

        if (screenShareTracks && screenShareTracks.length) {
            this.isVideoMuted = screenShareTracks[0].enabled;
            screenShareTracks[0].enabled = !(screenShareTracks[0].enabled);
        }
    };

    toggleAudio = () => {
        this.isAudioMuted = !this.isAudioMuted;
    };

    addUser = (id: string) => {
        if (this.usersOrder.every((order) => order.id !== id)) {
            this.usersOrder.push({ id, isEnabled: false });
        }
    };

    removeUser = (id: string) => {
        this.usersOrder = this.usersOrder.filter((user) => {
            return user.id !== id;
        });

        this.selectedProject.scenes.forEach((scene: IScene) => {
            scene.layers.forEach((layer: ILayer) => {
                if (layer.type === LayerTypeEnum.BrowserWindow && layer.specificData?.userId === id) {
                    this.removeLayer(scene, layer.id);
                }
            });
        });

        if (this.audioContext && this.mediaStreamAudioDestination) {
            const streamObj = this.rootStore.usersConferenceStore.streams[id];

            if (streamObj && streamObj.mediaStreamAudioSourceNode) {
                streamObj.mediaStreamAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
            }
        }
    };

    toggleUser = (id: string) => {
        const user = this.usersOrder.find((user) => {
            return user.id === id;
        });

        if (user) {
            user.isEnabled = !user.isEnabled;

            if (user.isEnabled) {
                this.rootStore.streamViewStore.enableUser(id);
            } else {
                this.rootStore.streamViewStore.disableUser(id);
            }

            if (this.audioContext && this.mediaStreamAudioDestination) {
                const streamObj = this.rootStore.usersConferenceStore.streams[id];

                if (streamObj && streamObj.mediaStreamAudioSourceNode) {
                    if (user.isEnabled) {
                        streamObj.mediaStreamAudioSourceNode.connect(this.mediaStreamAudioDestination);
                    } else {
                        streamObj.mediaStreamAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
                    }
                }
            }
        }
    };

    stopScreenShare = () => {
        this.isScreenShare = false;
        this.isScreenShareEnabled = false;
        this.isScreenShareMuted = false;

        if (this.screenShareStream) {
            this.screenShareStream.getTracks().map((track) => {
                track.stop();
            });
            this.screenShareStream = null;
        }

        if (this.mediaStreamScreenShareAudioSourceNode && this.mediaStreamAudioDestination) {
            this.mediaStreamScreenShareAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
            this.mediaStreamScreenShareAudioSourceNode = undefined;
        }

        this.rootStore.usersConferenceStore.removeScreenShare();
        this.currentSSVideoMediaTrackConstraints = { ...FULL_SCREEN_CONSTRAINTS };
    };

    addCamera = async (isInit: boolean = false) => {
        const constraint: MediaStreamConstraints = {
            audio: {
                deviceId: this.currentMicro || this.rootStore.userMediaStore.selectedMicro.deviceId || 'default',
            },
            video: {
                ...getCameraConstraint(),
                deviceId: this.currentCamera || this.rootStore.userMediaStore.selectedCamera.deviceId || 'default',
            }
        };

        if (!isFirefox()) {
            (constraint.video as MediaTrackConstraints).frameRate = {
                max: 25,
            };
        }

        if (this.cameraStream) {
            this.cameraStream.getTracks().forEach((track) => {
                track.stop();
            });
        }

        const stream = await navigator.mediaDevices.getUserMedia(constraint);

        this.cameraStream = stream;

        if (
          this.rootStore.userMediaStore.selectedCamera &&
          this.rootStore.userMediaStore.selectedCamera.label?.toLowerCase().includes('splitcam')
        ) {
            this.updateLocalStreamForConference(false);
        } else {
            this.updateLocalStreamForConference(isInit);
        }

        this.updateStreamUserAudio();
        this.applyEnabled();
        this.rootStore.usersConferenceStore.changeTrack(stream.getVideoTracks()[0]);
        this.rootStore.usersConferenceStore.changeTrack(stream.getAudioTracks()[0]);
    };

    updateLocalStreamForConference = (withApplyConstraints: boolean = true) => {
        if (
            this.cameraStream &&
            this.cameraStream.getTracks() &&
            this.cameraStream.getTracks().length
        ) {
            this.rootStore.usersConferenceStore.localStream = new MediaStream([
                ...this.cameraStream.getTracks(),
            ]);

            if (withApplyConstraints) {
                this.rootStore.usersConferenceStore.localStream.getVideoTracks().forEach((track) => {
                    track.applyConstraints(this.currentVideoMediaTrackConstraints);
                });
            }
        }
    };

    updateCamera = async (camera: string) => {
        try {
            if (camera !== this.currentCamera) {
                this.currentCamera = camera;
                await this.addCamera();
            }
        } catch (error) {
            console.error(error);
        }
    };

    updateMicro = async (micro: string) => {
        try {
            if (micro !== this.currentMicro) {
                this.currentMicro = micro;

                const constraint: MediaStreamConstraints = {
                    audio: {
                        deviceId: this.currentMicro || 'default',
                    },
                    video: {
                        ...getCameraConstraint(),
                        deviceId: this.currentCamera || this.rootStore.userMediaStore.selectedCamera.deviceId || 'default',
                    }
                };

                if (!isFirefox()) {
                    (constraint.video as MediaTrackConstraints).frameRate = {
                        max: 25,
                    };
                }

                const stream = await navigator.mediaDevices.getUserMedia(constraint);

                this.applyEnabled();
                this.rootStore.usersConferenceStore.changeTrack(stream.getVideoTracks()[0]);
                this.rootStore.usersConferenceStore.changeTrack(stream.getAudioTracks()[0]);
                this.cameraStream = stream;

                this.updateLocalStreamForConference();
                this.updateStreamUserAudio();
            }
        } catch (error) {
            console.error(error)
        }
    };

    addScreenShare = async () => {
        try {
            this.stopScreenShare();

            const constraint: MediaStreamConstraints = {
                video: {
                    // @ts-ignore
                    cursor: 'always',
                },
                audio: true,
            };

            if (!isFirefox()) {
                (constraint.video as MediaTrackConstraints).frameRate = {
                    max: 25,
                };
            }

            // @ts-ignore
            const stream: MediaStream = await navigator.mediaDevices.getDisplayMedia(constraint);

            this.screenShareStream = stream;

            if (stream.getVideoTracks() && stream.getVideoTracks().length) {
                const track = stream.getVideoTracks()[0];
                let w = 1280;
                let h = 720;

                try {
                    const { width, height } = track.getCapabilities();
                    w = width.max;
                    h = height.max;
                } catch (e) {
                    console.error(e);
                }


                this.defaultSSVideoMediaTrackConstraints = {
                    width: w,
                    height: h,
                };

                track.onended = () => {
                    this.stopScreenShare();
                };
            }

            this.rootStore.usersConferenceStore.addAdminScreenShare(this.screenShareStream);

            // todo add user ss to canvas
        } catch (e) {
            console.error(e);
            showScreenShareErrorMessage(this.dependencies.intl);
        }
    };

    updateStreamUserAudio = () => {
        if (this.audioContext && this.mediaStreamAudioDestination && this.mediaStreamAudioSourceNode) {
            this.mediaStreamAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
            this.mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(this.cameraStream);
            this.mediaStreamAudioSourceNode.connect(this.mediaStreamAudioDestination);
        }
    };

    changeTab = (tab: string) => {
        this.tab = tab as AdvanceModeTabs;
    };

    setStreamStatus = (status: boolean) => {
        this.isStreaming = status;
    };

    setCanvasStream = (stream: MediaStream) => {
        this.canvasStream = stream;
    };

    startStream = async () => {
        if (!this.rootStore.appStore.isUserLoggedIn) {
            this.showSignUpModal();
        } else {
            try {
                const self = this;

                if (this.renderInterval) {
                    clearInterval(this.renderInterval);
                }

                this.renderInterval = setInterval(() => {
                    self.canvas.renderAll();
                }, 40);

                // @ts-ignore
                const canvasCapturedStream: MediaStream = await this.canvas.getElement().captureStream(DEFAULT_FRAME_RATE);
                this.setCanvasStream(canvasCapturedStream);
                this.isStreaming = true;

                const { streams } = this.rootStore.usersConferenceStore;
                const { audioSources } = this.selectedProject;
                const canvasStream = new MediaStream(this.canvasStream.getTracks().map((track) => track.clone()));

                if (AudioContext) {
                    this.audioContext = new AudioContext();
                }

                if (this.audioContext) {
                    this.mediaStreamAudioDestination = this.audioContext.createMediaStreamDestination();
                    this.mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(this.cameraStream);
                    this.mediaStreamAudioSourceNode.connect(this.mediaStreamAudioDestination);
                }

                await Promise.all(Object.keys(streams).map(async (id) => {
                    const streamObj = streams[id];
                    const { stream } = streamObj;

                    if (stream && stream.getAudioTracks() && stream.getAudioTracks().length && this.audioContext) {
                        streamObj.mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(stream);
                        streamObj.mediaStreamAudioSourceNode.connect(this.mediaStreamAudioDestination);
                    }
                }));

                await Promise.all(audioSources.map(async (source: IAudioSource) => {
                    const stream = await navigator.mediaDevices.getUserMedia({
                        audio: {
                            deviceId: source.deviceId,
                        },
                        video: false,
                    });


                    if (stream && stream.getAudioTracks() && stream.getAudioTracks().length && this.audioContext) {
                        source.mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(stream);
                        source.mediaStreamAudioSourceNode.connect(this.mediaStreamAudioDestination);
                        source.stream = stream;
                    }
                }));

                if (
                    this.mediaStreamAudioDestination.stream.getAudioTracks() &&
                    this.mediaStreamAudioDestination.stream.getAudioTracks().length
                ) {
                    canvasStream.addTrack(this.mediaStreamAudioDestination.stream.getAudioTracks()[0]);
                }

                this.rootStore.usersConferenceStore.startStream(canvasStream);
            } catch (e) {
                console.error(e);
            }
        }
    };

    stopStream = () => {
        if (this.renderInterval) {
            clearInterval(this.renderInterval);
        }

        this.isStreaming = false;
        this.rootStore.usersConferenceStore.stopStream();

        if (this.mediaStreamAudioSourceNode) {
            this.mediaStreamAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
        }

        if (this.mediaStreamScreenShareAudioSourceNode) {
            this.mediaStreamScreenShareAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
        }

        Object.keys(this.rootStore.usersConferenceStore.streams).forEach((key) => {
            const streamObj = this.rootStore.usersConferenceStore.streams[key];

            if (streamObj.mediaStreamAudioSourceNode) {
                streamObj.mediaStreamAudioSourceNode.disconnect(this.mediaStreamAudioDestination);
                streamObj.mediaStreamAudioSourceNode = undefined;
            }
        });

        this.mediaStreamAudioSourceNode = undefined;
        this.mediaStreamScreenShareAudioSourceNode = undefined;
        this.audioContext = undefined;
        this.mediaStreamAudioDestination = undefined;
    };

    applyEnabled = () => {
        const cameraVideoTracks = this.cameraStream?.getVideoTracks();
        const cameraAudioTracks = this.cameraStream?.getAudioTracks();
        const screenShareVideoTracks = this.screenShareStream?.getVideoTracks();
        const screenShareAudioTracks = this.screenShareStream?.getAudioTracks();

        if (cameraVideoTracks && cameraVideoTracks.length) {
            cameraVideoTracks[0].enabled = !this.isVideoMuted;
        }

        if (cameraAudioTracks && cameraAudioTracks.length) {
            cameraAudioTracks[0].enabled = !this.isMicroMuted;
        }

        if (screenShareVideoTracks && screenShareVideoTracks.length) {
            screenShareVideoTracks[0].enabled = !this.isVideoMuted;
        }

        if (screenShareAudioTracks && screenShareAudioTracks.length) {
            screenShareAudioTracks[0].enabled = !this.isMicroMuted;
        }
    };
}
