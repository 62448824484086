import React from 'react';

interface IProps {}

export const EditPenIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6667 4.82666C14.6672 4.73892 14.6503 4.65194 14.6172 4.57072C14.584 4.48949 14.5351 4.41562 14.4733 4.35332L11.6467 1.52666C11.5844 1.46487 11.5105 1.41599 11.4293 1.38281C11.348 1.34963 11.2611 1.33282 11.1733 1.33332C11.0856 1.33282 10.9986 1.34963 10.9174 1.38281C10.8362 1.41599 10.7623 1.46487 10.7 1.52666L8.81332 3.41332L1.52666 10.7C1.46487 10.7623 1.41599 10.8362 1.38281 10.9174C1.34963 10.9986 1.33282 11.0856 1.33332 11.1733V14C1.33332 14.1768 1.40356 14.3464 1.52859 14.4714C1.65361 14.5964 1.82318 14.6667 1.99999 14.6667H4.82666C4.91994 14.6717 5.01325 14.6571 5.10054 14.6238C5.18782 14.5905 5.26713 14.5393 5.33332 14.4733L12.58 7.18666L14.4733 5.33332C14.5342 5.26871 14.5838 5.19435 14.62 5.11332C14.6264 5.06018 14.6264 5.00646 14.62 4.95332C14.6231 4.92229 14.6231 4.89102 14.62 4.85999L14.6667 4.82666ZM4.55332 13.3333H2.66666V11.4467L9.28666 4.82666L11.1733 6.71332L4.55332 13.3333ZM12.1133 5.77332L10.2267 3.88666L11.1733 2.94666L13.0533 4.82666L12.1133 5.77332Z" fill="#748499"/>
        </svg>
    );
};