import React from 'react';
import { observer, inject } from 'mobx-react';
import { Input, Modal } from 'antd';
import { v4 } from 'uuid';
import { PurpleButton, UserItem } from '@components';
import { getLayerNameByType, showInfoMessage } from '@utils';
import { AddGuestIcon, AddUserIcon } from '@assets/icons';
import { IStreamViewUser, LayerActionsEnum, LayerTypeEnum, Routes } from '@common-types';
import { UsersConferenceStore, UserMediaStore, AdvanceModeStore, StreamViewStore } from '@services';
import './styles.scss';

interface IProps {
    UsersConferenceStore?: UsersConferenceStore;
    UserMediaStore?: UserMediaStore;
    AdvanceModeStore?: AdvanceModeStore;
    StreamViewStore?: StreamViewStore;
}

@inject('UsersConferenceStore', 'UserMediaStore', 'AdvanceModeStore', 'StreamViewStore')
@observer
export class AdvanceUsers extends React.Component<IProps> {
    selectLink = () => {
        const input: HTMLInputElement = document.querySelector('.copy-link-input');

        if (input) {
            input.select();
        }
    };

    copyLink = () => {
        this.selectLink();
        document.execCommand('copy');
        showInfoMessage('Link copied');
        window.getSelection().removeAllRanges();
        Modal.destroyAll();
    };

    onConferenceStart = async (): Promise<void> => {
        try {
            const { name } = this.props.UserMediaStore!;
            const { roomId, cameraStream } = this.props.AdvanceModeStore;
            const streamHasAudio = this.props.AdvanceModeStore!.cameraStream?.getAudioTracks();
            const stream = new MediaStream([
                cameraStream.getVideoTracks()[0],
                streamHasAudio ? streamHasAudio[0] : undefined,
            ]);

            this.props.UsersConferenceStore!.start(stream, name, roomId, true, () => {});

            const link = `${location.origin}${Routes.SelectMedia}/?roomid=${roomId}`;

            Modal.info({
                className: 'add-user-modal',
                width: 400,
                content: (
                    <div className="add-user-modal__content">
                        <div style={{ width: '100%' }}>
                            <div className="add-user-modal__text">
                                Your Invite Link
                            </div>
                            <div className="add-user-modal__content-input">
                                <Input
                                    className='copy-link-input'
                                    value={link}
                                    readOnly={true}
                                    onFocus={this.selectLink}
                                    style={{ marginRight: '12px' }}
                                />
                            </div>
                        </div>
                        <div className="add-user-modal__content-action">
                            <PurpleButton onClick={this.copyLink}>
                                Copy Invite Link
                            </PurpleButton>
                        </div>
                    </div>
                ),
                title: (
                    <div className="add-user-modal__title">
                        <div className="add-user-modal__title-icon">
                            <AddUserIcon />
                        </div>
                        <div className="add-user-modal__title-text">
                            Invite Friends
                        </div>

                    </div>
                ),
                closable: true,
            });
        } catch (error) {
            console.error(error);
        }
    };

    addUserToCanvas = async (user: IStreamViewUser) => {
        try {
            const { addLayer, selectedScene, selectLayer } = this.props.AdvanceModeStore;
            const { streams } = this.props.UsersConferenceStore!;
            const { stream } = streams[user.id];
            const layer = {
                index: 0,
                name: `${user.name} Camera`,
                node: null,
                type: LayerTypeEnum.BrowserWindow,
                id: v4(),
                specificData: {
                    stream,
                    userId: user.id,
                },
            };

            await addLayer(selectedScene, layer);

            const createdLayer = selectedScene.layers.find(({ id }) => {
                return id === layer.id;
            });

            if (createdLayer) {
                selectLayer(createdLayer);
            }
        } catch (e) {
            console.error(e);
        }
    };

    render(): React.ReactNode {
        const { toggleUser } = this.props.AdvanceModeStore;
        const { state } = this.props.StreamViewStore;
        const { streams, muteUser } = this.props.UsersConferenceStore!;

        return (
            <div className="advance-users">
                <div className="advance-users__list" style={{ width: `${(state.users.length - 1) * 192}px` }}>
                    {
                        state.users.slice(1).map((user: IStreamViewUser) => {
                            const { stream, name, id, isMuted } = streams[user.id];

                            return (
                                <UserItem
                                    soundOff={false}
                                    key={id}
                                    stream={stream}
                                    name={name}
                                    mute={() => muteUser(id)}
                                    isMuted={isMuted}
                                    withMute={true}
                                    signal={100}
                                    isEnabled={user.isEnabled}
                                    withEnabled={true}
                                    toggleUser={() => toggleUser(user.id)}
                                    withAddToCanvas={true}
                                    addToCanvas={() => this.addUserToCanvas(user)}
                                />
                            );
                        })
                    }
                </div>
                <div className="advance-users__add-user" onClick={this.onConferenceStart}>
                    <div className="advance-users__add-icon">
                        <AddGuestIcon />
                    </div>
                    <div className="advance-users__add-text">
                        Add Guest
                    </div>
                </div>
            </div>
        );
    }
}
