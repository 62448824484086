import React from 'react';
import { observable, makeObservable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PurpleButton } from '@components';
import { Routes } from '@common-types';
import { LogoIcon } from '@assets/icons';
import { AppStore } from '@services';
import { isValidEmail } from '@utils';
import bgTop from '../../assets/images/bg-top.png';
import bgBottom from '../../assets/images/bg-bottom.png';
import './style.scss';

interface IProps {
    AppStore?: AppStore;
}

@inject('AppStore')
@observer
export class RegisterPage extends React.Component<IProps> {
    emailError: string = '';
    passwordError: string = '';
    nameError: string = '';

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            emailError: observable,
            passwordError: observable,
            nameError: observable,
            onRegister: action,
            onEmailChange: action,
            onPasswordChange: action,
        })
    }

    onKeyDown = (e) => {
        if (e.code === 'Enter') {
            this.onRegister();
        }
    };

    async componentDidMount() {
        try {
            document.body.addEventListener('keydown', this.onKeyDown);
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

            stream.getTracks().forEach((track) => {
                track.stop();
            });
        } catch (error) {
            console.error(error);
        }
    }

    componentWillUnmount(): void {
        document.body.removeEventListener('keydown', this.onKeyDown);
        this.props.AppStore.clearForm();
    }

    onEmailChange = (e) => {
        this.emailError = '';
        this.props.AppStore!.setEmail(e.target.value);
    };

    onNameChange = (e) => {
        this.nameError = '';
        this.props.AppStore!.setName(e.target.value);
    };

    onPasswordChange = (e) => {
        this.passwordError = '';
        this.props.AppStore!.setPassword(e.target.value)
    };

    onRegister = () => {
        const { email, password, register, name } = this.props.AppStore!;
        let isValid = true;

        if (!email || !isValidEmail(email)) {
            this.emailError = 'Please, input valid email';
            isValid = false;
        }

        if (!password) {
            this.passwordError = 'Please, input password';
            isValid = false;
        }

        if (password && password.length < 8) {
            this.passwordError = 'Password should have length more than 8 symbols';
            isValid = false;
        }

        if (!name) {
            this.nameError = 'Please, input your display name';
            isValid = false;
        }

        if (isValid) {
            register();
        }
    };

    render(): React.ReactNode {
        const { email, password, name } = this.props.AppStore!;

        return (
            <div className="register-page">
                <img className='register-page__bg-top' src={bgTop} alt="background" />
                <img className='register-page__bg-bottom' src={bgBottom} alt="background" />
                <div className="register-page__wrapper">
                    <div className="register-page__logo">
                        <Link to={Routes.Main}>
                            <LogoIcon />
                        </Link>
                    </div>
                    <div className="register-page__form">
                        <div className="register-page__text-wrapper">
                            <div className="register-page__text-header">
                                Create Your Account
                            </div>
                            <div className="register-page__text">
                                Start creating live broadcasts by creating your Go.Live account absolutely for free
                            </div>
                        </div>
                        <div className='register-page__input-wrapper'>
                            <div className='register-page__label'>
                                Your Display Name
                            </div>
                            <div className='register-page__input'>
                                <Input
                                    placeholder={"Name"}
                                    value={name}
                                    onChange={this.onNameChange}
                                />
                            </div>
                        </div>
                        <div className='register-page__input-wrapper'>
                            <div className='register-page__label'>
                                <FormattedMessage id='email' />
                            </div>
                            <div className='register-page__input'>
                                <Input
                                    placeholder={"Email"}
                                    value={email}
                                    type={'email'}
                                    onChange={this.onEmailChange}
                                />
                            </div>
                            {
                                this.emailError && (
                                    <div className="register-page__error">
                                        {this.emailError}
                                    </div>
                                )
                            }
                        </div>
                        <div className='register-page__input-wrapper'>
                            <div className='register-page__label'>
                                <FormattedMessage id='password' />
                            </div>
                            <div className='register-page__input'>
                                <Input
                                    value={password}
                                    type={'password'}
                                    onChange={this.onPasswordChange}
                                />
                            </div>
                            {
                                this.passwordError && (
                                    <div className="register-page__error">
                                        {this.passwordError}
                                    </div>
                                )
                            }
                        </div>
                        <div className="register-page__links">
                            <PurpleButton onClick={this.onRegister}>
                                Create Account
                            </PurpleButton>
                            <div className="register-page__links-footer">
                                Are you already have an account?{' '}
                                <Link to={`${Routes.Login}${location.search}`}>
                                    Sign In
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
