import React from 'react';

interface IProps {}

export const StretchToCanvasIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8334 10H14.1667V12.5H11.6667V14.1667H15.8334V10ZM5.83337 7.5H8.33337V5.83333H4.16671V10H5.83337V7.5ZM17.5 2.5H2.50004C1.58337 2.5 0.833374 3.25 0.833374 4.16667V15.8333C0.833374 16.75 1.58337 17.5 2.50004 17.5H17.5C18.4167 17.5 19.1667 16.75 19.1667 15.8333V4.16667C19.1667 3.25 18.4167 2.5 17.5 2.5ZM17.5 15.8417H2.50004V4.15833H17.5V15.8417Z" fill="#748499"/>
        </svg>
    );
};
