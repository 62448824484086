import React from 'react';

interface IProps {}

export const ChevronLeftIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.29 10.29L5.17 6.41L1.29 2.53C0.899997 2.14 0.899997 1.51 1.29 1.12C1.68 0.730003 2.31 0.730003 2.7 1.12L7.29 5.71C7.68 6.1 7.68 6.73 7.29 7.12L2.7 11.71C2.31 12.1 1.68 12.1 1.29 11.71C0.909998 11.32 0.899998 10.68 1.29 10.29Z" fill="#748499"/>
        </svg>
    );
};
