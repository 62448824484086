import * as React from 'react';
import { message } from 'antd';
import { IntlShape } from 'react-intl';
import { MessageInfoIcon, MessageWarnIcon } from '@assets/icons';

export const showInfoMessage = (content: string): void => {
    message.info({
        content,
        className: 'custom-message-info',
        icon: <MessageInfoIcon />,
    });
};

export const showWarnMessage = (content: string | React.ReactElement): void => {
    message.warn({
        content,
        className: 'custom-message-warn',
        icon: <MessageWarnIcon />,
    });
};

export const stopStream = (stream: MediaStream): void => {
    if (stream && stream.getTracks()) {
        stream.getTracks().forEach((track) => track.stop());
    }
};

export const showScreenShareErrorMessage = (intl: IntlShape) => {
    showWarnMessage(
        <span>
            {intl.formatMessage({ id: 'screen.share.error' })}{' '}
            {
                window.navigator.platform.toLowerCase().includes('mac') && (
                    <a href="https://help.daily.co/en/articles/3400532-enable-screen-recording-permission-for-macos-catalina-10-15-and-later#:~:text=If%20you%20do%20not%20see,your%20Daily.co%20video%20calls."> {intl.formatMessage({ id: 'enable.screen.share' })}</a>
                )
            }
        </span>
    );
};

export const preventPageReload = (e) => {
    e.preventDefault();
    e.returnValue = '';
    return false;
};

export const isFirefox = (): boolean => {
    return navigator.userAgent.indexOf('Firefox') !== -1;
};

export const getCameraConstraint = (
    width: number = 1280,
    height: number = 720,
): MediaTrackConstraints => {
  /*if (isMobile()) {
      return {
          width: {
              max: width,
          },
          height: {
              max: height,
          },
      }
  }*/

  /*return {
      width,
      height,
  }*/

    return {
        width: {
            max: width,
        },
        height: {
            max: height,
        },
    }
};

export const isMobile = (): boolean => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
};

export const isValidEmail = (email: string): boolean => {
    if (!email) {
        return false;
    }

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
};

export const getImageSrc = (src: string): string => {
    return src.includes('images')
        ? src
        : `/images/${src}`
};

export const setSinkId = (deviceId: string) => {
    try {
        const videoElements: HTMLVideoElement[] = Array.from(document.querySelectorAll('video') || []);

        videoElements.forEach(async (videoElement: HTMLVideoElement) => {
            try {
                // @ts-ignore
                if (videoElement.setSinkId) {
                    // @ts-ignore
                    await videoElement.setSinkId(deviceId);
                }
            } catch (e) {
                console.error(`Failed to set sink id for ${videoElement}`);
            }
        });
    } catch (e) {
        console.error('Set sink id function error')
    }
};
