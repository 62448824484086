import React from 'react';

interface IProps {}

export const CamSodeColorIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z" fill="#D73C9F"/>
            <path d="M37.2001 34.974C35.8741 36.564 35.7121 37.026 33.0091 38.886C30.3031 40.746 27.0841 41.751 23.8381 42.039C21.6391 42.147 19.3531 42.189 17.2831 41.31C13.9021 40.023 11.2411 37.146 9.96312 33.765C9.02712 31.446 8.89811 28.899 9.06011 26.424C9.40511 23.088 10.5451 19.884 12.0601 16.914C13.3201 14.58 14.8681 12.396 16.7701 10.551C18.9751 8.43895 21.7141 6.79495 24.7111 6.17095C25.0442 6.08715 25.3817 6.02205 25.7221 5.97595C28.5811 5.68495 31.6141 6.35095 33.8821 8.19295C35.5012 9.48104 36.6984 11.2236 37.3201 13.197C38.3401 16.392 36.9661 18.537 37.4551 21.105C37.7251 22.536 38.8081 24.609 38.9761 27.498C39.1351 30.303 38.5261 33.384 37.2001 34.974Z" fill="white"/>
            <path d="M33.2937 35.85C31.5117 37.164 29.5887 38.319 27.4527 38.94C25.1625 39.6037 22.7673 39.8276 20.3937 39.6C17.4687 39.216 14.8287 37.404 13.2237 34.935C11.4387 32.235 10.9797 28.839 11.4237 25.671C11.9037 22.527 13.0707 19.518 14.6517 16.776C16.2927 14.073 18.4227 11.586 21.1437 9.94499C22.6857 9.04499 24.3957 8.36399 26.1867 8.23799C27.3267 8.21399 28.4967 8.13598 29.6007 8.48099C31.9857 9.11099 34.0407 10.881 35.0157 13.167C36.1077 15.648 35.2947 17.73 35.0157 20.403C34.7367 23.076 35.2947 23.115 36.1467 25.383C36.5667 26.523 37.2717 28.875 36.0417 32.085C35.6037 33.231 34.0287 35.184 33.2937 35.853V35.85ZM17.4987 18.156C15.9687 20.718 14.8287 23.556 14.4507 26.535C14.1027 29.235 14.7657 32.211 16.7307 34.176C17.6757 35.22 18.9867 35.856 20.3427 36.153C24.2847 36.573 28.4697 35.412 31.3767 32.634C32.2737 31.854 33.1917 31.023 33.8247 30.414C35.7807 28.833 34.3857 27.036 32.8617 27.975C31.5417 28.911 30.4587 29.883 28.3497 31.308C26.6007 32.472 24.5847 33.414 22.4457 33.33C21.3807 33.369 20.2707 33.108 19.4517 32.388C18.4377 31.473 18.0117 30.078 17.9307 28.743C17.8467 25.803 18.7497 22.923 20.0127 20.298C21.0087 18.348 22.2297 16.407 24.0867 15.183C24.8697 14.706 25.7577 14.316 26.6937 14.388C27.5997 14.358 28.6287 14.772 29.0097 15.654C29.6157 16.857 29.3487 18.324 28.7397 19.47C28.3107 20.406 27.6897 21.342 27.7797 22.419C27.7917 22.914 28.2387 23.409 28.7577 23.199C31.1187 21.312 32.7327 18.084 31.8477 15.036C31.4187 13.836 30.6027 12.762 29.4597 12.18C27.6507 11.205 25.4247 11.499 23.6217 12.339C21.0057 13.533 19.0137 15.753 17.4987 18.159V18.156Z" fill="#3C8DD9"/>
        </svg>
    );
};
