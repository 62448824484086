import React from 'react';

interface IProps {}

export const ArrowLeftIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.70994 10.29L2.82994 6.41L6.70994 2.53C7.09994 2.14 7.09994 1.51 6.70994 1.12C6.31994 0.730003 5.68994 0.730003 5.29994 1.12L0.709942 5.71C0.319942 6.1 0.319942 6.73 0.709942 7.12L5.29994 11.71C5.68994 12.1 6.31994 12.1 6.70994 11.71C7.08994 11.32 7.09994 10.68 6.70994 10.29Z" fill="#748499"/>
        </svg>
    );
};
