import React from 'react';

interface IProps {}

export const AddGuestIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3899 14.559C13.7099 13.699 11.5299 12.999 8.99994 12.999C6.46994 12.999 4.28994 13.699 2.60994 14.559C1.60994 15.069 0.999939 16.099 0.999939 17.219V19.999H16.9999V17.219C16.9999 16.099 16.3899 15.069 15.3899 14.559ZM8.99994 11.999C11.2099 11.999 12.9999 10.209 12.9999 7.99902C12.9999 5.78902 11.2099 3.99902 8.99994 3.99902C6.78994 3.99902 4.99994 5.78902 4.99994 7.99902C4.99994 10.209 6.78994 11.999 8.99994 11.999ZM19.9999 8.99902V6.99902C19.9999 6.44902 19.5499 5.99902 18.9999 5.99902C18.4499 5.99902 17.9999 6.44902 17.9999 6.99902V8.99902H15.9999C15.4499 8.99902 14.9999 9.44902 14.9999 9.99902C14.9999 10.549 15.4499 10.999 15.9999 10.999H17.9999V12.999C17.9999 13.549 18.4499 13.999 18.9999 13.999C19.5499 13.999 19.9999 13.549 19.9999 12.999V10.999H21.9999C22.5499 10.999 22.9999 10.549 22.9999 9.99902C22.9999 9.44902 22.5499 8.99902 21.9999 8.99902H19.9999Z" fill="#748499"/>
        </svg>
    );
};
