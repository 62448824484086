import React from 'react';

interface IProps {}

export const BoldIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 8.99159C13.8083 8.43325 14.375 7.51659 14.375 6.66659C14.375 4.78325 12.9166 3.33325 11.0416 3.33325H5.83331V14.9999H11.7C13.4416 14.9999 14.7916 13.5833 14.7916 11.8416C14.7916 10.5749 14.075 9.49159 13 8.99159ZM8.33331 5.41659H10.8333C11.525 5.41659 12.0833 5.97492 12.0833 6.66659C12.0833 7.35825 11.525 7.91658 10.8333 7.91658H8.33331V5.41659ZM11.25 12.9166H8.33331V10.4166H11.25C11.9416 10.4166 12.5 10.9749 12.5 11.6666C12.5 12.3583 11.9416 12.9166 11.25 12.9166Z" fill="#748499"/>
        </svg>
    );
};
