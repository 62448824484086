import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { DependencyInjector as DependencyInjectorService } from '@services';

interface IProps extends WrappedComponentProps, RouteComponentProps {

}

class DependencyInjectorComponent extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);

        const instance: DependencyInjectorService = DependencyInjectorService.getInstance();

        instance.intl = props.intl;
        instance.history = props.history;
    }

    render(): React.ReactNode {
        return null;
    }
}

export const DependencyInjector = withRouter(injectIntl(DependencyInjectorComponent));
