import React from 'react';

interface IProps {}

export const LinkedinIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="62" height="16" viewBox="0 0 62 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M0 2.35988H2.42018V11.3199H6.90065V13.5899H0V2.35988Z" fill="white"/>
                <path d="M7.97066 5.97563H10.2957V13.5857H7.97066V5.97563ZM9.13317 2.19225C9.39991 2.1923 9.66065 2.27274 9.88242 2.42339C10.1042 2.57405 10.277 2.78816 10.3791 3.03865C10.4811 3.28915 10.5078 3.56477 10.4557 3.83067C10.4036 4.09656 10.2752 4.3408 10.0865 4.53249C9.89791 4.72418 9.65758 4.85471 9.39596 4.90759C9.13434 4.96046 8.86316 4.9333 8.61672 4.82954C8.37029 4.72578 8.15966 4.55007 8.01147 4.32465C7.86328 4.09922 7.78418 3.83419 7.78418 3.56308C7.78418 3.38304 7.81907 3.20476 7.88687 3.03843C7.95467 2.87209 8.05403 2.72096 8.1793 2.59367C8.30457 2.46637 8.45329 2.3654 8.61695 2.29653C8.78062 2.22765 8.95603 2.19222 9.13317 2.19225Z" fill="white"/>
                <path d="M20.0215 2.35815H22.3465V9.06954L24.9815 5.97218H27.832L24.7805 9.49538L27.7678 13.5889H24.8456L22.3775 9.8272H22.347V13.5884H20.022L20.0215 2.35815Z" fill="white"/>
                <path d="M11.585 5.97637H13.8177V7.01637H13.8494C14.0724 6.62803 14.395 6.30856 14.7828 6.09205C15.1705 5.87554 15.6088 5.77013 16.0509 5.78707C18.4086 5.78707 18.8426 7.36369 18.8426 9.41194V13.5852H16.5176V9.88529C16.5176 9.00209 16.5016 7.86683 15.3066 7.86683C14.0981 7.86683 13.9117 8.82855 13.9117 9.82104V13.5842H11.5867L11.585 5.97637Z" fill="white"/>
                <path d="M32.7284 8.89551C32.7329 8.70379 32.699 8.51316 32.6288 8.33514C32.5587 8.15713 32.4537 7.99545 32.3202 7.8599C32.1867 7.72434 32.0276 7.61773 31.8524 7.54653C31.6772 7.47533 31.4896 7.44101 31.301 7.44566C30.8948 7.41997 30.4949 7.55756 30.1875 7.82878C29.8802 8.09999 29.69 8.4831 29.658 8.89551H32.7284ZM34.6979 12.2678C34.3116 12.744 33.8259 13.1269 33.276 13.3888C32.7261 13.6506 32.1257 13.7849 31.5185 13.7819C29.1935 13.7819 27.332 12.2046 27.332 9.76197C27.332 7.31939 29.1932 5.74277 31.5185 5.74277C33.6916 5.74277 35.0544 7.31815 35.0544 9.76197V10.5029H29.658C29.7252 10.9231 29.9395 11.3045 30.2615 11.5767C30.5835 11.849 30.9915 11.9938 31.4102 11.9845C31.7412 11.9824 32.0663 11.8962 32.3561 11.7338C32.6459 11.5714 32.8911 11.3379 33.0695 11.0545L34.6979 12.2678Z" fill="white"/>
                <path d="M39.9003 7.81514C38.7378 7.81514 38.0403 8.60406 38.0403 9.7536C38.0403 10.9031 38.7376 11.6928 39.9003 11.6928C41.063 11.6928 41.762 10.9051 41.762 9.7536C41.762 8.60209 41.0643 7.81514 39.9003 7.81514ZM43.9025 13.5842H41.762V12.575H41.7303C41.4618 12.9421 41.1135 13.2412 40.7125 13.4491C40.3116 13.6569 39.8689 13.7679 39.4188 13.7733C37.1849 13.7733 35.7134 12.1346 35.7134 9.81736C35.7134 7.68911 37.0156 5.7349 39.1563 5.7349C40.1185 5.7349 41.0173 6.00246 41.5445 6.74413H41.575V2.35742H43.9022L43.9025 13.5842Z" fill="white"/>
                <path d="M59.5985 13.5887H57.2735V9.888C57.2735 9.00554 57.258 7.86954 56.0642 7.86954C54.8533 7.86954 54.668 8.83102 54.668 9.82375V13.5884H52.343V5.97834H54.575V7.01834H54.6063C54.8296 6.63016 55.1524 6.31083 55.5402 6.09436C55.928 5.87788 56.3663 5.77237 56.8085 5.78905C59.165 5.78905 59.5994 7.36443 59.5994 9.41391L59.5985 13.5887ZM49.7196 4.93809C49.4528 4.93814 49.1919 4.85776 48.97 4.70712C48.7481 4.55648 48.5751 4.34234 48.473 4.09178C48.3708 3.84122 48.344 3.5655 48.3961 3.29948C48.4481 3.03346 48.5765 2.78909 48.7652 2.59727C48.9538 2.40546 49.1942 2.27481 49.4559 2.22184C49.7177 2.16888 49.9889 2.19599 50.2355 2.29974C50.4821 2.40349 50.6928 2.57921 50.8411 2.8047C50.9894 3.03018 51.0686 3.2953 51.0686 3.56652C51.0687 3.74661 51.0338 3.92494 50.966 4.09132C50.8982 4.25771 50.7989 4.40891 50.6736 4.53627C50.5484 4.66363 50.3996 4.76467 50.2359 4.83361C50.0723 4.90256 49.8968 4.93806 49.7196 4.93809ZM50.8821 13.5887H48.5547V5.97834H50.8821V13.5887ZM60.7576 0.000985474H47.3864C47.0829 -0.00249547 46.7905 0.116627 46.5734 0.332177C46.3562 0.547727 46.2322 0.842069 46.2285 1.15052V14.797C46.2321 15.1057 46.356 15.4002 46.5732 15.616C46.7903 15.8317 47.0828 15.9511 47.3864 15.9478H60.7576C61.0618 15.9517 61.3551 15.8327 61.5731 15.6169C61.7911 15.4011 61.9159 15.1063 61.9201 14.797V1.14954C61.9157 0.840473 61.7909 0.545796 61.5729 0.330251C61.3549 0.114707 61.0617 -0.00407575 60.7576 4.60648e-07" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="62" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};