import React from 'react';

interface IProps {}

export const DliveColorIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="4" fill="#FFD300"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M33.5697 28.319C33.5637 29.5109 33.0521 30.6888 32.1824 31.5049C31.3416 32.327 30.167 32.7692 28.9959 32.7532C26.1572 32.749 23.3197 32.7625 20.4828 32.7469C18.8619 32.7195 17.3183 31.7231 16.602 30.2735C16.2967 29.6663 16.1332 28.9886 16.1323 28.3089C16.1332 26.2647 16.1323 24.2209 16.1323 22.1767C15.4172 22.9575 14.5548 23.5989 13.6384 24.1241C13.3701 23.283 13.035 22.4614 12.5947 21.6949C13.6966 21.5079 14.8032 21.3104 15.8542 20.9179C14.8499 20.3936 13.6817 20.3199 12.5926 20.0735C13.0512 19.3142 13.3607 18.4787 13.6478 17.6431C14.5442 18.196 15.4274 18.8055 16.1222 19.6069C16.1935 18.5035 16.6445 17.4232 17.4351 16.6433C18.2603 15.8082 19.4215 15.3121 20.5969 15.3247C23.4709 15.3277 26.3432 15.3247 29.2171 15.3256C30.545 15.3449 31.8363 16.0171 32.6401 17.0703C33.2423 17.8419 33.5706 18.8181 33.5706 19.7964C33.5685 22.6375 33.5697 25.4784 33.5697 28.3191V28.319ZM38.3851 16.9907C36.8305 13.7766 34.16 11.1192 30.9391 9.5816C28.2044 8.25163 25.0867 7.75301 22.0713 8.11392C19.1124 8.46683 16.2627 9.67383 13.9476 11.5487C11.3692 13.6241 9.46128 16.5182 8.5827 19.7117C7.63491 23.1028 7.84723 26.8096 9.19121 30.0655C10.5891 33.507 13.2312 36.4217 16.5196 38.1472C18.9481 39.4362 21.713 40.0836 24.4607 39.9913C28.3636 39.905 32.2053 38.298 35.0237 35.5976C37.3036 33.4435 38.9189 30.5991 39.6064 27.539C40.4068 24.0196 39.9801 20.2301 38.3851 16.9907" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M29.0307 20.961C28.1126 21.0591 27.192 21.1631 26.2706 21.2344C25.854 21.257 25.7372 20.6084 26.1215 20.4637C26.7924 20.3522 27.4749 20.327 28.1487 20.241C28.4609 20.2209 28.7797 20.1416 29.0923 20.1962C29.4405 20.3102 29.3947 20.9023 29.0307 20.961ZM27.5198 23.9491C26.7958 23.9184 26.2476 23.1502 26.4396 22.4559C26.5326 22.073 26.8324 21.7489 27.2035 21.6155C27.9126 21.3396 28.7751 21.8809 28.815 22.6416C28.9025 23.3532 28.2388 24.027 27.5198 23.9491ZM23.4319 21.2331C22.5061 21.1723 21.5821 21.0591 20.6581 20.9636C20.2976 20.9136 20.2067 20.3802 20.532 20.2163C20.732 20.1337 20.9579 20.1974 21.1664 20.2037C21.9397 20.2964 22.7193 20.345 23.4909 20.4502C23.9122 20.5329 23.8599 21.2143 23.4319 21.2331ZM21.8442 23.9331C21.1167 23.8258 20.6399 22.9804 20.9329 22.3058C21.078 21.951 21.3978 21.6662 21.7749 21.5782C22.5014 21.3803 23.2921 21.9996 23.2662 22.7502C23.2892 23.4719 22.5545 24.0857 21.8442 23.9331ZM31.3288 19.2887C30.7624 18.768 29.9781 18.5361 29.2188 18.5328C26.268 18.5374 23.3172 18.5336 20.3668 18.5348C20.0781 18.5395 19.7923 18.5902 19.5133 18.6581C18.6007 18.8942 17.8321 19.605 17.5289 20.4943C17.2746 21.2197 17.3994 21.9988 17.3701 22.7502C17.402 23.5322 17.2657 24.3453 17.5532 25.0946C17.9345 26.1538 18.9978 26.9404 20.135 26.9525C21.3189 27.0192 22.4866 26.5131 23.3388 25.7118C23.8064 25.2451 24.4637 24.8803 25.1461 25.0145C25.7665 25.1029 26.2353 25.5424 26.6698 25.9546C27.7402 26.7529 29.235 27.2532 30.5343 26.7399C31.5365 26.3265 32.2529 25.3143 32.2949 24.2329C32.3119 23.3251 32.2958 22.4189 32.3026 21.5111C32.3149 20.6763 31.9667 19.8389 31.3288 19.2887Z" fill="white"/>
        </svg>
    );
};