import React from 'react';
import { observable, makeObservable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PurpleButton } from '@components';
import { Routes } from '@common-types';
import { LogoIcon } from '@assets/icons';
import { AppStore } from '@services';
import { showWarnMessage } from '@utils';
import bgTop from '../../assets/images/bg-top.png';
import bgBottom from '../../assets/images/bg-bottom.png';
import './style.scss';

interface IProps {
    AppStore?: AppStore;
}

@inject('AppStore')
@observer
export class PasswordResetPage extends React.Component<IProps> {
    passwordError: string = '';
    confirmPasswordError: string = '';
    password: string = '';
    confirmPassword: string = '';
    token: string = '';
    email: string = '';

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            confirmPasswordError: observable,
            passwordError: observable,
            password: observable,
            confirmPassword: observable,
            token: observable,
            email: observable,
            onPasswordReset: action,
            onPasswordChange: action,
            checkToken: action,
            onKeyDown: action,
        });
    }

    onKeyDown = (e) => {
        if (e.code === 'Enter') {
            this.onPasswordReset();
        }
    };

    checkToken = () => {
        location.search.slice(1).split('&').forEach(action((value) => {
            const [key, val] = value.split('=');

            if (key === 'token') {
                this.token = val;
            }

            if (key === 'email') {
                this.email = val;
            }
        }));
    };

    async componentDidMount() {
        try {
            this.checkToken();
            document.body.addEventListener('keydown', this.onKeyDown);
        } catch (error) {
            console.error(error);
        }
    }

    componentWillUnmount(): void {
        document.body.removeEventListener('keydown', this.onKeyDown);
    }

    onPasswordChange = (e) => {
        this.passwordError = '';
        this.password = e.target.value;
    };

    onConfirmPasswordChange = (e) => {
        this.confirmPasswordError = '';
        this.confirmPassword = e.target.value;
    };

    onPasswordReset = () => {
        const { resetPassword } = this.props.AppStore!;
        let isValid = true;

        if (!this.password) {
            this.passwordError = 'Please, input password';
            isValid = false;
        }

        if (!this.confirmPassword) {
            this.confirmPasswordError = 'Please, input password';
            isValid = false;
        }

        if (this.confirmPassword && this.password && this.confirmPassword !== this.password) {
            this.confirmPasswordError = 'Please, input same password';
            isValid = false;
        }

        if (!this.email || !this.token) {
            isValid = false;
            showWarnMessage('Password reset link is invalid!');
        }

        if (isValid) {
            resetPassword(
                this.password,
                this.email,
                this.token,
            );
        }
    };

    render(): React.ReactNode {
        return (
            <div className="login-page">
                <img className='login-page__bg-top' src={bgTop} alt="background" />
                <img className='login-page__bg-bottom' src={bgBottom} alt="background" />
                <div className="login-page__wrapper">
                    <div className="login-page__logo">
                        <Link to={Routes.Main}>
                            <LogoIcon />
                        </Link>
                    </div>
                    <div className="login-page__form">
                        <div className="login-page__text-wrapper">
                            <div className="login-page__text-header">
                                New Password
                            </div>
                            <div className="login-page__text">
                                Enter you new password for your account
                            </div>
                        </div>
                        <div className='login-page__input-wrapper'>
                            <div className='login-page__label'>
                                <FormattedMessage id='password' />
                            </div>
                            <div className='login-page__input'>
                                <Input
                                    value={this.password}
                                    type={'password'}
                                    onChange={this.onPasswordChange}
                                />
                            </div>
                            {
                                this.passwordError && (
                                    <div className="login-page__error">
                                        {this.passwordError}
                                    </div>
                                )
                            }
                        </div>
                        <div className='login-page__input-wrapper'>
                            <div className='login-page__label'>
                                Repeat Password
                            </div>
                            <div className='login-page__input'>
                                <Input
                                    value={this.confirmPassword}
                                    type={'password'}
                                    onChange={this.onConfirmPasswordChange}
                                />
                            </div>
                            {
                                this.confirmPasswordError && (
                                    <div className="login-page__error">
                                        {this.confirmPasswordError}
                                    </div>
                                )
                            }
                        </div>
                        <div className="login-page__links">
                            <PurpleButton onClick={this.onPasswordReset}>
                                SAVE NEW PASSWORD
                            </PurpleButton>
                            <div className="login-page__links-footer">
                                Don't have an account?{' '}
                                <Link to={`${Routes.Register}${location.search}`}>
                                    Sign Up
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
