import React from 'react';

interface IProps {}

export const VideoClipIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 4V5H16V4C16 3.45 15.55 3 15 3H9C8.45 3 8 3.45 8 4V5H6V4C6 3.45 5.55 3 5 3C4.45 3 4 3.45 4 4V20C4 20.55 4.45 21 5 21C5.55 21 6 20.55 6 20V19H8V20C8 20.55 8.45 21 9 21H15C15.55 21 16 20.55 16 20V19H18V20C18 20.55 18.45 21 19 21C19.55 21 20 20.55 20 20V4C20 3.45 19.55 3 19 3C18.45 3 18 3.45 18 4ZM8 17H6V15H8V17ZM8 13H6V11H8V13ZM8 9H6V7H8V9ZM18 17H16V15H18V17ZM18 13H16V11H18V13ZM18 9H16V7H18V9Z" fill="white"/>
        </svg>
    );
};
