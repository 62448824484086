import React from 'react';
import './styles.scss';

interface IProps {

}

export class Spinner extends React.Component<IProps> {
    render(): React.ReactNode {
        return (
            <div className="custom-spinner">
                <div className="custom-spinner__inner one" />
                <div className="custom-spinner__inner two" />
                <div className="custom-spinner__inner three" />
            </div>
        );
    }
}
