import React from 'react';
import { action, makeObservable, observable } from 'mobx';
import { Input, Modal } from 'antd';
import { observer } from 'mobx-react';
import { CustomFileUpload, PurpleButton } from '@components';
import { CloseIcon, PlusCircleIcon, UploadIcon, EditPenIcon } from '@assets/icons';
import { ImageTypesEnum, IProject, IUser, IUserImage, LogoPositionsEnum, PatternsEnum } from '@common-types';
import './styles.scss';

interface IProps {
    project?: IProject;
    user: IUser;
    isModalOpen: boolean;
    closeModal: () => void;
    removeImage: (img: IUserImage) => Promise<void>;
    updateProject: (project: IProject) => Promise<void>;
    createProject: (project: IProject) => Promise<void>;
}

@observer
export class ProjectCreateModal extends React.Component<IProps> {
    name: string = '';
    nameError: string = '';
    description: string = '';
    avatar: string = '';
    avatarId: string = '';
    isUploadOpen: boolean = false;
    prevImg: IUserImage = null;

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            name: observable,
            isUploadOpen: observable,
            nameError: observable,
            description: observable,
            avatar: observable,
            avatarId: observable,
            onChangeName: action,
            onClose: action,
            onChangeDescription: action,
            onChangeAvatar: action,
            onSave: action,
            openUpload: action,
            closeUpload: action,
        });

        if (props.project) {
            this.name = props.project.name;
            this.avatar = props.project.avatar;
            this.avatarId = props.project.avatarId;
            this.prevImg = {
                id: props.project.avatarId,
                src: props.project.avatar,
                type: ImageTypesEnum.Avatar,
                isInitial: false,
            };
            this.description = props.project.description;
        }
    }

    openUpload = () => {
        this.isUploadOpen = true;
    };

    closeUpload = () => {
        this.isUploadOpen = false;
    };

    onClose = () => {
        this.name = '';
        this.description = '';
        this.avatar = '';
        this.avatarId = '';
        this.props.closeModal();
    };

    onChangeName = (e) => {
        this.nameError = '';
        this.name = e.target.value;
    };

    onChangeDescription = (e) => {
        this.description = e.target.value;
    };

    onChangeAvatar = (img: IUserImage) => {
        if (this.avatar && this.avatarId) {
            this.props.removeImage(this.prevImg);
        }

        this.prevImg = img;
        this.avatar = img.src;
        this.avatarId = img.id;
    };

    onSave = () => {
        const { project } = this.props;
        let isValid = true;

        if (!this.name) {
            this.nameError = 'Please input project name';
            isValid = false;
        }

        if (isValid) {
            if (project) {
                this.props.updateProject({
                    ...project,
                    name: this.name,
                    avatar: this.avatar,
                    avatarId: this.avatarId,
                    description: this.description,
                });
            } else {
                this.props.createProject({
                    selectedBg: '',
                    selectedLogo: '',
                    name: this.name,
                    selectedPattern: PatternsEnum.OneUser,
                    logoPosition: LogoPositionsEnum.TopRight,
                    showNames: true,
                    color: '4745CE',
                    isInitial: false,
                    avatar: this.avatar,
                    avatarId: this.avatarId,
                    description: this.description,
                });
            }

            this.onClose();
        }
    };

    render(): React.ReactNode {
        const { isModalOpen, project } = this.props;

        return (
            <div className="project-create-modal">
                <Modal
                    className="project-create-modal__modal"
                    destroyOnClose={true}
                    visible={isModalOpen}
                    onCancel={this.onClose}
                    closable={true}
                    width={400}
                    title={null}
                    footer={null}
                    closeIcon={<CloseIcon />}
                >
                    <div className="project-create-modal__header">
                        <div className="project-create-modal__icon">
                            {project ? <EditPenIcon /> : <PlusCircleIcon />}
                        </div>
                        <div className="project-create-modal__text">
                            {project ? 'Edit Project' : 'Create Project'}
                        </div>
                    </div>
                    <div className="project-create-modal__main">
                        <div className="project-create-modal__avatar" onClick={this.openUpload}>
                            {
                                this.avatar
                                    ? <img className="project-create-modal__img" src={this.avatar} />
                                    : <UploadIcon />
                            }
                        </div>
                        <div className='project-create-modal__input-wrapper'>
                            <div className='project-create-modal__label'>
                                Project Name
                            </div>
                            <div className='project-create-modal__input'>
                                <Input
                                    placeholder={'Name of the project'}
                                    value={this.name}
                                    onChange={this.onChangeName}
                                />
                            </div>
                            {
                                this.nameError && (
                                    <div className="project-create-modal__error">
                                        {this.nameError}
                                    </div>
                                )
                            }
                        </div>
                        <div className='project-create-modal__input-wrapper'>
                            <div className='project-create-modal__label'>
                                Project Description
                            </div>
                            <div className='project-create-modal__input'>
                                <Input.TextArea
                                    placeholder={'Short description of the project'}
                                    value={this.description}
                                    onChange={this.onChangeDescription}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="project-create-modal__footer">
                        <PurpleButton onClick={this.onSave}>
                            {
                                project
                                    ? 'UPDATE PROJECT'
                                    : 'CREATE NEW PROJECT'
                            }
                        </PurpleButton>
                    </div>
                </Modal>
                {
                    this.isUploadOpen && (
                        <CustomFileUpload
                            closeModal={this.closeUpload}
                            isModalOpen={this.isUploadOpen}
                            addFile={this.onChangeAvatar}
                            fileType={ImageTypesEnum.Avatar}
                            userId={this.props.user?.id}
                        />
                    )
                }
            </div>
        );
    }
}
