import React from 'react';
import { Dropdown, Menu, Select } from 'antd';
import { ILayer, IScene, TextEditClasses, ITextSettings } from '@common-types';
import {
    DeleteGrayIcon,
    FontSettingsIcon,
    BoldIcon,
    ItalicIcon,
    UnderIcon,
    StrikeIcon,
    ToTopIcon,
    ToBottomIcon,
} from '@assets/icons';
import './styles.scss';

interface IProps {
    textSettings: ITextSettings;
    textClasses: string[];
    selectedScene: IScene;
    layer: ILayer;
    makeBold: () => void;
    makeItalic: () => void;
    makeUnder: () => void;
    makeStrike: () => void;
    changeFontSettings: (settings: ITextSettings) => void;
    moveToTop: () => void;
    moveToBottom: () => void;
    removeLayer: () => void;
}

interface IState {
    ddVisible: boolean;
}

const fontsList = [
    { label: 'Verdana', value: 'Verdana, sans-serif' },
    { label: 'Arial', value: 'Arial, sans-serif' },
    { label: 'Helvetica', value: 'Helvetica, sans-serif' },
    { label: 'Tahoma', value: 'Tahoma, sans-serif' },
    { label: 'Trebuchet MS', value: `'Trebuchet MS', sans-serif` },
    { label: 'Times New Roman', value: `'Times New Roman', serif` },
    { label: 'Georgia', value: 'Georgia, serif' },
    { label: 'Garamond', value: 'Garamond, serif' },
    { label: 'Courier New', value: `'Courier New', monospace` },
    { label: 'Brush Script MT', value: `'Brush Script MT', cursive` },
];
const textSizesList = [
    { label: '8px', value: '8px' },
    { label: '10px', value: '10px' },
    { label: '12px', value: '12px' },
    { label: '14px', value: '14px' },
    { label: '16px', value: '16px' },
    { label: '18px', value: '18px' },
    { label: '20px', value: '20px' },
    { label: '24px', value: '24px' },
    { label: '30px', value: '30px' },
    { label: '40px', value: '40px' },
];
const colorsList = [
    { color: '#C24242' },
    { color: '#42C277' },
    { color: '#4745CE' },
    { color: '#FFFFFF' },
    { color: '#748499' },
    { color: '#EAF132' },
];

export class TextMenu extends React.Component<IProps, IState> {
    wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
    state: IState = {
        ddVisible: false,
    };

    onFontChange = (fontName: string) => {
        this.props.changeFontSettings({ fontName });
    };

    onColorChange = (color: string) => {
        this.props.changeFontSettings({ color });
    };

    onSizeChange = (fontSize: string) => {
        this.props.changeFontSettings({ fontSize });
    };

    getSubMenu = () => {
        const { textSettings } = this.props;

        return (
            <Menu className="advance-top-menu__dd-menu">
                <Menu.Item key={'typeface'}>
                    <div className="advance-top-menu__dd-header">
                        Typeface
                    </div>
                    <div className="advance-top-menu__select-wrapper">
                        <Select
                            dropdownClassName="advance-top-menu__select-dd-font-name"
                            value={textSettings?.fontName}
                            onChange={this.onFontChange}
                            getPopupContainer={() => {
                                return document.querySelector('.advance-top-menu__dd-menu') || document.body;
                            }}
                        >
                            {
                                fontsList.map(({ label, value }) => {
                                    return (
                                        <Select.Option value={value} key={value}>
                                            <span style={{ fontFamily: value }}>
                                                {label}
                                            </span>
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </div>
                </Menu.Item>
                <Menu.Item key={'textSize'}>
                    <div className="advance-top-menu__dd-header">
                        Text Size
                    </div>
                    <div className="advance-top-menu__select-wrapper">
                        <Select
                            dropdownClassName="advance-top-menu__select-dd-text-size"
                            value={textSettings?.fontSize}
                            onChange={this.onSizeChange}
                            getPopupContainer={() => {
                                return document.querySelector('.advance-top-menu__dd-menu') || document.body;
                            }}
                        >
                            {
                                textSizesList.map(({ label, value }) => {
                                    return (
                                        <Select.Option value={value} key={value}>
                                            <span>
                                                {label}
                                            </span>
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </div>
                </Menu.Item>
                <Menu.Item key={'color'}>
                    <div className="advance-top-menu__dd-header">
                        Color
                    </div>
                    <div className="advance-top-menu__color-wrapper">
                        {
                            colorsList.map(({ color }) => {
                                return (
                                    <div
                                        className="advance-top-menu__color-out"
                                        key={color}
                                        onClick={() => this.onColorChange(color)}
                                    >
                                        <div
                                            className="advance-top-menu__color-in"
                                            style={{ background: color }}
                                        />
                                    </div>
                                );
                            })
                        }
                    </div>
                </Menu.Item>
            </Menu>
        );
    };

    componentDidMount(): void {
        this.forceUpdate();
    }

    render(): React.ReactNode {
        const {
            textClasses,
            layer,
            selectedScene,
            moveToTop,
            moveToBottom,
            removeLayer,
            changeFontSettings,
            makeUnder,
            makeStrike,
            makeItalic,
            makeBold,
        } = this.props;

        return (
            <div className="advance-top-menu__wrapper text-menu" ref={this.wrapperRef}>
                {
                    this.wrapperRef?.current ? (
                        <Dropdown
                            getPopupContainer={() => {
                                return this.wrapperRef?.current || document.body;
                            }}
                            overlay={this.getSubMenu()}
                            visible={this.state.ddVisible}
                            onVisibleChange={(visible: boolean) => {
                                this.setState({ ddVisible: visible });
                            }}
                            trigger={['click']}
                            placement="bottomLeft"
                            className="advance-top-menu__text-dd-wrapper"
                        >
                            <div className="advance-top-menu__button">
                                <FontSettingsIcon />
                            </div>
                        </Dropdown>
                    ) : null
                }
                <div className={"text-menu__buttons"}>
                    <div
                        className={`advance-top-menu__button ${textClasses.includes(TextEditClasses.Bold) ? 'selected' : ''}`}
                        onClick={makeBold}
                    >
                        <BoldIcon />
                    </div>
                    <div
                        className={`advance-top-menu__button ${textClasses.includes(TextEditClasses.Italic) ? 'selected' : ''}`}
                        onClick={makeItalic}
                    >
                        <ItalicIcon />
                    </div>
                    <div
                        className={`advance-top-menu__button ${textClasses.includes(TextEditClasses.Under) ? 'selected' : ''}`}
                        onClick={makeUnder}
                    >
                        <UnderIcon />
                    </div>
                    <div
                        className={`advance-top-menu__button ${textClasses.includes(TextEditClasses.Strike) ? 'selected' : ''}`}
                        onClick={makeStrike}
                    >
                        <StrikeIcon />
                    </div>
                    <div
                        className={`advance-top-menu__button ${layer.index < selectedScene?.layers.length - 1 ? '' : 'disabled'}`}
                        onClick={moveToBottom}
                    >
                        <ToBottomIcon />
                    </div>
                    <div
                        className={`advance-top-menu__button ${layer.index > 0 ? '' : 'disabled'}`}
                        onClick={moveToTop}
                    >
                        <ToTopIcon />
                    </div>
                    <div className="advance-top-menu__button" onClick={removeLayer}>
                        <DeleteGrayIcon />
                    </div>
                </div>
            </div>
        );
    }
}
