import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Select, Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DeviceTypes } from '@common-types';
import { ArrowBottomIcon, SettingsIcon } from '@assets/icons';
import './styles.scss';

interface IProps {
    showModal: boolean;
    closeModal: () => void;
    selectedCamera: string | null;
    selectedMicro: string | null;
    selectedAudio: string | null;
    saveDevices: (
        camera: string,
        micro: string,
        audio: string,
    ) => void;
}

@observer
export class SettingsModal extends React.Component<IProps> {
    cameras: MediaDeviceInfo[] = [];
    micros: MediaDeviceInfo[] = [];
    audios: MediaDeviceInfo[] = [];
    selectedCamera: string = '';
    selectedMicro: string = '';
    selectedAudio: string = '';

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            cameras: observable,
            audios: observable,
            selectedAudio: observable,
            micros: observable,
            selectedCamera: observable,
            selectedMicro: observable,
            setDevices: action,
            onCameraSelect: action,
            onMicroSelect: action,
            onAudioSelect: action,
        });

        this.selectedCamera = props.selectedCamera;
        this.selectedMicro = props.selectedMicro;
        this.selectedAudio = props.selectedAudio;
    }

    setDevices = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();

        devices.forEach(action((device) => {
            if (device.kind === DeviceTypes.Video) {
                this.cameras.push(device);
            } else if (device.kind === DeviceTypes.Audio) {
                this.micros.push(device);
            } else if (device.kind === DeviceTypes.AudioOutput) {
                this.audios.push(device);
            }
        }));
    };

    componentDidMount() {
        this.setDevices();
    }

    onCameraSelect = (value: string) => {
        this.selectedCamera = value;
    };

    onMicroSelect = (value: string) => {
        this.selectedMicro = value;
    };

    onAudioSelect = (value: string) => {
        this.selectedAudio = value;
    };

    onSave = () => {
        const { closeModal, saveDevices } = this.props;

        saveDevices(this.selectedCamera, this.selectedMicro, this.selectedAudio);
        closeModal();
    };

    render(): React.ReactNode {
        const { showModal, closeModal } = this.props;

        return (
            <Modal
                className="settings-modal"
                title={<FormattedMessage id='settings' />}
                visible={showModal}
                onOk={this.onSave}
                onCancel={closeModal}
                destroyOnClose={true}
                width={400}
                okText="Save Changes"
                okButtonProps={{
                    className: 'purple-button',
                }}
                cancelButtonProps={{
                    className: 'secondary-button',
                }}
            >
                <div className='settings-modal__title'>
                    <div className='settings-modal__title-icon'>
                        <SettingsIcon />
                    </div>
                    <div className='settings-modal__title-text'>
                        Settings
                    </div>
                </div>
                <div className='settings-modal__input-wrapper'>
                    <div className='settings-modal__label'>
                        <FormattedMessage id='video.input' />
                    </div>
                    <div className='settings-modal__select'>
                        <Select
                            value={this.selectedCamera}
                            onChange={this.onCameraSelect}
                            suffixIcon={ArrowBottomIcon}
                        >
                            {
                                this.cameras.map(({ deviceId, label }) => {
                                    return (
                                        <Select.Option key={deviceId} value={deviceId}>
                                            {label || 'Camera'}
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className='settings-modal__input-wrapper'>
                    <div className='settings-modal__label'>
                        <FormattedMessage id='audio.input' />
                    </div>
                    <div className='settings-modal__select'>
                        <Select
                            value={this.selectedMicro}
                            onChange={this.onMicroSelect}
                            suffixIcon={ArrowBottomIcon}
                        >
                            {
                                this.micros.map(({ deviceId, label }) => {
                                    return (
                                        <Select.Option key={deviceId} value={deviceId}>
                                            {label || 'Micro'}
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </div>
                </div>
                {
                    this.audios && this.audios.length > 1 ? (
                      <div className='settings-modal__input-wrapper'>
                          <div className='settings-modal__label'>
                              <FormattedMessage id='audio.output' />
                          </div>
                          <div className='settings-modal__select'>
                              <Select
                                value={this.selectedAudio}
                                onChange={this.onAudioSelect}
                                suffixIcon={ArrowBottomIcon}
                              >
                                  {
                                      this.audios.map(({ deviceId, label }) => {
                                          return (
                                            <Select.Option key={deviceId} value={deviceId}>
                                                {label || 'Speakers'}
                                            </Select.Option>
                                          );
                                      })
                                  }
                              </Select>
                          </div>
                      </div>
                    ) : null
                }
            </Modal>
        );
    }
}
