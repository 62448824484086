import React from 'react';

interface IProps {}

export const DliveIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="61" height="22" viewBox="0 0 61 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M41.541 5.15813C41.9986 4.99122 42.555 5.15669 42.832 5.55826C43.1821 6.02297 43.0874 6.77477 42.5971 7.10691C42.0597 7.4998 41.1912 7.31791 40.9133 6.699C40.607 6.1302 40.9293 5.35762 41.541 5.15813Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.5963 13.9693C17.5921 14.7887 17.24 15.5985 16.6416 16.1596C16.0629 16.7248 15.2547 17.0288 14.4487 17.0178C12.4952 17.0149 10.5426 17.0242 8.59024 17.0135C7.4748 16.9947 6.4126 16.3096 5.91962 15.313C5.70951 14.8956 5.59701 14.4297 5.59642 13.9624C5.59701 12.557 5.59642 11.1519 5.59642 9.74645C5.10432 10.2833 4.51081 10.7242 3.8802 11.0853C3.69551 10.5071 3.46495 9.94219 3.16191 9.41522C3.92023 9.28665 4.68176 9.1509 5.40501 8.88108C4.7139 8.5206 3.91 8.46993 3.16045 8.30054C3.47605 7.77849 3.68908 7.20409 3.88662 6.62963C4.50351 7.00978 5.11133 7.42875 5.58941 7.97972C5.6385 7.22118 5.94884 6.47848 6.49296 5.94227C7.06078 5.36812 7.85995 5.02705 8.66882 5.03574C10.6466 5.03777 12.6232 5.03574 14.601 5.03632C15.5147 5.04964 16.4034 5.51174 16.9566 6.23586C17.371 6.76629 17.5969 7.43742 17.5969 8.11004C17.5954 10.0633 17.5963 12.0164 17.5963 13.9694V13.9693ZM20.9101 6.18113C19.8402 3.9714 18.0025 2.14444 15.7859 1.08735C13.904 0.172998 11.7585 -0.169808 9.68345 0.0783207C7.64724 0.320948 5.68614 1.15076 4.09294 2.43976C2.31856 3.86659 1.00561 5.85626 0.400997 8.05181C-0.251244 10.3832 -0.105132 12.9316 0.819754 15.17C1.78175 17.5361 3.59996 19.5399 5.86294 20.7262C7.53416 21.6124 9.43683 22.0575 11.3278 21.994C14.0136 21.9347 16.6573 20.8299 18.5969 18.9733C20.1658 17.4924 21.2774 15.5369 21.7505 13.4331C22.3013 11.0135 22.0077 8.40821 20.9101 6.18112" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.4725 8.91065C13.8408 8.97807 13.2072 9.04957 12.5731 9.09862C12.2864 9.11415 12.2061 8.66819 12.4705 8.56874C12.9322 8.49206 13.4019 8.47479 13.8656 8.41565C14.0804 8.4018 14.2998 8.34732 14.515 8.38485C14.7546 8.4632 14.723 8.87027 14.4725 8.91065ZM13.4328 10.9649C12.9346 10.9438 12.5573 10.4157 12.6894 9.93837C12.7534 9.67515 12.9597 9.4523 13.2151 9.36061C13.7031 9.17096 14.2967 9.54308 14.3241 10.066C14.3843 10.5553 13.9276 11.0185 13.4328 10.9649ZM10.6196 9.09771C9.98254 9.05591 9.34669 8.97807 8.71079 8.91239C8.46272 8.87803 8.40015 8.51135 8.62401 8.39863C8.76169 8.34188 8.91714 8.38569 9.06059 8.39002C9.59273 8.45369 10.1293 8.48715 10.6602 8.55949C10.9501 8.6163 10.9142 9.08477 10.6196 9.09771ZM9.52701 10.9539C9.02641 10.8802 8.69826 10.299 8.89989 9.83516C8.99978 9.59129 9.21984 9.39549 9.47932 9.33499C9.97932 9.19891 10.5235 9.62468 10.5056 10.1407C10.5214 10.6369 10.0159 11.0589 9.52701 10.9539ZM16.0541 7.76095C15.6643 7.40293 15.1245 7.2435 14.602 7.24123C12.5714 7.2444 10.5407 7.24182 8.51034 7.24266C8.31166 7.24583 8.11495 7.2807 7.92297 7.32742C7.295 7.4897 6.76607 7.97836 6.55741 8.58977C6.38237 9.08852 6.46829 9.62409 6.44813 10.1407C6.47004 10.6783 6.37624 11.2373 6.57408 11.7525C6.83651 12.4807 7.56825 13.0215 8.35082 13.0298C9.16553 13.0756 9.96909 12.7277 10.5556 12.1768C10.8773 11.8559 11.3297 11.6051 11.7993 11.6974C12.2262 11.7582 12.5489 12.0603 12.8478 12.3437C13.5845 12.8926 14.6132 13.2365 15.5073 12.8837C16.197 12.5994 16.69 11.9036 16.7189 11.1601C16.7306 10.5359 16.7195 9.91294 16.7242 9.28885C16.7326 8.71491 16.493 8.13922 16.0541 7.76095Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M44.2833 8.99949C44.5568 8.43389 45.4036 8.24223 45.8958 8.63888C46.1783 8.96403 46.2902 9.39128 46.4434 9.78275C46.9556 11.1927 47.4711 12.6021 47.986 14.0118C48.0422 14.1749 48.0941 14.3413 48.1833 14.492C48.8069 12.8085 49.4477 11.1322 50.0769 9.45211C50.2064 9.14682 50.3238 8.81158 50.5845 8.58815C51.0272 8.29523 51.6996 8.42496 51.9933 8.86432C52.2417 9.19121 52.14 9.62798 51.9979 9.97532C51.2671 11.7459 50.5369 13.5168 49.8057 15.2874C49.5921 15.7691 49.4509 16.3025 49.0851 16.7023C48.7677 17.0442 48.2508 17.1188 47.8075 17.0237C47.382 16.9222 47.0699 16.5665 46.9101 16.182C46.3531 14.9429 45.8601 13.6771 45.3332 12.4251C45.0056 11.624 44.6617 10.8301 44.3476 10.0238C44.2289 9.70232 44.0983 9.32153 44.2833 8.99949Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M41.547 8.4854C41.9515 8.36343 42.438 8.45311 42.7238 8.77308C42.9423 9.01004 43.016 9.33784 43.0198 9.65004V15.2484C43.0142 15.7154 43.075 16.2395 42.777 16.6399C42.4307 17.1286 41.6324 17.2078 41.1955 16.7956C40.9123 16.5442 40.8203 16.1539 40.8261 15.7924C40.8229 13.8229 40.827 11.8537 40.8237 9.88473C40.7715 9.34016 40.9444 8.65952 41.547 8.4854Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M55.6197 10.4414C56.3514 9.66505 57.8175 9.7636 58.423 10.6437C58.7176 11.0542 58.8169 11.5613 58.861 12.0522C57.5651 12.0522 56.2702 12.0517 54.9742 12.0522C55.0312 11.4746 55.1969 10.8692 55.6197 10.4414ZM58.8812 13.282C59.4414 13.2852 60.0384 13.289 60.5451 13.0229C60.84 12.8686 60.9285 12.5241 60.9998 12.231V11.54C60.9937 11.5697 60.9811 11.6288 60.9738 11.6588C60.8563 10.6896 60.3516 9.75525 59.5361 9.17989C58.4212 8.36117 56.904 8.25162 55.5893 8.59695C54.5967 8.85701 53.7173 9.52231 53.2577 10.4281C52.6496 11.6057 52.5835 13.0128 52.9354 14.2749C53.2732 15.4888 54.2705 16.5036 55.513 16.842C56.8286 17.1995 58.314 17.1533 59.5285 16.4984C60.1106 16.1706 60.6556 15.6782 60.8347 15.023C60.9309 14.7053 60.7929 14.3095 60.4607 14.1875C60.1693 14.0826 59.7947 14.0841 59.5612 14.3104C59.0302 14.7857 58.5028 15.3222 57.7953 15.5338C57.1474 15.7059 56.4017 15.6477 55.8576 15.2363C55.2492 14.7814 54.9809 14.0154 54.9654 13.2875C56.2711 13.2737 57.5758 13.2844 58.8812 13.282Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M37.4869 5.27966C37.8729 5.1774 38.3314 5.23754 38.6196 5.5324C38.879 5.79502 38.9439 6.1779 38.951 6.53148C38.9527 9.59976 38.9495 12.6678 38.9527 15.7361C38.9463 16.0795 38.9071 16.4545 38.6722 16.7264C38.2946 17.188 37.4962 17.2261 37.0863 16.7868C36.8327 16.5309 36.7517 16.1599 36.7566 15.8128C36.7572 12.6954 36.7549 9.57912 36.7581 6.46144C36.7379 5.97543 36.9775 5.42347 37.4869 5.27967" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M33.2091 5.49116C33.6697 5.05133 34.5919 5.17102 34.83 5.7927C35.015 6.2758 34.9437 6.80628 34.9549 7.31118C34.9531 10.1192 34.9563 12.9271 34.9539 15.7352C34.9063 16.0428 34.9475 16.3815 34.7591 16.6514C34.4484 17.1641 33.641 17.2443 33.2185 16.8263C32.902 16.543 32.854 16.0916 32.8651 15.6951C32.345 16.2834 31.6968 16.8028 30.9128 16.9869C29.9403 17.2336 28.8491 17.0587 28.0508 16.4434C27.1043 15.7398 26.6218 14.5824 26.4853 13.4483C26.3614 12.2436 26.476 10.9564 27.1381 9.90654C27.7296 10.362 28.3024 10.8413 28.8953 11.2945C28.604 12.0821 28.5639 12.9556 28.7676 13.769C28.9143 14.3204 29.2267 14.8631 29.7392 15.1548C30.3549 15.5266 31.1869 15.4908 31.7778 15.0864C32.113 14.8683 32.3324 14.5238 32.503 14.1734C33.3172 14.8198 34.1235 15.476 34.9484 16.1093C34.2173 15.3116 33.394 14.5955 32.6162 13.8394C32.7831 13.1691 32.7924 12.4565 32.6457 11.782C32.4829 11.1089 32.0644 10.4501 31.3985 10.1709C30.6784 9.85244 29.7729 10.0628 29.2737 10.6673C28.7708 10.1773 28.2536 9.70258 27.7515 9.2116C28.5984 8.47867 29.7994 8.28462 30.883 8.48708C31.6702 8.61637 32.3333 9.11253 32.8698 9.67088C32.8652 8.6504 32.8684 7.63044 32.8675 6.61016C32.8634 6.21799 32.923 5.7866 33.2091 5.49116Z" fill="white"/>
        </svg>
    );
};