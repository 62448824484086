import React from 'react';
import { observable, makeObservable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PurpleButton } from '@components';
import { Routes } from '@common-types';
import { LogoIcon } from '@assets/icons';
import { AppStore } from '@services';
import { isValidEmail } from '@utils';
import bgTop from '../../assets/images/bg-top.png';
import bgBottom from '../../assets/images/bg-bottom.png';
import './style.scss';

interface IProps {
    AppStore?: AppStore;
}

@inject('AppStore')
@observer
export class LoginPage extends React.Component<IProps> {
    emailError: string = '';
    passwordError: string = '';

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            emailError: observable,
            passwordError: observable,
            onLogin: action,
            onEmailChange: action,
            onPasswordChange: action,
        });
    }

    onKeyDown = (e) => {
        if (e.code === 'Enter') {
            this.onLogin();
        }
    };

    checkToken = () => {
        let token = '';

        location.search.slice(1).split('&').forEach((value) => {
            const [key, val] = value.split('=');

            if (key === 'token') {
                token = val;
            }
        });

        if (token) {
            this.props.AppStore.confirm(token);
        }
    };

    async componentDidMount() {
        try {
            this.checkToken();
            document.body.addEventListener('keydown', this.onKeyDown);
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

            stream.getTracks().forEach((track) => {
                track.stop();
            });
        } catch (error) {
            console.error(error);
        }
    }

    componentWillUnmount(): void {
        document.body.removeEventListener('keydown', this.onKeyDown);
        this.props.AppStore.clearForm();
    }

    onEmailChange = (e) => {
        this.emailError = '';
        this.props.AppStore!.setEmail(e.target.value);
    };

    onPasswordChange = (e) => {
        this.passwordError = '';
        this.props.AppStore!.setPassword(e.target.value)
    };

    onLogin = () => {
        const { email, password, login } = this.props.AppStore!;
        let isValid = true;

        if (!email || !isValidEmail(email)) {
            this.emailError = 'Please, input valid email';
            isValid = false;
        }

        if (!password) {
            this.passwordError = 'Please, input password';
            isValid = false;
        }

        if (isValid) {
            login();
        }
    };

    render(): React.ReactNode {
        const { email, password } = this.props.AppStore!;

        return (
            <div className="login-page">
                <img className='login-page__bg-top' src={bgTop} alt="background" />
                <img className='login-page__bg-bottom' src={bgBottom} alt="background" />
                <div className="login-page__wrapper">
                    <div className="login-page__logo">
                        <Link to={Routes.Main}>
                            <LogoIcon />
                        </Link>
                    </div>
                    <div className="login-page__form">
                        <div className="login-page__text-wrapper">
                            <div className="login-page__text-header">
                                Sign In
                            </div>
                            <div className="login-page__text">
                                Sign in to your account and start broadcasting
                            </div>
                        </div>
                        <div className='login-page__input-wrapper'>
                            <div className='login-page__label'>
                                <FormattedMessage id='email' />
                            </div>
                            <div className='login-page__input'>
                                <Input
                                    placeholder={'Email'}
                                    value={email}
                                    type={'email'}
                                    onChange={this.onEmailChange}
                                />
                            </div>
                            {
                                this.emailError && (
                                    <div className="login-page__error">
                                        {this.emailError}
                                    </div>
                                )
                            }
                        </div>
                        <div className='login-page__input-wrapper' style={{ position: 'relative' }}>
                            <div className='login-page__label'>
                                <FormattedMessage id='password' />
                            </div>
                            <div className='login-page__input'>
                                <Input
                                    value={password}
                                    type={'password'}
                                    onChange={this.onPasswordChange}
                                />
                            </div>
                            <div className='login-page__forgot-password'>
                                <Link to={Routes.RequestPasswordReset}>
                                    Forgot Password?
                                </Link>
                            </div>
                            {
                                this.passwordError && (
                                    <div className="login-page__error">
                                        {this.passwordError}
                                    </div>
                                )
                            }
                        </div>
                        <div className="login-page__links">
                            <PurpleButton onClick={this.onLogin}>
                                Sign In
                            </PurpleButton>
                            <div className="login-page__links-footer">
                                Don't have an account?{' '}
                                <Link to={`${Routes.Register}${location.search}`}>
                                    Sign Up
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
