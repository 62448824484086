import { action, observable, makeObservable } from 'mobx';
import jwtDecode from "jwt-decode";
import { RolesEnum, ApiRoutes, Routes, IProject, IScene, ILayer } from '@common-types';
import { Locales } from '@i18n';
import { axiosInstance, showWarnMessage, getAxiosHeaders, showInfoMessage } from '@utils';
import {
    STORAGE_TOKEN_LABEL,
    STORAGE_REFRESH_TOKEN_LABEL,
    ADVANCE_MODE_LABEL,
    SIMPLE_MODE_LABEL,
    SELECTED_MODE_LABEL,
} from '@constants';
import { Base } from './base-store';

export class AppStore extends Base {
    email: string = '';
    password: string = '';
    locale: Locales = Locales.English;
    userRole: RolesEnum = RolesEnum.User;
    name: string = '';
    isUserAuthorized: boolean = false;
    currentUserId: string = '';
    token: string = '';
    tokenExpiration: number = 0;
    refreshToken: string = '';
    userEmail: string = '';
    isSimpleMode: boolean = true;
    isLoading: boolean = false;
    refreshInterval;
    isUserLoggedIn: boolean = false;
    userAuthCheckPromise: Promise<void> = null;

    constructor(rootStore) {
        super(rootStore);

        makeObservable(this, {
            locale: observable,
            name: observable,
            email: observable,
            tokenExpiration: observable,
            password: observable,
            refreshToken: observable,
            isLoading: observable,
            userRole: observable,
            isUserAuthorized: observable,
            currentUserId: observable,
            token: observable,
            userEmail: observable,
            isSimpleMode: observable,
            changeLocale: action,
            setEmail: action,
            setPassword: action,
            login: action,
            register: action,
            clearForm: action,
            logout: action,
            getRefreshedToken: action,
            getUserData: action,
            checkUserAuth: action,
            setName: action,
            selectMode: action,
        });

        const mode = localStorage.getItem(SELECTED_MODE_LABEL);

        if (mode === ADVANCE_MODE_LABEL) {
            this.isSimpleMode = false;
        } else if (mode === SIMPLE_MODE_LABEL) {
            this.isSimpleMode = true;
        }

        axiosInstance.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error?.response?.status === 401) {
                    this.logout();
                }


                throw error;
            },
        );

        const searchItems = location.search.slice(1).split('&');
        let roomId = '';
        searchItems.forEach((searchItem: string) => {
            const [name, value] = searchItem.split('=');

            if (name.toLowerCase() === 'roomid') {
                roomId = value;
            }
        });

        if (!roomId) {
            this.isUserAuthorized = true;
        }

        this.userAuthCheckPromise = this.checkUserAuth();
    }

    selectMode = (mode: string) => {
        if (mode === ADVANCE_MODE_LABEL) {
            this.isSimpleMode = false;
            localStorage.setItem(SELECTED_MODE_LABEL, ADVANCE_MODE_LABEL);
        } else if (mode === SIMPLE_MODE_LABEL) {
            this.isSimpleMode = true;
            localStorage.setItem(SELECTED_MODE_LABEL, SIMPLE_MODE_LABEL);
        }
    };

    requestPasswordReset = async () => {
        try {
            this.isLoading = true;

            await axiosInstance.post(ApiRoutes.RequestResetPassword, {
                email: this.email,
            });

            this.isLoading = false;
            showInfoMessage('We sent reset password link to your email');
        } catch (error) {
            console.error(error);
            showWarnMessage(error?.response?.data?.message || '');
            this.isLoading = false;
        }
    };

    resetPassword = async (password: string, email: string, token: string) => {
        try {
            this.isLoading = true;

            await axiosInstance.post(ApiRoutes.ResetPassword, {
                email,
                password,
                token,
            });

            this.isLoading = false;
            showInfoMessage('Your password reset complete');
            this.dependencies?.history?.push(Routes.Login);
        } catch (error) {
            console.error(error);
            showWarnMessage(error?.response?.data?.message || '');
            this.isLoading = false;
        }
    };

    logout = () => {
        try {
            if (this.refreshInterval) {
                clearTimeout(this.refreshInterval);
            }

            localStorage.setItem(STORAGE_TOKEN_LABEL, null);
            localStorage.setItem(STORAGE_REFRESH_TOKEN_LABEL, null);

            this.email = '';
            this.password = '';
            this.userRole = RolesEnum.User;
            this.isUserLoggedIn = false;
            this.currentUserId = '';
            this.token = '';
            this.tokenExpiration = 0;
            this.refreshToken = '';
            this.userEmail = '';
            this.refreshInterval = undefined;

            if (this.dependencies?.history) {
                this.dependencies?.history?.push(`${Routes.Login}/${location.search}`);
            }

        } catch (error) {
            console.error(error);
        }
    };

    checkUserAuth = async () => {
        try {
            const authToken = JSON.parse(localStorage.getItem(STORAGE_TOKEN_LABEL));
            const refreshToken = JSON.parse(localStorage.getItem(STORAGE_REFRESH_TOKEN_LABEL));

            if (authToken?.token && refreshToken?.token) {
                this.isLoading = true;
                await this.getRefreshedToken();
                await this.getUserData();
                this.isLoading = false;
            } else {
                this.logout();
            }
        } catch (error) {
            this.isLoading = false;
            console.error(error);
            this.logout();
        }
    };

    getUserData = async () => {
        try {
            this.isLoading = true;

            const { token } = JSON.parse(localStorage.getItem(STORAGE_TOKEN_LABEL));
            const obj = jwtDecode(token);

            // @ts-ignore
            const { data } = await axiosInstance.get(`${ApiRoutes.User}/${obj?.id}`, getAxiosHeaders());

            this.currentUserId = data.id;
            this.isUserLoggedIn = true;
            this.isLoading = false;
            this.userRole = data.role;
            this.userEmail = data.email;
            this.rootStore.userMediaStore.name = data.name || data.email;

            const userObj = {
                id: data.id,
                email: data.email,
                name: data.name,
                role: data.role,
                lastSelectedProject: data.lastSelectedProject,
                projects: data.projects,
                destinations: data.destinations,
                images: data.images,
                locale: data.locale,
            };

            this.rootStore.simpleModeStore.user = userObj;
            this.rootStore.advanceModeStore.user = {
                ...userObj,
                projects: userObj.projects.map((project: IProject) => {
                    return {
                        ...project,
                        scenes: project.scenes.map((scene: IScene) => {
                            return {
                                ...scene,
                                layers: scene.layers.map((layer: ILayer) => {
                                    return {
                                        ...layer,
                                        // @ts-ignore
                                        position: layer.position ? JSON.parse(layer.position) : { x: 0, y: 0, },
                                        // @ts-ignore
                                        size: layer.size ? JSON.parse(layer.size) : { width: 0, height: 0, },
                                        specificData: layer.specificData ? JSON.parse(layer.specificData) : null,
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
            this.dependencies.history.push(`${Routes.SelectMedia}/${location.search}`);
        } catch (error) {
            console.error(error);
            this.isLoading = false;
            this.logout();
        }
    };

    clearForm = () => {
        this.email = '';
        this.password = '';
        this.name = '';
    };

    setEmail = (email: string) => {
        this.email = email;
    };

    setName = (name: string) => {
        this.name = name;
    };

    setPassword = (password: string) => {
        this.password = password;
    };

    changeLocale = (locale: Locales) => {
        this.locale = locale;
    };

    getRefreshedToken = async () => {
        try {
            const { token } = JSON.parse(localStorage.getItem(STORAGE_REFRESH_TOKEN_LABEL));

            const { data } = await axiosInstance.post(ApiRoutes.RefreshToken, {
                refreshToken: token,
            }, getAxiosHeaders());

            this.token = data.token.token;
            this.tokenExpiration = data.token.expireAt;
            localStorage.setItem(STORAGE_TOKEN_LABEL, JSON.stringify(data.token));

            if (this.refreshInterval) {
                clearTimeout(this.refreshInterval);
            }

            this.refreshInterval = setTimeout(() => {
                this.getRefreshedToken();
            }, data.token.expireAt - Date.now() - 30000);

        }
         catch (error) {
            console.error(error);
            this.logout();
        }
    };

    login = async () => {
        try {
             this.isLoading = true;

            const { data } = await axiosInstance.post(ApiRoutes.Login, {
                email: this.email,
                password: this.password,
            });

            this.currentUserId = data.id;
            this.isUserLoggedIn = true;
            this.isLoading = false;
            this.userRole = data.role;
            this.token = data.token.token;
            this.tokenExpiration = data.token.expireAt;
            this.userEmail = data.email;
            this.rootStore.userMediaStore.name = data.name || data.email;

            this.dependencies.history.push(Routes.SelectMedia);

            localStorage.setItem(STORAGE_TOKEN_LABEL, JSON.stringify(data.token));
            localStorage.setItem(STORAGE_REFRESH_TOKEN_LABEL, JSON.stringify(data.refreshToken));

            if (this.refreshInterval) {
                clearTimeout(this.refreshInterval);
            }

            this.refreshInterval = setTimeout(() => {
                this.getRefreshedToken();
            }, data.token.expireAt - Date.now() - 30000);

            this.clearForm();

            const userObj = {
                id: data.id,
                email: data.email,
                name: data.name,
                role: data.role,
                lastSelectedProject: data.lastSelectedProject,
                projects: data.projects,
                destinations: data.destinations,
                images: data.images,
                locale: data.locale,
            };

            this.rootStore.simpleModeStore.user = userObj;
            this.rootStore.advanceModeStore.user = userObj;
        } catch (error) {
            console.error(error);
            this.isUserLoggedIn = false;
            showWarnMessage(error?.response?.data?.message || '');
            this.isLoading = false;
        }
    };

    confirm = async (token: string) => {
        try {
            this.isLoading = true;

            await axiosInstance.post(`${ApiRoutes.Confirm}/${token}`);

            this.isLoading = false;
            showInfoMessage('Your email is confirmed');
        } catch (error) {
            console.error(error);
            console.dir(error);
            showWarnMessage(error?.response?.data?.message || '');
            this.isLoading = false;
        }
    };

    register = async () => {
        try {
            this.isLoading = true;

            await axiosInstance.post(ApiRoutes.Register, {
                email: this.email,
                password: this.password,
                name: this.name,
            });

            this.clearForm();
            this.dependencies.history.push(Routes.Login);

            this.isLoading = false;
            showInfoMessage('We have sent you a confirmation email, please check and confirm');
        } catch (error) {
            console.error(error);
            showWarnMessage('Error during registration, please, try later');
            this.isLoading = false;
        }
    };
}
