import React from 'react';

interface IProps {}

export const ChaturbateColorIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z" fill="#2A83A9"/>
            <path d="M30.921 42.039C23.697 42.039 19.029 36.6 19.029 28.2C19.0316 27.1835 19.1018 26.1682 19.239 25.161C10.902 24.126 6 21.261 6 17.328C6 14.727 8.106 12.453 12.435 10.359L15.243 9L15.354 12.117C15.498 16.14 18.399 18.09 20.904 19.02C23.478 12.999 28.2 9.348 33.579 9.348C39.279 9.348 41.829 12.528 41.829 15.684C41.829 19.803 37.515 24.306 29.25 25.329C28.587 26.964 28.2 28.65 28.2 30C28.2 32.568 29.358 32.568 30.051 32.568C31.023 32.568 33.396 30.591 33.75 28.395L34.023 26.682H35.757C39.117 26.682 41.289 28.734 41.289 31.911C41.289 36.039 37.251 42.039 30.921 42.039Z" fill="url(#paint0_linear)"/>
            <path d="M30.0541 34.599C32.2351 34.599 35.2831 31.701 35.7631 28.713C38.0311 28.713 39.2581 29.913 39.2581 31.914C39.2581 35.046 36.0001 39.999 30.9181 39.999C24.0481 39.999 21.0601 34.443 21.0601 28.2C21.0727 26.571 21.2639 24.9483 21.6301 23.361C13.8301 22.731 8.02814 20.373 8.02814 17.325C8.02814 15.741 9.61214 13.977 13.3171 12.183C13.5001 17.199 17.1991 20.346 22.1371 21.513C23.9581 15.984 28.0231 11.382 33.5791 11.382C37.7041 11.382 39.7951 13.353 39.7951 15.684C39.7951 18.885 35.8831 22.794 27.8731 23.424C26.8591 25.515 26.1691 27.933 26.1691 29.994C26.1691 32.601 27.2461 34.599 30.0541 34.599ZM28.7101 21.96C32.7091 21.66 34.1101 20.406 34.1101 19.509C34.1101 19.002 33.6301 18.585 32.9431 18.585C31.4881 18.585 29.9341 19.989 28.7101 21.96Z" fill="url(#paint1_linear)"/>
            <g opacity="0.4">
                <g opacity="0.4">
                    <path d="M30.8011 35.886C33.2911 35.886 36.2851 33.033 37.1011 30.033C37.4948 30.0719 37.8765 30.1903 38.2231 30.381C37.8871 29.763 37.2661 29.394 36.3571 29.289C35.5441 32.289 32.5591 35.142 30.0571 35.142C28.9201 35.142 27.9721 34.848 27.2371 34.293C28.0201 35.325 29.2291 35.886 30.8011 35.886ZM34.7011 18.831C34.8061 19.041 34.8601 19.275 34.8601 19.509C34.8601 20.16 34.4731 20.862 33.5491 21.447C34.2571 21.171 34.9381 20.838 35.5891 20.445C35.6227 20.1172 35.5554 19.787 35.3962 19.4985C35.237 19.2101 34.9934 18.9772 34.6981 18.831H34.7011ZM22.5481 28.95C22.5596 27.3746 22.7437 25.8052 23.0971 24.27C23.1202 24.1662 23.1208 24.0586 23.0988 23.9546C23.0768 23.8506 23.0327 23.7525 22.9696 23.6669C22.9065 23.5813 22.8258 23.5103 22.7329 23.4586C22.64 23.4068 22.5371 23.3756 22.4311 23.367H22.3711C22.3715 23.4235 22.3654 23.4798 22.3531 23.535C21.9997 25.0652 21.8146 26.6295 21.8011 28.2C21.8011 31.599 22.7701 35.139 25.2001 37.275C23.3191 35.121 22.5481 31.977 22.5481 28.947V28.95ZM9.52214 18.072C9.52214 16.944 10.7071 15.663 12.8971 14.391C12.8066 14.0491 12.7345 13.7026 12.6811 13.353C10.1551 14.718 8.77814 16.113 8.77814 17.328C8.77814 18.024 9.21014 18.699 9.99914 19.329C9.70471 18.9747 9.53685 18.5324 9.52214 18.072ZM21.3541 22.599L21.6871 22.629C21.8581 22.644 22.0171 22.713 22.1401 22.83C22.3291 22.881 22.5151 22.935 22.7101 22.98C22.8917 23.0231 23.0828 22.9965 23.2456 22.9054C23.4084 22.8142 23.531 22.6653 23.5891 22.488C25.1191 17.838 28.7461 12.873 34.3231 12.873C36.1111 12.873 37.5511 13.272 38.5111 13.989C37.6771 12.825 35.9221 12.129 33.5821 12.129C28.0021 12.129 24.3811 17.097 22.8451 21.747C22.787 21.9236 22.6649 22.0721 22.5027 22.1632C22.3406 22.2542 22.1502 22.2813 21.9691 22.239C19.4401 21.639 17.3491 20.568 15.7831 19.131C17.1391 20.655 19.0321 21.837 21.3541 22.599Z" fill="white"/>
                </g>
            </g>
            <defs>
                <linearGradient id="paint0_linear" x1="23.9145" y1="16.7219" x2="23.9145" y2="37.3911" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#02668C"/>
                    <stop offset="1" stop-color="#00333F"/>
                </linearGradient>
                <linearGradient id="paint1_linear" x1="28.1255" y1="49.8996" x2="25.0922" y2="21.7732" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFE5BE"/>
                    <stop offset="0.58" stop-color="#FEBE1D"/>
                    <stop offset="0.69" stop-color="#FCAE1E"/>
                    <stop offset="0.92" stop-color="#F58420"/>
                    <stop offset="1" stop-color="#F37321"/>
                </linearGradient>
            </defs>
        </svg>

    );
};
