import React from 'react';

interface IProps {}

export const ItalicIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.33333 3.33325V5.83325H10.175L7.325 12.4999H5V14.9999H11.6667V12.4999H9.825L12.675 5.83325H15V3.33325H8.33333Z" fill="#748499"/>
        </svg>
    );
};
