import React from 'react';
import { ILayer } from '@common-types';
import { MediaStreamVideo } from '@components';
import './styles.scss';

interface IProps {
    layer: ILayer;
    setScreenRatio: (width: number, height: number) => void;
}

export class ScreenShareElement extends React.Component<IProps> {
    video: HTMLVideoElement;

    setVideoRef = (videoRef: React.RefObject<HTMLVideoElement>) => {
        this.video = videoRef.current;
        this.video.addEventListener('resize', this.onVideoResize);
    };

    onVideoResize = () => {
        this.props.setScreenRatio(
            this.video.videoWidth,
            this.video.videoHeight,
        );
    };

    componentWillUnmount(): void {
        this.video.removeEventListener('resize', this.onVideoResize);
    }

    render(): React.ReactNode {
        const { layer } = this.props;

        return (
            <div className="screen-share-element">
                <MediaStreamVideo
                    id={layer.id}
                    stream={layer.specificData.stream}
                    setRef={this.setVideoRef}
                />
            </div>
        );
    }
}
