import React from 'react';
import { inject, observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { AppStore, GuestStore, SimpleModeStore, StreamViewStore, UsersConferenceStore } from '@services';
import { ADMIN_SS_ID } from '@constants';
import { LogoPositionsEnum, PatternsEnum } from '@common-types';
import { getBackground, getLogoClass, getPatternClass, getPatternUsersCount, isFirefox } from '@utils';
import { StreamViewUser } from '../stream-view-user';
import { StreamViewUserWrapper } from '../stream-view-user-wrapper';
import { MediaStreamVideo } from '../../common';
import './styles.scss';

interface IProps {
    StreamViewStore?: StreamViewStore;
    UsersConferenceStore?: UsersConferenceStore;
    SimpleModeStore?: SimpleModeStore;
    GuestStore?: GuestStore;
    AppStore?: AppStore;
    className?: string;
    zoomUpdateId?: string;
    afterUpdate?: () => void;
}

interface IState {
    zoom: number;
}

@inject('StreamViewStore', 'UsersConferenceStore', 'SimpleModeStore', 'GuestStore', 'AppStore')
@observer
export class StreamView extends React.Component<IProps, IState> {
    wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
    state: IState = {
        zoom: 1,
    };

    getLogoClass = (): string => {
        return getLogoClass(this.props!.StreamViewStore.state.logoPosition);
    };

    swapUsers = (id: string, position: number) => {
        this.props.StreamViewStore.swapUsers(id, position);
    };

    getBackground = (): string => {
        return getBackground(this.props!.StreamViewStore.state.backgroundImage);
    };

    getPatternClass = (): string => {
        return getPatternClass(this.props!.StreamViewStore.state.selectedPattern);
    };

    getPatternUsersCount = (): number => {
        return getPatternUsersCount(this.props!.StreamViewStore.state.selectedPattern);
    };

    getUserStream = (userId: string): MediaStream => {
        const { isUserAuthorized } = this.props.AppStore;
        const { cameraStream, screenShareStream } = this.props.SimpleModeStore;
        const { stream, screenShareStream: guestSS, isScreenShare } = this.props.GuestStore;
        const { streams, currentUserId } = this.props.UsersConferenceStore;
        const isMainUser = currentUserId === userId;

        if (isMainUser) {
            if (isUserAuthorized) {
                return cameraStream;
            } else {
                return isScreenShare ? guestSS : stream;
            }
        } else if (userId === ADMIN_SS_ID && isUserAuthorized) {
            return screenShareStream;
        } else {
            const streamObj = streams[userId];

            if (streamObj) {
                return streamObj.isOut ? null : streamObj.stream;
            }
        }
    };

    isDndDisabled = () => {
        const { isUserAuthorized } = this.props.AppStore;

        return (
            !isUserAuthorized
        );
    };

    getUsersComponents = (): React.ReactElement[] => {
        const { state } = this.props.StreamViewStore;
        const { users, primaryColor, showNames, selectedPattern } = state;
        const enabledUsers = users.filter(({ isEnabled }) => isEnabled);
        const usersCount = this.getPatternUsersCount();
        const resultUsers = enabledUsers.slice(0, usersCount);
        const disabledDnd = this.isDndDisabled();

        return (
            resultUsers.map(({ name, id }, index : number) => {
                let stream = this.getUserStream(id);

                return stream ? (
                   <StreamViewUserWrapper key={index} index={index}>
                        <StreamViewUser
                            zoom={this.state.zoom}
                            canDrag={!disabledDnd}
                            swapUsers={this.swapUsers}
                            showNames={showNames && selectedPattern !== PatternsEnum.OneUser}
                            key={id}
                            name={name}
                            stream={stream}
                            id={id}
                            primaryColor={primaryColor}
                        />
                   </StreamViewUserWrapper>
                ) : null;
            })
        );
    };

    getAdvanceUsersComponents = (): React.ReactNode => {
        const { state } = this.props.StreamViewStore!;
        const { users } = state;

        return (
          <>
              {
                  users.map(({ id }) => {
                      const stream = this.getUserStream(id);

                      return stream ? (
                        <div className="stream-view__advance-user">
                            <MediaStreamVideo setRef={() => void 0} stream={stream} />
                        </div>
                      ) : null;
                  })
              }
          </>
        );
    };

    getClassByUsersCount = (): string => {
        const { state } = this.props.StreamViewStore!;
        const { users } = state;

        if (users.length - 1 === 1) {
            return 'one';
        } else if (users.length - 1 === 2) {
            return 'two';
        } else if (users.length - 1 < 5) {
            return 'four';
        } else if (users.length - 1 >= 5) {
            return 'eight';
        }
    };

    recalculateZoom = () => {
        if (this.wrapperRef && this.wrapperRef.current) {
            const { parentElement } = this.wrapperRef.current;
            const { offsetHeight, offsetWidth } = parentElement;

            if (offsetWidth < 1280 || offsetHeight < 720) {
                const widthRatio = offsetWidth / 1280;
                const heightRation = offsetHeight / 720;

                this.setState({ zoom: heightRation > widthRatio ? widthRatio : heightRation });
            } else {
                this.setState({ zoom: 1 });
            }
        }
    };

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        if (prevProps.zoomUpdateId !== this.props.zoomUpdateId) {
            setTimeout(() => {
                this.recalculateZoom();
            }, 200);
        }
    }

    onResize = debounce(() => {
        this.recalculateZoom();
    }, 300);

    componentDidMount(): void {
        this.recalculateZoom();
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.onResize);
    }

    getLogoPosition = (): React.CSSProperties => {
        const { logoPosition } = this.props!.StreamViewStore.state;
        let position: React.CSSProperties;
        let padding = 20;

        if (isFirefox()) {
            padding = padding * this.state.zoom;
        }

        switch (logoPosition) {
            case LogoPositionsEnum.TopRight: {
                position = {
                    top: `${padding}px`,
                    right: `${padding}px`,
                };
                break;
            }
            case LogoPositionsEnum.TopLeft: {
                position = {
                    top: `${padding}px`,
                    left: `${padding}px`,
                };
                break;
            }
            case LogoPositionsEnum.BottomLeft: {
                position = {
                    bottom: `${padding}px`,
                    left: `${padding}px`,
                };
                break;
            }
            case LogoPositionsEnum.BottomRight: {
                position = {
                    bottom: `${padding}px`,
                    right: `${padding}px`,
                };
                break;
            }
        }

        return position;
    };

    render(): React.ReactNode {
        const { zoom } = this.state;
        const { className = '' } = this.props;
        const { state, updateId } = this.props!.StreamViewStore;
        const { logoImage } = state;
        console.log('SW state', state);
        updateId;

        return (
            <DndProvider backend={HTML5Backend}>
                <div className={`stream-view ${className}`} ref={this.wrapperRef}>
                    <div className='stream-view__wrapper' style={{ background: this.getBackground() }}>
                        {
                            logoImage && (
                                <img
                                    style={{
                                        zoom,
                                        maxWidth: isFirefox() ? `${140 * zoom}px` : '140px',
                                        ...this.getLogoPosition(),
                                    }}
                                    src={logoImage}
                                    alt='logo'
                                    className={`stream-view__logo ${this.getLogoClass()}`}
                                />
                            )
                        }
                        {
                            state?.isSimpleMode
                            ? (
                                <div className={`stream-view__users-list ${this.getPatternClass()}`}>
                                    {this.getUsersComponents()}
                                </div>
                            )
                            : (
                                <div className={`stream-view__advance-users ${this.getClassByUsersCount()}`}>
                                    {this.getAdvanceUsersComponents()}
                                </div>
                            )
                        }
                    </div>
                </div>
            </DndProvider>
        );
    }
}
