import React from 'react';
import { Rnd } from 'react-rnd';
import { observer } from 'mobx-react';
import { ILayer, IScene, ITextSettings, LayerTypeEnum, TextEditClasses } from '@common-types';
import { AdvanceTopMenu } from '../top-menu';
import { TextElement } from '../text-element';
import { ImageElement } from '../image-element';
import { CameraElement } from '../camera-element';
import { ScreenShareElement } from '../screen-share-element';
import './styles.scss';

interface IProps {
    zoom: number;
    selectedScene: IScene;
    layer: ILayer;
    selectedLayer: ILayer;
    onMouseDown: (layer: ILayer) => void;
    changeLayerData: (layer: ILayer, data: any) => Promise<void>;
    saveLayerPosition: (layer: ILayer, position: { x: number; y: number; }) => Promise<void>;
    saveLayerSize: (layer: ILayer, size: { width: number; height: number; }) => Promise<void>;
    removeLayer: () => void;
    moveToTop: () => void;
    moveToBottom: () => void;
}

const defaultControlNames = {
    bottom: 'resize-control control-bottom',
    bottomLeft: 'resize-control control-bottom-left',
    bottomRight: 'resize-control control-bottom-right',
    left: 'resize-control control-left',
    right: 'resize-control control-right',
    top: 'resize-control control-top',
    topLeft: 'resize-control control-top-left',
    topRight: 'resize-control control-top-right',
};
const textHeight = 40;
const textWidth = 320;

interface IState {
    elementWidth: number;
    elementHeight: number;
    size: { width: number; height: number; };
    position: { x: number; y: number; };
    textClasses: string[];
    textSettings: ITextSettings;
}

@observer
export class AdvanceControl extends React.Component<IProps, IState> {
    state: IState = {
        elementWidth: 0,
        elementHeight: 0,
        size: { width: 0, height: 0, },
        position: { x: 0, y: 0, },
        textClasses: [],
        textSettings: {
            color: '#FFFFFF',
            fontName: 'Arial, sans-serif',
            fontSize: '24px'
        },
    };

    constructor(props: IProps) {
        super(props);
        const { layer } = props;
        const { position, size } = layer;

        if (!position && !size) {
            if (props.layer.type === LayerTypeEnum.Text) {
                const { height, width, x, y } = this.getDefault();

                this.props.layer.position = { x, y };
                this.props.layer.size = { width, height };
                this.updateNodeSize({ width, height });
                this.updateNodePosition({ x, y });
                this.state.size = { width, height };
                this.state.position = { x, y };
            }
        } else {
            this.state.position = position || { x: 0, y: 0, };
            this.state.size = size || { width: 0, height: 0, };

            if (props.layer.type === LayerTypeEnum.Text) {
                this.state.textClasses = layer.specificData?.textClasses || [];
                this.state.textSettings = layer.specificData?.textSettings || {
                    color: '#FFFFFF',
                    fontName: 'Arial, sans-serif',
                    fontSize: '24px'
                };
            }
        }

        this.setTextNodeField();
    }

    setTextNodeField = () => {
        setTimeout(() => {
            const { layer } = this.props;
            const { textClasses, textSettings } = this.state;

            if (layer.node) {
                const { node } = layer;

                if (textClasses.includes(TextEditClasses.Bold)) {
                    node.set('fontWeight', 'bold');
                } else {
                    node.set('fontWeight', 'normal');
                }

                if (textClasses.includes(TextEditClasses.Italic)) {
                    node.set('fontStyle', 'italic');
                } else {
                    node.set('fontStyle', 'normal');
                }

                if (textClasses.includes(TextEditClasses.Under)) {
                    node.set('underline', true);
                } else {
                    node.set('underline', false);
                }

                if (textClasses.includes(TextEditClasses.Strike)) {
                    node.set('linethrough', true);
                } else {
                    node.set('linethrough', false);
                }

                node.set('fill', textSettings?.color || '#ffffff');
                node.set('fontFamily', textSettings?.fontName || 'Arial, sans-serif');
                node.set('fontSize', parseInt(textSettings?.fontSize) || 24);
            }
        });
    };

    updateNodeSize = (size: { width: number; height: number }) => {
        if (this.props.layer?.node) {
            if (this.props.layer.type === LayerTypeEnum.Text) {
                this.props.layer.node.set('width', size.width);
                this.props.layer.node.set('height', size.height);
            } else {
                this.props.layer.node.scaleToWidth(size.width);
            }
        }
    };

    updateNodePosition = (position: { x: number; y: number; }) => {
        if (this.props.layer?.node) {
            this.props.layer.node.set('left', position.x);
            this.props.layer.node.set('top', position.y);
            this.props.layer.node.setCoords();
        }
    };

    getTopMenu = () => {
        const { layer, removeLayer, moveToTop, moveToBottom, selectedScene, zoom } = this.props;

        return (
            <AdvanceTopMenu
                zoom={zoom}
                textSettings={this.state.textSettings}
                textClasses={this.state.textClasses}
                selectedScene={selectedScene}
                layer={layer}
                removeLayer={removeLayer}
                moveToTop={moveToTop}
                moveToBottom={moveToBottom}
                stretchToCanvas={this.stretchToCanvas}
                makeBold={this.makeTextBold}
                makeItalic={this.makeTextItalic}
                makeUnder={this.makeTextUnder}
                makeStrike={this.makeTextStrike}
                changeFontSettings={this.changeFontSettings}
            />
        );
    };

    toggleTextClass = (textClass: TextEditClasses, afterUpdate: () => void = () => void 0) => {
        this.setState((prevState: IState) => {
            let result = prevState.textClasses.slice();

            if (result.includes(textClass)) {
                result = result.filter((item: string) => item !== textClass);
            } else {
                result.push(textClass);
            }

            this.props.layer.specificData = {
                ...this.props.layer.specificData,
                textClasses: result,
            };

            this.props.changeLayerData(this.props.layer, this.props.layer.specificData);

            return {
                textClasses: result,
            };
        }, afterUpdate);
    };

    removeTextClass = (textClass: TextEditClasses) => {
        this.setState((prevState: IState) => {
            return {
                textClasses: prevState.textClasses.filter((item: string) => item !== textClass),
            };
        }, () => {
            this.props.layer.specificData = {
                ...this.props.layer.specificData,
                textClasses: this.state.textClasses,
            };

            this.props.changeLayerData(this.props.layer, this.props.layer.specificData);
        });
    };

    makeTextBold = () => {
        this.toggleTextClass(TextEditClasses.Bold, this.setTextNodeField);
    };

    makeTextItalic = () => {
        this.toggleTextClass(TextEditClasses.Italic, this.setTextNodeField);
    };

    makeTextUnder = () => {
        this.removeTextClass(TextEditClasses.Strike);
        this.toggleTextClass(TextEditClasses.Under, this.setTextNodeField);
    };

    makeTextStrike = () => {
        this.removeTextClass(TextEditClasses.Under);
        this.toggleTextClass(TextEditClasses.Strike, this.setTextNodeField);
    };

    changeFontSettings = (settings: ITextSettings) => {
        this.setState((prevState: IState) => ({
            textSettings: {
                ...prevState.textSettings,
                ...settings,
            }
        }), () => {
            this.props.layer.specificData = {
                ...this.props.layer.specificData,
                textSettings: this.state.textSettings,
            };
            this.setTextNodeField();
            this.props.changeLayerData(this.props.layer, this.props.layer.specificData);
        });
    };

    stretchToCanvas = () => {
        const scene: HTMLDivElement = document.querySelector('.advanced-canvas-wrapper__scene');
        const { offsetWidth, offsetHeight } = scene;
        const { size } = this.state;
        const { width, height } = size;
        const isWidthMore = width > height;
        const ratio = Math.min(offsetWidth / width, offsetHeight / height);
        const resultWidth = width * ratio;
        const resultHeight = height * ratio;

        this.setState({
            size: {
                width: resultWidth,
                height: resultHeight,
            },
            position: {
                x: isWidthMore ? 0.0001 : (offsetWidth - resultWidth) / 2 || 0.0001,
                y: isWidthMore ? (offsetHeight - resultHeight) / 2 || 0.0001 : 0.0001,
            },
        }, () => {
            this.props.layer.position = this.state.position;
            this.props.layer.size = this.state.size;
            this.props.saveLayerPosition(this.props.layer, this.props.layer.position);
            this.props.saveLayerSize(this.props.layer, this.props.layer.size);
            this.updateNodeSize(this.state.size);
            this.updateNodePosition(this.state.position);
        });
    };

    getCommonProps = () => {
        const { layer, onMouseDown, selectedLayer } = this.props;

        return {
            onMouseDown: (e) => {
                e.preventDefault();
                e.stopPropagation();
                onMouseDown(layer);
            },
            className: `
                advance-control
                ${selectedLayer?.id === layer.id ? 'selected': ''}
            `,
            resizeHandleClasses: defaultControlNames,
        };
    };

    getLayerName = () => {
        const { layer, zoom } = this.props;

        return (
            <div className="advance-control__name-wrapper" style={{ zoom: 1 / (zoom || 1) }}>
                <div className="advance-control__name">
                    {layer.name}
                </div>
            </div>
        );
    };

    getSizePositionOptions = () => {
        const { zoom, layer } = this.props;
        const { lockAspectRatio } = this.getDefault();

        return {
            scale: zoom,
            size: this.state.size,
            lockAspectRatio: layer.type === LayerTypeEnum.Text ? null : lockAspectRatio,
            position: this.state.position,
            onDragStop: (e, d) => {
                this.setState({
                    position: {
                        x: d.x,
                        y: d.y,
                    },
                }, () => {
                    this.props.layer.position = this.state.position;
                    this.props.saveLayerPosition(this.props.layer, this.props.layer.position);
                    this.updateNodePosition(this.state.position);
                });
            },
            onResizeStop: (e, direction, ref, delta, position) => {
                this.setState({
                    size: {
                        width: parseInt(ref.style.width),
                        height: parseInt(ref.style.height),
                    },
                    position: {
                        x: position.x,
                        y: position.y,
                    },
                }, () => {
                    this.props.layer.position = this.state.position;
                    this.props.layer.size = this.state.size;
                    this.props.saveLayerPosition(this.props.layer, this.props.layer.position);
                    this.props.saveLayerSize(this.props.layer, this.props.layer.size);
                    this.updateNodeSize(this.state.size);
                    this.updateNodePosition(this.state.position);
                });
            },
        };
    };

    setRatio = (width: number, height: number) => {
        const { size, position } = this.state;
        const scene: HTMLDivElement = document.querySelector('.advanced-canvas-wrapper__scene');
        const { offsetWidth, offsetHeight } = scene;
        const widthRatio = offsetWidth / width;
        const heightRatio = offsetHeight / height;
        const resultRatio = heightRatio > widthRatio ? widthRatio : heightRatio;
        const resultWidth = width * resultRatio;
        const resultHeight = height * resultRatio;

        this.setState({
            elementWidth: width,
            elementHeight: height,
            position: {
                x: position.x ? position.x : offsetWidth / 2 - resultWidth / 2,
                y: position.y ? position.y : offsetHeight / 2 - resultHeight / 2,
            },
            size: {
                height: size.height ? size.height : resultHeight,
                width: size.width ? size.width : resultWidth,
            },
        }, () => {
            this.props.layer.position = this.state.position;
            this.props.layer.size = this.state.size;
            this.props.saveLayerPosition(this.props.layer, this.props.layer.position);
            this.props.saveLayerSize(this.props.layer, this.props.layer.size);
            this.updateNodeSize(this.state.size);
            this.updateNodePosition(this.state.position);
        });
    };

    getComponentByType = () => {
        const { layer, changeLayerData } = this.props;
        const { type } = layer;

        if (type === LayerTypeEnum.Text) {
            return (
                <TextElement
                    textSettings={this.state.textSettings}
                    textClasses={this.state.textClasses}
                    layer={layer}
                    changeText={(text: string) => {
                        if (layer.node) {
                            layer.node?.set('text', text.replace(/<div>/g, `\n`).replace(/<\/div>/g, ''));
                        }

                        changeLayerData(layer, { text });
                    }}
                />
            );
        } else if (type === LayerTypeEnum.Image) {
            return (
                <ImageElement layer={layer} setImgRatio={this.setRatio} />
            );
        } else if (type === LayerTypeEnum.Camera) {
            return (
                <CameraElement
                    layer={layer}
                    setCameraRation={this.setRatio}
                />
            );
        } else if (type === LayerTypeEnum.SlideShow) {
            return null;
        } else if (type === LayerTypeEnum.BrowserWindow) {
            return (
                <ScreenShareElement
                    layer={layer}
                    setScreenRatio={this.setRatio}
                />
            );
        }

        return null;
    };

    getRndProps = () => {
        const { layer } = this.props;
        let props = { ...this.getCommonProps() };

        if (
            layer.type === LayerTypeEnum.Image ||
            layer.type === LayerTypeEnum.Camera ||
            layer.type === LayerTypeEnum.BrowserWindow ||
            layer.type === LayerTypeEnum.Text ||
            layer.type === LayerTypeEnum.SlideShow
        ) {
            props = {
                ...props,
                ...this.getSizePositionOptions(),
            };
        }

        return props;
    };

    getRndComponent = () => {
        return (
            <Rnd
                {...this.getRndProps()}
            >
                {this.getTopMenu()}
                {this.getComponentByType()}
                {this.getLayerName()}
            </Rnd>
        );
    };

    getControlElement = (layer: ILayer): React.ReactNode => {
        const scene: HTMLDivElement = document.querySelector('.advanced-canvas-wrapper__scene');

        if (scene) {
            if (
                layer.type === LayerTypeEnum.Text ||
                layer.type === LayerTypeEnum.Image ||
                layer.type === LayerTypeEnum.Camera ||
                layer.type === LayerTypeEnum.BrowserWindow ||
                layer.type === LayerTypeEnum.SlideShow
            ) {
                return this.getRndComponent();
            }
        }

        return null;
    };

    getDefault = (): { width: number; height: number; x: number; y: number; lockAspectRatio?: number; } => {
        const { layer } = this.props;
        const { type } = layer;
        const scene: HTMLDivElement = document.querySelector('.advanced-canvas-wrapper__scene');
        const { offsetWidth, offsetHeight } = scene;

        if (type === LayerTypeEnum.Text) {
            return {
                height: textHeight,
                width: textWidth,
                x: offsetWidth / 2 - textWidth / 2,
                y: offsetHeight / 2 - textHeight / 2,
            };
        } else if (
            type === LayerTypeEnum.Image ||
            type === LayerTypeEnum.Camera ||
            type === LayerTypeEnum.BrowserWindow
        ) {
            const { elementHeight, elementWidth } = this.state;

            return {
                lockAspectRatio: elementWidth / elementHeight,
                height: 0,
                width: 0,
                x: 0,
                y: 0,
            };
        } else if (type === LayerTypeEnum.SlideShow) {
            return null;
        }
    };

    render(): React.ReactNode {
        const { layer } = this.props;

        return this.getControlElement(layer);
    }
}
