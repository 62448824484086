import React from 'react';

interface IProps {}

export const TwitchColorIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="4" fill="#6441A5"/>
            <path d="M11 14.872V34.3473H17.4988V38H21.0404L24.5821 34.3447H29.9192L37 27.0545V10H12.7708L11 14.872ZM15.136 12.4335H34.6373V25.8251L30.4988 30.0862H24L20.4559 33.7338V30.0862H15.136V12.4335Z" fill="white"/>
            <path d="M21.6372 17.3054H23.9999V24.6083H21.6372V17.3054Z" fill="white"/>
            <path d="M28.1334 17.3054H30.4986V24.6083H28.1334V17.3054Z" fill="white"/>
        </svg>
    );
};