import React from 'react';
import { inject, observer } from 'mobx-react';
import { ShareWebCamConferenceStore } from '@services';
import './style.scss';

interface IProps {
    ShareWebCamConferenceStore?: ShareWebCamConferenceStore;
}

@inject('ShareWebCamConferenceStore')
@observer
export class SharedWebCamPage extends React.Component<IProps> {
    videoRef: React.RefObject<HTMLVideoElement> = React.createRef();

    componentDidMount(): void {
        const paramsArr = location.search.slice(1).split('&');
        let shareId = '';

        paramsArr.forEach((param: string) => {
            const [name, value] = param.split('=');

            if (name.toLowerCase() === 'shareid' && value) {
                shareId = value;
            }
        });

        if (shareId) {
            this.props.ShareWebCamConferenceStore.init();
            this.props.ShareWebCamConferenceStore.join(
                shareId,
                this.videoRef.current,
            );

            this.videoRef.current.addEventListener('resize', this.setVideoFit);
            window.addEventListener('resize', this.setVideoFit);
        }
    }

    setVideoFit = () => {
        if (this.videoRef && this.videoRef.current) {
            const { videoHeight, videoWidth } = this.videoRef.current;

            if (
                (videoWidth > videoHeight && window.innerWidth > window.innerHeight) ||
                (videoHeight > videoWidth && window.innerHeight > window.innerWidth)
            ) {
                this.videoRef.current.style.objectFit = 'cover';
            } else {
                this.videoRef.current.style.objectFit = 'contain';
            }
        }
    };

    componentWillUnmount(): void {
        try {
            const { remotePeerConnection, remoteCameraStream } = this.props.ShareWebCamConferenceStore;
            window.removeEventListener('resize', this.setVideoFit);
            this.videoRef.current.removeEventListener('resize', this.setVideoFit);

            if (remotePeerConnection) {
                remotePeerConnection.destroy();
            }

            if (remoteCameraStream) {
                remoteCameraStream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    render(): React.ReactNode {
        return (
            <div className="shared-web-cam-page">
                <video
                    className="shared-web-cam-page__video"
                    autoPlay={true}
                    ref={this.videoRef}
                />
            </div>
        );
    }
}
